import ACTION_ITEMS from '../actionItems';

export function setAlert(params) {
  return (dispatch) => {
    dispatch({
      type: ACTION_ITEMS.GLOBAL.ALERT.SET,
      payload: params,
    });
  };
}
