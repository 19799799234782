import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useCommonStyles } from 'common/common.style';
import { useStyles } from '../../PsychometricAssessmentResults.style';

const StrongestScores = ({ behavioural }) => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();

  return (
    <Grid item xs={12} className={classesCommon.noLefRightPadding}>
      <Paper className={classes.paper}>
        <Grid container>
          <Typography
            variant="h6"
            className={classes.subheader}
            align={isMobile ? 'center' : 'left'}
          >
            Your strongest 5 behaviours
          </Typography>
          {behavioural.map((topic, index) => {
            const { name, description, group } = topic;
            return (
              <Grid item xs={12} key={`strongestScore${index}`}>
                <Typography variant="subtitle1">
                  <b>
                    {group.name}: {name}
                  </b>
                </Typography>
                <Typography variant="subtitle2" className={classes.description}>
                  {description}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default StrongestScores;
