/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { fetchAllProjects } from '../../actions/fetchAllProjects';
import { fetchAllPositions } from '../../actions/fetchAllPositions';
import { fetchAllPositionSkills } from '../../actions/fetchAllPositionSkills';

const LoadPositionModal = (props) => {
  const {
    open,
    handleClose,
    projects,
    positions,
    skills,
    setRoleId,
    setDisciplineId,
    setSelectedFilters,
    setSelectedPosition,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [positionId, setPositionId] = useState('');

  useEffect(() => {
    props.fetchAllProjects();
  }, []);

  useEffect(() => {
    if (projectId !== '') {
      props.fetchAllPositions(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (positionId !== '') {
      props.fetchAllPositionSkills(positionId);
    }
  }, [positionId]);

  const handleAddPosition = async () => {
    setIsLoading(true);

    const position = positions.filter((e) => e.id === positionId).shift();

    setRoleId(position.roleId);
    setDisciplineId(position.disciplineId);
    setSelectedPosition(position);

    setSelectedFilters(
      skills.map((skill) => {
        return {
          topic: skill.disciplineTarget,
          lowest: skill.lowest.toString(),
          desired: skill.desired.toString(),
          highest: skill.highest.toString(),
        };
      }),
    );

    setIsLoading(false);
    handleClose();
  };

  const handleOnChange = (setter) => (event) => {
    setter(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="projectId-label">Project</InputLabel>
          <Select
            id="projectId"
            labelId="projectId-label"
            value={projectId}
            onChange={handleOnChange(setProjectId)}
            label="Project"
            fullWidth
          >
            {projects.map(({ name: label, id: value }, index) => (
              <MenuItem key={`projectId-option-${index}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="positionId-label">Position</InputLabel>
          <Select
            id="positionId"
            labelId="positionId-label"
            value={positionId}
            onChange={handleOnChange(setPositionId)}
            label="Position"
            disabled={projectId === ''}
            fullWidth
          >
            {positions.map(({ name: label, id: value }, index) => (
              <MenuItem key={`positionId-option-${index}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleAddPosition}>
          {isLoading ? <CircularProgress size={20} /> : 'Load'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { candidateSearch } = state;
  const { projects, positions, skills } = candidateSearch;
  return {
    projects,
    positions,
    skills,
  };
};

export default connect(mapStateToProps, {
  fetchAllProjects,
  fetchAllPositions,
  fetchAllPositionSkills,
})(withContext(LoadPositionModal));
