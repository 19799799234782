import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { Box, TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '1rem',
    gap: theme.spacing(2),
  },
}));

export const SortableTableSearchable = ({ columnName, items, onSearch }) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (searchValue) => {
    if (searchValue === '') {
      onSearch(items);
    } else {
      const filteredData = items.filter((item) => {
        const index = item.findIndex((elem) => elem.id === columnName);
        return item[index].value
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      onSearch(filteredData);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeSearchText = useCallback(
    debounce((value) => {
      handleSearch(value);
    }, 1000),
    [handleSearch],
  );

  const handleChangeText = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    debouncedChangeSearchText(value);
  };

  return (
    <Box className={classes.container}>
      <TextField
        label="Search Name"
        variant="outlined"
        value={searchTerm}
        onChange={handleChangeText}
        fullWidth
      />
    </Box>
  );
};
