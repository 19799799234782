const resolveAvatar = (passportPhoto) => {
  let avatar;
  if (passportPhoto && passportPhoto.indexOf('http') > -1) {
    avatar = passportPhoto;
  } else if (passportPhoto) {
    avatar = `/passport/${passportPhoto}`;
  }

  return avatar;
};

export default resolveAvatar;
