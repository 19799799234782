import ACTION_ITEMS from './actionItems';

const initialState = {
  candidates: undefined,
  question: null,
  count: 0,
  selectedCandidate: null,
  remainingTime: 0,
};

export const psychometricReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.PSYCHOMETRIC.CANDIDATES.SET: {
      newState = { ...state, candidates: action.payload };
      break;
    }

    case ACTION_ITEMS.PSYCHOMETRIC.QUESTION.SET: {
      newState = {
        ...state,
        question: action.payload.question,
        count: action.payload.count,
      };
      break;
    }

    case ACTION_ITEMS.PSYCHOMETRIC.SELECTED_CANDIDATE.SET: {
      newState = { ...state, selectedCandidate: action.payload };
      break;
    }

    case ACTION_ITEMS.PSYCHOMETRIC.REMAINING_TIME.SET: {
      newState = { ...state, remainingTime: action.payload };
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
