import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  legendWrapper: {
    paddingLeft: '0px',
    marginLeft: '0px',
  },
  legend: {
    display: 'inline',
    marginRight: '0.5em',
    padding: '5px',
    fontSize: theme.typography.fontSize * 0.75,
  },
  legendSelf: {
    backgroundColor: theme.palette.levels.self,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.levels.self,
    },
  },
  legendAchieved: {
    backgroundColor: theme.palette.levels.achieved,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.levels.achieved,
    },
  },
  row: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginBottom: theme.spacing(2),
    },
  },
  button: {
    borderRadius: '50%',
    cursor: 'disabled',
    border: '1px solid white !important',
    height: '30px',
    width: '30px',
    lineHeight: '25px',
    marginTop: '0px',
    color: '#cbcbcb',
    userSelect: 'none',
  },
  isSelf: {
    background: theme.palette.levels.self,
    color: theme.palette.common.white,
  },
  isTech: {
    background: theme.palette.levels.achieved,
    color: theme.palette.common.white,
  },
  isSelfAndTech: {
    background: `linear-gradient(120deg, ${theme.palette.levels.self} 50%, ${theme.palette.levels.achieved} 50%)`,
    color: theme.palette.common.white,
  },
  badge: {
    padding: '0px 5px',
    '& span': {
      backgroundColor: 'black',
      color: 'white',
      margin: '0px 5px',
    },
  },
}));
