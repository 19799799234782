import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SkillCard from './components/SkillCard/SkillCard';
import AddBulkSkillModal from '../AddBulkSkillModal/AddBulkSkillModal';
import EditSkillModal from '../EditSkillModal/EditSkillModal';

import { fetchAllPositionSkills } from '../../actions/fetchAllPositionSkills';

import { useStyles } from './SkillList.style';

const SkillList = (props) => {
  const classes = useStyles();

  const { skills, selectedPosition, selectedSkill } = props;

  const [addBulkSkillModal, setAddBulkSkillModal] = useState(false);

  useEffect(() => {
    if (selectedPosition) {
      props.fetchAllPositionSkills(selectedPosition.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPosition]);

  if (!selectedPosition) return null;

  const handleAddBulkSkill = () => {
    setAddBulkSkillModal(true);
  };

  const handleAddBulkSkillModalClose = () => {
    setAddBulkSkillModal(false);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5">
        Skills
        <Fab
          className={classes.add}
          color="primary"
          aria-label="add"
          size="small"
          onClick={handleAddBulkSkill}
        >
          <AddIcon />
        </Fab>
      </Typography>
      <Card className={classes.listContainer}>
        <CardContent className={classes.cardContent}>
          {skills.map((skill) => {
            const isSelected = !!(
              selectedSkill && selectedSkill.id === skill.id
            );
            return (
              <SkillCard key={skill.id} skill={skill} selected={isSelected} />
            );
          })}
        </CardContent>
      </Card>
      <AddBulkSkillModal
        open={addBulkSkillModal}
        handleClose={handleAddBulkSkillModalClose}
      />
      <EditSkillModal />
    </div>
  );
};

const mapStateToProps = ({
  projectBuilder: { skills, selectedPosition, selectedSkill },
}) => {
  return { skills, selectedPosition, selectedSkill };
};

export default connect(mapStateToProps, { fetchAllPositionSkills })(SkillList);
