import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';

const Copyright = ({ className }) => (
  <div className={className}>
    <Typography variant="body2" color="textSecondary" align="center">
      © {new Date().getFullYear()} - Competas {'.'}
    </Typography>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      mt={1}
      mb={1}
    >
      <Typography variant="caption" color="textSecondary" align="center">
        <Link color="inherit" href="/privacy-policy">
          Privacy Policy
        </Link>{' '}
        |
        <Link color="inherit" href="/data-protection-policy">
          {' '}
          Data Protection Policy
        </Link>{' '}
        |
        <Link color="inherit" href="/data-retention-policy">
          {' '}
          Data Retention Policy
        </Link>
      </Typography>
    </Box>
  </div>
);

export default Copyright;
