import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Typography, Paper } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { useStyles } from './DaysRemaining.style';

const DaysRemaining = ({ bookedDate }) => {
  const classes = useStyles();
  const remainingDate = moment(bookedDate).diff(moment(), 'days');
  const labelRemainingDate = `${remainingDate} day${
    remainingDate > 1 ? 's' : ''
  }`;

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <div className={classes.iconContainer}>
            <DateRangeIcon className={classes.icon} />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h3">
            {labelRemainingDate.toUpperCase()}
          </Typography>
          <Typography variant="h6" component="p" color="textSecondary">
            Until your assessment
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

DaysRemaining.propTypes = {
  bookedDate: PropTypes.string.isRequired,
};

export default DaysRemaining;
