import { createContext, useState } from 'react';

export const PermissionsModalContext = createContext({
  showPermissionsModal: false,
  selectedCandidate: null,
  handleSetSelectCandidate: () => {},
  handleShowPermissionsModal: () => {},
  handleClosePermissionsModal: () => {},
});

export const PermissionsModalProvider = ({ children }) => {
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const handleSetSelectCandidate = (user) => setSelectedCandidate(user);
  const handleShowPermissionsModal = () => setShowPermissionsModal(true);
  const handleClosePermissionsModal = () => setShowPermissionsModal(false);

  return (
    <PermissionsModalContext.Provider
      value={{
        showPermissionsModal,
        selectedCandidate,
        handleSetSelectCandidate,
        handleShowPermissionsModal,
        handleClosePermissionsModal,
      }}
    >
      {children}
    </PermissionsModalContext.Provider>
  );
};
