const ACTION_ITEMS = {
  OPTIONS: {
    SET: 'OPTIONS_SET',
  },
  GLOBAL: {
    CONFIG: {
      SET: 'GLOBAL_CONFIG_SET',
    },
    UNDER_CONSTRUCTION: {
      SET: 'GLOBAL_UNDER_CONSTRUCTION_SET',
    },
    ALERT: {
      SET: 'GLOBAL_ALERT_SET',
    },
  },
};

export default ACTION_ITEMS;
