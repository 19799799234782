import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash';
import ACTION_ITEMS from '../actionItems';

export const fetchPurchaseHistory = (sponsorId) => (dispatch) => {
  Swal.fire({
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
  axios
    .get(`/sponsor/${sponsorId}/purchase-history`)
    .then((response) => {
      Swal.close();
      const { status, purchaseHistory } = response.data;
      if (status === 'SUCCESS' && !_.isNil(purchaseHistory)) {
        dispatch(setPurchaseHistory(purchaseHistory));
      }
    })
    .catch((error) => {
      Swal.close();
      const { data } = error.response;
      if (data && data.status === 'ERROR') {
        Swal.fire({
          title: 'Error!',
          text: data.status_message,
          icon: 'error',
          confirmButtonColor: '#3f51b5',
        });
      }
    });
};

function setPurchaseHistory(purchaseHistory) {
  return {
    type: ACTION_ITEMS.ASSESSMENT_PURCHASE.PURCHASE_HISTORY.SET,
    payload: purchaseHistory,
  };
}
