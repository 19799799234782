import React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './Legends.style';
import resource from './Legends.resource';

const LEVEL_DESCRIPTIONS = [
  {
    title: resource.LEVEL0.TITLE,
    level: 0,
    description: resource.LEVEL0.DESCRIPTION,
  },
  {
    title: resource.LEVEL1.TITLE,
    level: 1,
    description: resource.LEVEL1.DESCRIPTION,
  },
  {
    title: resource.LEVEL2.TITLE,
    level: 2,
    description: resource.LEVEL2.DESCRIPTION,
  },
  {
    title: resource.LEVEL3.TITLE,
    level: 3,
    description: resource.LEVEL3.DESCRIPTION,
  },
  {
    title: resource.LEVEL4.TITLE,
    level: 4,
    description: resource.LEVEL4.DESCRIPTION,
  },
  {
    title: resource.LEVEL5.TITLE,
    level: 5,
    description: resource.LEVEL5.DESCRIPTION,
  },
];

const LegendItem = ({ legend }) => {
  const { title, level, description } = legend;
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <span className={clsx(classes.icons, classes[`level${level}`])}>
            {level}
          </span>
        </Grid>
        <Grid item xs={11}>
          <Typography component="p" variant="subtitle2">
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Legends = () => {
  return (
    <Grid container>
      {LEVEL_DESCRIPTIONS.map((legend) => (
        <LegendItem key={`legend-${legend.level}`} legend={legend} />
      ))}
    </Grid>
  );
};

export default Legends;
