/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import CustomTextField from 'common/components/CustomTextField';
import CustomSelect from 'common/components/CustomSelect';
import CustomDateTimePicker from 'common/components/CustomDateTimePicker';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { OPTIONS_TIMEZONE } from 'common/options';
import { submitBooking } from './actions/submitBooking';

const BookingModal = (props) => {
  const { token, open, refresh, handleClose } = props;
  const [formValues, setFormValues] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const onFormSubmit = (values) => {
    setFormValues({
      assignmentId: token.assignment.id,
      timezone: values.timezone,
      location: values.location,
      bookedDate: values.bookedDate,
      specialRequirements: values.specialRequirements,
    });
    setOpenConfirm(true);
  };

  const handleConfirmYes = () => {
    setOpenConfirm(false);
    handleClose();
    props.submitBooking(formValues, () => refresh());
  };
  const handleConfirmClose = () => setOpenConfirm(false);

  let fullName = '';
  if (!_.isNil(token) && !_.isNil(token.assignment)) {
    const { firstName, lastName } = token.assignment.candidate.user;
    fullName = `${firstName} ${lastName}`;
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            name: fullName,
            timezone: '',
            bookedDate: '',
          }}
          onSubmit={onFormSubmit}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            timezone: Yup.string().required('Required'),
            bookedDate: Yup.date().required('Required'),
          })}
        >
          {(p) => {
            const {
              values,
              touched,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            } = p;
            const propsToPass = {
              values,
              touched,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
            };

            return (
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                  BOOK AN ASSESSMENT
                  <Typography
                    variant="subtitle2"
                    component="p"
                    color="textSecondary"
                  >
                    It may take COMPETAS up to 24 hours to confirm your booking.
                    Weekend bookings and outside of UK standard office hours may
                    not be accepted
                  </Typography>
                </DialogTitle>
                <DialogContent style={{ height: '170px' }}>
                  <Grid container spacing={4}>
                    <CustomTextField
                      id="name"
                      labelText="Candidate Name"
                      disabled
                      {...propsToPass}
                    />
                    <CustomSelect
                      id="timezone"
                      labelText="Timezone"
                      options={OPTIONS_TIMEZONE}
                      {...propsToPass}
                    />
                    <CustomDateTimePicker
                      id="bookedDate"
                      labelText="Date - Time"
                      disablePast
                      showTodayButton
                      {...propsToPass}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                  >
                    Book assessment
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ConfirmationModal
        open={openConfirm}
        text="You won't be able to reverse this action."
        handleYes={handleConfirmYes}
        handleClose={handleConfirmClose}
      />
    </>
  );
};

BookingModal.propTypes = {
  token: PropTypes.object,
  open: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitBooking: PropTypes.func.isRequired,
};

BookingModal.defaultProps = {
  token: null,
};

export default connect(null, { submitBooking })(BookingModal);
