/* eslint-disable no-unused-vars */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setSelectedTeamId } from 'components/Dashboard/components/TeamViewer/actions/setSelectedTeamId';
import { useStyles } from './TeamSelector.style';
import { initializeGodView } from '../GodViewModal/actions/initializeGodView';
import { clearGodView } from '../../actions/clearGodView';

export const TeamSelector = (props) => {
  const classes = useStyles();

  const { teams, selectedTeamId, isTeamMasquerading, masqueradingFromTeam } =
    props;
  const id = 'team-select';

  useEffect(() => {
    if (selectedTeamId !== '') {
      const userTeam = teams.find((t) => t.team.id === selectedTeamId);
      props.initializeGodView(userTeam.team.sponsor.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeamId]);

  const handleChange = (e) => {
    props.setSelectedTeamId(e.target.value);
  };

  const options = teams.map((t) => ({
    label: t.team.name,
    value: t.team.id,
  }));

  if (isTeamMasquerading && masqueradingFromTeam) {
    return (
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel
          shrink
          className={classes.label}
          htmlFor={`outlined-${id}-always-notched`}
        >
          Team
        </InputLabel>
        <Select
          id={id}
          labelId={`${id}-label`}
          value={1}
          onChange={handleChange}
          label="Team"
          className={classes.select}
          inputProps={{
            classes: {
              icon: classes.icon,
              root: classes.root,
            },
          }}
          disabled
          autoWidth
        >
          <MenuItem value={1}>{masqueradingFromTeam.name}</MenuItem>
        </Select>
      </FormControl>
    );
  }

  if (options.length === 0) return null;

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel
        className={classes.label}
        htmlFor={`outlined-${id}-always-notched`}
      >
        Team
      </InputLabel>
      <Select
        id={id}
        labelId={`${id}-label`}
        value={selectedTeamId}
        onChange={handleChange}
        label="Team"
        className={classes.select}
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.root,
          },
          'aria-label': 'Without label',
        }}
        autoWidth
      >
        {options.map(({ label, value }, index) => (
          <MenuItem key={`${id}-option-${index}`} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

TeamSelector.defaultProps = {
  teams: [],
};

TeamSelector.propTypes = {
  teams: PropTypes.array,
};

const mapStateToProps = ({ dashboard, teamViewer }) => ({
  teams: dashboard.userInfo.teams,
  selectedTeamId: teamViewer.selectedTeamId,
  isTeamMasquerading: dashboard.isTeamMasquerading,
  masqueradingFrom: dashboard.masqueradingFrom,
  masqueradingFromTeam: dashboard.masqueradingFromTeam,
});

const mapDispatchToProps = {
  setSelectedTeamId,
  initializeGodView,
  clearGodView,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamSelector);
