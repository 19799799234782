/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import clsx from 'clsx';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { setSelectedSkill } from '../../actions/setSelectedSkill';
import { useStyles } from '../AddBulkSkillModal/components/DisciplineTarget/DisciplineTarget.style';
import { updatePositionSkill } from '../../actions/updatePositionSkill';

const MIN_LEVEL = 1;
const MAX_LEVEL = 5;
const LEVELS = [1, 2, 3, 4, 5];

const LevelGroup = ({ label, selectedLevel, allowed, onClick }) => {
  const classes = useStyles();

  return (
    <Grid
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px',
      }}
      item
      xs={12}
    >
      <Typography variant="h6" align="center" style={{ lineHeight: '40px' }}>
        {label}
      </Typography>
      <div style={{ display: 'flex', marginLeft: '25px' }}>
        {LEVELS.map((level) => {
          const isSelected = selectedLevel === level;
          return (
            <button
              key={`area-of-expertise-${level}`}
              type="button"
              className={clsx(
                classes.level,
                classes[`level${level}`],
                isSelected && classes[`level${level}Selected`],
              )}
              disabled={allowed.indexOf(level) === -1}
              onClick={() => onClick(level)}
            >
              {level}
            </button>
          );
        })}
      </div>
    </Grid>
  );
};

const EditSkillModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [lowest, setLowest] = useState('');
  const [desired, setDesired] = useState('');
  const [highest, setHighest] = useState('');

  const { selectedPosition, selectedSkill } = props;

  useEffect(() => {
    if (!isNil(selectedSkill)) {
      setLowest(selectedSkill.lowest);
      setDesired(selectedSkill.desired);
      setHighest(selectedSkill.highest);
    }
  }, [selectedSkill]);

  if (isNil(selectedSkill)) return null;

  const { disciplineTarget } = selectedSkill;

  const handleClickBulkAdd = async () => {
    setIsLoading(true);

    await props.updatePositionSkill(selectedPosition.id, selectedSkill.id, {
      disciplineTargetId: disciplineTarget.id,
      lowest,
      desired,
      highest,
    });

    setIsLoading(false);
    handleClose();
  };

  const handleChangeDesired = (selectedLevel) => {
    setDesired(selectedLevel);
    setLowest(
      selectedLevel - 1 >= MIN_LEVEL ? selectedLevel - 1 : selectedLevel,
    );
    setHighest(
      selectedLevel + 1 <= MAX_LEVEL ? selectedLevel + 1 : selectedLevel,
    );
  };

  const handleClose = () => {
    props.setSelectedSkill(null);
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-edit-skill"
    >
      <DialogContent>
        <Typography
          variant="h4"
          align="center"
          style={{ marginBottom: '10px' }}
        >
          {disciplineTarget.name}
        </Typography>
        <LevelGroup
          label="Lowest"
          selectedLevel={lowest}
          allowed={LEVELS.slice(0, desired - 1)}
          onClick={setLowest}
        />
        <LevelGroup
          label="Desired"
          selectedLevel={desired}
          allowed={LEVELS}
          onClick={handleChangeDesired}
        />
        <LevelGroup
          label="Highest"
          selectedLevel={highest}
          allowed={LEVELS.slice(desired, 5)}
          onClick={setHighest}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickBulkAdd}
        >
          {isLoading ? <CircularProgress size={20} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ projectBuilder }) => {
  const { selectedPosition, selectedSkill } = projectBuilder;

  return {
    selectedPosition,
    selectedSkill,
  };
};

export default connect(mapStateToProps, {
  setSelectedSkill,
  updatePositionSkill,
})(EditSkillModal);
