import axios from 'axios';

export function validateSponsorCode(sponsorCode, resolve) {
  return async () => {
    try {
      await axios.get(`/user/sponsor-code`, {
        params: {
          sponsorCode,
        },
      });
      resolve(true);
    } catch (error) {
      resolve(false);
    }
  };
}
