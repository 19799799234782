import React from 'react';
import { FormControlLabel } from '@material-ui/core';

import IOSSwitch from '../IOSSwitch';
import { useStyles } from './Checkbox.style';

export default function Checkbox(props) {
  const classes = useStyles();

  const { label, helpText, labelPlacement, checked, onChange } = props;

  return (
    <div>
      <FormControlLabel
        className={classes.label}
        control={
          <IOSSwitch
            size="small"
            color="primary"
            checked={checked || false}
            onChange={onChange}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {helpText && <p className={classes.helpText}>{helpText}</p>}
    </div>
  );
}
/* eslint-enable react/jsx-props-no-spreading */
