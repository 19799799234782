import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { deleteProject } from 'components/Dashboard/components/ProjectBuilder/actions/deleteProject';
import { setSelectedProject } from 'components/Dashboard/components/ProjectBuilder/actions/setSelectedProject';
import { useStyles } from './ProjectCard.style';

const ProjectCard = (props) => {
  const classes = useStyles();

  const { project, selected } = props;
  const { id, name, description } = project;

  const handleClickDelete = () => {
    Swal.fire({
      text: 'Are you sure you want to delete this project?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        props.deleteProject(id);
      }
    });
  };

  const handleClickSelectedProject = () => {
    props.setSelectedProject(selected ? null : project);
  };

  return (
    <Card
      className={clsx(classes.root, selected && classes.selected)}
      onClick={handleClickSelectedProject}
    >
      <CardContent>
        <div className={classes.title}>
          <Typography variant="subtitle2">{name}</Typography>
          <Tooltip title={`Delete ${name}`} aria-label="delete">
            <DeleteIcon onClick={handleClickDelete} color="primary" />
          </Tooltip>
        </div>
        <Typography variant="subtitle2">{description}</Typography>
      </CardContent>
    </Card>
  );
};

export default connect(null, { deleteProject, setSelectedProject })(
  ProjectCard,
);
