import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';

const PsychometricDrawerItem = ({ show, selected, onClick }) => {
  if (!show) return null;

  const handleClick = () => {
    onClick(PsychometricDrawerItem.pathname);
  };

  return (
    <ListItem button selected={selected} onClick={handleClick}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Psychometric Evaluations" />
    </ListItem>
  );
};

PsychometricDrawerItem.propTypes = {
  show: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

PsychometricDrawerItem.defaultProps = {
  show: true,
  selected: false,
};

PsychometricDrawerItem.pathname = '/psychometric';

export default PsychometricDrawerItem;
