import axios from 'axios';
import { push } from 'connected-react-router';

export function clearGodView() {
  return (dispatch) => {
    axios
      .post(`/masquerade/clear`)
      .then(() => {
        dispatch(push('/'));
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
