import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CssBaseline, Grid, Paper, Typography } from '@material-ui/core';
import logo from 'assets/images/logoDarkBg.png';
import { useStyles } from './ForgotPassword.style';
import ResetForm from './components/ResetForm';
import PasswordForm from './components/PasswordForm';

const ForgotPassword = (props) => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.logoWrapper}>
            <img alt="COMPETAS" src={logo} className={classes.logo} />
          </div>
          <Typography component="h1" variant="h5">
            Reset your Competas password
          </Typography>
          <Switch>
            <Route
              path="/forgot-password"
              exact
              component={() => (
                <ResetForm userEmail={props.location.userEmail} />
              )}
            />
            <Route
              path="/forgot-password/enter/:userId/:token"
              component={PasswordForm}
            />
          </Switch>
        </div>
      </Grid>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
    </Grid>
  );
};

export default ForgotPassword;
