import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './rootReducer';
import { history } from './history';

const middleware = routerMiddleware(history);

const store = createStore(
  createRootReducer(history),
  applyMiddleware(middleware, thunkMiddleware),
);

export default store;
