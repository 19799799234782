import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import './SingleSlider.css';

const CompetasSlider = withStyles({
  root: {
    color: '#2E5BFF',
    height: 8,
  },
  thumb: {
    height: 8,
    width: 44,
    backgroundColor: '#2E5BFF',
    marginTop: -6,
    marginLeft: -22,
    borderRadius: 0,
  },
  track: {
    width: 6,
    height: 8,
    borderRadius: 8,
  },
  rail: {
    width: 17,
    height: 8,
    borderRadius: 8,
  },
})(Slider);

const SingleSlider = (props) => {
  const { index, levels, topic, filter, updateFilter, removeFilter } = props;

  // const convertFilter = (filter) => {
  //   // set ranges
  //   const oldRange = [1, 5];
  //   const newRange = [0, 100];

  //   // create values array from filter prop
  //   const values = [filter.lowest, filter.desired, filter.highest];

  //   // for each value convert the range to the 0-100 scale from 1-5 scale and add to new array
  //   const newScale = [];
  //   for (let i = 0; i < values.length; i++) {
  //     let newScaleValue =
  //       ((values[i] - oldRange[0]) * (newRange[1] - newRange[0])) /
  //         (oldRange[1] - oldRange[0]) +
  //       newRange[0];
  //     newScaleValue = Math.round(newScaleValue * 10) / 10;
  //     newScale.push(newScaleValue);
  //   }

  //   return newScale;
  // };

  const valuetext = (value) => `${value}`;

  const handleChange = (event, value) => updateFilter(event, value, topic.id);

  const handleRemoveFilter = (event) => removeFilter(event, topic.id);

  const containerClass = clsx(
    'single-filter-container',
    `filter-level-${topic.maxLevel}`,
  );

  return (
    <div className="single-filter">
      <div className={containerClass}>
        <IconButton className="remove-filter" onClick={handleRemoveFilter}>
          <CloseIcon />
        </IconButton>
        <CompetasSlider
          orientation="vertical"
          value={filter}
          aria-labelledby="vertical-slider"
          getAriaValueText={valuetext}
          onChange={handleChange}
          marks={levels}
          step={null}
        />

        <div className="single-slider__footer">
          <h3>
            {index}. {topic.name}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default SingleSlider;
