import axios from 'axios';
import { setAlert } from 'common/actions/setAlert';

export function toggleVisibility(candidateId, visible) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/candidate/${candidateId}/visibility`, {
        visible,
      });

      const { status } = response.data;

      if (status === 'SUCCESS') {
        setAlert({
          open: true,
          message: `Successfully set visibility`,
          type: 'success',
        })(dispatch);
      }
    } catch (error) {
      console.error(error);
    }
  };
}
