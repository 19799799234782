import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './HelperSection.style';

const HelperItem = ({ info: { title, image, descriptions }, align }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" align="center">
          {title || 'Helper Section'}
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <img className={classes.image} src={image} alt="helper" />
      </Grid>
      {descriptions &&
        descriptions.map((description, index) => {
          return (
            <Grid key={`helper-section-${index}`} item xs={12}>
              <Typography variant="subtitle2" align={align}>
                {description}
              </Typography>
              <br />
            </Grid>
          );
        })}
      <Grid item xs={12} style={{ marginBottom: '40px' }} />
    </>
  );
};

const HelperSection = ({ info, align }) => {
  let elems;
  if (_.isArray(info)) {
    elems = info.map((e, i) => <HelperItem key={`helperItem_${i}`} info={e} />);
  } else {
    elems = <HelperItem info={info} align={align} />;
  }

  return <Grid container>{elems}</Grid>;
};

HelperSection.propTypes = {
  info: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default HelperSection;
