import axios from 'axios';
import { push } from 'connected-react-router';
import Swal from 'sweetalert2';

export function verifyEmail(candidateId) {
  return (dispatch) => {
    axios
      .put('/candidate/verify', { candidateId })
      .then(() => {

      })
      .catch((error) => {
        const { data } = error.response;
        if (data && data.status === 'ERROR') {
          Swal.fire({
            title: 'Error!',
            text: data.status_message,
            icon: 'error',
            confirmButtonColor: '#3f51b5',
          }).then(() => {
            dispatch(push('/login'));
          });
        }
      });
  };
}
