import _ from 'lodash';
import ACTION_ITEMS from './actionItems';

const initialState = {
  users: null,
  companies: [],
  requestedRequests: [],
  acceptedRequests: [],
  rejectedRequests: [],
  selectedRequest: null,
  selectedCandidate: null,
  projects: [],
  groups: [],
  teams: [],
  selectedGroup: null,
  selectedTeam: null,
  selectedTeamId: '',
};

/* eslint-disable no-case-declarations */
export const teamViewerReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.TEAM_VIEWER.USERS.SET:
      newState = { ...state, users: action.payload };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.USERS.ADD:
      const newUsers = [...state.users, action.payload];
      newState = { ...state, users: newUsers };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.SELECTED_CANDIDATE.SET:
      newState = { ...state, selectedCandidate: action.payload };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.REQUESTS.SET:
      newState = {
        ...state,
        requestedRequests: action.payload.filter(
          (request) => request.feedback === 0,
        ),
        acceptedRequests: action.payload.filter(
          (request) => request.feedback === 1,
        ),
        rejectedRequests: action.payload.filter(
          (request) => request.feedback === 2,
        ),
      };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.REQUESTS.UPDATE_LIST:
      const { listName, requests } = action.payload;

      newState = {
        ...state,
        [listName]: requests,
      };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.COMPANIES.ADD:
      const newCompanies = [...state.companies, action.payload];
      newState = { ...state, companies: newCompanies };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.PROJECTS.ADD:
      const newProjects = [...state.projects, action.payload];
      newState = { ...state, projects: newProjects };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.SELECTED_REQUEST.SET:
      newState = { ...state, selectedRequest: action.payload };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.REQUESTS.ACCEPT:
      const request = action.payload;

      let list = '';
      switch (request.status) {
        case 'accepted':
          list = 'acceptedRequests';
          break;
        case 'rejected':
          list = 'rejectedRequests';
          break;

        default:
          list = 'requestedRequests';
      }

      const updatedOriginList = state[list].filter(
        (req) => req.id !== request.id,
      );
      const updatedRequest = { ...request, status: 'accepted' };
      const newAcceptedList = [...state.acceptedRequests, updatedRequest];

      newState = {
        ...state,
        selectedRequest: null,
        [list]: updatedOriginList,
        acceptedRequests: newAcceptedList,
      };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.REQUESTS.REJECT:
      const rejectedRequest = action.payload;

      let list2 = '';
      switch (rejectedRequest.status) {
        case 'accepted':
          list2 = 'acceptedRequests';
          break;
        case 'rejected':
          list2 = 'rejectedRequests';
          break;

        default:
          list2 = 'requestedRequests';
      }

      const updatedOriginList2 = state[list2].filter(
        (req) => req.id !== rejectedRequest.id,
      );
      const updatedRequest2 = { ...rejectedRequest, status: 'rejected' };
      const newRejectedList = [...state.rejectedRequests, updatedRequest2];

      newState = {
        ...state,
        selectedRequest: null,
        [list2]: updatedOriginList2,
        rejectedRequests: newRejectedList,
      };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.TEAMS.SET: {
      const teams = action.payload.teams.map((group) => {
        const users = group.userTeams.map((ut) => ut.user);
        return {
          id: group.id,
          name: group.name,
          users: _.sortBy(users, [(u) => u.firstName]),
        };
      });

      teams.forEach((team) => {
        team.users.push({
          id: -1,
        });
      });

      newState = {
        ...state,
        teams,
      };
      break;
    }

    case ACTION_ITEMS.TEAM_VIEWER.TEAMS.UPDATE: {
      const updatedTeam = action.payload.team;
      const users = updatedTeam.userTeams.map((t) => t.user);
      const group = {
        id: updatedTeam.id,
        name: updatedTeam.name,
        users: _.sortBy(users, [(o) => o.user.firstName]),
      };

      const teamIndex = state.teams.findIndex((g) => g.id === updatedTeam.id);
      const teams = state.teams.filter(g => g.id !== updatedTeam.id);
      teams.splice(teamIndex, 0, group);
      teams[teamIndex].users.push({
        id: -1,
      });

      newState = {
        ...state,
        teams,
      };
      break;
    }

    case ACTION_ITEMS.TEAM_VIEWER.TEAMS.SELECTED:
      newState = {
        ...state,
        selectedTeam: action.payload,
      };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.TEAMS.DELETE: {
      const teamId = action.payload.team.id;
      const teams = state.teams.filter(t => t.id !== teamId);

      newState = {
        ...state,
        teams,
      };
      break;
    }

    case ACTION_ITEMS.TEAM_VIEWER.TEAMS.USER.ADD:
      {
        const { teamId, user } = action.payload;
        const teams = state.teams.map((team) => {
          if (team.id === teamId) {
            // eslint-disable-next-line no-param-reassign
            return {
              ...team,
              users: addAndSort(team.users, user),
            };
          }
          return team;
        });
        newState = {
          ...state,
          teams,
        };
      }
      break;

    case ACTION_ITEMS.TEAM_VIEWER.TEAMS.USER.REMOVE:
      {
        const { teamId, userId } = action.payload;

        const teams = state.teams.map((t) => {
          if(t.id === teamId) {
            // eslint-disable-next-line no-param-reassign
            return {
              ...t,
              users: t.users.filter((u) => u.id !== userId),
            };
          }
          return t;
        });
        newState = {
          ...state,
          teams,
        };
      }
      break;

    case ACTION_ITEMS.TEAM_VIEWER.GROUPS.SET: {
      const groups = action.payload.groups.map((group) => {
        const candidates = group.groupCandidates.map((gp) => gp.candidate);
        return {
          id: group.id,
          name: group.name,
          candidates: _.sortBy(candidates, [(o) => o.user.firstName]),
        };
      });

      if (groups.length === 0) {
        groups.push({
          id: -1,
          name: 'Group 1',
          candidates: [],
        });
      }

      groups.forEach((group) => {
        group.candidates.push({
          id: -1,
        });
      });

      newState = {
        ...state,
        groups,
      };
      break;
    }

    case ACTION_ITEMS.TEAM_VIEWER.GROUPS.UPDATE: {
      const updatedGroup = action.payload.group;
      const candidates = updatedGroup.groupCandidates.map((gp) => gp.candidate);
      const group = {
        id: updatedGroup.id,
        name: updatedGroup.name,
        candidates: _.sortBy(candidates, [(o) => o.user.firstName]),
      };

      const groupIndex = state.groups.findIndex((g) => g.id === updatedGroup.id);
      const groups = state.groups.filter(g => g.id !== updatedGroup.id);
      groups.splice(groupIndex, 0, group);
      groups[groupIndex].candidates.push({
        id: -1,
      });

      newState = {
        ...state,
        groups,
      };
      break;
    }

    case ACTION_ITEMS.TEAM_VIEWER.GROUPS.DELETE: {
      const groupId = action.payload.group.id;
      const groups = state.groups.filter(g => g.id !== groupId);

      newState = {
        ...state,
        groups,
      };
      break;
    }

    case ACTION_ITEMS.TEAM_VIEWER.GROUPS.SELECTED:
      newState = {
        ...state,
        selectedGroup: action.payload,
      };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.GROUPS.ADD:
      newState = {
        ...state,
        groups: [...state.groups, action.payload],
      };
      break;

    case ACTION_ITEMS.TEAM_VIEWER.GROUPS.CANDIDATE.ADD:
      {
        const { groupId, candidate } = action.payload;
        const groups = state.groups.map((group) => {
          if (group.id === groupId) {
            // eslint-disable-next-line no-param-reassign
            return {
              ...group,
              candidates: addAndSort(group.candidates, candidate),
            };
          }
          return group;
        });
        newState = {
          ...state,
          groups,
        };
      }
      break;

    case ACTION_ITEMS.TEAM_VIEWER.GROUPS.CANDIDATE.REMOVE:
      {
        const { group, candidateId } = action.payload;

        const groups = state.groups.map((g) => {
          if(g.id === group) {
            // eslint-disable-next-line no-param-reassign
            return {
              ...g,
              candidates: g.candidates.filter((c) => c.id !== candidateId),
            };
          }
          return g;
        });
        newState = {
          ...state,
          groups,
        };
      }
      break;

    case ACTION_ITEMS.TEAM_VIEWER.TEAM.SET:
      newState = {
        ...state,
        selectedTeamId: action.payload.teamId,
      };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
};
/* eslint-enable no-case-declarations */

function addAndSort(arr, elemToAdd) {
  let arrCopy = [...arr];

  // Remove placeholder
  const temp = arrCopy.pop();

  arrCopy.push(elemToAdd);

  // Sort candidates by firstName
  arrCopy = _.sortBy(arrCopy, [(o) => o.firstName || o.user.firstName]);

  // Add back placeholder
  arrCopy.push(temp);

  return arrCopy;
}
