/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  Done as DoneIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import { withContext } from 'common/helpers/context';
import { getStatusText } from 'common/helpers/getStatusText';
import { SortableTable } from 'common/components/SortableTable';
import PassportActionMenu from 'common/components/PassportActionMenu/PassportActionMenu';

import { submitPassportRequest } from '../../actions/submitPassportRequest';
import resource from './PassportRequestTabular.resource';
import { RequestStatusPill } from './components/RequestStatusPill';
import { getRandomCrn } from 'common/helpers/getRandomCrn';

function createHeaders(isOutbound) {
  return [
    {
      id: 'crn',
      align: 'left',
      numeric: true,
      label: 'ID',
    },
    {
      id: 'name',
      align: 'left',
      numeric: false,
      label: 'Candidate Name',
    },
    {
      id: 'role',
      align: 'left',
      numeric: false,
      label: 'Role',
    },
    {
      id: 'discipline',
      align: 'left',
      numeric: false,
      label: 'Discipline',
    },
    {
      id: 'requestor',
      align: 'left',
      numeric: true,
      label: isOutbound ? 'Sponsor' : 'Requestor',
    },
    {
      id: 'status',
      align: 'center',
      numeric: false,
      label: 'Status',
    },
    {
      id: 'buttonGroup',
      align: 'left',
      numeric: false,
      label: '',
    },
  ];
}

function createData(
  item,
  isOutbound,
  handleClickAction,
  handleClickApprove,
  handleClickReject,
) {
  const { candidate, requestor, feedback } = item;
  const {
    user,
    crn,
    role,
    discipline,
    assignment,
    id: candidateId,
  } = candidate;
  const { firstName, lastName } = user;

  const hasPermission = feedback === 1;

  let buttonGroup;
  if (feedback === 0 && !isOutbound) {
    buttonGroup = (
      <>
        <Tooltip title="Approve">
          <IconButton
            size="small"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={handleClickApprove(candidateId)}
          >
            <DoneIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Reject">
          <IconButton
            size="small"
            color="secondary"
            onClick={handleClickReject(candidateId)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          aria-controls="passport-action-menu"
          aria-haspopup="true"
          onClick={handleClickAction(item)}
        >
          <MoreVertIcon />
        </IconButton>
      </>
    );
  } else {
    buttonGroup = (
      <IconButton
        aria-controls="passport-action-menu"
        aria-haspopup="true"
        onClick={handleClickAction(item)}
      >
        <MoreVertIcon />
      </IconButton>
    );
  }

  return [
    {
      id: 'crn',
      value: isOutbound ? getRandomCrn() : crn,
      blur: isOutbound,
    },
    {
      id: 'name',
      value:
        isOutbound && !hasPermission ? 'Jane Doe' : `${firstName} ${lastName}`,
      blur: isOutbound,
    },
    {
      id: 'role',
      value: role.name,
    },
    {
      id: 'discipline',
      value: discipline.name,
    },
    {
      id: 'requestor',
      value: isOutbound
        ? `${assignment.token.sponsor.firstName} ${assignment.token.sponsor.lastName}`
        : `${requestor.firstName} ${requestor.lastName}`,
    },
    {
      id: 'status',
      value: <RequestStatusPill status={getStatusText(feedback)} />,
      align: 'center',
    },
    {
      id: 'buttonGroup',
      value: buttonGroup,
      align: 'right',
    },
  ];
}

const PassportRequestTabular = (props) => {
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [selectedPassportRequest, setSelectedPassportRequest] = useState(null);
  const [rows, setRows] = useState([]);

  const { userInfo, isOutbound, title, passportRequests } = props;
  const { id: userId } = userInfo;

  useEffect(() => {
    setRows(
      passportRequests.map((e) =>
        createData(
          e,
          isOutbound,
          handleClickAction,
          handleClickApprove,
          handleClickReject,
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passportRequests]);

  const handleClickApprove = (candidateId) => () => {
    props.submitPassportRequest({ userId, candidateId, feedback: 1 });
  };

  const handleClickReject = (candidateId) => () => {
    props.submitPassportRequest({ userId, candidateId, feedback: 2 });
  };

  const handleClickAction = (candidate) => (e) => {
    setSelectedPassportRequest(candidate);
    setAnchorElAction(e.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorElAction(null);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body2" color="textSecondary">
            {isOutbound
              ? resource.DESCRIPTIONS.OUTBOUND
              : resource.DESCRIPTIONS.INBOUND}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SortableTable
            name={`${isOutbound ? 'outbound' : 'inbound'}-request-table`}
            headers={createHeaders(isOutbound)}
            rows={rows}
            defaultSortColumnIndex={1}
            searchable
          />
          <PassportActionMenu
            candidate={
              selectedPassportRequest && selectedPassportRequest.candidate
            }
            feedback={
              selectedPassportRequest && selectedPassportRequest.feedback
            }
            anchorEl={anchorElAction}
            onClose={handleActionClose}
            showRequests={!isOutbound}
            showPermissions={!isOutbound}
            showShare={
              !isOutbound ||
              (selectedPassportRequest &&
                selectedPassportRequest.feedback === 1)
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

PassportRequestTabular.propTypes = {
  isOutbound: PropTypes.bool,
  title: PropTypes.string.isRequired,
  passportRequests: PropTypes.array.isRequired,
  submitPassportRequest: PropTypes.func.isRequired,
};

PassportRequestTabular.defaultProps = {
  isOutbound: false,
};

export default withContext(
  connect(null, { submitPassportRequest })(PassportRequestTabular),
);
