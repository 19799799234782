import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import { answerChangeRequest } from './actions/answerChangeRequest';

const ChangeRequestModal = (props) => {
  const { handleClose, open, assignment, options } = props;
  const { roles, disciplines } = options;
  const { candidate } = assignment;
  const [assignedRole, setAssignedRole] = useState('');
  const [assignedDiscipline, setAssignedDiscipline] = useState('');
  const [requestedRole, setRequestedRole] = useState('');
  const [requestedDiscipline, setRequestedDiscipline] = useState('');

  useEffect(() => {
    roles.forEach(role => {
      if(role.id === candidate.roleId) {
        setAssignedRole(role.name);
      }
      if(role.id === candidate.requestedRoleId) {
        setRequestedRole(role.name);
      }
    });

    disciplines.forEach(discipline => {
      if(discipline.id === candidate.disciplineId) {
        setAssignedDiscipline(discipline.name);
      }
      if(discipline.id === candidate.requestedDisciplineId) {
        setRequestedDiscipline(discipline.name);
      }
    });
  }, [candidate, disciplines, roles]);

  const displayRejectResult = () => {
    handleClose();
    Swal.fire({
      title: 'Rejection Complete',
      text: "The candidate will be notified of your response and asked to continue with the original assessment",
      icon: 'success',
    });
  };

  const displayAcceptResult = () => {
    handleClose();
    Swal.fire({
      title: 'Acceptance Complete',
      text: "The candidate will be notified of your response and asked to continue with their assessment with the new role and discipline",
      icon: 'success',
    });
  };

  const handleAccept = () => {
    const formValues = {
      assignmentId: assignment.id,
      decision: 'accepted',
    };
    props.answerChangeRequest(formValues, displayAcceptResult);
  };

  const handleReject = () => {
    const formValues = {
      assignmentId: assignment.id,
      decision: 'rejected',
    };
    props.answerChangeRequest(formValues, displayRejectResult);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
        <DialogTitle onClose={handleClose}>
          Candidate Assesment Change Request
        </DialogTitle>
        <DialogContent>
          <Box mb={3}>
            <Typography variant="body1">
              {candidate.user.firstName} {candidate.user.lastName} has requested the following change to their assessment.
            </Typography>
          </Box>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography variant="overline">
                      Current Assignment
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="body1">
                      <strong>Role:</strong>
                    </Typography>
                    <Typography variant="body1">
                      {assignedRole}
                    </Typography>
                  </Box>
                  <Typography variant="body1">
                    <strong>Discipline:</strong>
                  </Typography>
                  <Typography variant="body1">
                    {assignedDiscipline}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <Typography variant="overline">
                      Requested Assignment
                    </Typography>
                  </Box>

                  <Box mb={1}>
                    <Typography variant="body1">
                      <strong>Role:</strong>
                    </Typography>
                    <Typography variant="body1">
                      {requestedRole}
                    </Typography>
                  </Box>
                  <Typography variant="body1">
                    <strong>Discipline:</strong>
                  </Typography>
                  <Typography variant="body1">
                    {requestedDiscipline}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={handleReject}
                color="secondary"
                fullWidth
              >
                Reject
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={handleAccept}
                color="primary"
                fullWidth
              >
                Accept
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
  );
};

const mapStateToProps = (state) => ({
  options: state.options,
});

const mapDispatchToProps = (dispatch) => ({
  answerChangeRequest: (assignmentId, decision) => dispatch(answerChangeRequest(assignmentId, decision)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequestModal);
