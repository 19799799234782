import React from 'react';
import clsx from 'clsx';
import { Container, Typography } from '@material-ui/core';

import ProjectList from './components/ProjectList';
import PositionList from './components/PositionList';
import SkillList from './components/SkillList/SkillList';
import CandidateList from './components/CandidateList/CandidateList';

import { useStyles } from './ProjectBuilder.style';

const ProjectBuilder = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h4">Framework Builder</Typography>
      <div className={classes.content}>
        <div className={clsx(classes.column, classes.left)}>
          <ProjectList />
        </div>
        <div className={clsx(classes.column, classes.middle)}>
          <PositionList />
        </div>
        <div className={clsx(classes.column, classes.right)}>
          <SkillList />
          <CandidateList />
        </div>
      </div>
    </Container>
  );
};

export default ProjectBuilder;
