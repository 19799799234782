const resource = {
  DESCRIPTIONS: {
    OUTBOUND:
      'Access to your passport is managed via your manager. To gain full access to your passport your manager will need to provide approval. Until then you can view the results of your assessment anonymously',
    INBOUND:
      'Requests from candidates you manage to view their passports will appear below. Until you accept their request they will only be able to see their assessment results anonymously',
  },
};

export default resource;
