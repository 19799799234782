import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { addGroup } from './actions/addGroup';

const AddGroupModal = (props) => {
  const { userId, open, handleClose } = props;

  const [groupName, setGroupName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddGroup = async () => {
    setIsLoading(true);
    await props.addGroup(userId, groupName);
    setIsLoading(false);
    handleClose();
  };

  const handleOnChange = (event) => {
    setGroupName(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Group Name"
          type="email"
          value={groupName}
          onChange={handleOnChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={groupName.length === 0}
          onClick={handleAddGroup}
        >
          {isLoading ? <CircularProgress /> : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return { groups: state.teamViewer.groups };
};

export default connect(mapStateToProps, {
  addGroup,
})(withContext(AddGroupModal));
