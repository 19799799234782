import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function fetchPositionTopics(positionId) {
  return (dispatch) => {
    axios
      .get(`/position/${positionId}/topics`)
      .then((response) => {
        const { status, disciplines } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setPositionTopics(disciplines));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setPositionTopics(disciplines) {
  return {
    type: ACTION_ITEMS.PROJECT_BUILDER.DISCIPLINES.SET,
    payload: disciplines,
  };
}
