import ACTION_ITEMS from './actionItems';

const initialState = {
  authenticated: false,
  userInfo: null,
  isMasquerading: false,
  isTeamMasquerading: false,
  masqueradingFrom: null,
  masqueradingFromTeam: null,
  isLoading: false,
  message: '',
};

export const dashboardReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.AUTHENTICATE.POST.SUCCESS: {
      newState = {
        ...state,
        authenticated: true,
        userInfo: action.payload.user,
        isMasquerading: action.payload.isMasquerading,
        isTeamMasquerading: action.payload.isTeamMasquerading,
        masqueradingFrom: action.payload.masqueradingFrom,
        masqueradingFromTeam: action.payload.masqueradingFromTeam,
      };
      break;
    }

    case ACTION_ITEMS.AUTHENTICATE.POST.FAILED: {
      newState = { ...state, authenticated: false };
      break;
    }

    case ACTION_ITEMS.USER_INFO.IS_LOADING.SET: {
      newState = { ...state, isLoading: action.payload };
      break;
    }

    case ACTION_ITEMS.USER_INFO.MESSAGE.SET: {
      newState = { ...state, message: action.payload };
      break;
    }

    case ACTION_ITEMS.USER_INFO.PASSPORT_PHOTO.SET: {
      newState = {
        ...state,
        userInfo: {
          ...state.userInfo,
          passportPhoto: action.payload.passportPhoto,
        },
      };
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
