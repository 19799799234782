/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import PsychometricList from './components/PsychometricList';
import PsychometricAssessment from './components/PsychometricAssessment';

import { getPsychAssessments } from './actions/getPsychAssessments';
import { useStyles } from './Psychometric.style';

const Psychometric = (props) => {
  const classes = useStyles();
  const { match, userId, candidates, selectedCandidate } = props;

  useEffect(() => {
    if (_.isNil(candidates)) {
      props.getPsychAssessments(userId);
    }
  });

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Switch>
        <Route
          path={`${match.path}/assessment`}
          render={() => (
            <PsychometricAssessment
              userId={userId}
              candidate={selectedCandidate}
            />
          )}
        />
        <Route
          path={`${match.path}`}
          render={() => <PsychometricList candidates={candidates || []} />}
        />
      </Switch>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dashboard, psychometric } = state;
  return {
    userId: dashboard.userInfo.id,
    candidates: psychometric.candidates,
    selectedCandidate: psychometric.selectedCandidate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPsychAssessments: (userId) => dispatch(getPsychAssessments(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Psychometric);
