import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    background: 'f9fbfd',
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));
