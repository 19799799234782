import axios from 'axios';

export function candidateChangeRequest(formData, callback) {
  return () => {
    axios
      .post('/candidate/data-change-request', formData)
      .then((response) => {
        const { status } = response.data;

        if (status === 'SUCCESS') {
          callback();
        }
      })
      .catch((error) => {
        console.error(error.response.data);
      });
  };
}
