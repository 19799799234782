/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { removeFromTeam } from 'components/Dashboard/components/TeamViewer/components/Teams/actions/removeFromTeam';
import { setSelectedTeam } from '../../../../../../actions/setSelectedTeam';
// import ActionMenu from '../../../../../../../ActionMenu';

const grid = 16;

const getItemStyle = (isDragging, isDragDisabled, draggableStyle) => ({
  // some basic styles to make the users look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',
  border: isDragDisabled ? '2px dashed lightblue' : '2px solid lightblue',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const UserTeam = (props) => {
  const { index, team, user, showAddUserTeamModal } = props;
  const { email } = user;
  const isDragDisabled = user.id < 0;

  let title;
  let subtext;
  if (isDragDisabled) {
    title = 'Your team members will display here';
    subtext = 'click an empty slot to add';
  } else {
    title = `${user.firstName} ${user.lastName}`;
    subtext = `Email: ${email}`;
  }

  const handleClick = () => {
    props.setSelectedTeam(team);
    showAddUserTeamModal();
  };

  const handleRemoveUser = (event) => {
    event.stopPropagation();
    Swal.fire({
      text: 'Are you sure you want to delete this team?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        props.removeFromTeam(team.id, user.id);
      }
    });
  };

  const renderUser = () => {
    if (isDragDisabled) {
      return (
        <div>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {subtext}
          </Typography>
        </div>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {subtext}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Tooltip
            title={`Remove ${title} from ${team.name} team`}
            aria-label="delete"
          >
            <DeleteIcon onClick={handleRemoveUser} color="error" />
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} md={6}>
      <Draggable
        draggableId={`user-${user.id}`}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              isDragDisabled,
              provided.draggableProps.style,
            )}
            onClick={handleClick}
          >
            {renderUser()}
          </div>
        )}
      </Draggable>
    </Grid>
  );
};

UserTeam.propTypes = {
  index: PropTypes.number.isRequired,
  team: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedTeam: (team) => dispatch(setSelectedTeam(team)),
  removeFromTeam: (...params) => dispatch(removeFromTeam(...params)),
});

export default connect(null, mapDispatchToProps)(withContext(UserTeam));
