/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';

import formatWithComma from 'common/helpers/formatWithComma';
import { useStyles } from './PurchaseToken.style';
import SponsorCodeDialog from './components/SponsorCodeDialog';
import TokenQuantitySelector from './components/TokenQuantitySelector';
import PaymentDialog from './components/PaymentDialog';
import { postPurchaseToken } from './actions/postPurchaseToken';

const PER_TECHNICIAN_TOKEN = 300;
const PER_ENGINEER_TOKEN = 450;

const getTotalAmmount = (technicianToken, engineerToken, discount) => {
  let total =
    technicianToken * PER_TECHNICIAN_TOKEN + engineerToken * PER_ENGINEER_TOKEN;
  if (discount !== 0) {
    total -= total * (discount / 100);
  }
  return total;
};

const PurchaseToken = (props) => {
  const { userInfo, isStripeEnabled, discount } = props;
  const { id: userId, sponsorCode, intentProcess } = userInfo;
  const postPurchaseTokens = props.postPurchaseToken;

  const [purchasedTokens, setPurchasedTokens] = useState([]);
  const isSelfSponsored = intentProcess.selfSponsor.enabled;
  const [technicianTokens, setTechnicianTokens] = useState(0);
  const [technicianDisabled, setTechnicianDisabled] = useState(false);
  const [engineerTokens, setEngineerTokens] = useState(0);
  const [engineerDisabled, setEngineerDisabled] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [sponsorCodeDialogOpen, setSponsorCodeDialogOpen] = useState(false);

  const fetchTokens = async () => {
    Swal.fire({
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const {
        data: { tokens },
      } = await axios.get(`/sponsor/tokens`);

      Swal.close();
      setPurchasedTokens(tokens);
    } catch (error) {
      Swal.close();
      const { data } = error.response;
      if (data && data.status === 'ERROR') {
        Swal.fire({
          title: 'Error!',
          text: data.status_message,
          icon: 'error',
          confirmButtonColor: '#3f51b5',
        });
      }
    }
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  useEffect(() => {
    const disablePurchase = isSelfSponsored && purchasedTokens.length > 0;

    setTechnicianDisabled(disablePurchase);
    setEngineerDisabled(disablePurchase);
  }, [purchasedTokens]);

  const description = [];
  if (technicianTokens > 0) description.push(`technician x${technicianTokens}`);
  if (engineerTokens > 0) description.push(`engineer x${engineerTokens}`);

  const handleChangeTechnician = (e) => {
    let { value } = e.target;
    if (isSelfSponsored) {
      setEngineerDisabled(value > 0);
      if (value > 1) value = 1;
    } else if (value > 1000) {
      value = 1000;
    } else if (value < 0) {
      value = 0;
    }
    setTechnicianTokens(Number(value));
  };

  const handleChangeEngineer = (e) => {
    let { value } = e.target;
    if (isSelfSponsored) {
      setTechnicianDisabled(value > 0);
      if (value > 1) value = 1;
    } else if (value > 1000) {
      value = 1000;
    } else if (value < 0) {
      value = 0;
    }
    setEngineerTokens(Number(value));
  };

  const totalAmount = getTotalAmmount(
    technicianTokens,
    engineerTokens,
    discount,
  );

  const classes = useStyles();
  const fixedDoubleHeightPaper = clsx(classes.paper, classes.fixedDoubleHeight);

  const handleClick = () => {
    if (totalAmount < 1) {
      Swal.fire({
        // title: 'Are you sure?',
        text: 'Please select at least one token before purchasing',
        icon: 'warning',
      });

      return;
    }

    if (_.isNil(sponsorCode)) {
      handleSponsorCodeDialogOpen();
      return;
    }

    if (isStripeEnabled) {
      handlePaymentDialogOpen();
    } else {
      addTokensToAccount();
    }
  };

  const addTokensToAccount = () => {
    postPurchaseTokens(
      {
        technicianTokens,
        engineerTokens,
        totalAmount,
        discount,
      },
      userId,
      () => {
        setTechnicianTokens(0);
        setEngineerTokens(0);
      },
    );
  };

  const handleSponsorCodeDialogOpen = () => {
    setSponsorCodeDialogOpen(true);
  };

  const handleSponsorCodeDialogClose = (shouldProceed) => {
    setSponsorCodeDialogOpen(false);
    if (shouldProceed) {
      if (isStripeEnabled) {
        handlePaymentDialogOpen();
      } else {
        addTokensToAccount();
      }
    }
  };

  const handlePaymentDialogOpen = () => {
    setPaymentDialogOpen(true);
  };

  const handlePaymentDialogClose = () => {
    setPaymentDialogOpen(false);
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Paper className={fixedDoubleHeightPaper}>
          <Typography variant="h6">
            Select the number of Assessment tokens
          </Typography>

          <TokenQuantitySelector
            roleType={2}
            value={technicianTokens}
            disabled={technicianDisabled}
            handleChange={handleChangeTechnician}
          />
          <TokenQuantitySelector
            roleType={1}
            value={engineerTokens}
            disabled={engineerDisabled}
            handleChange={handleChangeEngineer}
          />

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={totalAmount === 0}
            fullWidth
          >
            Proceed to next step
          </Button>
        </Paper>
      </Grid>
      <SponsorCodeDialog
        userId={userInfo.id}
        open={sponsorCodeDialogOpen}
        handleClose={handleSponsorCodeDialogClose}
      />
      <PaymentDialog
        open={paymentDialogOpen}
        amount={formatWithComma(totalAmount)}
        description={description.join(' and ')}
        handleClose={handlePaymentDialogClose}
        addTokensToAccount={addTokensToAccount}
      />
    </Grid>
  );
};

PurchaseToken.propTypes = {
  userInfo: PropTypes.object.isRequired,
  isStripeEnabled: PropTypes.bool.isRequired,
  discount: PropTypes.number.isRequired,
};

const mapStateToProps = ({ dashboard, purchaseToken, global }) => {
  const { userInfo } = dashboard;
  const { config } = global;
  return {
    userInfo,
    isStripeEnabled: !_.isNil(config) && config.settings.features.stripe,
    discount: purchaseToken.discount || 0,
  };
};

export default connect(mapStateToProps, { postPurchaseToken })(PurchaseToken);
