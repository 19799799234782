import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { addPosition } from '../../actions/addPosition';

const AddPositionModal = (props) => {
  const { open, handleClose, selectedProject, options } = props;
  const { roles, disciplineRoleSpecific } = options;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleId, setRoleId] = useState('');
  const [disciplineId, setDisciplineId] = useState('');

  const handleAddPosition = async () => {
    setIsLoading(true);
    await props.addPosition(selectedProject.id, {
      name,
      roleId,
      disciplineId,
      description,
    });
    setIsLoading(false);
    handleClose();
  };

  const handleOnChange = (setter) => (event) => {
    setter(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          value={name}
          onChange={handleOnChange(setName)}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel id="roleId-label">Role</InputLabel>
          <Select
            id="roleId"
            labelId="roleId-label"
            value={roleId}
            onChange={handleOnChange(setRoleId)}
            label="Role"
            fullWidth
          >
            {roles.map(({ name: label, id: value }, index) => (
              <MenuItem key={`roleId-option-${index}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="disciplineId-label">Discipline</InputLabel>
          <Select
            id="disciplineId"
            labelId="disciplineId-label"
            value={disciplineId}
            onChange={handleOnChange(setDisciplineId)}
            label="Discipline"
            fullWidth
          >
            {disciplineRoleSpecific.map(({ name: label, id: value }, index) => (
              <MenuItem key={`roleId-option-${index}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          value={description}
          onChange={handleOnChange(setDescription)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={name.length === 0}
          onClick={handleAddPosition}
        >
          {isLoading ? <CircularProgress /> : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddPositionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addPosition: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { projectBuilder, options } = state;
  const { projects, selectedProject } = projectBuilder;
  return {
    projects,
    selectedProject,
    options,
  };
};

export default connect(mapStateToProps, {
  addPosition,
})(withContext(AddPositionModal));
