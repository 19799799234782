import ACTION_ITEMS from './actionItems';

const initialState = {
  isSelfSponsored: false,
  disciplines: [],
  hasSafety: true,
  hasCompletions: true,
};

export const selfAssessmentReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.SELF_ASSESSMENT.DISCIPLINE.SET:
      newState = {
        ...state,
        ...action.payload,
      };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
};
