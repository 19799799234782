// import React, { useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Grid, Typography, Button } from '@material-ui/core';

import DaysRemaining from './components/DaysRemaining';
import BookingDetails from './components/BookingDetails/BookingDetails';

const UpcomingAssessment = ({ booking }) => {
  if (!booking) return null;

  const { bookedDate } = booking;

  if (moment().isAfter(bookedDate)) {
    // Exit if booked date has passed
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Upcoming Assessments</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DaysRemaining bookedDate={bookedDate} />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                // onClick={onClickStartAssessment}
                fullWidth
              >
                What to bring
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                // color="primary"
                // onClick={onClickStartAssessment}
                fullWidth
              >
                Report a problem
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <BookingDetails booking={booking} />
        </Grid>
      </Grid>
    </Grid>
  );
};

UpcomingAssessment.propTypes = {
  booking: PropTypes.object,
};

UpcomingAssessment.defaultProps = {
  booking: null,
};

export default connect(null)(UpcomingAssessment);
