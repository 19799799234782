const resource = {
  LEVEL0: {
    TITLE: 'LEVEL 0 - No/Minimal Knowledge',
    DESCRIPTION:
      'No/Minimal knowledge or awareness of the topic, no experience or exposure to relevant activities within a technical environment. No questions will be presented at this level.',
  },
  LEVEL1: {
    TITLE: 'LEVEL 1 - Elementary Knowledge',
    DESCRIPTION:
      'Has elementary knowledge/can recall some information of the topic. Would have limited experience to apply basic skills under supervision only. Further training and experience required.',
  },
  LEVEL2: {
    TITLE: 'LEVEL 2 - Limited Knowledge',
    DESCRIPTION:
      'Has limited knowledge/can recall some information of the skill area when used. Has appropriate experience to apply skills under supervision or alone to a basic level. Further training and experience recommended.',
  },
  LEVEL3: {
    TITLE: 'LEVEL 3 - Moderate Comprehension/Understanding',
    DESCRIPTION:
      'Has a broad based level of knowledge and experience, can explain and demonstrate to others in situations of average complexity and can successfully apply skills independently requiring limited supervision.',
  },
  LEVEL4: {
    TITLE: 'LEVEL 4 - Considerable Knowledge/Application',
    DESCRIPTION:
      'Has detailed knowledge and experience of this skill area and can critically evaluate and adapt as required in complex environments. Has successfully executed skill set.',
  },
  LEVEL5: {
    TITLE: 'LEVEL 5 - Extensive/Strategic Application',
    DESCRIPTION:
      'Has in-depth knowledge of this topic. Has successfully and independently applied the skill via direct and management of complex situations. Recognised as fully conversant in this area and may be called on by others for advice or guidance as a Subject Matter Expert.',
  },
};

export default resource;
