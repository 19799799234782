import axios from 'axios';
import ACTION_ITEMS from '../../Dashboard/components/Passport/actionItems';

export function getPassportByHash(shareHash) {
  return (dispatch) => {
    axios
      .get(`/candidate/${shareHash}/passport-share`)
      .then((response) => {
        const { status, passport } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setPassport(passport));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setPassport(passport) {
  return {
    type: ACTION_ITEMS.PASSPORT.RESULTS.SET,
    payload: passport,
  };
}
