import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: '-20px -20px 20px -20px',
    paddingTop: '10px',
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarItem: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  toolbarItemSplit: {
    flex: 1,
  },
  toolbarItemRight: {
    [theme.breakpoints.down('sm')]: {
      '& span, p': {
        textAlign: 'right',
      },
    },
  },
  content: {
    fontWeight: 'bold',
    wordWrap: 'break-word',
  },
  header: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.common.white,
    fontFamily: 'Nunito',
    letterSpacing: '0.1rem',
  },
  qrCodeContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  qrCode: {
    display: 'flex',
    flexDirection: 'column',
  },
  qrCodeTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'Nunito',
    letterSpacing: '0.1rem',
    textAlign: 'center',
  },
  backDrop: {
    filter: 'blur(5px)',
  },
}));
