import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { initializeGodView } from './actions/initializeGodView';
import { getAllUsers } from './actions/getAllUsers';

const GodViewModal = (props) => {
  const { open } = props;

  const [users, setUsers] = useState([]);
  const [, , removeCookie] = useCookies(['roleId', 'disciplineId', 'filters']);

  useEffect(() => {
    if (users && users.length === 0) {
      (async () => {
        setUsers(await getAllUsers());
      })();
    }
  });

  const handleInitializeGodView = (userId) => () => {
    removeCookie('roleId');
    removeCookie('disciplineId');
    removeCookie('filters');
    props.initializeGodView(userId);
  };

  const handleClose = () => {
    if (props.handleClose) props.handleClose();
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">GOD VIEW</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right">ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={`user-list-${user.id}`}>
                  <TableCell align="right">{user.id}</TableCell>
                  <TableCell align="left">
                    {user.firstName} {user.lastName}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="inherit"
                      onClick={handleInitializeGodView(user.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GodViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  initializeGodView: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  initializeGodView: (userId) => dispatch(initializeGodView(userId)),
});

export default connect(null, mapDispatchToProps)(GodViewModal);
