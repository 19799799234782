import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AppBar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import {
  Minimize as MinimizeIcon,
  Maximize as MaximizeIcon,
} from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import { redirect } from 'common/actions';
import ProcessItem from './components/ProcessItem';
import OnboardingModal from '../TokenAssignmentTabular/components/OnboardingModal';

const SMOOTH_OPTION = { behavior: 'smooth' };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabPanel = (props) => {
  const { children, value, index, title, arrProcesses, ...other } = props;
  const indexOfLastAchieved = arrProcesses.reduce(
    (finalIndex, { processStatus }, newIndex) => {
      return processStatus ? newIndex : finalIndex;
    },
    0,
  );

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">The {title} process</Typography>
              </Grid>
              {arrProcesses.map(
                (
                  {
                    processStatus,
                    processName,
                    helpTitle,
                    helpDescription,
                    handleClickItem,
                  },
                  i,
                ) => (
                  <ProcessItem
                    key={`processes-${i}`}
                    isNext={indexOfLastAchieved + 1 === i}
                    processStatus={processStatus}
                    processName={processName}
                    helpTitle={helpTitle}
                    helpDescription={helpDescription}
                    handleClickItem={handleClickItem}
                  />
                ),
              )}
            </Grid>
          </AccordionDetails>
        </Box>
      )}
    </div>
  );
};

const SelfSponsorProcess = (props) => {
  const {
    selectedToken,
    refresh,
    type,
    processes,
    forwardedRef,
    passportButtonRef,
    assignments,
  } = props;
  const { sponsor, selfSponsor, sponsored } = processes;
  const { enabled: enableSponsor } = sponsor;
  const { enabled: enableSelfSponsor } = selfSponsor;
  const { enabled: enableSponsored } = sponsored;

  const [cookie, setCookie] = useCookies(['selfSponsorProcessMinMax']);
  const isMinimized = cookie.selfSponsorProcessMinMax === 'true';

  const [page, setPage] = useState(type - 1);

  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);

  const pendingAssessments = assignments.filter(
    ({ candidate }) =>
      !candidate.verified || !candidate.flags.doneSelfAssessment,
  );

  let candidate;
  if (pendingAssessments.length > 0) {
    candidate = pendingAssessments[0].candidate;
  }

  const handleChange = (event, newPage) => {
    setPage(newPage);
  };

  let arrProcesses;
  switch (page) {
    case 0:
      {
        const {
          registered,
          purchasedToken,
          assignedToken,
          trackCandidates,
          payAttentionToRequests,
        } = sponsor;
        arrProcesses = [
          {
            processStatus: registered,
            processName: 'Register to Competas',
            helpTitle: '',
          },
          {
            processStatus: purchasedToken,
            processName:
              'Click here to purchase Technician and/or Engineering tokens',
            helpTitle: "What's a token?",
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  A token is a payment for one Candidate assessment. The cost of
                  a token will depend on the selected role; which is identified
                  on the <Link to="/assessment-purchase">payment page</Link>.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>Do tokens expire?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Yes. Every 3 years, however we will contact you in advance.
                </Typography>
              </>
            ),
            handleClickItem: () => props.redirect('/assessment-purchase'),
          },
          {
            processStatus: assignedToken,
            processName:
              'Assign tokens to your Candidates via the Assessment Dashboard',
            helpTitle: '',
            handleClickItem: () => {
              if (forwardedRef.current) {
                forwardedRef.current.scrollIntoView(SMOOTH_OPTION);
              }
            },
          },
          {
            processStatus: trackCandidates,
            processName:
              'Track your Candidates progress via the Assessment Dashboard',
            helpTitle: 'What are the various stages of progress?',
            handleClickItem: () => {
              if (forwardedRef.current) {
                forwardedRef.current.scrollIntoView(SMOOTH_OPTION);
              }
            },
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="textSecondary"
                >
                  <ol>
                    <li style={{ marginLeft: '15px' }}>
                      Your candidate will be invited to confirm they wish to sit
                      the assessment via email
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      If an account does not exist for that candidate, they will
                      be invited to create one...or alternatively, log in to
                      their existing account
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      The candidate will confirm that you have assigned them the
                      correct role and discipline, and perform a profile-picture
                      upload
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      Your candidate may now self-assess. You will be notified
                      by email when they have done so
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      Psychometric assessment will become available for your
                      candidate
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      At this point you are required to book your candidate into
                      their technical assessment, the process list on the left
                      will guide you. Your candidate cannot book themselves in.
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      Your candidate will follow the assessment process, which
                      involves configuring the Proctor Exam secure proctoring
                      service
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      Post completion of assessment, the Competas team will
                      review the assessment footage (Mobile profile view, screen
                      recording, and webcam) to ensure no foul play. This
                      process takes up to 2 business days.
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      You will be notified of successful/unsuccessful completion
                      of this Integrity Review
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      Assuming your Candidate has followed all the rules, their
                      Passport will immediately become available to you
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      Your candidate may request access to their non-anonymised
                      passport. This action is at your discretion.
                    </li>
                  </ol>
                </Typography>
              </>
            ),
          },
          {
            processStatus: payAttentionToRequests,
            processName:
              'Pay attention to your inbox/dashboard for Candidate-passport-requests',
            helpTitle: 'What is a Passport access request?',
            handleClickItem: () => {
              if (forwardedRef.current) {
                forwardedRef.current.scrollIntoView(SMOOTH_OPTION);
              }
            },
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  As you have purchased the candidate assessment, access to the
                  non-anonymised passport is at your discretion. You will be
                  notified via email of candidate requests to access their non
                  anonymised passport.
                </Typography>
              </>
            ),
          },
        ];
      }
      break;

    case 1:
      {
        const {
          registered,
          purchasedToken,
          assignedToken,
          verifyAndUploadPhoto,
          completedSelfAssessment,
          completedPsychometric,
          bookAssessment,
          completedTechnical,
          waitAssessmentApproval,
          viewingPassport,
        } = selfSponsor;

        arrProcesses = [
          {
            processStatus: registered,
            processName: 'Register to Competas',
            helpTitle: '',
          },
          {
            processStatus: purchasedToken,
            processName: 'Purchase an assessment token',
            helpTitle: "What's a token?",
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  A token is a payment for one Candidate assessment. The cost of
                  a token will depend on the selected role; which is identified
                  on the <Link to="/assessment-purchase">payment page</Link>.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>Do tokens expire?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Yes. Every 3 years, however we will contact you in advance.
                </Typography>
              </>
            ),
            handleClickItem: () => props.redirect('/assessment-purchase'),
          },
          {
            processStatus: assignedToken,
            processName: 'Assign your token a specific role and discipline',
            helpTitle: 'What role and disciplines are available?',
            helpDescription: (
              <Typography
                variant="subtitle2"
                component="p"
                color="textSecondary"
              >
                Presently Engineer and Technician roles are available, as
                defined on the{' '}
                <Link to="/assessment-purchase">payment page</Link>. Once a
                token is purchased, you can assign the required discipline. The
                available Technician and Engineer disciplines are also listed on
                the payment page.
              </Typography>
            ),
            handleClickItem: () => {
              if (selectedToken && !selectedToken.assignment) {
                setOpenOnboardingModal(true);
              } else if (forwardedRef.current) {
                forwardedRef.current.scrollIntoView(SMOOTH_OPTION);
              }
            },
          },
          {
            processStatus: verifyAndUploadPhoto,
            processName: 'Complete verification and photo upload',
            helpTitle: 'What verification, and what to upload?',
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Please verify that the correct role and discipline has been
                  assigned to you.
                </Typography>
                <br />
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Please upload a passport style photograph of yourself. This
                  aids Competas in secure verification of Competas Passport, and
                  will be used for the display on your Competas Smartcard.
                </Typography>
              </>
            ),
            handleClickItem: () => {
              if (candidate) {
                props.redirect(
                  `/candidate-onboarding/verify/info/${candidate.id}`,
                );
              }
            },
          },
          {
            processStatus: completedSelfAssessment,
            processName: 'Complete your Self-Assessment',
            helpTitle: "What's a self-assessment?",
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  The self-assessment is how you predict your levels of
                  competencies in the topics presented, based on your assigned
                  role.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>What are the levels of competency?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  The Competency levels are defined on the self-assessment page.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>Will my self-assessment impact my technical assessment?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Yes. Your self-assessed level will set the entry difficulty
                  point for your technical assessment.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>
                    Will scoring myself higher/lower improve my chances of
                    scoring higher in the technical assessment?
                  </b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  No. Quite the opposite. Honesty allows us to start you at the
                  most appropriate level of difficulty. You have limited
                  questions to prove your competency, please don&apos;t waste
                  that opportunity answering questions you&apos;re unlikely to
                  get correct; or questions which present little difficulty.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>
                    Are self-assessment levels presented on my Competas
                    Passport?
                  </b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Yes. They are shown graphically against your achieved score.
                </Typography>
              </>
            ),
          },
          {
            processStatus: completedPsychometric,
            processName: 'Please complete your Psychometric Evaluation',
            helpTitle: "What's a psychometric evaluation",
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  As organisations place more emphasis on cultural fit,
                  psychometric evaluations are becoming more common during the
                  selection process.
                </Typography>
                <br />
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Psychometric evaluations examine an individual’s capabilities
                  and preferences, to provide data that can help assess a
                  candidate’s “fit” for a role and organisation.
                </Typography>
              </>
            ),
            handleClickItem: () => props.redirect('/psychometric'),
          },
          {
            processStatus: bookAssessment,
            processName: 'Book your Technical Assessment',
            helpTitle: '',
            handleClickItem: () => {
              if (forwardedRef.current) {
                forwardedRef.current.scrollIntoView(SMOOTH_OPTION);
              }
            },
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Press the button to your left, or, if you have multiple
                  candidates, simply press &quot;Assign Candidate&quot; via the
                  menu item in their table row below.
                </Typography>
              </>
            ),
          },
          {
            processStatus: completedTechnical,
            processName: 'Complete your Technical Assessment',
            helpTitle: 'What to expect in your technical assessment',
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  The assessment will take 2-3 hours, during this time you will
                  answer questions on each of the topics you self-assessed in
                  above a level zero.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>How does the assessment work?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="textSecondary"
                >
                  <ol>
                    <li style={{ marginLeft: '15px' }}>
                      If you answer questions correctly, the difficulty
                      increases.
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      If you answer questions incorrectly the difficulty
                      decreases.
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      We will stop asking questions once we find your accurate
                      level of competency.
                    </li>
                  </ol>
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>Can I take a comfort break?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Yes. Please indicate you are taking a break, so we don&apos;t
                  think anything suspicious is going on. Please note, the
                  assessment timer will continue to countdown.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>Can I pause the assessment?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  No. We have factored comfort breaks into the total time.
                  Please ensure you have a 3 hour time-period available prior to
                  commencing your assessment.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>
                    What happens if something goes wrong during my assessment?
                  </b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Don&apos;t panic. If you can, just explain what&apos;s
                  happening in the recorded session and send an email to
                  contact@competas.com
                </Typography>
              </>
            ),
          },
          {
            processStatus: waitAssessmentApproval,
            processName:
              'Wait 2 business days for Competas Proctors to approve your assessment',
            helpTitle: 'Why the wait?',
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  We scrutinise each recorded proctored assessment to ensure
                  that the Candidate has complied fully with the agreed terms
                  and conditions of the technical assessment.
                </Typography>
                <br />
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  This can take up to two working days to complete the review.
                  Once approved your Passport will be immediately made
                  available, you will be notified via e-mail.
                </Typography>
                <br />
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Your Passport will not be made available if the Competas
                  Integrity Check reaches the verdict that the terms of Competas
                  assessment have been breached.
                </Typography>
              </>
            ),
          },
          {
            processStatus: viewingPassport,
            processName: 'Review your results in your Competas Passport',
            helpTitle: '',
            handleClickItem: () => {
              if (passportButtonRef.current) {
                passportButtonRef.current.scrollIntoView(SMOOTH_OPTION);
              }
            },
          },
        ];
      }
      break;

    default:
      {
        const {
          registered,
          verifyAndUploadPhoto,
          completedSelfAssessment,
          completedPsychometric,
          completedTechnical,
          waitAssessmentApproval,
          viewingPassport,
        } = sponsored;
        arrProcesses = [
          {
            processStatus: registered,
            processName: 'Register to Competas',
            helpTitle: '',
          },
          {
            processStatus: verifyAndUploadPhoto,
            processName: 'Complete verification and photo upload',
            helpTitle: 'What verification, and what to upload?',
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Please verify that the correct role and discipline has been
                  assigned to you.
                </Typography>
                <br />
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Please upload a passport style photograph of yourself. This
                  aids Competas in secure verification of Competas Passport, and
                  will be used for the display on your Competas Smartcard.
                </Typography>
              </>
            ),
            handleClickItem: () => {
              if (candidate) {
                props.redirect(
                  `/candidate-onboarding/verify/info/${candidate.id}`,
                );
              }
            },
          },
          {
            processStatus: completedSelfAssessment,
            processName: 'Complete your Self-Assessment',
            helpTitle: "What's a self-assessment?",
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  The self-assessment is how you predict your levels of
                  competencies in the topics presented, based on your assigned
                  role.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>What are the levels of competency?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  The Competency levels are defined on the self-assessment page.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>Will my self-assessment impact my technical assessment?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Yes. Your self-assessed level will set the entry difficulty
                  point for your technical assessment.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>
                    Will scoring myself higher/lower improve my chances of
                    scoring higher in the technical assessment?
                  </b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  No. Quite the opposite. Honesty allows us to start you at the
                  most appropriate level of difficulty. You have limited
                  questions to prove your competency, please don&apos;t waste
                  that opportunity answering questions you&apos;re unlikely to
                  get correct; or questions which present little difficulty.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>
                    Are self-assessment levels presented on my Competas
                    Passport?
                  </b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Yes. They are shown graphically against your achieved score.
                </Typography>
              </>
            ),
          },
          {
            processStatus: completedPsychometric,
            processName: 'Please complete your Psychometric Evaluation',
            helpTitle: "What's a psychometric evaluation",
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  As organisations place more emphasis on cultural fit,
                  psychometric evaluations are becoming more common during the
                  selection process.
                </Typography>
                <br />
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Psychometric evaluations examine an individual’s capabilities
                  and preferences, to provide data that can help assess a
                  candidate’s “fit” for a role and organisation.
                </Typography>
              </>
            ),
            handleClickItem: () => props.redirect('/psychometric'),
          },
          {
            processStatus: completedTechnical,
            processName: 'Complete your Technical Assessment',
            helpTitle: 'What to expect in your technical assessment',
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  The assessment will take 2-3 hours, during this time you will
                  answer questions on each of the topics you self-assessed in
                  above a level zero.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>How does the assessment work?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color="textSecondary"
                >
                  <ol>
                    <li style={{ marginLeft: '15px' }}>
                      If you answer questions correctly, the difficulty
                      increases.
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      If you answer questions incorrectly the difficulty
                      decreases.
                    </li>
                    <li style={{ marginLeft: '15px' }}>
                      We will stop asking questions once we find your accurate
                      level of competency.
                    </li>
                  </ol>
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>Can I take a comfort break?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Yes. Please indicate you are taking a break, so we don&apos;t
                  think anything suspicious is going on. Please note, the
                  assessment timer will continue to countdown.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>Can I pause the assessment?</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  No. We have factored comfort breaks into the total time.
                  Please ensure you have a 3 hour time-period available prior to
                  commencing your assessment.
                </Typography>
                <br />
                <Typography variant="subtitle2" color="textSecondary">
                  <b>
                    What happens if something goes wrong during my assessment?
                  </b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Don&apos;t panic. If you can, just explain what&apos;s
                  happening in the recorded session and send an email to
                  contact@competas.com
                </Typography>
              </>
            ),
          },
          {
            processStatus: waitAssessmentApproval,
            processName:
              'Wait 2 business days for Competas Proctors to approve your assessment',
            helpTitle: 'Why the wait?',
            helpDescription: (
              <>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  We scrutinise each recorded proctored assessment to ensure
                  that the Candidate has complied fully with the agreed terms
                  and conditions of the technical assessment.
                </Typography>
                <br />
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  This can take up to two working days to complete the review.
                  Once approved your Passport will be immediately made
                  available, you will be notified via e-mail.
                </Typography>
                <br />
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  Your Passport will not be made available if the Competas
                  Integrity Check reaches the verdict that the terms of Competas
                  assessment have been breached.
                </Typography>
              </>
            ),
          },
          {
            processStatus: viewingPassport,
            processName: 'Review your results in your Competas Passport',
            helpTitle: '',
            handleClickItem: () => {
              if (passportButtonRef.current) {
                passportButtonRef.current.scrollIntoView(SMOOTH_OPTION);
              }
            },
          },
        ];
      }
      break;
  }

  const handleClickToggleMinMax = () => {
    setCookie('selfSponsorProcessMinMax', !isMinimized);
  };

  return (
    <Grid item xs={12}>
      <Accordion defaultExpanded>
        <AppBar
          position="static"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <Tabs
            variant="fullWidth"
            value={page}
            onChange={handleChange}
            aria-label="simple tabs"
            style={{ flexGrow: 1 }}
          >
            <Tab label="Sponsor" disabled={!enableSponsor} {...a11yProps(0)} />
            <Tab
              label="Self-Sponsor"
              disabled={!enableSelfSponsor}
              {...a11yProps(1)}
            />
            <Tab
              label="Sponsored"
              disabled={!enableSponsored}
              {...a11yProps(2)}
            />
          </Tabs>
          <Button
            style={{ color: 'white' }}
            color="primary"
            aria-label="add"
            onClick={handleClickToggleMinMax}
          >
            {isMinimized ? <MaximizeIcon /> : <MinimizeIcon />}
          </Button>
        </AppBar>
        <Collapse in={!isMinimized}>
          <TabPanel
            value={page}
            index={0}
            title="Sponsor"
            arrProcesses={arrProcesses}
          />
          <TabPanel
            value={page}
            index={1}
            title="Self-Sponsor"
            arrProcesses={arrProcesses}
          />
          <TabPanel
            value={page}
            index={2}
            title="Sponsored"
            arrProcesses={arrProcesses}
          />
        </Collapse>
      </Accordion>
      <OnboardingModal
        token={selectedToken}
        open={openOnboardingModal}
        refresh={refresh}
        handleClose={() => setOpenOnboardingModal(false)}
      />
    </Grid>
  );
};

export default connect(null, { redirect })(SelfSponsorProcess);
