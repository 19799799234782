import React, { useContext } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';

import { PermissionsModalContext } from 'common/context';
import { useStyles } from './PermissionsModal.style';
import UserProfile from './components/UserProfile';
import PermissionsControl from './components/PermissionsControl';

const PermissionsModal = () => {
  const {
    showPermissionsModal,
    selectedCandidate,
    handleClosePermissionsModal,
  } = useContext(PermissionsModalContext);
  const classes = useStyles();

  if (!selectedCandidate) {
    return null;
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={showPermissionsModal}
      onClose={handleClosePermissionsModal}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <DialogTitle>MANAGE PERMISSIONS</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <UserProfile candidate={selectedCandidate} parent="permissions" />
          <PermissionsControl candidate={selectedCandidate} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PermissionsModal;
