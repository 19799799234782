import axios from 'axios';
import ACTION_ITEMS from '../../../actionItems';

export function deleteGroup(groupId) {
  return (dispatch) => {
    axios
      .put(`/sponsor/group/remove/group`, {
        groupId,
      })
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          dispatch(removeGroup(groupId));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function removeGroup(groupId) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.GROUPS.DELETE,
    payload: { group: { id: groupId } },
  };
}
