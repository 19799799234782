/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Container, Grid, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import {
  Info as InfoIcon,
  Assessment as AssessmentIcon,
  People as PeopleIcon,
} from '@material-ui/icons';

import { Provider } from 'common/helpers/context';
import BasicInformation from '../Dashboard/components/Passport/components/BasicInformation';
import TechnicalAssessmentResults from '../Dashboard/components/Passport/components/TechnicalAssessmentResults';
import PsychometricAssessmentResults from '../Dashboard/components/Passport/components/PsychometricAssessmentResults';
import FooterLogo from '../Dashboard/components/Passport/components/FooterLogo';
import {
  backgroundColor,
  useStyles,
} from '../Dashboard/components/Passport/Passport.style';
import { getPassportByHash } from './actions/getPassportByHash';
import { setPassport } from '../Dashboard/components/Passport/actions/setPassport';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`passport-tabpanel-${index}`}
      aria-labelledby={`passport-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `passport-tab-${index}`,
    'aria-controls': `passport-tabpanel-${index}`,
  };
}

const PassportShare = (props) => {
  const classes = useStyles();

  const { passport, match } = props;
  const { shareHash } = match.params;

  const [tabNo, setTabNo] = useState(0);

  useEffect(() => {
    props.getPassportByHash(shareHash);

    return () => {
      props.setPassport({
        candidate: null,
        technical: [],
        psychometric: [],
      });
    };
  }, []);

  const getContext = () => ({
    passport,
  });

  const handleChange = (event, newValue) => {
    setTabNo(newValue);
  };

  const tabs = [
    <Tab
      key={`tabHeader_0`}
      icon={<InfoIcon />}
      label={isMobile ? 'Info' : 'Basic Information'}
    />,
    <Tab
      key={`tabHeader_1`}
      icon={<AssessmentIcon />}
      label={isMobile ? 'Tech' : 'Technical Results'}
    />,
    <Tab
      key={`tabHeader_2`}
      icon={<PeopleIcon />}
      label={isMobile ? 'Psyche' : 'Psychometric Results'}
    />,
  ];

  const tabPanels = [
    <BasicInformation isAnon={passport.isAnon} />,
    <TechnicalAssessmentResults />,
    <PsychometricAssessmentResults />,
  ];

  return (
    <Provider value={getContext()}>
      {/* This will apply styling changes to document head */}
      <Helmet>
        <style>{`body { background-color: ${backgroundColor}; }`}</style>
      </Helmet>

      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={5}>
          <AppBar position="static">
            <Tabs
              value={tabNo}
              onChange={handleChange}
              aria-label="simple tabs example"
              indicatorColor="secondary"
              className={classes.tabBackground}
              centered
            >
              {tabs.map((Elem, index) => {
                const elem = React.cloneElement(Elem, {
                  className: classes.tab,
                  ...a11yProps(index),
                });
                return elem;
              })}
            </Tabs>
          </AppBar>
          {tabPanels.map((elem, index) => (
            <TabPanel key={`tabPanel_${index}`} value={tabNo} index={index}>
              {elem}
            </TabPanel>
          ))}
          <FooterLogo />
        </Grid>
      </Container>
    </Provider>
  );
};

const mapStateToProps = ({ passport }) => ({ passport });

export default connect(mapStateToProps, { getPassportByHash, setPassport })(
  PassportShare,
);
