/* eslint-disable prefer-destructuring */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Container, Grid, Link, Typography } from '@material-ui/core';
import { Provider } from 'common/helpers/context';

import { useStyles } from './SponsorPortal.style';
import DataStats from './components/DataStats';
import PendingAssessments from './components/PendingAssessments';
import TokenAssignmentTabular from './components/TokenAssignmentTabular';
import SelfSponsorProcess from './components/SelfSponsorProcess';
import YourPassports from '../CandidatePortal/components/YourPassports';
import { getUserTokens } from './actions/getUserTokens';
import { getCandidateAssignments } from '../CandidatePortal/actions/getCandidateAssignments';
// import Welcome from './components/Welcome/Welcome';

const TokenAssignmentTabularWrapper = ({
  forwardedRef,
  roleType,
  tokens,
  refresh,
}) => {
  if (tokens.length === 0) return null;

  return (
    <TokenAssignmentTabular
      forwardedRef={forwardedRef}
      roleType={roleType}
      tokens={tokens}
      refresh={refresh}
    />
  );
};

const SponsorPortal = (props) => {
  const classes = useStyles();
  const tokenAssignmentRef = useRef(null);
  const passportButtonRef = useRef(null);

  const {
    userId,
    userInfo,
    hasTokens,
    isDataBlocksEnabled,
    statistics,
    assignments,
    technicianTokens,
    engineerTokens,
  } = props;
  useEffect(() => {
    retrieveUserTokens();
    props.getCandidateAssignments(userId);
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getContext = () => ({
    userInfo,
    assignments,
  });

  let selectedToken;
  const retrieveUserTokens = () => props.getUserTokens(userId);

  if (technicianTokens.length === 1 && engineerTokens.length === 0) {
    selectedToken = technicianTokens[0];
  }
  if (engineerTokens.length === 1 && technicianTokens.length === 0) {
    selectedToken = engineerTokens[0];
  }

  const hasTokenPurchasedAssigned =
    technicianTokens.length > 0 ||
    engineerTokens.length > 0 ||
    assignments.length > 0;

  return (
    <Provider value={getContext()}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={5}>
          {/* <Welcome /> */}
          {isDataBlocksEnabled ? (
            <DataStats userId={userId} statistics={statistics} />
          ) : null}
          <PendingAssessments />
          {userInfo.intentProcess ? (
            <>
              <SelfSponsorProcess
                selectedToken={selectedToken}
                refresh={retrieveUserTokens}
                forwardedRef={tokenAssignmentRef}
                passportButtonRef={passportButtonRef}
                type={userInfo.intentType}
                processes={userInfo.intentProcess}
                assignments={assignments}
              />
            </>
          ) : null}
          {hasTokenPurchasedAssigned ? (
            <>
              <YourPassports
                assignments={assignments}
                passportButtonRef={passportButtonRef}
                shouldHide
              />
              {hasTokens ? (
                <>
                  <TokenAssignmentTabularWrapper
                    forwardedRef={tokenAssignmentRef}
                    roleType={1}
                    tokens={technicianTokens}
                    refresh={retrieveUserTokens}
                  />
                  <TokenAssignmentTabularWrapper
                    forwardedRef={tokenAssignmentRef}
                    roleType={2}
                    tokens={engineerTokens}
                    refresh={retrieveUserTokens}
                  />
                </>
              ) : null}
            </>
          ) : (
            <Grid item xs={12} className={classes.hide}>
              <Typography variant="h5" align="center">
                Contact <a href="mailto:contact@competas.com">Competas</a>, or,{' '}
                <Link href="/assessment-purchase" className={classes.purchase}>
                  purchase a token.
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Provider>
  );
};

SponsorPortal.propTypes = {
  userId: PropTypes.number.isRequired,
  userInfo: PropTypes.object.isRequired,
  hasTokens: PropTypes.bool.isRequired,
  isDataBlocksEnabled: PropTypes.bool.isRequired,
  technicianTokens: PropTypes.array.isRequired,
  engineerTokens: PropTypes.array.isRequired,
  assignments: PropTypes.array.isRequired,
  statistics: PropTypes.object.isRequired,
  getUserTokens: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  dashboard,
  sponsorPortal,
  candidatePortal,
  global,
}) => {
  const { userInfo } = dashboard;
  const { config } = global;
  const { tokens, statistics } = sponsorPortal;
  const { assignments } = candidatePortal;
  const technicianTokens = [];
  const engineerTokens = [];
  tokens.forEach((token) => {
    switch (token.type) {
      case 1:
        engineerTokens.push(token);
        break;

      case 2:
        technicianTokens.push(token);
        break;

      default:
        break;
    }
  });

  return {
    userId: userInfo ? userInfo.id : 0,
    userInfo,
    hasTokens: tokens.length > 0,
    isDataBlocksEnabled:
      !_.isNil(config) && config.settings.features.dataBlocks,
    technicianTokens,
    engineerTokens,
    assignments,
    statistics,
  };
};

export default connect(mapStateToProps, {
  getUserTokens,
  getCandidateAssignments,
})(SponsorPortal);
