/* eslint-disable react/button-has-type */
import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';

import { useStyles } from './TabBar.style';

const TabBar = ({ topicGroups }) => {
  const classes = useStyles(topicGroups)();

  return (
    <Grid item xs={12}>
      <ul className={classes.ul}>
        {topicGroups.map((topicGroup, index) => {
          if (index === 0) {
            return (
              <li key={`topic-group-${topicGroup.id}`}>
                <button
                  className={clsx(
                    classes.span,
                    classes.firstSpan,
                    classes[`topicGroup_${index}`],
                  )}
                >
                  {topicGroup.name}
                </button>
              </li>
            );
          } else if (index === topicGroups.length - 1) {
            return (
              <li key={`topic-group-${topicGroup.id}`}>
                <button
                  className={clsx(
                    classes.span,
                    classes.lastSpan,
                    classes[`topicGroup_${index}`],
                  )}
                >
                  {topicGroup.name}
                </button>
              </li>
            );
          }

          return (
            <li key={`topic-group-${topicGroup.id}`}>
              <button
                className={clsx(classes.span, classes[`topicGroup_${index}`])}
              >
                {topicGroup.name}
              </button>
            </li>
          );
        })}
      </ul>
    </Grid>
  );
};

TabBar.propTypes = {};

export default TabBar;
