import axios from 'axios';
import { push } from 'connected-react-router';
import ACTION_ITEMS from '../actionItems';

export function authenticate() {
  return (dispatch) => {
    axios
      .post('/auth/authenticate')
      .then((response) => {
        const {
          status,
          user,
          isMasquerading,
          isTeamMasquerading,
          masqueradingFrom,
          masqueradingFromTeam,
        } = response.data;
        if (status === 'SUCCESS') {
          dispatch(authenticateSuccess({
            user,
            isMasquerading,
            isTeamMasquerading,
            masqueradingFrom,
            masqueradingFromTeam,
          }));
        } else {
          dispatch(authenticateFailed());
          dispatch(push('/login'));
        }
      })
      .catch((error) => {
        dispatch(authenticateFailed());
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data.http_code === 400) {
            dispatch(push('/login'));
          } else {
            console.log(error);
          }
        } else {
          console.log(error);
        }
      });
  };
}

function authenticateSuccess({
  user,
  isMasquerading,
  isTeamMasquerading,
  masqueradingFrom,
  masqueradingFromTeam,
}) {
  return {
    type: ACTION_ITEMS.AUTHENTICATE.POST.SUCCESS,
    payload: {
      user,
      isMasquerading,
      isTeamMasquerading,
      masqueradingFrom,
      masqueradingFromTeam,
    },
  };
}

function authenticateFailed() {
  return {
    type: ACTION_ITEMS.AUTHENTICATE.POST.FAILED,
  };
}
