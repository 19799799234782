import React from 'react';
import { IconButton, MenuItem, Typography, ListItemText } from '@material-ui/core';
import moment from 'moment';
import CommentIcon from '@material-ui/icons/Comment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import NotificationsIcon from '@material-ui/icons/Notifications';

const AlertItem = (props) => {
  const { alert } = props;

  const renderIcon = (alertItem) => {
    const { label, seen } = alertItem;
    let color;
    if(seen) { color = ''; } else { color = 'primary'; }

    if(label === "MESSAGE") {
      return (
        <CommentIcon color={color} fontSize="small" />
      );
    }
    if(label === "APPLICATION") {
      return (
        <MailOutlineIcon color={color} fontSize="small" />
      );
    }
    if(label === "COMPLAINT") {
      return (
        <FlashOnIcon color={color} fontSize="small" />
      );
    }
    return (
      <NotificationsIcon color={color} fontSize="small" />
    );
  };

  const isSelected = () => {
    if(alert.seen) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <MenuItem selected={isSelected()} dense>
      <IconButton>
        {renderIcon(alert)}
      </IconButton>

      <ListItemText>
        <Typography variant="overline">
          {alert.label}
        </Typography>
        <Typography variant="body1">
          {alert.text}
        </Typography>
        <Typography variant="caption">
          {moment(alert.time).fromNow()}
        </Typography>
      </ListItemText>
    </MenuItem>
  );
};

export default AlertItem;
