import './ProjectBuilder.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import Alert from 'common/components/Alert';
import MUICookieConsent from 'material-ui-cookie-consent';
import UnderConstruction from 'common/components/UnderConstruction';
import PassportShare from 'components/PassportShare';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import CandidateOnboarding from './components/CandidateOnboarding';
import ForgotPassword from './components/ForgotPassword';
import Intercom from './components/Intercom';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import DataProtection from './components/Legal/DataProtection';
import DataRetention from './components/Legal/DataRetention';

const App = () => {
  return (
    <div className="App">
      {process.env.REACT_APP_ENV === 'production' ? (
        <Helmet>
          <script>
            {`
              <!-- Hotjar Tracking Code for https://app-portal.competas.com -->
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:2684921,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
          </script>
        </Helmet>
      ) : null}
      <CssBaseline />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/signup/candidate/:candidateId" component={Signup} />
        <Route path="/signup" component={Signup} />
        <Route path="/candidate-onboarding" component={CandidateOnboarding} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/data-protection-policy" component={DataProtection} />
        <Route path="/data-retention-policy" component={DataRetention} />
        <Route path="/passport-share/:shareHash" component={PassportShare} />
        <Route path="/" component={Dashboard} />
      </Switch>
      <Intercom />
      <Alert />
      <MUICookieConsent
        cookieName="competasCookieConsent"
        componentType="Snackbar" // default value is Snackbar
        message="We use cookies to give you the best online experience. By clicking on Accept you agree to our terms and conditions."
      />
      <UnderConstruction />
    </div>
  );
};

export default App;
