import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  header: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'Nunito',
    letterSpacing: '0.1rem',
  },
}));
