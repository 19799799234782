import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useCommonStyles } from 'common/common.style';
import TabBar from './components/TabBar';
import BarChart from './components/BarChart';
import { useStyles } from '../../PsychometricAssessmentResults.style';

const ResultGroup = ({ groups }) => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();

  return (
    <Grid item xs={12} className={classesCommon.noLefRightPadding}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.subheader}>
              Behavioural Summary
            </Typography>
          </Grid>
          <TabBar topicGroups={groups} />
          <BarChart
            canvassId="bar-canvass-role-discipline-specific"
            title="Role/Discipline Specific"
            topicGroups={groups}
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

ResultGroup.propTypes = {
  groups: PropTypes.array,
};

ResultGroup.defaultProps = {
  groups: [],
};

export default ResultGroup;
