import axios from 'axios';
import Swal from 'sweetalert2';
import { fetchAllProjects } from './fetchAllProjects';

export function addProject(name, description, candidate = false) {
  return (dispatch) => {
    return axios
      .post(`/project`, { name, description })
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          if(!candidate) {
            Swal.fire({
              title: 'Success',
              text: 'Successfully added a project',
              icon: 'success',
              confirmButtonColor: '#3f51b5',
            });
          }
          dispatch(fetchAllProjects());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
