import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { resendInvitation } from './actions/resendInvitation';
import MenuWrapper from '../MenuWrapper';

const AssignedActionMenu = (props) => {
  const { isSelfSponsored, candidate, onClickBookCandidate } = props;
  const buttons = [];

  if (!candidate) {
    return null;
  }

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleClickBookCandidate = () => {
    onClickBookCandidate();
  };

  const handleClickResendInvitation = (event) => {
    stopPropagation(event);
    props.resendInvitation(candidate.id);
  };

  const enableBookCandidate =
    candidate.user.feedback === 1 &&
    candidate.verified &&
    candidate.flags.doneSelfAssessment &&
    !candidate.flags.hasChangeRequest;
  if (enableBookCandidate) {
    buttons.push(
      <Button
        key="menu-item-button-book"
        size="small"
        variant="contained"
        color="primary"
        disabled={!enableBookCandidate}
        onClick={handleClickBookCandidate}
        fullWidth
      >
        Book Assessment
      </Button>,
    );
  }

  const enableResendEmail = !candidate.flags.doneSelfAssessment;
  if (enableResendEmail && !isSelfSponsored) {
    buttons.push(
      <Button
        key="menu-item-button-resend"
        size="small"
        variant="contained"
        color="primary"
        disabled={!enableResendEmail}
        onClick={handleClickResendInvitation}
        fullWidth
      >
        Resend Invitation Email
      </Button>,
    );
  }

  return <MenuWrapper>{buttons}</MenuWrapper>;
};

export default connect(null, { resendInvitation })(AssignedActionMenu);
