import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    height: '100%',
    width: '100%',
    borderRadius: '10px',
    marginTop: '4px',
  },
  cardContent: {
    padding: '5px',
  },
  add: {
    marginLeft: '10px',
  },
}));
