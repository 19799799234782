const ACTION_ITEMS = {
  AUTHENTICATE: {
    POST: {
      SUCCESS: 'AUTHENTICATE_POST_SUCCESS',
      FAILED: 'AUTHENTICATE_POST_FAILED',
    },
  },
  USER_INFO: {
    MESSAGE: {
      SET: 'USER_INFO_MESSAGE_SET',
    },
    IS_LOADING: {
      SET: 'USER_INFO_IS_LOADING',
    },
    PASSPORT_PHOTO: {
      SET: 'USER_INFO_PASSPORT_PHOTO_SET',
    },
  },
};

export default ACTION_ITEMS;
