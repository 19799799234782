import axios from 'axios';
import Swal from 'sweetalert2';

export function submitBooking(formData, callback) {
  return () => {
    Swal.fire({
      title: 'processing...',
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .post('/user/booking', formData)
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          Swal.hideLoading();
          Swal.fire({
            title: 'Success!',
            text: 'Competas are now reviewing your booking, please allow up to 24 hours for this process to complete.  You will be notified by email.  Should your application be rejected, you will receive a full refund.',
            icon: 'success',
            confirmButtonColor: '#3f51b5',
          }).then(() => {
            if (callback) callback();
          });
        } else if (callback) {
          callback();
        }
      })
      .catch((error) => {
        Swal.hideLoading();
        if (callback) callback();
        if (error.response && error.response.data) {
          Swal.fire({
            title: 'Error',
            text: error.response.data.status_message,
            icon: 'error',
          }).then(() => {
            if (callback) callback();
          });
        } else {
          console.error(error);
        }
      });
  };
}
