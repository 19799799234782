import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'common/components/PageHeader';
import {
  Box,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import Copyright from 'common/components/Copyright';

const PrivacyPolicy = (props) => {
  const { showLayout } = props;

  const renderHeader = () => {
    if(!showLayout) {
      return(null);
    }

    return (
      <PageHeader />
    );
  };

  const renderFooter = () => {
    if(!showLayout) {
      return(null);
    }

    return (
      <Box mb={5}>
        <Copyright />
      </Box>
    );
  };

  return (
    <div>
      {renderHeader()}
      <Box mt={5} mb={5}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3" component="h1" gutterBottom>Privacy Policy</Typography>

              <Typography variant="body1" gutterBottom>
                Your personal data is important to both you and us and it requires respectful and careful protection.  This privacy policy informs you of our privacy practices and of the choices you can make about the way we hold information about you.  We are committed to complying with the GDPR (2016) and the Data Protection Act (2018) and good business practices.  We are both a data controller and a data processor.
              </Typography>

              <Typography variant="body1" gutterBottom>
                This is our privacy policy so please be aware that should you follow a link to another website, you are no longer covered by this policy. It’s a good idea to understand the privacy policy of any website before sharing personal information with it.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                What personal information do we collect from the people that visit our blog, website or app?
              </Typography>

              <Typography variant="body1" gutterBottom>
                When registering on our site, you may be asked to enter your name, address, email address, telephone numbers, date of birth, gender and credit card information. 
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                When do we collect information?
              </Typography>

              <Typography variant="body1" gutterBottom>
                We collect information from you when you register on our site, fill out a form or otherwise enter information on our site.  
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                How do we use your information?
              </Typography>

              <Typography variant="body1" gutterBottom>
                We may use the information we collect from you when you register, sign up for a subscription, sign up for our newsletter, respond to a survey or marketing communication, visit the website, or use certain other site features in the following ways: 
              </Typography>

              <ul>
                <li>To personalise your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                <li>To improve our website in order to better serve you.</li>
                <li>To quickly process your transactions.</li>
                <li>To send periodic emails regarding your order in relation to purchased products and services.</li>
                <li>To follow up with you after correspondence (live chat, email or phone inquiries).</li>
                <li>To perform necessary security checks to ensure the safety of your account.</li>
              </ul>

              <Typography variant="body1" gutterBottom>
                Other relevant details that you provide in relation to the services you receive from us may be added to your data, but anything not required will be deleted immediately.  We will not ask for any irrelevant information.  We will not hold or process any special category personal data at any time.  If your contact details change, please advise us and we will update our records accordingly.  We do not carry out automated decision making or any type of automated profiling.  We will always process your data in a fair and lawful way in accordance with article 5 and article 6 of the GDPR.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Retaining your data
              </Typography>

              <Typography variant="body1" gutterBottom>
                We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.  Our data retention policy considers the amount of data, its nature and sensitivity, the potential risk of harm from unauthorised use or disclosure, the processing purposes and if these can be achieved by other means and legal requirements.
              </Typography>

              <Typography variant="body1" gutterBottom>
                For tax purposes the law requires us to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for xxx years after they stop being customers.  If you have had no communication with us for xxx years, we will delete all your personal information.
              </Typography>

              <Typography variant="body1" gutterBottom>
                In some circumstances we may anonymise your personal data for research or statistical purposes in which case we may use this information indefinitely without further notice to you. You will not be recognisable as a natural living person from this anonymised data.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Your rights
              </Typography>

              <Typography variant="body1" gutterBottom>
                The GDPR provides the following rights for individuals:
              </Typography>

              <ol>
                <li>The right to be informed</li>
                <li>The right of access</li>
                <li>The right to rectification</li>
                <li>The right to erasure</li>
                <li>The right to restrict processing</li>
                <li>The right to data portability</li>
                <li>The right to object</li>
                <li>Rights in relation to automated decision making and profiling.</li>
              </ol>

              <Typography variant="body1" gutterBottom>
                You have a right to see what information that we hold about you and you can get in contact with our Data Protection Officer at the following address: <a href="mailto:contact@competas.com">contact@competas.com</a>.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Under the GDPR you have the right to request a copy of the personal information Competas Global Ltd hold about you and to have any inaccuracies corrected or information deleted.  You will need to prove your identity with 2 pieces of approved identification which can be a:  passport, driving licence, birth certificate, utility bill (from last 3 months), current vehicle registration document, bank statement (from last 3 months) or a rent book (from last 3 months).  We will verify your identity, noting how and when we verified it, then we will immediately delete that data.  We will send you a form which clarifies what information you are looking for and to verify your identity.
              </Typography>

              <Typography variant="body1" gutterBottom>
                If you can advise of the specific information that you require, we can process your request more quickly.  We will respond to your request within one month of you providing information that confirms your identity. You are obviously entitled to all your personal information.
              </Typography>

              <Typography variant="body1" gutterBottom>
                We will respond within one month, giving you a copy of your data, why we have it, who it could be disclosed to, the categories of data it involves, and it will be in a format that you can access easily.  You have the right to clarify and correct the information as necessary.  It can be deleted providing that it is not required for legal or public interest reasons.  If your request is more complex, for example it involves other data subjects and we need their consent to release the relevant information we can extend our response time to three months, but we will inform you of this.  If they do not give their consent, we will anonymise this data or remove the relevant detail before sending this to you.  We will not charge for data subject access requests unless they are excessive or manifestly unfounded.  Then we will charge for administrative time only.
              </Typography>

              <Typography variant="body1" gutterBottom>
                If you are not satisfied with our response, please get in touch and we will do our best to help you: <a href="mailto:contact@competas.com">contact@competas.com</a>.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                How do we secure your information?
              </Typography>

              <Typography variant="body1" gutterBottom>
                Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible. 
              </Typography>

              <Typography variant="body1" gutterBottom>
                Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.  
              </Typography>

              <Typography variant="body1" gutterBottom>
                We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.
              </Typography>

              <Typography variant="body1" gutterBottom>
                All transactions are processed through a gateway provider and are not stored or processed on our servers. 
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Do we use ‘cookies’?
              </Typography>

              <Typography variant="body1" gutterBottom>
                Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognise your browser and capture and remember certain information. 
              </Typography>

              <Typography variant="h6" component="h2">
                We use cookies to:
              </Typography>

              <Typography variant="body1" gutterBottom>
                Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since each browser is a little different, please look at your browser’s Help Menu to learn the correct way to modify your cookies.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Third-party disclosure
              </Typography>

              <Typography variant="body1" gutterBottom>
                We do not sell, trade, or otherwise transfer your personal data to outside parties. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We ensure that our suppliers and third parties are GDPR compliant and are aware of data protection requirements.
              </Typography>

              <Typography variant="body1" gutterBottom>
                We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property or safety, only to those parties that are entitled to receive it. 
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Personal Data Breaches
              </Typography>

              <Typography variant="body1" gutterBottom>
                We have procedures in place to deal with any suspected personal data breach and will notify you and any supervisory body of a breach if we are legally required to.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Third-party links
              </Typography>

              <Typography variant="body1" gutterBottom>
                Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Google
              </Typography>

              <Typography variant="body1" gutterBottom>
                This site uses Google Analytics and Google AdWords.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                We have implemented the following:
              </Typography>

              <Typography variant="body1" gutterBottom>
                Remarketing with Google AdWords Demographics and Interests Reporting We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website.  
              </Typography>

              <Typography variant="h6" component="h2">
                Opting out:
              </Typography>

              <Typography variant="body1" gutterBottom>
                Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on. Data subject requests/deletion can be submitted via email to contact@competas.com. 
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Age of consent
              </Typography>

              <Typography variant="body1" gutterBottom>
                By using this site, you represent that you are at least the age of majority in your county of residence, or that you are the age of majority in your county of residence.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Unsubscribe
              </Typography>

              <Typography variant="body1" gutterBottom>
                If at any time you would like to unsubscribe from receiving future emails, you can unsubscribe at the bottom of each email, or send an email to <a href="mailto:contact@competas.com">contact@competas.com</a> and we will promptly remove you from all correspondence.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Changes to this Privacy Policy
              </Typography>

              <Typography variant="body1" gutterBottom>
                We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated; so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. 
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Concerns
              </Typography>

              <Typography variant="body1" gutterBottom>
                If you have any concerns about your data then please get in touch and we will do our best to help you at <a href="mailto:contact@competas.com">contact@competas.com</a>
              </Typography>

              <Typography variant="body1" gutterBottom>
                If you have any concerns about how your data is being used or processed and we have not been able to help you, then you can contact the ICO. Ways to report concerns are detailed on their website: <a href="https://ico.org.uk/concerns/">https://ico.org.uk/concerns/</a>
              </Typography>

              <Typography variant="body1" gutterBottom>
                If you have any questions, comments or ways we can improve please get in touch.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {renderFooter()}
    </div>
  );
};

PrivacyPolicy.propTypes = {
  showLayout: PropTypes.bool,
};

PrivacyPolicy.defaultProps = {
  showLayout: true,
};

export default PrivacyPolicy;
