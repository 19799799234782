/* eslint-disable react/jsx-props-no-spreading */

import './RequestSummary.css';
import React from 'react';
import { connect } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 0 0`,
  padding: 0,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const renderSummary = (request, rowClass, selectRequest) => {
  const { candidate } = request;

  return (
    <div
      className="request-list__item"
      style={{ width: '100%', cursor: 'pointer' }}
      onClick={() => selectRequest(request)}
    >
      <div className="request-list__item__detail">
        <Box fontWeight="fontWeightBold">
          <Typography variant="overline" display="block">
            ID
          </Typography>
        </Box>
        <Typography color="textSecondary" variant="caption">
          {candidate.crn}
        </Typography>
      </div>

      <div className="request-list__item__detail">
        <Box fontWeight="fontWeightBold">
          <Typography variant="overline" display="block">
            Company
          </Typography>
        </Box>
        <Typography color="textSecondary" variant="caption">
          {candidate.user.company || 'n/a'}
        </Typography>
      </div>

      <div className="request-list__item__detail">
        <Box fontWeight="fontWeightBold">
          <Typography variant="overline" display="block">
            Project
          </Typography>
        </Box>
        <Typography color="textSecondary" variant="caption">
          {/* {request.project} */}
          n/a
        </Typography>
      </div>
    </div>
  );
};

const RequestSummary = (props) => {
  const { request, selectRequest, selectedRequest, index } = props;

  let rowClass = '';
  if (selectedRequest) {
    rowClass = selectedRequest.id === request.id ? 'selected-row' : '';
  }

  return (
    <Draggable draggableId={`request-${request.id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
          className="request-summary"
        >
          {renderSummary(request, rowClass, selectRequest)}
        </div>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => {
  return { selectedRequest: state.teamViewer.selectedRequest };
};

export default connect(mapStateToProps)(RequestSummary);

/* eslint-enable react/jsx-props-no-spreading */
