import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '8px',
    padding: '5px 10px',
    borderRadius: '5px',
  },
  selected: {
    border: '2px solid #084ab3',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleText: {
    fontSize: '0.9rem',
    textTransform: 'uppercase',
  },
  levelContainer: {
    display: 'flex',
    gap: 10,
    marginTop: '10px',
  },
  level: {
    border: 0,
    alignSelf: 'center',
    display: 'block',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold',
    color: 'gray',
  },
  level0Selected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  level1Selected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  level2Selected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  level3Selected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  level4Selected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  level5Selected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));
