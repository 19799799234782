import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Paper,
  Button,
  Switch,
  withStyles,
  Tooltip,
  Typography,
  Box,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  PlayForWork as PlayForWorkIcon,
} from '@material-ui/icons';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { withContext } from 'common/helpers/context';
import CustomSelect from 'common/components/CustomSelect';
import { useCommonStyles } from 'common/common.style';
import { useStyles } from './SearchBar.style';
import LoadPositionModal from '../LoadPositionModal/LoadPositionModal';

const PurpleSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        // thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const mapValues = (e) => ({
  label: e.name,
  value: e.id,
});

const SearchBar = (props) => {
  const classesCommon = useCommonStyles();
  const classes = useStyles();

  const formikRef = useRef();
  const [showLoadPosition, setShowLoadPosition] = useState(false);

  const {
    roleId,
    setRoleId,
    disciplineId,
    setDisciplineId,
    region,
    setRegion,
    options,
    handleClickSearch,
  } = props;
  const { roles, disciplines } = options;

  const OPTIONS_ROLE = roles.map(mapValues);
  const OPTIONS_DISCIPLINE = disciplines
    .filter((discipline) => ![1, 2].includes(discipline.id))
    .map(mapValues);

  useEffect(() => {
    if (roles.length > 0 && disciplines.length > 0) {
      formikRef.current.setFieldValue('roleId', roleId);
      formikRef.current.setFieldValue('disciplineId', disciplineId);
      formikRef.current.setFieldValue('region', region);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, roles, disciplineId, disciplines, region]);

  const handleClickLoadPosition = () => {
    setShowLoadPosition(true);
  };

  const handleCloseLoadPosition = () => {
    setShowLoadPosition(false);
  };

  return (
    <Grid item xs={12}>
      <Paper className={classesCommon.paper}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            roleId: '',
            disciplineId: '',
            region: false,
          }}
          onSubmit={handleClickSearch}
          validationSchema={Yup.object().shape({
            roleId: Yup.number().required('Required'),
            disciplineId: Yup.number().required('Required'),
            region: Yup.boolean().required('Required'),
          })}
        >
          {(p) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = p;

            const handleChangeToPass = (id) => (event) => {
              if (id === 'roleId') setRoleId(event.target.value);
              if (id === 'disciplineId') setDisciplineId(event.target.value);
              if (id === 'region') setRegion(event.target.checked);
              handleChange(id, event);
            };

            const propsToPass = {
              values,
              touched,
              errors,
              handleChange: handleChangeToPass,
              handleBlur,
            };
            const disableSearch =
              values.roleId === 0 || values.disciplineId === 0;

            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} className={classes.container}>
                  <CustomSelect
                    id="roleId"
                    labelText="Role"
                    options={OPTIONS_ROLE}
                    gridSize={4}
                    {...propsToPass}
                  />
                  <CustomSelect
                    id="disciplineId"
                    labelText="Discipline"
                    options={OPTIONS_DISCIPLINE}
                    gridSize={3}
                    {...propsToPass}
                  />
                  <Grid item xs="auto" className={classes.buttonGroup}>
                    <Box className={classes.switchContainer}>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Tooltip
                            title={`"Local" searches only Passports which you have access to.`}
                          >
                            <Grid item>Local</Grid>
                          </Tooltip>
                          <Grid item>
                            <PurpleSwitch
                              id="region"
                              name="region"
                              color="primary"
                              checked={values.region}
                              onChange={handleChangeToPass('region')}
                            />
                          </Grid>
                          <Tooltip
                            title={`"Global" searches the entire Competas database, you may request access to private Passports.`}
                          >
                            <Grid item>Global</Grid>
                          </Tooltip>
                        </Grid>
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      startIcon={<SearchIcon />}
                      className={classes.button}
                      disabled={disableSearch}
                    >
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      startIcon={<PlayForWorkIcon />}
                      className={classes.button}
                      disabled={disableSearch}
                      onClick={handleClickLoadPosition}
                    >
                      Load Position
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Paper>
      <LoadPositionModal
        open={showLoadPosition}
        handleClose={handleCloseLoadPosition}
      />
    </Grid>
  );
};

SearchBar.propTypes = {
  userInfo: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userInfo: state.dashboard.userInfo,
  options: state.options,
});

export default connect(mapStateToProps)(withContext(SearchBar));
