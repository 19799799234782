const ACTION_ITEMS = {
  INTEGRITY_REVIEW: {
    ASSIGNMENTS: {
      SET: 'INTEGRITY_REVIEW_SET_ASSIGNMENTS',
      REMOVE: 'INTEGRITY_REVIEW_REMOVE_ASSIGNMENT',
    },
  },
};

export default ACTION_ITEMS;
