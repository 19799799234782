import ACTION_ITEMS from './actionItems';

const initialState = {
  purchaseHistory: [],
};

export const purchaseHistoryReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.ASSESSMENT_PURCHASE.PURCHASE_HISTORY.SET: {
      newState = { ...state, purchaseHistory: action.payload };
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
