const addIfExists = (arr, value) => {
  if (value) {
    arr.push(value);
  }
};

const resolveLocation = (user) => {
  const { country, state, streetAddress, postCode } = user;
  const location = [];
  if(country) {
    addIfExists(location, country.name);
  }
  addIfExists(location, state);
  addIfExists(location, streetAddress);

  return `${location.join(', ')} ${postCode}`;
};

export default resolveLocation;
