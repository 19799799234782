import { makeStyles } from '@material-ui/core/styles';
// import image from 'assets/images/942d11cf69a97a3b9625f796c2b3db1de1e20ac0';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  helperSection: {
    padding: theme.spacing(20, 4),
    // backgroundColor: '#F4F6FC',
  },
  footer: {
    marginTop: '50px',
  },
}));
