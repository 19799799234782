import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { redirect } from 'common/actions';
import { getCandidateAssignments } from '../../../CandidatePortal/actions/getCandidateAssignments';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PendingAssessments = (props) => {
  const { userId, assignments } = props;
  useEffect(() => {
    props.getCandidateAssignments(userId);
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const pendingAssessments = assignments.filter(
    ({ candidate, token }) =>
      (!candidate.verified || !candidate.flags.doneSelfAssessment) &&
      // Don't show alert for verifying role-discipline and uploading photo
      userId !== token.sponsor.id,
  );

  if (pendingAssessments.length === 0) return null;

  const handleClick = (url) => () => props.redirect(url);

  return (
    <Grid item xs={12}>
      {pendingAssessments.map((assignment) => {
        let url;
        let message;
        let name;
        let buttonLabel;
        const { token, candidate } = assignment;
        const { sponsor } = token;

        if (userId === sponsor.id) {
          name = 'yourself';
        } else {
          name = `${sponsor.firstName} ${sponsor.lastName}`;
        }

        if (!candidate.verified) {
          url = `/candidate-onboarding/verify/info/${candidate.id}`;
          if (name === 'yourself') {
            message = `Please verify your role-discipline and profile picture.`;
          } else {
            message = `You have a pending verification from ${name}.`;
          }
          buttonLabel = 'Verify';
        } else if (!candidate.flags.doneSelfAssessment) {
          url = `/candidate-onboarding/assessment/${candidate.id}`;
          message = `You have a pending self assessment from ${name}.`;
          buttonLabel = 'Start Assessment';
        }

        return (
          <Alert severity="warning" key={`assignment-${assignment.id}`}>
            <Typography variant="subtitle1">
              {message}{' '}
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleClick(url)}
              >
                {buttonLabel}
              </Button>
            </Typography>
          </Alert>
        );
      })}
    </Grid>
  );
};

PendingAssessments.propTypes = {
  userId: PropTypes.number.isRequired,
  getCandidateAssignments: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
};

const mapStateToProps = ({ dashboard, candidatePortal }) => {
  const { userInfo } = dashboard;
  const { assignments } = candidatePortal;
  return {
    userId: userInfo.id,
    assignments,
  };
};

export default connect(mapStateToProps, { getCandidateAssignments, redirect })(
  PendingAssessments,
);
