import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ProjectCard from './components/ProjectCard/ProjectCard';

import { useStyles } from './ProjectList.style';
import AddProjectModal from '../AddProjectModal/AddProjectModal';
import { fetchAllProjects } from '../../actions/fetchAllProjects';

const ProjectList = (props) => {
  const classes = useStyles();

  const { projects, selectedProject } = props;

  const [addProjectModal, setAddProjectModal] = useState(false);

  useEffect(() => {
    props.fetchAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddProject = () => {
    setAddProjectModal(true);
  };

  const handleAddProjectModalClose = () => {
    setAddProjectModal(false);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5">
        Projects
        <Fab
          className={classes.add}
          color="primary"
          aria-label="add"
          size="small"
          onClick={handleAddProject}
        >
          <AddIcon />
        </Fab>
      </Typography>
      <div className={classes.listContainer}>
        {projects.map((project) => {
          const isSelected = !!(
            selectedProject && selectedProject.id === project.id
          );
          return (
            <ProjectCard
              key={project.id}
              project={project}
              selected={isSelected}
            />
          );
        })}
      </div>
      <AddProjectModal
        open={addProjectModal}
        handleClose={handleAddProjectModalClose}
      />
    </div>
  );
};

const mapStateToProps = ({ projectBuilder: { projects, selectedProject } }) => {
  return { projects, selectedProject };
};

export default connect(mapStateToProps, { fetchAllProjects })(ProjectList);
