/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { Grid, Typography } from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { setSelectedGroup } from '../../../../../../actions/setSelectedGroup';
import ActionMenu from '../../../../../../../ActionMenu';

const grid = 16;

const getItemStyle = (isDragging, isDragDisabled, draggableStyle) => ({
  // some basic styles to make the candidates look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',
  border: isDragDisabled ? '2px dashed lightblue' : '2px solid lightblue',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const GroupCandidate = (props) => {
  const { index, group, candidate, showCandidateModal } = props;
  const { user, crn } = candidate;
  const isDragDisabled = candidate.id < 0;

  let title;
  let subtext;
  if (isDragDisabled) {
    title = 'Your group members will display here';
    subtext = 'click an empty slot to add';
  } else {
    title = `${user.firstName} ${user.lastName}`;
    subtext = `ID: ${crn}`;
  }

  const handleClick = () => {
    props.setSelectedGroup(group);
    showCandidateModal();
  };

  const renderCandidate = () => {
    if (isDragDisabled) {
      return (
        <div onClick={handleClick}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {subtext}
          </Typography>
        </div>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {subtext}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <ActionMenu user={candidate} groupId={group.id} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Draggable
        draggableId={`candidate-${candidate.id}`}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              isDragDisabled,
              provided.draggableProps.style,
            )}
          >
            {renderCandidate()}
          </div>
        )}
      </Draggable>
    </Grid>
  );
};

GroupCandidate.propTypes = {
  index: PropTypes.number.isRequired,
  group: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedGroup: (group) => dispatch(setSelectedGroup(group)),
});

export default connect(null, mapDispatchToProps)(withContext(GroupCandidate));
