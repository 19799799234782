import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Store as StoreIcon } from '@material-ui/icons';

const AssessmentPurchaseDrawerItem = ({ show, selected, onClick }) => {
  if (!show) return null;

  const handleClick = () => {
    onClick(AssessmentPurchaseDrawerItem.pathname);
  };

  return (
    <ListItem button selected={selected} onClick={handleClick}>
      <ListItemIcon>
        <StoreIcon />
      </ListItemIcon>
      <ListItemText primary="Purchase an Assessment" />
    </ListItem>
  );
};

AssessmentPurchaseDrawerItem.propTypes = {
  show: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

AssessmentPurchaseDrawerItem.defaultProps = {
  show: true,
  selected: false,
};

AssessmentPurchaseDrawerItem.pathname = '/assessment-purchase';

export default AssessmentPurchaseDrawerItem;
