const ACTION_ITEMS = {
  PSYCHOMETRIC: {
    CANDIDATES: {
      SET: 'PSYCHOMETRIC_CANDIDATES_SET',
    },
    QUESTION: {
      SET: 'PSYCHOMETRIC_QUESTION_SET',
    },
    SELECTED_CANDIDATE: {
      SET: 'PSYCHOMETRIC_SELECTED_CANDIDATE_SET',
    },
    REMAINING_TIME: {
      SET: 'PSYCHOMETRIC_REMAINING_TIME_SET',
    },
  },
};

export default ACTION_ITEMS;
