import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, FormControl } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const CustomDateTimePicker = ({
  id,
  labelText,
  format,
  values,
  touched,
  errors,
  setFieldValue,
  shouldDisableDate,
  handleBlur,
  autoFocus,
  disabled,
  disablePast,
  disableFuture,
  showTodayButton,
  minDate,
  gridSize,
}) => {
  const errorMessage = errors[id];
  const hasError =
    !_.isNil(errorMessage) && !_.isNil(touched[id]) && touched[id];

  return (
    <Grid item xs={gridSize}>
      <FormControl fullWidth error={hasError}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            ampm={false}
            disabled={disabled}
            autoFocus={autoFocus}
            showTodayButton={showTodayButton}
            id={id}
            inputVariant="outlined"
            label={labelText}
            value={values[id]}
            format={format}
            onChange={(value) => setFieldValue(id, value)}
            onBlur={handleBlur(id)}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasError}
            helperText={hasError ? errorMessage : null}
            disablePast={disablePast}
            disableFuture={disableFuture}
            minDate={minDate}
            shouldDisableDate={shouldDisableDate}
            clearable
            autoOk
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </Grid>
  );
};

CustomDateTimePicker.propTypes = {
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  showTodayButton: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  minDate: PropTypes.object,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  format: PropTypes.string,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  handleBlur: PropTypes.func.isRequired,
  gridSize: PropTypes.number,
};

CustomDateTimePicker.defaultProps = {
  disabled: false,
  autoFocus: false,
  showTodayButton: false,
  disablePast: false,
  disableFuture: false,
  minDate: undefined,
  gridSize: 6,
  format: 'YYYY-MM-DD HH:mm',
  setFieldValue: null,
  shouldDisableDate: null,
};

export default CustomDateTimePicker;
