import axios from 'axios';
import Swal from 'sweetalert2';
import { fetchAllPositionSkills } from './fetchAllPositionSkills';

export function updatePositionSkill(positionId, positionSkillId, params) {
  return (dispatch) => {
    return axios
      .patch(`/position/skill/${positionSkillId}`, params)
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          Swal.fire({
            title: 'Success',
            text: 'Successfully updated a skill',
            icon: 'success',
            confirmButtonColor: '#3f51b5',
          });
          dispatch(fetchAllPositionSkills(positionId));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
