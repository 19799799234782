import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  showControls: {
    '& input[type=number]::-webkit-inner-spin-button': {
      opacity: 1,
    },
  },
}));
