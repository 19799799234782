import ACTION_ITEMS from './actionItems';

const initialState = {
  assignments: [],
};

/* eslint-disable no-case-declarations */
export const integrityReviewReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.INTEGRITY_REVIEW.ASSIGNMENTS.SET:
      newState = { ...state, assignments: action.payload };
      break;

    case ACTION_ITEMS.INTEGRITY_REVIEW.ASSIGNMENTS.REMOVE:
      const newAssignmentList = state.assignments.filter(a => a.id !== action.payload.id);
      newState = { ...state, assignments: newAssignmentList };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
};
/* eslint-enable no-case-declarations */
