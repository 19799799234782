/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Grid, Button } from '@material-ui/core';
import { Provider } from 'common/helpers/context';
import { LEVEL_DEFEINITIONS as levelDetails } from 'common/constants';
import Topic from './components/Topic';
import resource from './AreaOfExpertise.resource';
import { submitAssessment } from './actions/submitAssessment';

const AreaOfExpertise = (props) => {
  const {
    candidateId,
    isSelfSponsored,
    disciplines,
    hasSafety,
    hasCompletions,
  } = props;

  /* eslint-disable */
  const [assessment, setAssessment] = useState(null);
  const [lvl0Triggered, setLvl0Triggered] = useState(false);
  const [lvl1Triggered, setLvl1Triggered] = useState(false);
  const [lvl2Triggered, setLvl2Triggered] = useState(false);
  const [lvl3Triggered, setLvl3Triggered] = useState(false);
  const [lvl4Triggered, setLvl4Triggered] = useState(false);
  const [lvl5Triggered, setLvl5Triggered] = useState(false);
  const [succeedingLevels, setSucceedingLevels] = useState([]);
  /* eslint-enable */

  useEffect(() => {
    const newAssessment = {};
    disciplines.forEach((discipline) => {
      discipline.disciplineTargets.forEach((disciplineTarget) => {
        newAssessment[disciplineTarget.id] = -1;
      });
    });
    setAssessment(newAssessment);
  }, [disciplines]);

  if (disciplines.length === 0) {
    return null;
  }

  const getContext = () => ({
    assessment,
    onLevelClick,
  });

  const onSubmitAssessment = () => {
    if (!validate()) {
      return Swal.fire({
        title: 'Error!',
        text: 'Please assess all topics.',
        icon: 'error',
      });
    }

    return props.submitAssessment(candidateId, isSelfSponsored, assessment);
  };

  const fireLevelAlert = (level) => {
    const { title, description } = levelDetails[level];

    Swal.fire({
      title,
      text: description,
      icon: 'info',
    });
  };

  const onLevelClick = (disciplineTargetId, level) => {
    switch (level) {
      case 0:
        if (!lvl0Triggered) {
          fireLevelAlert(level);
          setLvl0Triggered(true);
        }
        break;

      case 1:
        if (!lvl1Triggered) {
          fireLevelAlert(level);
          setLvl1Triggered(true);
        }
        break;

      case 2:
        if (!lvl2Triggered) {
          fireLevelAlert(level);
          setLvl2Triggered(true);
        }
        break;

      case 3:
        if (!lvl3Triggered) {
          fireLevelAlert(level);
          setLvl3Triggered(true);
        }
        break;

      case 4:
        if (!lvl4Triggered) {
          fireLevelAlert(level);
          setLvl4Triggered(true);
        }
        break;

      case 5:
        if (!lvl5Triggered) {
          fireLevelAlert(level);
          setLvl5Triggered(true);
        }
        break;

      default:
        break;
    }

    const newAssessment = { ...assessment };
    newAssessment[disciplineTargetId] = level;
    setAssessment(newAssessment);

    if (succeedingLevels.length === 0) {
      setSucceedingLevels([level]);
    } else {
      const [prevLevel] = succeedingLevels;
      if (prevLevel === level) {
        if (succeedingLevels.length === 2) {
          Swal.fire({
            text: 'Please ensure you complete your self-assessment accurately.',
            icon: 'warning',
          }).then(() => {
            setSucceedingLevels([]);
          });
        } else {
          setSucceedingLevels([...succeedingLevels, level]);
        }
      } else {
        setSucceedingLevels([level]);
      }
    }
  };

  const validate = () => {
    let isValid = true;
    Object.keys(assessment).forEach((id) => {
      if (isValid && assessment[id] === -1) {
        isValid = false;
      }
    });
    return isValid;
  };

  const arrDisciplines = [...disciplines];
  return (
    <Provider value={getContext()}>
      <Grid container spacing={4}>
        {hasSafety ? (
          <Topic
            title={resource.SAFETY.TITLE}
            description={resource.SAFETY.DESCRIPTION}
            discipline={arrDisciplines.shift()}
            onLevelClick={onLevelClick}
          />
        ) : null}
        {hasCompletions ? (
          <Topic
            title={resource.COMPLETIONS.TITLE}
            discipline={arrDisciplines.shift()}
            onLevelClick={onLevelClick}
          />
        ) : null}
        {arrDisciplines.length > 0 ? (
          <Topic
            title={resource.ROLE_SPECIFIC.TITLE}
            description={resource.ROLE_SPECIFIC.DESCRIPTION}
            discipline={arrDisciplines.shift()}
            onLevelClick={onLevelClick}
          />
        ) : null}
        {
          // <Grid item xs={12} sm={6}>
          //   <Button variant="contained" fullWidth>
          //     Review sponsor provided information
          //   </Button>
          // </Grid>
        }
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitAssessment}
            fullWidth
          >
            Save and continue
          </Button>
        </Grid>
      </Grid>
    </Provider>
  );
};

AreaOfExpertise.propTypes = {
  candidateId: PropTypes.number.isRequired,
  disciplines: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const {
    isSelfSponsored,
    disciplines,
    hasSafety,
    hasCompletions,
  } = state.selfAssessment;
  return { isSelfSponsored, disciplines, hasSafety, hasCompletions };
};

export default connect(mapStateToProps, { submitAssessment })(AreaOfExpertise);
