/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';

import { redirect } from 'common/actions';
import { getSponsorStatistics } from '../../actions/getSponsorStatistics';
import DataBlock from './components/DataBlock';
import { useStyles } from './DataBlock.style';

const DataStats = (props) => {
  const classes = useStyles();

  const { statistics } = props;
  const {
    assessmentsAvailable,
    techniciansAssessed,
    engineersAssessed,
    avgCompetency,
  } = statistics;

  useEffect(() => {
    props.getSponsorStatistics(props.userId);
  }, [
    assessmentsAvailable,
    techniciansAssessed,
    engineersAssessed,
    avgCompetency,
  ]);

  const handleClickManageAssessments = () => props.redirect('/');
  const handleClickBuyAssessments = () =>
    props.redirect('/assessment-purchase');

  return (
    <Grid item xs={12}>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <DataBlock title="Assessment available" value={assessmentsAvailable}>
            {techniciansAssessed > 0 ? (
              <Button
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
                className={classes.button}
                onClick={handleClickManageAssessments}
              >
                Manage Assessments
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
                className={classes.button}
                onClick={handleClickBuyAssessments}
              >
                Purchase Assessments
              </Button>
            )}
          </DataBlock>
        </Grid>
        <Grid item xs={3}>
          <DataBlock title="Technicians assessed" value={techniciansAssessed} />
        </Grid>
        <Grid item xs={3}>
          <DataBlock title="Engineers assessed" value={engineersAssessed} />
        </Grid>
        <Grid item xs={3}>
          <DataBlock title="Avg competency" value={avgCompetency} />
        </Grid>
      </Grid>
    </Grid>
  );
};

DataStats.propTypes = {
  redirect: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  statistics: PropTypes.object.isRequired,
};

export default connect(null, { redirect, getSponsorStatistics })(DataStats);
