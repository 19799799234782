import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { setAlert } from 'common/actions/setAlert';

const Alert = (props) => {
  const handleClose = () => {
    props.setAlert({
      open: false,
    });
  };

  return (
    <Snackbar open={props.open} autoHideDuration={4000} onClose={handleClose}>
      <MuiAlert
        elevation={6}
        severity={props.type}
        variant="filled"
        onClose={handleClose}
      >
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => ({ ...state.global.alert });

export default connect(mapStateToProps, { setAlert })(Alert);
