import axios from 'axios';
import { push } from 'connected-react-router';

export function initializeGodView(userId) {
  return (dispatch) => {
    axios
      .post(`/masquerade/${userId}`)
      .then(() => {
        dispatch(push('/'));
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
