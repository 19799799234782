import React from 'react';
import { Grid } from '@material-ui/core';
import GroupItem from './components/GroupItem';

const GroupList = ({ groups }) => {
  return (
    <Grid item xs={12}>
      {groups.map((group) => (
        <GroupItem key={group.id} group={group} />
      ))}
    </Grid>
  );
};

export default GroupList;
