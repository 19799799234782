import React from 'react';
import clsx from 'clsx';
import { Grid, Paper, Typography } from '@material-ui/core';

import { withContext } from 'common/helpers/context';
import { useCommonStyles } from 'common/common.style';

const GUIDES = [
  {
    title: 'Competency Levels',
    description:
      'COMPETAS assessess Candidates from Level 0 (no/minimal knowledge), to Level 5 (highest competency). Please be advised that scores of 4 and 5 are rare, and to be used in search with discretion.',
  },
  {
    title: 'Level Deviations',
    description:
      'Use the deviation input boxes marked with “+/-“ to manage the plus or minus acceptable criteria for your search. You can manage this on a global scale, or per topic.',
  },
  {
    title: 'Maximum Recommended Topics',
    description:
      'In order to present search results in the most readable manner possible, COMPETAS advise selecting a maximum of 5 topics, however, you may select as many as you wish.',
  },
];

const GuideContent = () => {
  const classes = useCommonStyles();
  const fixedDoubleHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Grid container spacing={5}>
      {GUIDES.map(({ title, description }, i) => (
        <Grid key={`guide-content-${i}`} item xs={4}>
          <Paper className={fixedDoubleHeightPaper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {title.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="textSecondary"
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default withContext(GuideContent);
