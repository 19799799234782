/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import {
  CssBaseline,
  Avatar,
  Button,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import CustomTextField from 'common/components/CustomTextField';
import CustomSelect from 'common/components/CustomSelect';
import { OPTIONS_DAY, OPTIONS_MONTH, OPTIONS_YEAR } from 'common/options';
import NotificationBar from 'common/components/NotificationBar';
import resolveAvatar from 'common/helpers/resolveAvatar';
import Teams from '../TeamViewer/components/Teams/Teams';
import { useStyles } from './UserProfile.style';
import { updateProfile } from '../../actions/userInfo';
import { uploadImage } from '../../../CandidateOnboarding/components/CandidateVerify/components/InfoVerifyForm/components/FaceDetection/actions/uploadImage';

const UserProfile = (props) => {
  const { options, isLoading, message, info } = props;

  const classes = useStyles();
  const formikRef = useRef();
  const inputFileRef = useRef();

  const OPTIONS_COUNTRY = options.countries.map((e) => ({
    label: e.name,
    value: e.id,
  }));

  const OPTIONS_COUNTRY_CODE = options.countries.map((e) => ({
    label: `+${e.code}`,
    value: e.code,
  }));

  const user = info;
  useEffect(() => {
    if (info.id) {
      if (formikRef.current && OPTIONS_COUNTRY.length > 0) {
        formikRef.current.setFieldValue('userId', user.id);
        formikRef.current.setFieldValue('company', user.company?.name || '');
        formikRef.current.setFieldValue('firstName', user.firstName);
        formikRef.current.setFieldValue('lastName', user.lastName);
        formikRef.current.setFieldValue('email', user.email);
        formikRef.current.setFieldValue('sponsorCode', user.sponsorCode || '');

        formikRef.current.setFieldValue('contactPhone', user.contactPhone);
        formikRef.current.setFieldValue('streetAddress', user.streetAddress);
        formikRef.current.setFieldValue('town', user.town);
        formikRef.current.setFieldValue('state', user.state);
        formikRef.current.setFieldValue('postCode', user.postCode);
        formikRef.current.setFieldValue('countryId', user.countryId);
        formikRef.current.setFieldValue('countryCode', user.countryCode);

        if (user.dateOfBirth) {
          const [dobYear, dobMonth, dobDay] = user.dateOfBirth.split('-');
          formikRef.current.setFieldValue('dobYear', parseInt(dobYear, 10));
          formikRef.current.setFieldValue('dobMonth', parseInt(dobMonth, 10));
          formikRef.current.setFieldValue('dobDay', parseInt(dobDay, 10));
        }
      }
    }
  }, [info, OPTIONS_COUNTRY]);

  const onFormSubmit = (values) => {
    const dateOfBirth = moment(
      `${values.dobYear}-${values.dobMonth}-${values.dobDay}`,
      'YYYY-MM-DD',
    );
    const formData = {
      ...values,
      dateOfBirth,
    };
    props.updateProfile(formData);
  };

  const handleCountryCallback = (value) => {
    const countryCode = options.countries.filter(
      (country) => country.id === value,
    )[0];
    formikRef.current.setFieldValue('countryCode', countryCode.code);
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    const allowedFileTypes = ['jpg', 'jpeg', 'png'];
    if (allowedFileTypes.some((type) => file.type.includes(type))) {
      const formData = new FormData();
      formData.append('userId', user.id);
      formData.append('passport-image', file);
      props.uploadImage(formData);
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'You must upload a jpg or png image',
        icon: 'error',
        confirmButtonColor: '#3f51b5',
      });

      // remove invalid file from input
      e.target.value = null;
    }
  };

  const handleClickPhoto = () => {
    if (user.passportPhoto) inputFileRef.current.click();
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} component={Paper} sm={false} lg={2} />
      <Grid item sm={12} lg={8} component={Paper} square>
        <div className={classes.paper}>
          <Avatar
            alt={user.firstName}
            className={classes.avatar}
            src={resolveAvatar(user.passportPhoto)}
            aria-controls="avatar-menu"
            aria-haspopup="true"
            onClick={handleClickPhoto}
          >
            <label htmlFor="identificationImage">
              {user.firstName.charAt(0)}
              <input
                type="file"
                id="identificationImage"
                className="file-input"
                ref={inputFileRef}
                onChange={handleChangeAvatar}
              />
            </label>
          </Avatar>
          {!isNil(user.passportPhoto) && (
            <input
              hidden
              type="file"
              id="identificationImage"
              className="file-input"
              ref={inputFileRef}
              onChange={handleChangeAvatar}
            />
          )}
          <Typography component="h1" variant="h5">
            Profile
          </Typography>
          <Box mb={3}>
            <Typography variant="subtitle2" color="textSecondary">
              Please ensure that your information is kept accurate and up to
              date.
            </Typography>
          </Box>

          <Formik
            innerRef={formikRef}
            initialValues={{
              userId: '',
              company: '',
              firstName: '',
              lastName: '',
              sponsorCode: '',
              email: '',
              town: '',
              streetAddress: '',
              postCode: '',
              state: '',
              countryId: '',
              countryCode: '',
              contactPhone: '',
              dobDay: '',
              dobMonth: '',
              dobYear: '',
            }}
            onSubmit={onFormSubmit}
            validationSchema={Yup.object().shape({
              company: Yup.string().required('Required'),
              firstName: Yup.string().required('Required'),
              lastName: Yup.string().required('Required'),
              sponsorCode: Yup.string().required('Required'),
              email: Yup.string()
                .email('Please enter a valid email')
                .required('Required'),
              town: Yup.string().required('Required'),
              streetAddress: Yup.string().required('Required'),
              postCode: Yup.string().required('Required'),
              state: Yup.string().required('Required'),
              countryId: Yup.string().required('Required'),
              countryCode: Yup.string().required('Required'),
              contactPhone: Yup.number().required('Required'),
            })}
          >
            {(p) => {
              const {
                values,
                touched,
                errors,
                // isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = p;
              const propsToPass = {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
              };
              return (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Typography variant="overline">
                        Company Information
                      </Typography>
                    </Grid>
                    <CustomTextField
                      id="company"
                      labelText="Company Name (if applicable)"
                      gridSize={6}
                      {...propsToPass}
                    />
                    <Grid item xs={6} />

                    <Grid item xs={12}>
                      <Typography variant="overline" className="alignCenter">
                        Sponsor Information
                      </Typography>
                    </Grid>
                    <CustomTextField
                      autoFocus
                      id="firstName"
                      labelText="First Name"
                      disabled={isLoading}
                      {...propsToPass}
                    />
                    <CustomTextField
                      id="lastName"
                      labelText="Last Name"
                      disabled={isLoading}
                      {...propsToPass}
                    />
                    <CustomTextField
                      id="email"
                      type="email"
                      labelText="Email"
                      {...propsToPass}
                    />
                    <CustomTextField
                      id="sponsorCode"
                      labelText="Sponsor Code"
                      disabled={isLoading}
                      {...propsToPass}
                    />

                    <Grid item xs={12}>
                      <Typography variant="overline">Address</Typography>
                    </Grid>
                    <CustomTextField
                      id="streetAddress"
                      labelText="Street Address"
                      {...propsToPass}
                    />
                    <CustomTextField
                      id="town"
                      labelText="Town"
                      {...propsToPass}
                    />
                    <CustomTextField
                      id="state"
                      labelText="State / County"
                      {...propsToPass}
                    />
                    <CustomTextField
                      id="postCode"
                      labelText="Post Code"
                      {...propsToPass}
                    />
                    <CustomSelect
                      id="countryId"
                      labelText="Country"
                      options={OPTIONS_COUNTRY}
                      callback={handleCountryCallback}
                      {...propsToPass}
                    />
                    <CustomSelect
                      id="countryCode"
                      labelText="Country Code"
                      options={OPTIONS_COUNTRY_CODE}
                      gridSize={2}
                      {...propsToPass}
                    />
                    <CustomTextField
                      id="contactPhone"
                      labelText="Contact Phone"
                      gridSize={4}
                      {...propsToPass}
                    />
                    <Grid item xs={12}>
                      <Typography variant="overline">Date of Birth</Typography>
                    </Grid>
                    <CustomSelect
                      id="dobDay"
                      labelText="Day"
                      options={OPTIONS_DAY}
                      gridSize={4}
                      {...propsToPass}
                    />
                    <CustomSelect
                      id="dobMonth"
                      labelText="Month"
                      options={OPTIONS_MONTH}
                      gridSize={4}
                      {...propsToPass}
                    />
                    <CustomSelect
                      id="dobYear"
                      labelText="Year"
                      options={OPTIONS_YEAR}
                      gridSize={4}
                      {...propsToPass}
                    />
                    <NotificationBar
                      show={message !== ''}
                      variant="error"
                      className={classes.margin}
                      message={message}
                    />
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isLoading}
                      >
                        {isLoading ? <CircularProgress /> : 'Update Profile'}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
        <Divider />
        <div className={classes.paper}>
          <Teams />
        </div>
      </Grid>
      <Grid item xs={false} component={Paper} sm={false} lg={2} />
    </Grid>
  );
};

UserProfile.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  updateProfile: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { options } = state;
  const { userInfo, isLoading, message } = state.dashboard;

  return {
    options,
    info: userInfo,
    isLoading,
    message,
  };
};

export default connect(mapStateToProps, { updateProfile, uploadImage })(
  UserProfile,
);
