/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import { useCookies } from 'react-cookie';
import { Provider } from 'common/helpers/context';
import HelpSection from 'common/components/HelpSection';
import { setUnderConstruction } from 'common/actions/setUnderConstruction';
import LevelDefinitions from 'common/components/LevelDefinitions';
import TeamTable from './components/TeamTable/TeamTable';
// import HelpButton from './components/HelpButton';
import Groups from './components/Groups';
import CandidateSelectModal from './components/Groups/components/CandidateSelectModal';

import { useStyles } from './TeamViewer.style';

import { getCandidateSearchResult } from '../CandidateSearch/actions/getCandidateSearchResult';
import { fetchTeamCandidates, setSelectedCandidate } from './actions';
import { setSelectedGroup } from './components/Groups/actions/setSelectedGroup';
import { setSelectedTeam } from './components/Teams/actions/setSelectedTeam';

import './TeamViewer.css';
import GuideContent from '../CandidateSearch/components/GuideContent';
import SearchBar from '../CandidateSearch/components/SearchBar';
import SliderFilter from '../CandidateSearch/components/SliderFilter';
import SearchStatistics from '../CandidateSearch/components/SearchStatistics';
import SearchMatches from '../CandidateSearch/components/SearchMatches';
import ACTION_ITEMS from '../CandidateSearch/actionItems';

const TeamViewer = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const statsRef = useRef(null);

  const [cookie, setCookie, removeCookie] = useCookies([
    'roleId',
    'disciplineId',
    'region',
    'filters',
  ]);

  const [showLevelDefinitions, setShowLevelDefinition] = useState(false);
  const [showHelpText, setShowHelpText] = useState(false);
  const [roleId, setRoleId] = useState(Number(cookie.roleId) || '');
  const [disciplineId, setDisciplineId] = useState(
    Number(cookie.disciplineId) || '',
  );
  const [region, setRegion] = useState(cookie.region === 'true' || false);
  const [selectedFilters, setSelectedFilters] = useState(cookie.filters || []);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const { disciplines, disciplineTargets, stats, results } = props;

  const [displayHelpText, setDisplayHelpText] = useState(false);
  const [candidateModal, setCandidateModal] = useState(false);

  const { userId, candidates } = props;

  useEffect(() => {
    if (roleId && disciplineId) handleClickSearch();

    return () => {
      dispatch({
        type: ACTION_ITEMS.CANDIDATE_SEARCH.RESULTS.SET,
        payload: {
          exact: [],
          partial: [],
        },
      });
    };
  }, []);

  useEffect(() => {
    if (candidates === null) {
      props.fetchTeamCandidates(userId);
    }
  }, [candidates]);

  useEffect(() => {
    // Set cookie
    setCookie('roleId', roleId);
  }, [roleId]);

  useEffect(() => {
    // Set cookie
    setCookie('disciplineId', disciplineId);
  }, [disciplineId]);

  useEffect(() => {
    // Set cookie
    setCookie('region', region);
  }, [region]);

  const toggleHelpText = () => setDisplayHelpText(!displayHelpText);
  const showCandidateModal = () => setCandidateModal(true);
  const closeCandidateModal = () => {
    setCandidateModal(false);
    props.setSelectedGroup(null);
  };

  const onPreview = () => {
    props.setUnderConstruction(true);
  };

  const handleClickSearch = async () => {
    setRoleId(roleId);
    setDisciplineId(disciplineId);
    setRegion(region);

    const params = {
      roleId: roleId || undefined,
      disciplineId: disciplineId || undefined,
      filters: selectedFilters,
      region,
    };
    await props.getCandidateSearchResult(params);

    // ! Clear cookies after triggering a search
    removeCookie('roleId');
    removeCookie('disciplineId');
    removeCookie('filters');

    if (statsRef.current) window.scrollTo(0, statsRef.current.offsetTop);
  };

  const getContext = () => ({
    // Candidate Search
    roleId,
    setRoleId,
    disciplineId,
    setDisciplineId,
    region,
    setRegion,
    selectedPosition,
    setSelectedPosition,
    disciplines,
    disciplineTargets,

    showHelpText,
    selectedFilters,
    setSelectedFilters,
    handleClickSearch,

    // Team Viewer
    userId,

    candidates,
    displayHelpText,

    toggleHelpText,
    setSelectedCandidate: props.setSelectedCandidate,
    onPreview,

    candidateModal,
    showCandidateModal,
    closeCandidateModal,
  });

  const handleClickHideLevelDefinition = () =>
    setShowLevelDefinition(!showLevelDefinitions);
  const handleClickHideText = () => setShowHelpText(!showHelpText);

  return (
    <Provider value={getContext()}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={5} className={classes.groupContainer}>
              <Grid item xs={6}>
                <Typography variant="h4">
                  Talent Management Dashboard
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.buttonGroup}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleClickHideText}
                >
                  {showHelpText ? 'Hide' : 'Show'} help text
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleClickHideLevelDefinition}
                >
                  {showLevelDefinitions ? 'Hide' : 'Show'} Level Definitions
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: showHelpText ? 'block' : 'none' }}
              >
                <Collapse in={showHelpText}>
                  <GuideContent />
                </Collapse>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: showLevelDefinitions ? 'block' : 'none' }}
              >
                <Collapse in={showLevelDefinitions}>
                  <LevelDefinitions />
                </Collapse>
              </Grid>
              <SearchBar onFormSubmit={handleClickSearch} />
              <SliderFilter title="Competency Requirements" />
              {results.exact && results.partial ? (
                <>
                  <SearchStatistics
                    forwardRef={statsRef}
                    value={stats.total}
                    title="Total"
                    description="Exact matches plus partials"
                  />
                  <SearchStatistics
                    value={stats.partial}
                    title="Partial"
                    description="Including level deviations"
                  />
                  <SearchStatistics
                    value={stats.exact}
                    title="Exact"
                    description="Perfect search matches"
                  />
                </>
              ) : null}
              {results.exact && results.exact.length > 0 ? (
                <SearchMatches title="Exact matches" results={results.exact} />
              ) : null}
              {results.partial && results.partial.length > 0 ? (
                <SearchMatches
                  title="Partial matches"
                  results={results.partial}
                />
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5} className={classes.groupContainer}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h5">Team Viewer</Typography>
                  </Grid>
                  {/* <Grid item>
                <HelpButton />
              </Grid> */}
                </Grid>
              </Grid>
              <HelpSection />
              <TeamTable />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={5} className={classes.groupContainer}>
              <Groups />
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {candidateModal ? <CandidateSelectModal /> : null}
    </Provider>
  );
};

const mapStateToProps = (state) => {
  const { dashboard, teamViewer, candidateSearch, options } = state;
  return {
    isTeamMasquerading: dashboard.isTeamMasquerading,
    userId: dashboard.userInfo.id,
    candidates: teamViewer.users,
    disciplines: options.disciplines,
    disciplineTargets: options.disciplineTargets,
    stats: candidateSearch.stats,
    results: candidateSearch.results,
  };
};

export default connect(mapStateToProps, {
  fetchTeamCandidates,
  setSelectedCandidate,
  setUnderConstruction,
  setSelectedGroup,
  setSelectedTeam,
  getCandidateSearchResult,
})(TeamViewer);
