import ACTION_ITEMS from '../actionItems';

export const resetAnonResults = () => (dispatch) => {
  dispatch({
    type: ACTION_ITEMS.ANON_RESULTS.OPEN.SET,
    payload: false,
  });
  setTimeout(
    () =>
      dispatch({
        type: ACTION_ITEMS.ANON_RESULTS.ASSESSMENTS.RESET,
      }),
    200,
  );
};
