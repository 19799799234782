import React from 'react';
import { connect } from 'react-redux';

const Intercom = (props) => {
  const { userInfo, config } = props;

  if (!(config && config.settings.features.intercom)) return null;

  const renderUserIntercom = () => {
    return window.Intercom('boot', {
      app_id: 'u3gv8erh',
      user_id: userInfo.id,
      name: userInfo.firstName, // Full name
      email: userInfo.email, // Email address
      created_at: userInfo.created_at, // Signup date as a Unix timestamp
    });
  };

  const renderGuestIntercom = () => {
    const APP_ID = 'u3gv8erh';
    window.intercomSettings = {
      app_id: APP_ID,
    };
  };

  const hideIntercomMessages = () => {
    return window.Intercom('hide');
  };

  return (
    <div>
      {userInfo && renderUserIntercom()}
      {!userInfo && renderGuestIntercom()}
      {hideIntercomMessages()}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.dashboard;
  const { config } = state.global;

  return {
    userInfo,
    config,
  };
};

export default connect(mapStateToProps)(Intercom);
