import React from 'react';
import _ from 'lodash';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import { withContext } from 'common/helpers/context';

import './AddFilterDialog.css';

const AddFilterDialog = (props) => {
  const {
    open,
    onClose,
    onAddTopic,
    selectedFilters,
    disciplineId,
    disciplines,
    disciplineTargets,
  } = props;

  const filteredDisciplines =
    disciplineId !== 0
      ? disciplines.filter(({ id }) => [1, 2, disciplineId].includes(id))
      : disciplines;

  const selectedFilterIds = selectedFilters.map((filter) => filter.topic.id);

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        Add Filter
        <IconButton className="close-dialog" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {filteredDisciplines.map((discipline, i) => {
        const filteredTopics = disciplineTargets
          .filter((topic) => topic.disciplineId === discipline.id)
          .filter((topic) => !selectedFilterIds.includes(topic.id));
        const sortedTopics = _.sortBy(filteredTopics, ['disciplineId', 'name']);

        return (
          <React.Fragment key={`discipline-${i}`}>
            <Divider />
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: '10px' }}
            >
              {discipline.name}
            </Typography>
            <List>
              {sortedTopics.map((topic, j) => (
                <ListItem
                  button
                  onClick={() => onAddTopic(topic)}
                  key={`topics-${j}`}
                >
                  <ListItemText primary={topic.name} />
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        );
      })}
    </Dialog>
  );
};

export default withContext(AddFilterDialog);
