import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function fetchAllPositionCandidates(positionId) {
  return (dispatch) => {
    axios
      .get(`/position/${positionId}/candidates`)
      .then((response) => {
        const { status, candidates } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setPositionCandidates(candidates));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setPositionCandidates(candidates) {
  return {
    type: ACTION_ITEMS.PROJECT_BUILDER.CANDIDATE.SET,
    payload: candidates,
  };
}
