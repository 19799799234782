/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { redirect } from 'common/actions';
import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';

import { uploadImage } from './actions/uploadImage';

import './FaceDetection.css';

const FaceDetection = (props) => {
  const { candidateInfo } = props;
  const candidateId = candidateInfo.id;

  const [showFileUpload, setShowFileUpload] = useState(false);
  const fileInputRef = useRef();

  // If already uploaded and verified
  if (candidateInfo.passportPhoto) return null;

  const handleClickVerify = () => {
    setShowFileUpload(true);
  };

  const handleClickSkip = () => {
    props.redirect(`/candidate-onboarding/assessment/${candidateId}`);
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    const allowedFileTypes = ['jpg', 'jpeg', 'png'];
    if (allowedFileTypes.some((type) => file.type.includes(type))) {
      const formData = new FormData();
      formData.append('userId', candidateInfo.user.id);
      formData.append('passport-image', file);

      props.uploadImage(formData, (err) => {
        if (err) {
          fileInputRef.current.files = null;
        } else {
          props.redirect(`/candidate-onboarding/assessment/${candidateId}`);
        }
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'You must upload a jpg or png image',
        icon: 'error',
        confirmButtonColor: '#3f51b5',
      });

      // remove invalid file from input
      e.target.value = null;
    }
  };

  let fileContent;
  if (showFileUpload) {
    fileContent = (
      <div>
        <form className="file-upload">
          <label className="file-upload__label" htmlFor="identificationImage">
            Click here or drag and drop your identification image.
            <input
              type="file"
              id="identificationImage"
              className="file-input"
              ref={fileInputRef}
              onChange={onChangeFile}
            />
          </label>
        </form>
        <div className="skip-button">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickSkip}
            style={{ marginRight: '5px' }}
          >
            Skip this step
          </Button>
        </div>
      </div>
    );
  } else {
    fileContent = (
      <div className="file-activator">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickVerify}
          style={{ marginRight: '5px' }}
        >
          Upload photo
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickSkip}
          style={{ marginRight: '5px' }}
        >
          Skip this step
        </Button>
      </div>
    );
  }

  return (
    <Grid item xs={12}>
      <Typography variant="h6">Please upload your photo</Typography>

      <Typography variant="overline" gutterBottom>
        Your photo must
      </Typography>
      <ul>
        <li>be a close-up of your full head and upper shoulders</li>
        <li>clear and in focus</li>
        <li>be in clear contrast to the background</li>
        <li>not have ‘red eye’</li>
        <li>be taken against a plain cream or light grey background</li>
        <li>be a close-up of your full head and upper shoulders</li>
        <li>contain no other objects or people</li>
      </ul>

      <Typography variant="overline" gutterBottom>
        In your photo, you must
      </Typography>
      <ul>
        <li>be facing forwards and looking straight at the camera</li>
        <li>have your eyes open and visible</li>
        <li>have a plain expression and your mouth closed</li>
        <li>not have hair in front of your eyes</li>
        <li>
          not have a head covering (unless it’s for religious or medical
          reasons)
        </li>
        <li>not have anything covering your face</li>
        <li>not have any shadows on your face or behind you</li>
        <li>
          Do not wear sunglasses or tinted glasses. You can wear other glasses
          if you need to, but your eyes must be visible without any glare or
          reflection.
        </li>
      </ul>
      {fileContent}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { userInfo } = state.dashboard;

  return {
    userId: userInfo ? userInfo.id : 0,
  };
};

const mapDispatchToProps = (dispatch) => ({
  redirect: (url) => dispatch(redirect(url)),
  uploadImage: (formData, callback) =>
    dispatch(uploadImage(formData, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FaceDetection);
