import ACTION_ITEMS from './actionItems';

const initialState = {
  disciplines: [],
};

export const topicControlReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.TOPIC_CONTROLS.TOPICS.SET:
      newState = { ...state, disciplines: action.payload };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
};
