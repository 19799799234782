import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { redirect } from 'common/actions';
import image from 'assets/images/d59a5728dc9f1103c71de35cb39edfa0c09a1613.png';
import resource from './FinishedOnboarding.resource';

const FinishedOnboarding = (props) => {
  const handleClick = () => {
    props.redirect(`/login`);
  };

  return (
    <Grid container component="main" spacing={4}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              {resource.TITLE}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="subtitle2" color="textSecondary">
              {resource.DESCRIPTION}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleClick}>
          Proceed to login
        </Button>
      </Grid>
    </Grid>
  );
};

FinishedOnboarding.helperInfo = {
  image,
  descriptions: resource.HELPER_SECTION.DESCRIPTIONS,
};

export default connect(null, { redirect })(FinishedOnboarding);
