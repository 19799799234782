import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  legendWrapper: {
    paddingLeft: '0px',
    marginLeft: '0px',
  },
  legend: {
    display: 'inline',
    marginRight: '0.5em',
    padding: '5px',
    fontSize: theme.typography.fontSize * 0.75,
  },
  legendSelf: {
    backgroundColor: theme.palette.levels.self,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.levels.self,
    },
  },
  legendAchieved: {
    backgroundColor: theme.palette.levels.achieved,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.levels.achieved,
    },
  },
  topicName: {
    fontSize: '0.65rem',
  },
  button: {
    borderRadius: '50%',
    cursor: 'disabled',
    border: '1px solid white !important',
    height: '30px',
    width: '30px',
    lineHeight: '25px',
    marginTop: '0px',
    color: '#cbcbcb',
    userSelect: 'none',
  },
  isSelf: {
    background: theme.palette.levels.self,
    color: theme.palette.common.white,
  },
  isTech: {
    background: theme.palette.levels.achieved,
    color: theme.palette.common.white,
  },
  isSelfAndTech: {
    background: `linear-gradient(120deg, ${theme.palette.levels.self} 50%, ${theme.palette.levels.achieved} 50%)`,
    color: theme.palette.common.white,
  },
  badge: {
    '& span': {
      backgroundColor: 'black',
      color: 'white',
    },
  },
}));
