/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

const useStyles = makeStyles({
  '@keyframes flicker': {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.7,
    },
  },
  flicker: {
    animationName: '$flicker',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },
  withAnimation: ({ disabled }) => ({
    animationPlayState: disabled ? 'paused' : 'running',
  }),
});

const UnassignedActionMenu = ({ onClickAssignCandidate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { flicker, withAnimation } = useStyles({ disabled: false });

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleClickAction = (event) => {
    stopPropagation(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    onClickAssignCandidate();
    setAnchorEl(null);
  };

  return (
    // <>
    //   <Button
    //     aria-label="more"
    //     aria-controls="long-menu"
    //     aria-haspopup="true"
    //     // color="primary"
    //     // variant="contained"
    //     onClick={handleClickAction}
    //   >
    //     <MoreVertIcon />
    //   </Button>
    //   <Menu
    //     anchorEl={anchorEl}
    //     keepMounted
    //     open={Boolean(anchorEl)}
    //     onClose={handleClose}
    //   >
    //     <MenuItem onClick={handleClick}>
    //       <Button
    //         size="small"
    //         variant="contained"
    //         color="primary"
    //         onClick={handleClick}
    //       >
    //         Assign Candidate
    //       </Button>
    //     </MenuItem>
    //   </Menu>
    // </>
    <Button
      className={`${flicker} ${withAnimation}`}
      size="small"
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      Assign Candidate
    </Button>
  );
};

UnassignedActionMenu.propTypes = {};

export default UnassignedActionMenu;
