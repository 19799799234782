import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Avatar,
  // Badge,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Settings as SettingsIcon,
  ControlPoint as ControlPointIcon,
  Cancel as CancelIcon,
  // Mail as MailIcon,
  // Notifications as NotificationsIcon,
} from '@material-ui/icons';
import Swal from 'sweetalert2';

import logo from 'assets/images/logoDarkBgWhiteFont.png';
import { redirect } from 'common/actions';
import resolveAvatar from 'common/helpers/resolveAvatar';
import UserAlerts from 'common/components/UserAlerts';
import GodViewModal from './components/GodViewModal';
import AdminTokenModal from './components/AdminTokenModal';
import { useStyles } from './ApplicationBar.style';
import { logout } from './actions/logout';
import { clearGodView } from './actions/clearGodView';
import TeamSelector from './components/TeamSelector';

const ApplicationBar = (props) => {
  const classes = useStyles();

  const {
    open,
    userInfo,
    isMasquerading,
    isTeamMasquerading,
    handleDrawerOpen,
  } = props;
  const { accountType, firstName, passportPhoto } = userInfo;

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openGodView, setOpenGodView] = useState(false);
  const [openAdminTokenAdd, setOpenAdminTokenAdd] = useState(false);
  const [, , removeCookie] = useCookies(['roleId', 'disciplineId', 'filters']);

  const handleClickAvatar = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseAvatar = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = () => {
    history.push('/profile');
  };

  const handleClickLogout = () => {
    setAnchorEl(null);
    Swal.fire({
      text: 'Are you sure you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        removeCookie('roleId');
        removeCookie('disciplineId');
        removeCookie('filters');
        props.logout();
      }
    });
  };

  const handleClickGodView = () => {
    setOpenGodView(true);
  };

  const handleCloseGodView = () => {
    setOpenGodView(false);
  };

  const handleClickAdminTokenAdd = () => {
    setOpenAdminTokenAdd(true);
  };

  const handleCloseAdminTokenAdd = () => {
    setOpenAdminTokenAdd(false);
  };

  const handleClearGodView = () => {
    removeCookie('roleId');
    removeCookie('disciplineId');
    removeCookie('filters');
    props.clearGodView();
  };

  const handleSettings = () => {
    props.redirect('/config-control');
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.logoWrapper}>
          <img alt="logo" src={logo} className={classes.logo} />
        </div>
        {/* <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary" overlap="rectangular">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary" overlap="rectangular">
            <MailIcon />
          </Badge>
        </IconButton> */}
        <TeamSelector />
        {isTeamMasquerading ? (
          <Tooltip title="Exit Team View" aria-label="delete">
            <IconButton color="inherit" onClick={handleClearGodView}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <UserAlerts />
        {accountType === 0 && !isMasquerading ? (
          <IconButton color="inherit" onClick={handleClickGodView}>
            <VisibilityIcon />
          </IconButton>
        ) : null}
        {isMasquerading && !isTeamMasquerading ? (
          <IconButton color="inherit" onClick={handleClearGodView}>
            <VisibilityOffIcon />
          </IconButton>
        ) : null}
        {accountType === 0 && !isMasquerading ? (
          <IconButton color="inherit" onClick={handleClickAdminTokenAdd}>
            <ControlPointIcon />
          </IconButton>
        ) : null}
        {accountType === 0 && !isMasquerading ? (
          <IconButton color="inherit" onClick={handleSettings}>
            <SettingsIcon />
          </IconButton>
        ) : null}
        <Avatar
          alt={firstName}
          src={resolveAvatar(passportPhoto)}
          className={classes.avatar}
          aria-controls="avatar-menu"
          aria-haspopup="true"
          onClick={handleClickAvatar}
        >
          {firstName.charAt(0)}
        </Avatar>
        <Menu
          id="avatar-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseAvatar}
        >
          <Grid container alignContent="center">
            <Grid item xs={12} className={classes.avatarWrapper}>
              <Avatar
                alt={firstName}
                src={resolveAvatar(passportPhoto)}
                className={classes.avatarInner}
                aria-controls="avatar-menu"
                aria-haspopup="true"
              >
                {firstName.charAt(0)}
              </Avatar>
            </Grid>
            <Grid item xs={12} className={classes.avatarName}>
              {firstName}
            </Grid>
          </Grid>
          <MenuItem
            disabled={isMasquerading && isTeamMasquerading}
            onClick={handleClickProfile}
          >
            Profile
          </MenuItem>
          <MenuItem disabled={isMasquerading} onClick={handleClickLogout}>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
      {openGodView ? (
        <GodViewModal open={openGodView} handleClose={handleCloseGodView} />
      ) : null}
      {openAdminTokenAdd ? (
        <AdminTokenModal
          open={openAdminTokenAdd}
          handleClose={handleCloseAdminTokenAdd}
          userInfo={userInfo}
        />
      ) : null}
    </AppBar>
  );
};

ApplicationBar.propTypes = {
  open: PropTypes.bool.isRequired,
  userInfo: PropTypes.object,
  isMasquerading: PropTypes.bool.isRequired,
  isTeamMasquerading: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  clearGodView: PropTypes.func.isRequired,
};

ApplicationBar.defaultProps = {
  userInfo: null,
};

const mapStateToProps = (state) => {
  const { userInfo, isMasquerading, isTeamMasquerading } = state.dashboard;
  return {
    userInfo,
    isMasquerading,
    isTeamMasquerading,
  };
};

export default connect(mapStateToProps, {
  logout,
  clearGodView,
  redirect,
})(ApplicationBar);
