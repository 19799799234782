import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import Checkbox from 'common/components/Form/components/Checkbox';
import { PermissionsModalContext } from 'common/context';
import { useStyles } from './PermissionsControl.style';
import { toggleVisibility } from './actions/toggleVisibility';

const PermissionsControl = (props) => {
  const classes = useStyles();

  const { candidate } = props;
  const [visible, setVisible] = useState(candidate.flags.visible);
  const { handleClosePermissionsModal } = useContext(PermissionsModalContext);

  const handleToggleVisibility = async (event) => {
    setVisible(event.target.checked);
  };

  const handleSubmit = async () => {
    await props.toggleVisibility(candidate.id, visible);
  };

  return (
    <Grid item xs={12} md={6}>
      <Box
        display="flex"
        flexDirection="column"
        alignContent="space-between"
        className={classes.permissionsContainer}
      >
        <Box flexGrow={1}>
          <Card className={classes.permissions}>
            <CardContent>
              <Typography variant="overline">PERMISSIONS</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Control this individuals data visibility throughout COMPETAS
              </Typography>

              <Checkbox
                label="Candidate Search visibilty"
                labelPlacement="start"
                helpText="Anonymous data is visible"
                checked={visible}
                onChange={handleToggleVisibility}
              />

              <Checkbox
                label="Has Passport access"
                labelPlacement="start"
                helpText="Personal results are visible"
              />
            </CardContent>
          </Card>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                onClick={handleClosePermissionsModal}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Cancel/Exit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default connect(null, { toggleVisibility })(PermissionsControl);
