import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  Badge,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { RESULT_ACTUAL_LEVELS } from 'common/constants';
import AnonResults from 'common/components/AnonResults';
import PassportActionMenu from 'common/components/PassportActionMenu/PassportActionMenu';
import { getAnonResults } from 'common/components/AnonResults/actions/getAnonResults';
import { resetAnonResults } from 'common/components/AnonResults/actions/resetAnonResults';
import { SortableTable } from 'common/components/SortableTable';
import { getRandomCrn } from 'common/helpers/getRandomCrn';
import { withContext } from 'common/helpers/context';
import { useCommonStyles } from 'common/common.style';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backDrop: {
    filter: 'blur(5px)',
  },
  topic: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    borderRadius: '50%',
    cursor: 'disabled',
    border: '1px solid white !important',
    height: '30px',
    width: '30px',
    lineHeight: '25px',
    marginTop: '0px',
    color: '#cbcbcb',
    userSelect: 'none',
  },
  isTech: {
    background: theme.palette.levels.achieved,
    color: theme.palette.common.white,
  },
  badge: {
    '& span': {
      backgroundColor: 'black',
      color: 'white',
    },
  },
}));

const TABLE_HEADERS = [
  { id: 'crn', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'role', label: 'Role' },
  { id: 'topics', label: 'Topic', align: 'center' },
  { id: 'preview', label: '' },
  { id: 'actions', label: '' },
];

function createData(item, handleClickAction, getAnonResults, classes) {
  const { id: candidateId, user, crn, role, assessments, isAnon } = item;
  const { firstName, lastName } = user;

  return [
    {
      id: 'crn',
      value: isAnon ? getRandomCrn() : crn,
      blur: isAnon,
    },
    {
      id: 'name',
      value: isAnon ? 'Jane Doe' : `${firstName} ${lastName}`,
      blur: isAnon,
    },
    {
      id: 'role',
      value: role.name,
    },
    {
      id: 'topics',
      value: assessments.map((assessment) => {
        const {
          id: assessmentId,
          disciplineTarget,
          level: technicalLevel,
        } = assessment;
        const [technicalLevelNumber, sign] = technicalLevel.split('');

        return (
          <div
            key={`topic-${assessmentId}`}
            align="left"
            className={classes.topic}
          >
            <div>{disciplineTarget.name}</div>
            <div>
              {RESULT_ACTUAL_LEVELS.map((level, index) => {
                const highlightLevel =
                  technicalLevelNumber.toString() === level;

                const btnClassNames = clsx(
                  classes.button,
                  highlightLevel && classes.isTech,
                );

                return (
                  <Badge
                    key={`button-level-${assessmentId}-${index + 1}`}
                    badgeContent={highlightLevel ? sign : 0}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: sign === '-' ? 'left' : 'right',
                    }}
                    overlap="rectangular"
                    className={classes.badge}
                  >
                    <button className={btnClassNames} type="button">
                      {level}
                    </button>
                  </Badge>
                );
              })}
            </div>
          </div>
        );
      }),
    },
    {
      id: 'preview',
      value: (
        <Button
          variant="contained"
          onClick={() => {
            console.log('candidateId =', candidateId);
            getAnonResults(candidateId);
          }}
        >
          Preview
        </Button>
      ),
    },
    {
      id: 'actions',
      value: (
        <IconButton
          aria-controls="search-action-menu"
          aria-haspopup="true"
          onClick={(e) => {
            handleClickAction(e, item);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];
}

const SearchMatches = (props) => {
  const { title, results } = props;

  const classesCommon = useCommonStyles();
  const classes = useStyles();

  const [anchorElAction, setAnchorElAction] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      results.map((e) =>
        createData(e, handleClickAction, props.getAnonResults, classes),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const handleModalClose = () => {
    props.resetAnonResults();
  };

  const handleClickAction = (e, item) => {
    setSelectedCandidate(item);
    setAnchorElAction(e.currentTarget);
  };

  const handleActionClose = () => {
    setSelectedCandidate(null);
    setAnchorElAction(null);
  };

  const feedback =
    selectedCandidate &&
    selectedCandidate.passportRequest &&
    selectedCandidate.passportRequest.feedback;

  return (
    <Grid item xs={12}>
      <Paper className={classesCommon.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <SortableTable
              name="exact-match-table"
              headers={TABLE_HEADERS}
              rows={rows}
              defaultSortColumnIndex={1}
              searchable
            />
          </Grid>
        </Grid>
      </Paper>
      <AnonResults handleClose={handleModalClose} />
      <PassportActionMenu
        candidate={selectedCandidate}
        feedback={feedback}
        anchorEl={anchorElAction}
        onClose={handleActionClose}
      />
    </Grid>
  );
};

SearchMatches.propTypes = {
  title: PropTypes.string.isRequired,
  results: PropTypes.array,
};

SearchMatches.defaultProps = {
  results: [],
};

const mapDispatchToProps = (dispatch) => ({
  getAnonResults: (candidateId) => dispatch(getAnonResults(candidateId)),
  resetAnonResults: () => dispatch(resetAnonResults()),
});

export default withContext(connect(null, mapDispatchToProps)(SearchMatches));
