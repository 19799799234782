import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { withContext } from 'common/helpers/context';
import { SortableTable } from 'common/components/SortableTable';
import AnonResults from 'common/components/AnonResults';
import PassportActionMenu from 'common/components/PassportActionMenu/PassportActionMenu';
import { getAnonResults } from 'common/components/AnonResults/actions/getAnonResults';
import { resetAnonResults } from 'common/components/AnonResults/actions/resetAnonResults';
import { submitPassportRequest } from '../../actions/submitPassportRequest';
import { getPassportRequest } from '../../actions/getPassportRequest';

const TABLE_HEADERS = [
  {
    id: 'crn',
    label: 'ID',
  },
  {
    id: 'role',
    label: 'ROLE',
  },
  {
    id: 'discipline',
    label: 'DISCIPLINE',
  },
  {
    id: 'sponsor',
    label: 'SPONSOR',
  },
  {
    id: 'expiry',
    label: 'EXPIRES',
    align: 'right',
  },
  {
    id: 'request',
    label: '',
  },
];

const renderRequestPassportButton = (
  candidate,
  passportButtonRef,
  handleClickAction,
) => {
  const { flags } = candidate;
  const { doneCompetasDueDilligence } = flags;

  if (doneCompetasDueDilligence) {
    return (
      <IconButton
        aria-controls="passport-action-menu"
        aria-haspopup="true"
        onClick={handleClickAction(candidate)}
        ref={passportButtonRef}
      >
        <MoreVertIcon />
      </IconButton>
    );
  } else {
    return (
      <Button variant="contained" size="small" disabled>
        Awaiting integrity review
      </Button>
    );
  }
};

const createData = (
  { candidate, token, expiry },
  passportButtonRef,
  handleClickAction,
) => {
  const { role, discipline, crn } = candidate;
  const { sponsor } = token;

  return [
    {
      id: 'crn',
      value: crn,
    },
    {
      id: 'role',
      value: role.name,
    },
    {
      id: 'discipline',
      value: discipline.name,
    },
    {
      id: 'sponsor',
      value: `${sponsor.firstName} ${sponsor.lastName}`,
    },
    {
      id: 'expiry',
      value: expiry,
      align: 'right',
    },
    {
      id: 'request',
      value: renderRequestPassportButton(
        candidate,
        passportButtonRef,
        handleClickAction,
      ),
      align: 'right',
    },
  ];
};

const YourPassports = (props) => {
  const { assignments, passportButtonRef, shouldHide } = props;

  const [anchorElAction, setAnchorElAction] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      assignments
        .filter((assignment) => assignment.status === 3)
        .map((assignment) =>
          createData(assignment, passportButtonRef, handleClickAction),
        ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignments]);

  const handleModalClose = () => {
    props.resetAnonResults();
  };

  const handleClickAction = (candidate) => (e) => {
    setSelectedCandidate(candidate);
    setAnchorElAction(e.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorElAction(null);
  };

  if (shouldHide && rows.length === 0) return null;

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Your Passports</Typography>
        </Grid>
        <Grid item xs={12}>
          <SortableTable
            name="your-passport-table"
            headers={TABLE_HEADERS}
            rows={rows}
            defaultSortColumnIndex={1}
            searchable
          />
        </Grid>
      </Grid>
      <AnonResults handleClose={handleModalClose} />
      <PassportActionMenu
        candidate={selectedCandidate}
        feedback={
          selectedCandidate && selectedCandidate.passportRequest
            ? selectedCandidate.passportRequest.feedback
            : undefined
        }
        anchorEl={anchorElAction}
        onClose={handleActionClose}
        showRequests={false}
        showPermissions={false}
        showShare={
          selectedCandidate &&
          selectedCandidate.passportRequest &&
          selectedCandidate.passportRequest.feedback === 1
        }
      />
    </Grid>
  );
};

YourPassports.propTypes = {
  getAnonResults: PropTypes.func.isRequired,
  resetAnonResults: PropTypes.func.isRequired,
  submitPassportRequest: PropTypes.func.isRequired,
  getPassportRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getAnonResults: (candidateId) => dispatch(getAnonResults(candidateId)),
  resetAnonResults: () => dispatch(resetAnonResults()),
  submitPassportRequest: (params, callback) =>
    dispatch(submitPassportRequest(params, callback)),
  getPassportRequest: (sponsorId) => dispatch(getPassportRequest(sponsorId)),
});

export default withContext(connect(null, mapDispatchToProps)(YourPassports));
