import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '85px',
    width: '85px',
    backgroundColor: '#E1F3E0',
    borderRadius: '50%',
  },
  icon: {
    display: 'block',
    margin: '0 auto',
    fontSize: '60px',
    color: 'green',
  },
}));
