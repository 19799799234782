import axios from 'axios';
import Swal from 'sweetalert2';
import ACTION_ITEMS from '../actionItems';

export const getAnonResults = (candidateId) => (dispatch) => {
  Swal.fire({
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
  axios
    .get(`/candidate/${candidateId}/anon-tech-results`)
    .then((response) => {
      Swal.close();
      const { status, anonResults } = response.data;
      if (status === 'SUCCESS') {
        dispatch(setAnonResults(anonResults));
      }
    })
    .catch((error) => {
      Swal.close();
      const { data } = error.response;
      if (data && data.status === 'ERROR') {
        Swal.fire({
          title: 'Error!',
          text: data.status_message,
          icon: 'error',
          confirmButtonColor: '#3f51b5',
        });
      }
    });
};

function setAnonResults(anonResults) {
  return {
    type: ACTION_ITEMS.ANON_RESULTS.ASSESSMENTS.SET,
    payload: anonResults,
  };
}
