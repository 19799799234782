import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function getPassportRequest(sponsorId) {
  return (dispatch) => {
    axios
      .get(`/sponsor/${sponsorId}/passport-requests`)
      .then((response) => {
        const { status, passportRequests } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setPassportRequest(passportRequests));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setPassportRequest(passportRequests) {
  return {
    type: ACTION_ITEMS.CANDIDATE_PORTAL.PASSPORT_REQUEST.SET,
    payload: { passportRequests },
  };
}
