/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import './RequestsModal.css';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Badge,
  Dialog,
  Box,
  DialogContent,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { withContext } from 'common/helpers/context';
import {
  setSelectedRequest,
  fetchUserRequests,
  updateRequestList,
  fetchTeamCandidates,
} from '../../../components/Dashboard/components/TeamViewer/actions';
import UserProfile from '../PermissionsModal/components/UserProfile';
import RequestSummary from './components/RequestSummary';
import RequestorSummary from './components/RequestorSummary';
import { RequestsModalContext } from 'common/context/RequestsModalContext';

const getListStyle = (isDraggingOver) => ({
  minHeight: '60px',
  border: '1px dashed #3557CD',
  width: '90%',
  margin: '0 auto',
  background: isDraggingOver ? 'lightblue' : '#E7EBFB',
});

const RequestsModal = (props) => {
  const { userId, requestedRequests, acceptedRequests, rejectedRequests } =
    props;
  const { selectedCandidate, showRequestsModal, handleCloseRequestsModal } =
    useContext(RequestsModalContext);

  useEffect(() => {
    if (selectedCandidate && showRequestsModal) {
      props.fetchUserRequests(selectedCandidate.id);
      props.fetchTeamCandidates(userId);
    }
  }, [showRequestsModal]);

  if (!selectedCandidate) return null;

  const onDragEnd = (data) => {
    const { destination, source, draggableId } = data;

    // if dropped outside list
    if (!destination) {
      return;
    }

    // if nothing has changed
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const sourceList = props[source.droppableId];
    const destinationList = props[destination.droppableId];

    // if the same list then reorder
    if (sourceList === destinationList) {
      // reorder ? is this to be done?
    } else {
      // else if different list then move list
      const requestId = draggableId.split('-')[1];
      const newSourceList = sourceList.filter(
        (item) => parseInt(item.id, 10) !== parseInt(requestId, 10),
      );
      const requestToMove = sourceList.filter(
        (item) => parseInt(item.id, 10) === parseInt(requestId, 10),
      )[0];
      props.updateRequestList(source.droppableId, newSourceList);

      let newDestinationList = destinationList.filter(
        (item) => parseInt(item.id, 10) !== parseInt(requestId, 10),
      );
      newDestinationList = [...newDestinationList, requestToMove];
      props.updateRequestList(destination.droppableId, newDestinationList);
    }
  };

  const selectRequest = (request) => {
    props.setSelectedRequest(request);
  };

  const renderRequests = (requests) => {
    return requests.map((request, index) => {
      return (
        <RequestSummary
          key={request.candidate.crn}
          request={request}
          index={index}
          selectRequest={selectRequest}
        />
      );
    });
  };

  const renderRequestList = (requests, requestType) => {
    let label = 'Requested';

    if (requestType === 'acceptedRequests') {
      label = 'Accepted';
    }
    if (requestType === 'rejectedRequests') {
      label = 'Rejected';
    }

    return (
      <Box style={{ width: '100%' }}>
        <Box pl={2} className="table-cell__heading">
          <Badge
            badgeContent={requests.length}
            color="primary"
            overlap="rectangular"
            showZero
          >
            <Typography
              component="span"
              variant="overline"
              display="block"
              color="textSecondary"
            >
              {label}
            </Typography>
          </Badge>
        </Box>

        <Droppable droppableId={requestType}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="request-list"
            >
              {provided.placeholder}

              {renderRequests(requests)}
            </div>
          )}
        </Droppable>
      </Box>
    );
  };

  const renderRequestSummary = () => {
    if (!props.selectedRequest) {
      return (
        <Card>
          <CardContent>
            <p>Please select a request to view more information</p>
          </CardContent>
        </Card>
      );
    }

    return (
      <RequestorSummary
        request={props.selectedRequest}
        clearSelected={props.setSelectedRequest}
      />
    );
  };

  return (
    <Dialog
      open={showRequestsModal}
      onClose={handleCloseRequestsModal}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
    >
      <form>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Grid container>
                <UserProfile candidate={selectedCandidate} parent="requests" />
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography component="h2" variant="overline" display="block">
                    Viewing requests for {selectedCandidate.user.firstName}{' '}
                    {selectedCandidate.user.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={5} className="requests-container">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Grid container>
                      {renderRequestList(
                        requestedRequests,
                        'requestedRequests',
                      )}
                    </Grid>

                    <Grid container>
                      {renderRequestList(rejectedRequests, 'rejectedRequests')}
                    </Grid>

                    <Grid container>
                      {renderRequestList(acceptedRequests, 'acceptedRequests')}
                    </Grid>
                  </DragDropContext>
                </Grid>

                <Grid item xs={7}>
                  {renderRequestSummary()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

const mapStateToProps = ({ dashboard: { userInfo }, teamViewer }) => {
  const {
    selectedRequest,
    requestedRequests,
    acceptedRequests,
    rejectedRequests,
  } = teamViewer;

  return {
    userId: userInfo.id,
    rejectedRequests,
    acceptedRequests,
    requestedRequests,
    selectedRequest,
  };
};

export default connect(mapStateToProps, {
  fetchTeamCandidates,
  setSelectedRequest,
  fetchUserRequests,
  updateRequestList,
})(withContext(RequestsModal));
