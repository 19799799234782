/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Badge, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { Notifications as NotificationsIcon } from '@material-ui/icons';
import { fetchUserAlerts } from './actions/fetchUserAlerts';
import AlertList from './components/AlertList';

const UserAlerts = (props) => {
  const { alerts, userInfo } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    props.fetchUserAlerts(userInfo.id);
  }, []);

  const openNotificationMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeNotificationMenu = () => {
    setAnchorEl(null);
  };

  const newNotificationCount = () => {
    const unseenNotifications = alerts.filter((alert) => alert.seen === false);

    return unseenNotifications.length;
  };

  return (
    <>
      <IconButton onClick={openNotificationMenu} color="inherit">
        <Badge
          badgeContent={newNotificationCount()}
          color="secondary"
          overlap="rectangular"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <AlertList
        alerts={alerts}
        anchorEl={anchorEl}
        handleClose={closeNotificationMenu}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.dashboard.userInfo,
    alerts: state.userAlerts.alerts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserAlerts: (userId) => dispatch(fetchUserAlerts(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAlerts);
