import axios from 'axios';

export async function getAllUsers() {
  try {
    const res = await axios.get(`/users`);
    return res.data.users;
  } catch (error) {
    console.error(error);
    return [];
  }
}
