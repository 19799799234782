import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import _ from 'lodash';
import {
  Divider,
  Drawer,
  IconButton,
  // Typography,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  // ListSubheader,
} from '@material-ui/core';
import {
  // Assignment as AssignmentIcon,
  // BarChart as BarChartIcon,
  ChevronLeft as ChevronLeftIcon,
  // Layers as LayersIcon,
  // ShoppingCart as ShoppingCartIcon,
} from '@material-ui/icons';
import { redirect } from 'common/actions';
import DashboardDrawerItem from './components/DashboardDrawerItem';
import AssessmentPurchaseDrawerItem from './components/AssessmentPurchaseDrawerItem';
import PassportDashboardItem from './components/PassportDashboardItem';
// import CandidateSearchDrawerItem from './components/CandidateSearchDrawerItem';
import TeamViewerDrawerItem from './components/TeamViewerDrawerItem';
import PsychometricDrawerItem from './components/PsychometricDrawerItem';
import ProjectBuilderDrawerItem from './components/ProjectBuilderDrawerItem/ProjectBuilderDrawerItem';
import { useStyles } from './ApplicationDrawer.style';

const ApplicationDrawer = (props) => {
  const classes = useStyles();

  const {
    location,
    open,
    showDivider,
    userInfo,
    // hasTokens,
    isPurchaseTokenEnabled,
    isCandidateSearchEnabled,
    handleDrawerClose,
  } = props;
  const { pathname } = location;
  const { intentProcess } = userInfo;
  // const { intentProcess, accountType, candidates } = userInfo;
  // const isAdmin = accountType === 0;

  let isSponsor = true;
  if (intentProcess) {
    isSponsor = intentProcess.sponsor.enabled;
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon className={classes.toolbarCloseIcon} />
        </IconButton>
      </div>
      {showDivider ? <Divider className={classes.divider} /> : null}
      {/* <CandidateSearchDrawerItem
        disabled={!hasTokens}
        show={isAdmin && isCandidateSearchEnabled}
        selected={CandidateSearchDrawerItem.pathname === pathname}
        onClick={props.redirect}
      /> */}
      {/* {open ? (
        <>
          <Divider />
          <Typography
            variant="body1"
            align="center"
            className={classes.sponsorHeader}
          >
            Sponsor Pages
          </Typography>
        </>
      ) : null} */}
      {/* <Divider /> */}
      <DashboardDrawerItem
        selected={DashboardDrawerItem.pathname === pathname}
        onClick={props.redirect}
      />
      <PassportDashboardItem
        selected={PassportDashboardItem.pathname === pathname}
        onClick={props.redirect}
      />
      <AssessmentPurchaseDrawerItem
        show={isPurchaseTokenEnabled}
        selected={AssessmentPurchaseDrawerItem.pathname === pathname}
        onClick={props.redirect}
      />
      <TeamViewerDrawerItem
        show={isSponsor && isCandidateSearchEnabled}
        selected={TeamViewerDrawerItem.pathname === pathname}
        onClick={props.redirect}
      />
      <ProjectBuilderDrawerItem
        show={isSponsor}
        selected={ProjectBuilderDrawerItem.pathname === pathname}
        onClick={props.redirect}
      />
      <PsychometricDrawerItem
        selected={PsychometricDrawerItem.pathname === pathname}
        onClick={props.redirect}
      />
      {/* <ListItem button>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Integrations" />
        </ListItem> */}
      {/* <Divider />
      <div>
        <ListSubheader inset>Saved reports</ListSubheader>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Current month" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Last quarter" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Year-end sale" />
        </ListItem>
      </div> */}
    </Drawer>
  );
};

ApplicationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  showDivider: PropTypes.bool,
  userInfo: PropTypes.object,
  hasTokens: PropTypes.bool.isRequired,
  isPurchaseTokenEnabled: PropTypes.bool.isRequired,
  isCandidateSearchEnabled: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
};

ApplicationDrawer.defaultProps = {
  userInfo: null,
  showDivider: false,
};

const mapStateToProps = ({ dashboard, sponsorPortal, global }) => {
  const { userInfo } = dashboard;
  const { config } = global;
  return {
    userInfo,
    hasTokens: sponsorPortal.tokens.length > 0,
    isPurchaseTokenEnabled:
      !_.isNil(config) && config.settings.features.purchaseToken,
    isCandidateSearchEnabled:
      !_.isNil(config) && config.settings.features.candidateSearch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  redirect: (url) => dispatch(redirect(url)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationDrawer),
);
