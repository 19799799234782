import axios from 'axios';
import Swal from 'sweetalert2';
import { getCandidateAssignments } from 'components/Dashboard/components/CandidatePortal/actions/getCandidateAssignments';

export function submitOnboarding(userId, formData, successText, callback) {
  return (dispatch) => {
    Swal.fire({
      title: 'processing...',
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .post('/user/onboarding', formData)
      .then((response) => {
        const { status } = response.data;

        if (status === 'SUCCESS') {
          Swal.hideLoading();
          Swal.fire({
            title: 'Success!',
            text: successText,
            icon: 'success',
            confirmButtonColor: '#3f51b5',
          }).then(() => {
            if (callback) callback();
            dispatch(getCandidateAssignments(userId));
          });
        } else if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (callback) callback();
        if (error.response && error.response.data) {
          Swal.fire({
            title: 'Error',
            text: error.response.data.status_message,
            icon: 'error',
          }).then(() => {
            if (callback) callback();
          });
        } else {
          console.error(error);
        }
      });
  };
}
