/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Collapse,
  Button,
  Grid,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Paper,
} from '@material-ui/core';
import LevelDefinitions from 'common/components/LevelDefinitions';
import DisciplineTarget from './components/DisciplineTarget';
import { useStyles } from './AnonResults.style';

const AnonResultsContent = ({ assessments }) => {
  if (isEmpty(assessments)) {
    return <CircularProgress size={68} />;
  }

  return Object.keys(assessments).map((discipline, index) => {
    return (
      <Paper
        key={`anon-results-${index}`}
        style={{ padding: '10px 15px', marginBottom: '10px' }}
      >
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Typography variant="h5">{discipline}</Typography>
        </Grid>
        <DisciplineTarget assessments={assessments[discipline]} />
      </Paper>
    );
  });
};

const AnonResults = ({ open, assessments, handleClose }) => {
  const classes = useStyles();
  const [showLevelDefinitions, setShowLevelDefinitions] = useState(false);

  const toggleLevelDefinitions = () => {
    setShowLevelDefinitions(!showLevelDefinitions);
  };

  const buttonLabel = () => {
    if (showLevelDefinitions) {
      return 'Hide Level Definitions';
    }

    return 'Show Level Definitions';
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="subtitle2">
              LEGEND:
              <div className={clsx(classes.legend, classes.legendSelf)}>
                Self Assessed
              </div>
              <div className={clsx(classes.legend, classes.legendAchieved)}>
                Achieved
              </div>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => toggleLevelDefinitions()}
            >
              {buttonLabel()}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: showLevelDefinitions ? 'block' : 'none' }}
          >
            <Collapse in={showLevelDefinitions}>
              <LevelDefinitions size={6} />
            </Collapse>
          </Grid>
          <AnonResultsContent assessments={assessments} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

AnonResults.propTypes = {
  assessments: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { open, assessments } = state.anonResults;
  return { open, assessments };
};

export default connect(mapStateToProps)(AnonResults);
