import React from 'react';

const MeasurementLabel = ({ levels }) => {
  return (
    <div className="chart-measurement">
      {levels.map((level, index) => {
        const { label } = level;
        const text =
          label.indexOf('0') > -1
            ? '•'
            : label;
        return (
          <span key={`level-key=${index}`} className="chart-measurement__label">
            {text}
          </span>
        );
      })}
    </div>
  );
};

export default MeasurementLabel;
