const resource = {
  SAFETY: {
    TITLE: 'Safety',
    DESCRIPTION: `These safety topics are assigned to your role and discipline`,
  },
  COMPLETIONS: {
    TITLE: 'Systems Completion & Commissioning',
  },
  ROLE_SPECIFIC: {
    TITLE: 'Technical',
    DESCRIPTION: `These topics are relevant to your assigned role and discipline.`,
  },
};

export default resource;
