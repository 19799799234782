import ACTION_ITEMS from './actionItems';

const initialState = {
  isLoading: false,
  message: '',
  info: {},
};

export const signupReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.SIGNUP.MESSAGE.SET:
      newState = { ...state, message: action.payload.message };
      break;

    case ACTION_ITEMS.SIGNUP.IS_LOADING.SET:
      newState = { ...state, isLoading: action.payload.isLoading };
      break;

    case ACTION_ITEMS.SIGNUP.INFO.SET:
      newState = { ...state, info: action.payload };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
};
