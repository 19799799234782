import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import { redirect } from 'common/actions';

import { setPsychSelectedCandidate } from './actions/setPsychSelectedCandidate';
import { useStyles } from './PsychometricList.style';

const ListItem = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { candidate } = props;
  const { sponsorCode, role, discipline, flags } = candidate;
  const { donePsychAssessment } = flags;

  const handleClickStart = () => {
    props.setPsychSelectedCandidate(candidate);
    props.redirect(`/psychometric/assessment`);
  };

  const handleClickViewPassport = () => {
    history.push(`/passport/${candidate.id}/3`);
  };

  return (
    <Grid item xs={4}>
      <Paper className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography variant="subtitle1">Status:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1">
              {donePsychAssessment ? 'Completed' : 'Pending completion'}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">Sponsor Code:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1">{sponsorCode || 'n/a'}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">Role:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1">{role.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1">Discipline:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1">{discipline.name}</Typography>
          </Grid>
          <Grid item xs={5} />
          <Grid item xs={7}>
            <Typography variant="subtitle1">
              {donePsychAssessment ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleClickViewPassport}
                >
                  View Passport
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleClickStart}
                >
                  Start
                </Button>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const WrappedListItem = connect(null, { redirect, setPsychSelectedCandidate })(
  ListItem,
);

const PsychometricList = (props) => {
  const { candidates } = props;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h5">Psychometric Evaluations</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {candidates.map((e) => (
            <WrappedListItem key={`psych-${e.id}`} candidate={e} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

PsychometricList.propTypes = {
  candidates: PropTypes.array.isRequired,
};

export default PsychometricList;
