import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Provider, withContext } from 'common/helpers/context';
import TeamList from './components/TeamList';
import AddTeamModal from './components/AddTeamModal';
import AddUserTeamModal from './components/AddUserTeamModal/AddUserTeamModal';
import { getSponsorTeams } from './actions/getSponsorTeams';
import { setSelectedTeam } from './actions/setSelectedTeam';

import { useStyles } from './Teams.style';

const Teams = (props) => {
  const classes = useStyles();

  const { userId, teams } = props;
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [addUserTeamModal, setUserTeamModal] = useState(false);

  useEffect(() => {
    if (teams.length === 0) {
      props.getSponsorTeams(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddTeam = () => {
    setAddTeamModal(true);
  };

  const handleModalClose = () => {
    setAddTeamModal(false);
  };
  const showAddUserTeamModal = () => setUserTeamModal(true);
  const closeAddUserTeamModal = () => {
    setUserTeamModal(false);
    props.setSelectedTeam(null);
  };

  const getContext = () => ({
    userId,
    teams,
    addUserTeamModal,
    showAddUserTeamModal,
    closeAddUserTeamModal,
  });

  return (
    <Provider value={getContext()}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">
            Teams
            <Fab
              className={classes.add}
              color="primary"
              aria-label="add"
              size="small"
              onClick={handleAddTeam}
            >
              <AddIcon />
            </Fab>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TeamList teams={teams || []} />
        </Grid>
      </Grid>
      <AddTeamModal open={addTeamModal} handleClose={handleModalClose} />
      {addUserTeamModal ? <AddUserTeamModal /> : null}
    </Provider>
  );
};

Teams.propTypes = {
  // userId: PropTypes.number.isRequired,
  teams: PropTypes.array.isRequired,
  getSponsorTeams: PropTypes.func.isRequired,
  setSelectedTeam: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return { teams: state.teamViewer.teams, userId: state.dashboard.userInfo.id };
};

export default connect(mapStateToProps, {
  getSponsorTeams,
  setSelectedTeam,
})(withContext(Teams));
