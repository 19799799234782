import axios from 'axios';
import { push } from 'connected-react-router';
import ACTION_ITEMS from '../actionItems';

export function authenticate() {
  return (dispatch) => {
    axios
      .post('/auth/authenticate')
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setAuthenticated(true));
        } else {
          dispatch(setAuthenticated(false));
        }
      })
      .catch((error) => {
        dispatch(setAuthenticated(false));
        console.log(error);
      });
  };
}

export function login({ email, password }, homepage, setCookie) {
  return (dispatch) => {
    dispatch(setMessage(''));
    dispatch(setIsLoading(true));
    axios
      .post('/auth/login', { username: email, password })
      .then(async (response) => {
        dispatch(setIsLoading(false));
        const { status, accessToken } = response.data;
        if (status === 'SUCCESS') {
          setCookie('accessToken', accessToken);
          if (homepage) {
            dispatch(push(homepage));
          } else {
            dispatch(push('/'));
          }
        } else {
          dispatch(push('/login'));
        }
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        const { data } = error.response;
        if (data.status_code === 102) {
          dispatch(setMessage(data.status_message));
        } else if (data === 'Invalid username or password') {
          dispatch(setMessage(data));
        }
      });
  };
}

function setAuthenticated(authenticated) {
  return {
    type: ACTION_ITEMS.LOGIN.AUTHENTICATED.SET,
    payload: { authenticated },
  };
}

function setIsLoading(isLoading) {
  return {
    type: ACTION_ITEMS.LOGIN.IS_LOADING.SET,
    payload: { isLoading },
  };
}

function setMessage(message) {
  return {
    type: ACTION_ITEMS.LOGIN.MESSAGE.SET,
    payload: { message },
  };
}
