import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  label: {
    color: 'white',
  },
  formControl: {
    color: 'white',
    minWidth: 120,
  },
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:not(.Mui-disabled):hover::before': {
        borderColor: 'primary',
    },
  },
  icon: {
    fill: 'white',
  },
  root: {
    color: 'white',
  },
}));
