import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useStyles } from '../BasicInformation.style';
import { submitPassportRequest } from '../../../../CandidatePortal/actions/submitPassportRequest';

const getGenderText = (gender) => {
  let text;
  switch (gender) {
    case 1:
      text = 'Male';
      break;

    case 2:
      text = 'Female';
      break;

    case 3:
      text = 'Prefer not to say';
      break;

    default:
      break;
  }

  return text;
};

const PersonalDetails = (props) => {
  const classes = useStyles();

  const { isAnon, candidate } = props;
  const { sponsorCode, user } = candidate;

  const handleSubmitPassportRequest = () => {
    props.submitPassportRequest({
      userId: user.id,
      candidateId: candidate.id,
      feedback: 0,
    });
  };

  return (
    <>
      {isAnon ? (
        <Alert severity="warning" elevation={6} variant="filled">
          <Typography>Anonymous passport - For full details:</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmitPassportRequest}
          >
            Request passport
          </Button>
        </Alert>
      ) : null}
      <Grid container className={isAnon ? classes.backDrop : ''}>
        <Grid item xs={5}>
          <Typography variant="caption" color="textSecondary">
            Name:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body1" className={classes.content}>
            {user.firstName} {user.lastName}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="caption" color="textSecondary">
            Gender:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body1" className={classes.content}>
            {getGenderText(user.gender)}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="caption" color="textSecondary">
            DOB:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body1" className={classes.content}>
            {user.dateOfBirth}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="caption" color="textSecondary">
            Contact Email:
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body1" className={classes.content}>
            {user.email}
          </Typography>
        </Grid>
        {sponsorCode ? (
          <>
            <Grid item xs={5}>
              <Typography variant="caption" color="textSecondary">
                Sponsor Code:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body1" className={classes.content}>
                {sponsorCode}
              </Typography>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  submitPassportRequest: (params, callback) =>
    dispatch(submitPassportRequest(params, callback)),
});

export default connect(null, mapDispatchToProps)(PersonalDetails);
