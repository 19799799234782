import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { addCandidateToGroup } from './actions/addCandidateToGroup';
import { addGroup } from '../AddGroupModal/actions/addGroup';

const STATUS_ADDED = 'added';
const STATUS_NOT = 'not';
const STATUS_ADDING = 'adding';

const TABLE_HEADERS = [
  {
    align: 'left',
    numeric: false,
    name: 'crn',
    label: 'ID',
  },
  {
    align: 'left',
    numeric: false,
    name: 'first_name',
    label: 'First Name',
  },
  {
    align: 'left',
    numeric: false,
    name: 'second_name',
    label: 'Second Name',
  },
  {
    align: 'left',
    numeric: false,
    name: 'role',
    label: 'Role',
  },
  {
    align: 'left',
    numeric: false,
    name: 'discipline',
    label: 'Discipline',
  },
];

const TeamTableItem = ({ item, actionClick }) => {
  const { user, crn, role, discipline, status } = item;
  const { firstName, lastName } = user;

  let buttonText;
  switch (status) {
    case STATUS_NOT:
      buttonText = 'Add';
      break;

    case STATUS_ADDING:
      buttonText = 'Adding';
      break;

    default:
      break;
  }

  return (
    <TableRow>
      <TableCell>#{crn}</TableCell>
      <TableCell>{firstName}</TableCell>
      <TableCell>{lastName}</TableCell>
      <TableCell>{role.name}</TableCell>
      <TableCell>{discipline.name}</TableCell>
      <TableCell>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          disabled={status === STATUS_ADDING}
          onClick={() => actionClick(item)}
        >
          {buttonText}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const TableBodyItem = ({ list, actionClick }) => {
  if (list.length > 0) {
    return list.map((candidate) => (
      <TeamTableItem
        key={candidate.id}
        item={candidate}
        actionClick={actionClick}
      />
    ));
  } else {
    return (
      <TableRow>
        <TableCell align="center" colSpan={TABLE_HEADERS.length}>
          No candidates
        </TableCell>
      </TableRow>
    );
  }
};

const CandidateSelectModal = (props) => {
  const {
    candidateModal,
    candidates,
    selectedGroup,
    userInfo,
    closeCandidateModal,
  } = props;

  const [list, setList] = useState(
    candidates.map((candidate) => ({
      ...candidate,
      status: selectedGroup.candidates.find((e) => e.id === candidate.id)
        ? STATUS_ADDED
        : STATUS_NOT,
    })),
  );

  const handleClickAction = async (item) => {
    setList(
      list.map((e) => ({
        ...e,
        status: item.id === e.id ? STATUS_ADDING : e.status,
      })),
    );
    if (selectedGroup.id === -1) {
      await props.addGroup(userInfo.id, 'Group 1', item);
    } else {
      await props.addCandidateToGroup({
        groupId: selectedGroup.id,
        candidate: item,
      });
    }

    setList(
      list.map((e) => ({
        ...e,
        status: item.id === e.id ? STATUS_ADDED : e.status,
      })),
    );
  };

  return (
    <Dialog
      open={candidateModal}
      onClose={closeCandidateModal}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <DialogTitle>Select Candidates</DialogTitle>

      <DialogContent>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="Search Bank">
            <TableHead>
              <TableRow>
                {TABLE_HEADERS.map((item) => (
                  <TableCell key={item.name} align={item.align}>
                    <Typography variant="overline" display="block">
                      {item.label}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableBodyItem
                list={list.filter((e) => e.status !== STATUS_ADDED)}
                actionClick={handleClickAction}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

CandidateSelectModal.propTypes = {
  candidateModal: PropTypes.bool.isRequired,
  candidates: PropTypes.array.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  addCandidateToGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedGroup: state.teamViewer.selectedGroup,
    userInfo: state.dashboard.userInfo,
  };
};

export default connect(mapStateToProps, {
  addCandidateToGroup,
  addGroup,
})(withContext(CandidateSelectModal));
