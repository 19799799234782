import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';
import { Container, Grid, Paper, Typography } from '@material-ui/core';

import PurchaseToken from './components/PurchaseToken';
import PurchaseHistory from './components/PurchaseHistory';
import { useStyles } from './AssessmentPurchase.style';

const AssessmentPurchase = (props) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper);
  const { isPurchaseTokenEnabled } = props;

  if (!isPurchaseTokenEnabled) return null;

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Paper className={fixedHeightPaper}>
                    <Typography variant="h6">Technicians explained</Typography>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      color="textSecondary"
                    >
                      An engineering technician is skilled in their discipline
                      of work with a level of practical competence. The
                      technician will typically have undergone a vocational
                      training program at college and a formal training
                      apprenticeship.
                      <br />
                      A technician should have a relatively good practical
                      understanding within their discipline of the theoretical
                      principles to enable them to perform their tasks
                      competently.
                      <br />
                      Technicians generally have a higher capability for
                      practical tasks with the utilisation of hand tools and
                      equipment compared to engineers in their field of
                      technology. Technicians are ‘Hands On’.
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={fixedHeightPaper}>
                    <Typography variant="h6">Engineers explained</Typography>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      color="textSecondary"
                    >
                      Engineers within their field of expertise would have had a
                      formal education and training at a high level. This
                      enables them to take personal responsibility to apply
                      their knowledge and experience for the development and
                      application of engineering tasks and solutions.
                      <br />
                      Engineers in general will not have had a vocational
                      training, their practical skills will not be at the same
                      level as that of a technician.
                      <br />
                      Engineers have the capability to direct and supervise the
                      technical and administrative work of others and to take
                      responsibility for the direction of tasks in their field
                      of expertise. Engineers are ‘Hands Off, Eyes On’.
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <PurchaseToken />
            </Grid>
            <Grid item xs={12}>
              <PurchaseHistory />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

AssessmentPurchase.propTypes = {
  isPurchaseTokenEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ global }) => {
  const { config } = global;
  return {
    isPurchaseTokenEnabled:
      !_.isNil(config) && config.settings.features.purchaseToken,
  };
};

export default connect(mapStateToProps)(AssessmentPurchase);
