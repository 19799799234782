import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';

import IntentTypeButton from './components/IntentTypeButton';
import { useStyles } from './IntentType.style';

const types = [
  {
    id: 1,
    title: 'Organisation (sponsor/talent-search)',
    descriptions: [
      'Purchasing and assigning assessments',
      'Searching for talent',
      'Graduate Assurance Programme',
    ],
  },
  {
    id: 2,
    title: 'Self-Sponsor',
    descriptions: ['Complete a Competas Assessment for yourself'],
  },
];

const IntentType = (props) => {
  const classes = useStyles();

  const { intentType, setConfirmedIntent, setIntentType } = props;

  return (
    <>
      <Grid item xs={12}>
        <Grid className={classes.root} container justifyContent="center">
          {types.map((item) => (
            <IntentTypeButton
              key={`intentTypeButton${item.id}`}
              item={item}
              intentType={intentType}
              setIntentType={setIntentType}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={setConfirmedIntent}
        >
          Continue
        </Button>
      </Grid>
    </>
  );
};

IntentType.propTypes = {
  intentType: PropTypes.number.isRequired,
  setConfirmedIntent: PropTypes.func.isRequired,
  setIntentType: PropTypes.func.isRequired,
};

export default IntentType;
