import axios from 'axios';
import Swal from 'sweetalert2';
import ACTION_ITEMS from '../actionItems';

export const fetchTeamCandidates = (sponsorId) => (dispatch) => {
  Swal.fire({
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
  axios
    .get(`/sponsor/${sponsorId}/sponsees`)
    .then((response) => {
      const { status, sponsees } = response.data;
      if (status === 'SUCCESS') {
        dispatch({
          type: ACTION_ITEMS.TEAM_VIEWER.USERS.SET,
          payload: sponsees,
        });
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      Swal.close();
    });
};

export const setSelectedCandidate = (user) => {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.SELECTED_CANDIDATE.SET,
    payload: user,
  };
};

export const fetchCompany = (companyId) => {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.COMPANIES.ADD,
    payload: {
      id: companyId,
      name: `Texaco -${companyId}`,
    },
  };
};

export function setSelectedRequest(request) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.SELECTED_REQUEST.SET,
    payload: request,
  };
}

export function updateRequestList(listName, requests) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.REQUESTS.UPDATE_LIST,
    payload: {
      listName,
      requests,
    },
  };
}

// @TODO - extend action to contain ajax request to get project data
export function fetchProject(projectId) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.PROJECTS.ADD,
    payload: {
      id: projectId,
      name: 'Vantage Black',
      startDate: 'JULY-2020',
    },
  };
}

// @TODO - extend action to contain ajax request to get requests for user
export const fetchUserRequests = (candidateId) => (dispatch) => {
  axios
    .get(`/sponsor/candidate/${candidateId}/passport-requests`)
    .then((response) => {
      const { status, passportRequests } = response.data;
      if (status === 'SUCCESS') {
        dispatch({
          type: ACTION_ITEMS.TEAM_VIEWER.REQUESTS.SET,
          payload: passportRequests,
        });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const feedbackRequest =
  (request, feedback, setAlert, clearSelected) => (dispatch) => {
    axios
      .put(`/sponsor/requestor/passport-requests/${request.id}`, {
        feedback,
      })
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          let text;
          switch (feedback) {
            case 1:
              text = 'accepted';
              break;
            case 2:
              text = 'rejected';
              break;

            default:
              break;
          }
          setAlert({
            open: true,
            message: `Successfully ${text} a request`,
            type: 'success',
          })(dispatch);
          fetchUserRequests(request.sponsorId)(dispatch);
          clearSelected(null);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

export function blockCompany(request) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.REQUESTS.BLOCK_COMPANY,
    payload: request,
  };
}
