import imgIncorrect from 'assets/images/self-assessment-incorrect.png';
import imgCorrect from 'assets/images/self-assessment-correct.png';

const resource = {
  TITLE: 'Please complete your Self-Assessment',
  HELPER_SECTION: [
    {
      TITLE: 'Example of an incorrect self-assessment',
      IMAGE: imgIncorrect,
    },
    {
      TITLE: 'Example of a correct self-assessment',
      IMAGE: imgCorrect,
    },
  ],
};

export default resource;
