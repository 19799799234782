import axios from 'axios';
import ACTION_ITEMS from '../../../actionItems';

export function removeFromGroup(groupId, candidateId, callback) {
  return (dispatch) => {
    axios
      .put(`/sponsor/group/remove/candidate`, {
        groupId,
        candidateId,
      })
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          callback();
          dispatch(removeCandidateFromGroup(groupId, candidateId));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function removeCandidateFromGroup(groupId, candidateId) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.GROUPS.CANDIDATE.REMOVE,
    payload: {
      group: groupId,
      candidateId,
    },
  };
}
