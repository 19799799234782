/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';

import CustomSelect from 'common/components/CustomSelect';
import { redirect } from 'common/actions';
import FaceDetection from './components/FaceDetection';
import CorrectionDialog from './components/CorrectionDialog';
import { candidateChangeRequest } from './actions/candidateChangeRequest';

const InfoVerifyForm = (props) => {
  const { options, candidateInfo, onClickFeedback, passportPhoto } = props;
  const { roles, disciplineRoleSpecific, countries } = options;
  const { user, roleId, disciplineId } = candidateInfo;
  const [disableButtons, setDisableButtons] = useState(true);
  const [userVerified, setUserVerified] = useState(false);
  // const [invalidCandidateResponse, setInvalidCandidateResponse] = useState('');
  const [correctionDialog, setCorrectionDialog] = useState(false);
  const [changeRequestSubmitted, setChangeRequestSubmitted] = useState(false);

  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current && countries.length > 0) {
      formikRef.current.setFieldValue('firstName', user.firstName);
      formikRef.current.setFieldValue('lastName', user.lastName);
      formikRef.current.setFieldValue('countryId', user.countryId);
      formikRef.current.setFieldValue('town', user.town);
      formikRef.current.setFieldValue('streetAddress', user.streetAddress);
      formikRef.current.setFieldValue('postCode', user.postCode);
      formikRef.current.setFieldValue('state', user.state);
      formikRef.current.setFieldValue('countryCode', user.countryCode);
      formikRef.current.setFieldValue('contactPhone', user.contactPhone);

      const dob = moment(user.dateOfBirth, 'YYYY-MM-DD');
      formikRef.current.setFieldValue('dobDay', dob.format('D'));
      formikRef.current.setFieldValue('dobMonth', dob.format('M'));
      formikRef.current.setFieldValue('dobYear', dob.format('YYYY'));

      formikRef.current.setFieldValue('gender', user.gender);
      formikRef.current.setFieldValue('email', user.email);
      formikRef.current.setFieldValue('roleId', roleId);
      formikRef.current.setFieldValue('disciplineId', disciplineId);
      setDisableButtons(false);
    }
  }, [countries]);

  const incorrectDataDialog = () => {
    setCorrectionDialog(true);
  };

  const showDataRequestSuccess = () => {
    setChangeRequestSubmitted(true);
    setCorrectionDialog(false);
    Swal.fire({
      title: 'Your sponsor will review your request',
      text: "You will be notified of your sponsor's decision via email in the near future",
      icon: 'success',
    }).then(() => {
      sendFeedback(2, false);
    });
  };

  const handleInvalidData = (changes) => {
    const formData = {
      ...changes,
      candidateId: candidateInfo.id,
    };

    props.candidateChangeRequest(formData, showDataRequestSuccess);
  };

  const handleCorrectionDialogClose = () => {
    setCorrectionDialog(false);
  };

  const handleClickNo = () => {
    Swal.fire({
      text: 'If you are a self-sponsor please email contact@competas.com, if you have been sponsored, please contact your sponsor.',
      icon: 'info',
    });
  };

  const OPTIONS_ROLE = roles.map((e) => ({
    label: e.name,
    value: e.id,
  }));

  const OPTIONS_DISCIPLINE = disciplineRoleSpecific.map((e) => ({
    label: e.name,
    value: e.id,
  }));

  const sendFeedback = (feedbackValue, goToNextPage = true) => {
    onClickFeedback(feedbackValue, goToNextPage);
  };

  const correctDataVerified = () => {
    sendFeedback(1, false);
    setUserVerified(true);
  };

  if (candidateInfo.flags.hasChangeRequest || changeRequestSubmitted) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box mt={3} mb={3}>
            <Box mb={4}>
              <Typography variant="h2">Change Request Submitted</Typography>
            </Box>
            <Typography variant="body1">
              Your sponsor will review your requested changes and you will be
              notified of their decision via email.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (passportPhoto && userVerified) {
    // TODO: Needs to be refactored to prevent transition when component is rendering
    props.redirect(`/candidate-onboarding/assessment/${candidateInfo.id}`);
    return null;
  } else if (!passportPhoto && userVerified) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box mt={3} mb={3}>
            <FaceDetection candidateInfo={candidateInfo} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <CorrectionDialog
        open={correctionDialog}
        handleClose={handleCorrectionDialogClose}
        handleChangeRequest={handleInvalidData}
        roldeId={roleId}
        disciplineId={disciplineId}
        roleOptions={OPTIONS_ROLE}
        disciplineOptions={OPTIONS_DISCIPLINE}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          firstName: '',
          lastName: '',
          town: '',
          streetAddress: '',
          postCode: '',
          state: '',
          countryId: '',
          countryCode: '',
          contactPhone: '',
          dobDay: '',
          dobMonth: '',
          dobYear: '',
          email: '',
          gender: '',
          roleId: '',
          disciplineId: '',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
          town: Yup.string().required('Required'),
          streetAddress: Yup.string().required('Required'),
          postCode: Yup.string().required('Required'),
          state: Yup.string(),
          countryId: Yup.number().required('Required'),
          countryCode: Yup.string().required('Required'),
          contactPhone: Yup.string().required('Required'),
          dobDay: Yup.number().required('Required'),
          dobMonth: Yup.number().required('Required'),
          dobYear: Yup.number().required('Required'),
          email: Yup.string()
            .email('Please enter a valid email')
            .required('Required'),
          gender: Yup.number().required('Required'),
          roleId: Yup.number().required('Required'),
          disciplineId: Yup.number().required('Required'),
        })}
      >
        {(p) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleBlur,
            handleSubmit,
          } = p;
          const propsToPass = {
            disabled: true,
            values,
            touched,
            errors,
            handleBlur,
          };
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h5">
                    Please verify your information
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Please verify your assigned Role/Discipline are correct by
                    pressing the ‘Yes information is correct’ tab below. If the
                    information is incorrect, please press the ‘No
                    Role/Discipline incorrect’ tab below.
                  </Typography>
                </Grid>
                <CustomSelect
                  id="roleId"
                  labelText="Assessed Role"
                  options={OPTIONS_ROLE}
                  {...propsToPass}
                />
                <CustomSelect
                  id="disciplineId"
                  labelText="Assessed Discipline"
                  options={OPTIONS_DISCIPLINE}
                  {...propsToPass}
                />
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => correctDataVerified()}
                    disabled={isSubmitting || disableButtons}
                    fullWidth
                  >
                    Yes. Information is correct.
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClickNo}
                    // onClick={() => incorrectDataDialog()}
                    disabled={isSubmitting || disableButtons}
                    fullWidth
                  >
                    No. This information is incorrect.
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

InfoVerifyForm.propTypes = {
  options: PropTypes.object.isRequired,
  candidateInfo: PropTypes.object.isRequired,
  onClickFeedback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { dashboard, options } = state;
  const { userInfo } = dashboard;

  return {
    passportPhoto: userInfo ? userInfo.passportPhoto : false,
    options,
  };
};

export default connect(mapStateToProps, { candidateChangeRequest, redirect })(
  InfoVerifyForm,
);
