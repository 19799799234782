import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash';
import ACTION_ITEMS from '../actionItems';

export const fetchPromotionCode = (code) => (dispatch) => {
  Swal.fire({
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
  axios
    .get(`/payment/promotion-code/${code}`)
    .then((response) => {
      Swal.close();
      const { status, promotion_code: promotionCode } = response.data;
      if (status === 'SUCCESS' && !_.isNil(promotionCode)) {
        dispatch(setPromotionCode(promotionCode));
      }
    })
    .catch((error) => {
      Swal.close();
      const { data } = error.response;
      if (data && data.status === 'ERROR') {
        Swal.fire({
          title: 'Error!',
          text: data.status_message,
          icon: 'error',
          confirmButtonColor: '#3f51b5',
        });
      }
    });
};

function setPromotionCode(promotionCode) {
  return {
    type: ACTION_ITEMS.PAYMENT.PROMOTION_CODE.SET,
    payload: promotionCode,
  };
}
