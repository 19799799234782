import React, {useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, FormControl, TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const CustomTextField = ({
  id,
  labelText,
  values,
  touched,
  errors,
  handleChange,
  hint,
  handleBlur,
  autoFocus,
  disabled,
  gridSize,
}) => {
  const errorMessage = errors[id];
  const hasError =
    !_.isNil(errorMessage) && !_.isNil(touched[id]) && touched[id];
  const [visible, setVisible] = useState(false);

  let type = 'password';
  if(visible) {
    type = 'text';
  }

  const handleClickShowPassword = () => {
    const newValue = !visible;
    setVisible(newValue);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid item xs={gridSize}>
      <FormControl fullWidth error={hasError}>
        <TextField
          disabled={disabled}
          autoFocus={autoFocus}
          id={id}
          variant="outlined"
          label={labelText}
          type={type}
          value={values[id]}
          onChange={handleChange ? handleChange(id) : null}
          onBlur={handleBlur(id)}
          InputLabelProps={{
            shrink: true,
          }}
          error={hasError}
          helperText={hasError ? errorMessage : hint}
          fullWidth
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {visible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,

          }}
        />
      </FormControl>
    </Grid>
  );
};

CustomTextField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  hint: PropTypes.string,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  gridSize: PropTypes.number,
};

CustomTextField.defaultProps = {
  disabled: false,
  autoFocus: false,
  gridSize: 6,
  handleChange: null,
  hint: '',
};

export default CustomTextField;
