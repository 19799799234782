import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, SnackbarContent } from '@material-ui/core';
import * as style from './NotificationBar.style';

export default function NotificationBar(props) {
  const classes = style.useStyles();
  const { show, className, message, variant } = props;

  if (!show) return null;
  const Icon = style.variantIcon[variant];

  return (
    <Grid item xs={12}>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
      />
    </Grid>
  );
}

NotificationBar.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

NotificationBar.defaultProps = {
  show: true,
  className: '',
  message: '',
};
