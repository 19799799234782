import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Fingerprint as FingerprintIcon } from '@material-ui/icons';

import { useCommonStyles } from 'common/common.style';
import { useStyles } from './SearchStatistics.style';

const SearchStatistics = ({ title, value, description }) => {
  const classesCommon = useCommonStyles();
  const classes = useStyles();

  return (
    <Grid item xs={4}>
      <Paper className={classesCommon.paper}>
        <Grid container>
          <Grid item xs={3}>
            <div className={classes.iconWrapper}>
              <FingerprintIcon className={classes.iconFingerprint} />
            </div>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h3">
              {value} {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              component="p"
              color="textSecondary"
              align="center"
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

SearchStatistics.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SearchStatistics;
