import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PositionCard from './components/PositionCard/PositionCard';
import AddPositionModal from '../AddPositionModal/AddPositionModal';

import { fetchAllPositions } from '../../actions/fetchAllPositions';

import { useStyles } from './PositionList.style';

const PositionList = (props) => {
  const classes = useStyles();

  const { positions, selectedProject, selectedPosition } = props;

  const [addPositionModal, setAddPositionModal] = useState(false);

  useEffect(() => {
    if (selectedProject) {
      props.fetchAllPositions(selectedProject.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  if (!selectedProject) return null;

  const handleAddPosition = () => {
    setAddPositionModal(true);
  };

  const handleAddPositionModalClose = () => {
    setAddPositionModal(false);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5">
        Positions
        <Fab
          className={classes.add}
          color="primary"
          aria-label="add"
          size="small"
          onClick={handleAddPosition}
        >
          <AddIcon />
        </Fab>
      </Typography>
      <div className={classes.listContainer}>
        {positions.map((position) => {
          const isSelected = !!(
            selectedPosition && selectedPosition.id === position.id
          );
          return (
            <PositionCard
              key={position.id}
              position={position}
              selected={isSelected}
            />
          );
        })}
      </div>
      <AddPositionModal
        open={addPositionModal}
        handleClose={handleAddPositionModalClose}
      />
    </div>
  );
};

const mapStateToProps = ({
  projectBuilder: { positions, selectedProject, selectedPosition },
}) => {
  return { positions, selectedProject, selectedPosition };
};

export default connect(mapStateToProps, { fetchAllPositions })(PositionList);
