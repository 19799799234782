import axios from 'axios';
import Swal from 'sweetalert2';
import ACTION_ITEMS from '../actionItems';

export const getCandidateSearchResult = (formData, callback) => async (
  dispatch,
) => {
  Swal.fire({
    title: 'processing...',
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  try {
    const response = await axios.post('/candidate/search', formData);
    const { results } = response.data;

    Swal.close();
    dispatch({
      type: ACTION_ITEMS.CANDIDATE_SEARCH.RESULTS.SET,
      payload: results,
    });
  } catch (error) {
    Swal.close();
    if (callback) callback();
    if (error.response && error.response.data) {
      Swal.fire({
        title: 'Error',
        text: error.response.data.status_message,
        icon: 'error',
      }).then(() => {
        if (callback) callback();
      });
    } else {
      console.error(error);
    }
  }
};
