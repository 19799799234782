const ACTION_ITEMS = {
  SIGNUP: {
    IS_LOADING: {
      SET: 'SIGNUP_IS_LOADING_SET',
    },
    MESSAGE: {
      SET: 'SIGNUP_MESSAGE_SET',
    },
    INFO: {
      SET: 'SIGNUP_INFO_SET',
    },
  },
};

export default ACTION_ITEMS;
