import { createContext, useState } from 'react';

export const RequestsModalContext = createContext({
  showRequestsModal: false,
  selectedCandidate: null,
  handleSetSelectCandidate: () => {},
  handleShowRequestsModal: () => {},
  handleCloseRequestsModal: () => {},
});

export const RequestsModalProvider = ({ children }) => {
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const handleSetSelectCandidate = (user) => setSelectedCandidate(user);
  const handleShowRequestsModal = () => setShowRequestsModal(true);
  const handleCloseRequestsModal = () => setShowRequestsModal(false);

  return (
    <RequestsModalContext.Provider
      value={{
        showRequestsModal,
        selectedCandidate,
        handleSetSelectCandidate,
        handleShowRequestsModal,
        handleCloseRequestsModal,
      }}
    >
      {children}
    </RequestsModalContext.Provider>
  );
};
