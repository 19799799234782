import axios from 'axios';
import ACTION_ITEMS from '../../../../../actionItems';

export function addCandidateToGroup(params) {
  return (dispatch) => {
    const { groupId, candidate } = params;
    return axios
      .post(`/sponsor/groups/${groupId}/candidate`, {
        groupId,
        candidateId: candidate.id,
      })
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          dispatch(addCandidateToGroupSuccess(params));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function addCandidateToGroupSuccess(params) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.GROUPS.CANDIDATE.ADD,
    payload: params,
  };
}
