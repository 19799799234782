import axios from 'axios';
import { getUserTokens } from '../../../../../../SponsorPortal/actions/getUserTokens';

export function postAddTokens(formData, userId, callback) {
  return (dispatch) => {
    callback();
    axios
      .post(`/admin/tokens`, formData)
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          dispatch(getUserTokens(userId));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
