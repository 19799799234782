import React from 'react';
import { Card, Grid, CardContent, Typography } from '@material-ui/core';
import { withContext } from 'common/helpers/context';

const introduction = {
  title: 'Getting started guide',
  content:
    'Follow the guidelines below in order to describe the important of following the instructions carefully. For more information, consult the Knowledge Library',
};

const sections = [
  {
    title: 'Guidance One',
    content:
      'COMPETAS assessess Candidates from Level 1 (no competency), to Level 5 (highest competency).  Please be advised that scores of 4 and 5 are rare, and to be used in search with discretion.',
  },
  {
    title: 'Guidance Two',
    content:
      'Use the deviation input boxes marked with “+/-“ to manage the plus or minus acceptable criteria for your search.  You can manage this on a global scale, or per topic.',
  },
  {
    title: 'Guidance Three',
    content:
      'In order to present search results in the most readable manner possible, COMPETAS advise selecting a maximum of 5 topics, however, you may select as many as you wish.',
  },
];

const HelpSection = ({ displayHelpText }) =>
  !displayHelpText ? null : (
    <Grid item xs={12}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5">{introduction.title}</Typography>
              <Typography variant="body1">{introduction.content}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={5}
          >
            {sections.map((section) => (
              <Grid item xs={4} key={section.title}>
                <Card>
                  <CardContent>
                    <Typography variant="h5">{section.title}</Typography>
                    <Typography variant="body1">{section.content}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

export default withContext(HelpSection);
