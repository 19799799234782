import ACTION_ITEMS from './actionItems';

const initialState = {
  candidateInfo: null,
};

export const candidateVerifyReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.CANDIDATE_VERIFY.INFO.SET: {
      newState = {
        ...state,
        candidateInfo: action.payload.candidate,
      };
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
