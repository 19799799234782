import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { Alert } from '@material-ui/lab';
import { addUserToTeam } from './actions/addUserToTeam';

const AddUserTeamModal = (props) => {
  const { addUserTeamModal, selectedTeam, closeAddUserTeamModal } = props;

  const [userEmail, setUserEmail] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const userSchema = Yup.object({
    teamId: Yup.number().required('Required'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Required'),
  });

  const handleAddTeam = async () => {
    setIsLoading(true);

    let params;
    try {
      params = await userSchema.validate({
        teamId: selectedTeam.id,
        email: userEmail,
      });

      await props.addUserToTeam(params);

      setIsLoading(false);
      closeAddUserTeamModal();
      Swal.fire({
        title: 'Success!',
        text: 'Successfully added a user to a team.',
        icon: 'success',
        confirmButtonColor: '#3f51b5',
      });
    } catch (error) {
      setIsLoading(false);
      setErrMessage(error.message);
    }
  };

  const handleOnChange = (event) => {
    setUserEmail(event.target.value);
  };

  return (
    <Dialog
      open={addUserTeamModal}
      onClose={closeAddUserTeamModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="User Email"
          type="email"
          value={userEmail}
          onChange={handleOnChange}
          fullWidth
        />
        {errMessage ? <Alert severity="error">{errMessage}</Alert> : null}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={closeAddUserTeamModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={userEmail.length === 0}
          onClick={handleAddTeam}
        >
          {isLoading ? <CircularProgress /> : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddUserTeamModal.propTypes = {
  addUserTeamModal: PropTypes.bool.isRequired,
  closeAddUserTeamModal: PropTypes.func.isRequired,
  addUserToTeam: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedTeam: state.teamViewer.selectedTeam,
  };
};

export default connect(mapStateToProps, {
  addUserToTeam,
})(withContext(AddUserTeamModal));
