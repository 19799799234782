import axios from 'axios';
import { push } from 'connected-react-router';
import ACTION_ITEMS from '../actionItems';

export function submitSignup(formData) {
  return (dispatch) => {
    if (formData.accountType === 0) {
      dispatch(setMessage('Please select account type.'));
      return;
    }

    dispatch(setMessage(''));
    dispatch(setIsLoading(true));
    axios
      .post('/auth/signup', formData)
      .then((response) => {
        dispatch(setIsLoading(false));
        const { status, user } = response.data;

        if (status === 'SUCCESS') {
            const [candidate] = user.candidates;
            if (candidate) {
              dispatch(
                push(`/candidate-onboarding/verify/info/${candidate.id}`),
              );
            } else {
              dispatch(push('/'));
            }
          };

      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        const { data } = error.response;
        if (data.status_code === 102) {
          dispatch(setMessage(data.status_message));
        }
      });
  };
}

function setIsLoading(isLoading) {
  return {
    type: ACTION_ITEMS.SIGNUP.IS_LOADING.SET,
    payload: { isLoading },
  };
}

function setMessage(message) {
  return {
    type: ACTION_ITEMS.SIGNUP.MESSAGE.SET,
    payload: { message },
  };
}
