import React, { useState, Children } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

const renderChildren = (children) => {
  return Children.map(children, (child, index) => (
    <MenuItem key={`menu-item-${index}`}>{child}</MenuItem>
  ));
};

const MenuWrapper = ({ onMenuClick, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  if (children.length === 0) return null;
  if (children.length === 1) return children[0];

  const handleClickAction = (event) => {
    event.stopPropagation();
    if (onMenuClick) onMenuClick();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClickAction}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderChildren(children)}
      </Menu>
    </>
  );
};

export default MenuWrapper;
