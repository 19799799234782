import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function getCandidateInfo(candidateId) {
  return (dispatch) => {
    axios
      .get(`/candidate/${candidateId}/info`)
      .then((response) => {
        const { status, candidate } = response.data;

        if (status === 'SUCCESS') {
          dispatch(getCandidateInfoSuccess(candidate));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function getCandidateInfoSuccess(candidate) {
  return {
    type: ACTION_ITEMS.CANDIDATE_VERIFY.INFO.SET,
    payload: { candidate },
  };
}
