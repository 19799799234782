import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function fetchAllPositions(projectId) {
  return (dispatch) => {
    axios
      .get(`/project/${projectId}/positions`)
      .then((response) => {
        const { status, positions } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setPositions(positions));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setPositions(positions) {
  return {
    type: ACTION_ITEMS.CANDIDATE_SEARCH.POSITION.SET,
    payload: positions,
  };
}
