import axios from 'axios';
import Swal from 'sweetalert2';
import { fetchAllProjects } from './fetchAllProjects';

export function deleteProject(projectId) {
  return (dispatch) => {
    return axios
      .delete(`/project/${projectId}`)
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          Swal.fire({
            title: 'Success',
            text: 'Successfully deleted a project',
            icon: 'success',
            confirmButtonColor: '#3f51b5',
          });
          dispatch(fetchAllProjects());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
