import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '2rem 1rem 2rem 1rem',
  },
  headerTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  headerSub2: {
    fontWeight: 'bold',
  },
}));
