import ACTION_ITEMS from './actionItems';

const initialState = {
  bookings: [],
  assignments: [],
  passportRequests: {
    outbound: [],
    inbound: [],
  },
};

export const candidatePortalReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.CANDIDATE_PORTAL.UPCOMING_ASSESSMENT.DETAILS.SET: {
      newState = {
        ...state,
        bookings: action.payload.bookings,
      };
      break;
    }

    case ACTION_ITEMS.CANDIDATE_PORTAL.ASSIGNMENTS.SET: {
      newState = {
        ...state,
        assignments: action.payload.assignments,
      };
      break;
    }

    case ACTION_ITEMS.CANDIDATE_PORTAL.PASSPORT_REQUEST.SET: {
      newState = {
        ...state,
        passportRequests: action.payload.passportRequests,
      };
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
