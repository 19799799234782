import axios from 'axios';
import Swal from 'sweetalert2';
import ACTION_ITEMS from '../actionItems';

export const getCandidateInfo = (userId) => (dispatch) => {
  Swal.fire({
    title: 'processing...',
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
  axios
    .get(`/candidate/${userId}/info`)
    .then((response) => {
      Swal.close();
      const { status, candidate, sponsor } = response.data;
      const data = {
        ...candidate,
        sponsor,
      };

      if (status === 'SUCCESS') {
        dispatch(getCandidateInfoSuccess(data));
      }
    })
    .catch((error) => {
      Swal.close();
      if (error.response && error.response.data) {
        Swal.fire({
          title: 'Error',
          text: error.response.data.status_message,
          icon: 'error',
        });
      } else {
        console.error(error);
      }
    });
};

function getCandidateInfoSuccess(info) {
  return {
    type: ACTION_ITEMS.SIGNUP.INFO.SET,
    payload: info,
  };
}
