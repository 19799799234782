import axios from 'axios';
import ACTION_ITEMS from '../../../actionItems';

export function updateTeamName(teamId, teamName, callback) {
  return (dispatch) => {
    axios
      .put(`/sponsor/teams/${teamId}`, {
        teamId,
        teamName,
      })
      .then((response) => {
        const { status, team } = response.data;
        if (status === 'SUCCESS') {
          callback();
          dispatch(updateTeam(team));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function updateTeam(team) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.TEAMS.UPDATE,
    payload: { team },
  };
}
