import ACTION_ITEMS from './actionItems';

const initialState = {
  alerts: [],
};

/* eslint-disable no-case-declarations */
export const userAlertsReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.USER_ALERTS.GET:
      newState = { alerts: action.payload.alerts };
      break;

    case ACTION_ITEMS.USER_ALERTS.CLEAR:
      newState = initialState;
      break;

    default:
      newState = state;
      break;
  }

  return newState;
};
/* eslint-enable no-case-declarations */
