import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function getBookings(userId) {
  return (dispatch) => {
    axios
      .get(`/booking/${userId}`)
      .then((response) => {
        const { status, bookings } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setBookings(bookings));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setBookings(bookings) {
  return {
    type: ACTION_ITEMS.CANDIDATE_PORTAL.UPCOMING_ASSESSMENT.DETAILS.SET,
    payload: { bookings },
  };
}
