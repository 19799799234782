import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';

const renderChart = ({ canvassId, label, datalabels, datasets, color }) => {
  return new Chart(canvassId, {
    type: 'line',
    data: {
      labels: datalabels,
      datasets: [
        {
          label,
          data: datasets,
          backgroundColor: color,
          borderColor: color.replace('.6', '1'),
          borderWidth: 1,
          fill: false,
          lineTension: 0,
        },
      ],
    },
    options: {
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 5,
              min: 0,
              stepSize: 0.5,
              suggestedMax: 5,
            },
          },
        ],
      },
    },
  });
};

const LineChart = ({
  canvassId,
  label,
  datalabels,
  datasets,
  color,
  width,
  height,
}) => {
  if (datasets.length > 0) {
    setTimeout(() => {
      renderChart({ canvassId, label, datalabels, datasets, color });
    }, 0);
  }

  return <canvas id={canvassId} width={width} height={height} />;
};

LineChart.propTypes = {
  canvassId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  datalabels: PropTypes.array.isRequired,
  datasets: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string.isRequired,
};

LineChart.defaultProps = {
  width: 400,
  height: 200,
};

export default LineChart;
