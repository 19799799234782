/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import CustomTextField from 'common/components/CustomTextField';
import CustomPasswordField from 'common/components/CustomPasswordField';
import CustomSelect from 'common/components/CustomSelect';
import {
  OPTIONS_GENDER,
  OPTIONS_DAY,
  OPTIONS_MONTH,
  OPTIONS_YEAR,
} from 'common/options';
import { getConfig } from 'common/actions/getConfig';
import { getOptions } from 'common/actions/getOptions';
import NotificationBar from 'common/components/NotificationBar';
import Copyright from 'common/components/Copyright';
import logo from 'assets/images/logoDarkBg.png';
import IntentType from './components/IntentType';
// import AccountType from './components/AccountType';
import { submitSignup } from './actions/submitSignup';
import { getCandidateInfo } from './actions/getCandidateInfo';
import { useStyles } from './Signup.style';
import PrivacyPolicy from '../Legal/PrivacyPolicy';
import DataProtection from '../Legal/DataProtection';
import DataRetention from '../Legal/DataRetention';

const Signup = (props) => {
  const { options, isLoading, message, match, info } = props;
  const [candidateId] = useState(match.params.candidateId);
  const isOnboarding = !_.isUndefined(candidateId);

  const [accountType, setAccountType] = useState(3);
  const [intentType, setIntentType] = useState(isOnboarding ? 3 : 0);
  const [confirmedIntent, setConfirmedIntent] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [dataProtectionAccepted, setDataProtectionAccepted] = useState(false);
  const [dataRetentionAccepted, setDataRetentionAccepted] = useState(false);
  const [termsDialog, setTermsDialog] = useState(false);
  const [dataProtectionDialog, setDataProtectionDialog] = useState(false);
  const [dataRetentionDialog, setDataRetentionDialog] = useState(false);
  const [termsError, setTermsError] = useState('');

  const classes = useStyles();
  const formikRef = useRef();

  const isSponsor = intentType === 1;

  const OPTIONS_COUNTRY = options.countries.map((e) => ({
    label: e.name,
    value: e.id,
  }));

  const OPTIONS_COUNTRY_CODE = options.countries.map((e) => ({
    label: `+${e.code}`,
    value: e.code,
  }));

  const handlePrivacyChange = (event) => {
    setPrivacyAccepted(event.target.checked);
  };

  const handleDataProtectionChange = (event) => {
    setDataProtectionAccepted(event.target.checked);
  };

  const handleDataRetentionChange = (event) => {
    setDataRetentionAccepted(event.target.checked);
  };

  const handleClickContinue = () => {
    setConfirmedIntent(true);
  };

  useEffect(() => {
    props.getConfig();
    props.getOptions();
  }, []);

  useEffect(() => {
    if (candidateId) {
      props.getCandidateInfo(candidateId);
    }
  }, [info.id]);

  const { user } = info;
  useEffect(() => {
    if (info.id) {
      setAccountType(user.accountType);
      if (formikRef.current && OPTIONS_COUNTRY.length > 0) {
        formikRef.current.setFieldValue('userId', user.id);
        formikRef.current.setFieldValue('firstName', user.firstName);
        formikRef.current.setFieldValue('lastName', user.lastName);
        formikRef.current.setFieldValue('username', user.email);
      }
    }
  }, [info, OPTIONS_COUNTRY]);

  const onFormSubmit = (values) => {
    if (privacyAccepted && dataProtectionAccepted && dataRetentionAccepted) {
      const formData = {
        ...values,
        accountType,
        intentType,
      };

      if (formData.company === '') {
        delete formData.company;
      }

      if (!isSponsor) {
        const dateOfBirth = moment(
          `${values.dobYear}-${values.dobMonth}-${values.dobDay}`,
          'YYYY-MM-DD',
        );
        formData.dateOfBirth = dateOfBirth;
      }

      props.submitSignup(formData);
    } else {
      setTermsError(
        'You must accept the Privacy Policy and Data Protection policies.',
      );
    }
  };

  const handleCountryCallback = (value) => {
    const countryCode = options.countries.filter(
      (country) => country.id === value,
    )[0];
    formikRef.current.setFieldValue('countryCode', countryCode.code);
  };

  const currDate = moment();

  const showTermsDialog = () => {
    setTermsDialog(true);
  };

  const closeTermsDialog = () => {
    setTermsDialog(false);
  };

  const showDataProtectionDialog = () => {
    setDataProtectionDialog(true);
  };

  const closeDataProtectionDialog = () => {
    setDataProtectionDialog(false);
  };

  const showDataRetentionDialog = () => {
    setDataRetentionDialog(true);
  };

  const closeDataRetentionDialog = () => {
    setDataRetentionDialog(false);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    town: '',
    streetAddress: '',
    postCode: '',
    state: '',
    countryId: '',
    countryCode: '',
    contactPhone: '',
    username: '',
    confirmUsername: '',
    password: '',
  };

  const validationObj = {
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    town: Yup.string().required('Required'),
    streetAddress: Yup.string().required('Required'),
    postCode: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    countryId: Yup.string().required('Required'),
    countryCode: Yup.string().required('Required'),
    contactPhone: Yup.number().required('Required'),
    username: Yup.string()
      .email('Please enter a valid email')
      .required('Required'),
    confirmUsername: Yup.string()
      .email('Please enter a valid email')
      .required('Required'),
    password: Yup.string().required('Required'),
  };

  if (!isOnboarding) {
    initialValues.company = '';

    validationObj.company = Yup.string().required('Required');
  } else {
    initialValues.userId = '';
  }

  // Only show DOB for self-sponsors
  if (!isSponsor) {
    initialValues.gender = '';
    initialValues.dobDay = currDate.format('D');
    initialValues.dobMonth = currDate.format('M');
    initialValues.dobYear = currDate.format('YYYY');

    validationObj.gender = Yup.number().required('Required');
    validationObj.dobDay = Yup.number().required('Required');
    validationObj.dobMonth = Yup.number().required('Required');
    validationObj.dobYear = Yup.number().required('Required');
  }

  const validationSchema = Yup.object().shape(validationObj);

  const validateUsername = (username, value) => {
    let error = '';
    if (username && value) {
      if (username !== value) {
        error = 'Email does not match';
      }
    }
    return error;
  };

  let title;
  let description;
  if (isOnboarding) {
    title = 'Onboarding';
    description = (
      <div className={classes.descriptionContainer}>
        <Typography
          component="p"
          variant="body2"
          className={classes.description}
        >
          Please remember your password; you will need this to access your
          COMPETAS dashboard in the future.
        </Typography>
        <Typography
          component="p"
          variant="body2"
          className={classes.description}
        >
          During the registration process you will be required to upload a
          passport-style photo of yourself. Please have this ready in advance.
        </Typography>
        <Typography
          component="p"
          variant="body2"
          className={classes.description}
        >
          The information that we collect during your registration is in line
          with{` `}
          <a href="#" onClick={showTermsDialog}>
            GDPR requirements
          </a>
          .
        </Typography>
        <Typography
          component="p"
          variant="body2"
          className={classes.description}
        >
          Following registration, you will be automatically directed to
          self-assess. This is where you will predict your self-assessed skills
          against topics presented.
        </Typography>
        <Typography
          component="p"
          variant="body2"
          className={classes.description}
        >
          <a href="mailto:contact@competas.com">
            If you need help during your onboarding process, email:
            contact@competas.com
          </a>
        </Typography>
      </div>
    );
  } else {
    title = 'Registration';
    description = 'Please enter or verify your information is correct';
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={8} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.logoWrapper}>
            <img alt="COMPETAS" src={logo} className={classes.logo} />
          </div>
          {!isOnboarding && !confirmedIntent ? (
            <>
              <Typography component="h1" variant="h5">
                How would you like to use Competas today?
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                This helps us get you started with the most helpful guidance
                { <br />  }{ <br />  }
              </Typography>
              <Grid container spacing={4}>
                <IntentType
                  intentType={intentType}
                  setConfirmedIntent={handleClickContinue}
                  setIntentType={setIntentType}
                />
              </Grid>
            </>
          ) : (
            <>
              <Typography component="h1" variant="h5">
                Competas {title}
              </Typography>
              <Typography component="h2" variant="h6">
                {description}
              </Typography>
              <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={onFormSubmit}
                validationSchema={validationSchema}
              >
                {(p) => {
                  const {
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    // isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = p;
                  const propsToPass = {
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                  };
                  return (
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <Grid container spacing={4}>
                        {!isOnboarding ? (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="overline">
                                Company Information
                              </Typography>
                            </Grid>
                            <CustomTextField
                              id="company"
                              labelText="Company Name (if applicable)"
                              gridSize={6}
                              {...propsToPass}
                            />
                            <Grid item xs={6} />
                          </>
                        ) : null}

                        <Grid item xs={12}>
                          <Typography
                            variant="overline"
                            className="alignCenter"
                          >
                            Sponsor Information
                          </Typography>
                        </Grid>
                        <CustomTextField
                          id="firstName"
                          labelText="First Name"
                          {...propsToPass}
                        />
                        <CustomTextField
                          id="lastName"
                          labelText="Last Name"
                          {...propsToPass}
                        />
                        {!isSponsor ? (
                          <>
                            <CustomSelect
                              id="gender"
                              labelText="Gender"
                              options={OPTIONS_GENDER}
                              {...propsToPass}
                            />
                            <Grid item xs={6} />
                          </>
                        ) : null}
                        <CustomTextField
                          id="username"
                          type="username"
                          labelText="Email"
                          {...propsToPass}
                        />
                        <Field
                          type="email"
                          name="confirmUsername"
                          validate={(value) =>
                            validateUsername(values.username, value)
                          }
                        >
                          {() => (
                            <CustomTextField
                              id="confirmUsername"
                              type="username"
                              labelText="Confirm Email"
                              {...propsToPass}
                            />
                          )}
                        </Field>
                        <CustomPasswordField
                          id="password"
                          labelText="Password"
                          hint="Password must contain a minimum of 8 characters to include a combination of number(s); upper case letter(s) and lower case letter(s)"
                          {...propsToPass}
                        />

                        <Grid item xs={12}>
                          <Typography variant="overline">Address</Typography>
                        </Grid>
                        <CustomTextField
                          id="streetAddress"
                          labelText="Street Address"
                          {...propsToPass}
                        />
                        <CustomTextField
                          id="town"
                          labelText="Town"
                          {...propsToPass}
                        />
                        <CustomTextField
                          id="state"
                          labelText="State / County"
                          {...propsToPass}
                        />
                        <CustomTextField
                          id="postCode"
                          labelText="Post Code"
                          {...propsToPass}
                        />
                        <CustomSelect
                          id="countryId"
                          labelText="Country"
                          options={OPTIONS_COUNTRY}
                          callback={handleCountryCallback}
                          {...propsToPass}
                        />
                        <CustomSelect
                          id="countryCode"
                          labelText="Country Code"
                          options={OPTIONS_COUNTRY_CODE}
                          gridSize={2}
                          {...propsToPass}
                        />
                        <CustomTextField
                          id="contactPhone"
                          labelText="Contact Phone"
                          gridSize={4}
                          {...propsToPass}
                        />
                        {!isSponsor ? (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="overline">
                                Date of Birth
                              </Typography>
                            </Grid>
                            <CustomSelect
                              id="dobDay"
                              labelText="Day"
                              options={OPTIONS_DAY}
                              gridSize={4}
                              {...propsToPass}
                            />
                            <CustomSelect
                              id="dobMonth"
                              labelText="Month"
                              options={OPTIONS_MONTH}
                              gridSize={4}
                              {...propsToPass}
                            />
                            <CustomSelect
                              id="dobYear"
                              labelText="Year"
                              options={OPTIONS_YEAR}
                              gridSize={4}
                              {...propsToPass}
                            />
                          </>
                        ) : null}

                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="privacyPolicy"
                                name="privacyPolicy"
                                checked={privacyAccepted}
                                onChange={handlePrivacyChange}
                              />
                            }
                            label={
                              <div>
                                <span>I accept the </span>
                                <Link onClick={showTermsDialog}>
                                  privacy policy
                                </Link>
                              </div>
                            }
                          />
                          <br />
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="dataProtection"
                                name="dataProtection"
                                checked={dataProtectionAccepted}
                                onChange={handleDataProtectionChange}
                              />
                            }
                            label={
                              <div>
                                <span>I accept the </span>
                                <Link onClick={showDataProtectionDialog}>
                                  data protection policy
                                </Link>
                              </div>
                            }
                          />
                          <br />
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="dataRetention"
                                name="dataRetention"
                                checked={dataRetentionAccepted}
                                onChange={handleDataRetentionChange}
                              />
                            }
                            label={
                              <div>
                                <span>I accept the </span>
                                <Link onClick={showDataRetentionDialog}>
                                  data retention policy
                                </Link>
                              </div>
                            }
                          />
                        </Grid>
                        {/* {isOnboarding ? (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="overline">
                            Company Information
                          </Typography>
                        </Grid>
                        <CustomTextField
                          id="company"
                          labelText="Company Name"
                          {...propsToPass}
                        />
                        <CustomTextField
                          id="sponsorCode"
                          labelText="Sponsor Code"
                          {...propsToPass}
                        />
                        <Grid item xs={12}>
                          <Typography
                            variant="overline"
                            className="alignCenter"
                          >
                            Sponsor Information
                          </Typography>
                        </Grid>
                        <CustomTextField
                          id="sponsorCompany"
                          labelText="Sponsor Company"
                          disabled
                          {...propsToPass}
                        />
                      </>
                    ) : null} */}
                        <NotificationBar
                          show={message !== '' || termsError !== ''}
                          variant="error"
                          className={classes.margin}
                          message={message || termsError}
                        />
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                          >
                            {isLoading ? (
                              <CircularProgress />
                            ) : (
                              'Create Account'
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </>
          )}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              Already have an account?&nbsp;
              <Link href="/login" variant="body2">
                Sign In
              </Link>
              <Box mt={5}>
                <Copyright />
              </Box>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={false} sm={12} md={4} className={classes.image} />
      <Dialog onClose={closeTermsDialog} open={termsDialog}>
        <DialogContent>
          <PrivacyPolicy showLayout={false} />
        </DialogContent>
      </Dialog>
      <Dialog onClose={closeDataProtectionDialog} open={dataProtectionDialog}>
        <DialogContent>
          <DataProtection showLayout={false} />
        </DialogContent>
      </Dialog>
      <Dialog onClose={closeDataRetentionDialog} open={dataRetentionDialog}>
        <DialogContent>
          <DataRetention showLayout={false} />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

Signup.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  submitSignup: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  getCandidateInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { options, signup } = state;
  const { isLoading, message, info } = signup;

  return {
    options,
    isLoading,
    message,
    info,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    submitSignup,
    getCandidateInfo,
    getOptions,
    getConfig,
  })(Signup),
);
