import { makeStyles } from '@material-ui/core/styles';

export const backgroundColor = '#181738';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor,
  },
  tabBackground: {
    backgroundColor: '#133E9C',
  },
  tab: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'Nunito',
    letterSpacing: '0.1rem',
    backgroundColor: '#133E9C',
  },
}));
