import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function getConfig() {
  return (dispatch) => {
    axios
      .get(`/config`)
      .then((response) => {
        const { status, config } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setConfig(config));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setConfig(config) {
  return {
    type: ACTION_ITEMS.GLOBAL.CONFIG.SET,
    payload: config,
  };
}
