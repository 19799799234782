import axios from 'axios';
import Swal from 'sweetalert2';
import { push } from 'connected-react-router';
import ACTION_ITEMS from '../actionItems';

export function getDisciplineTargets(candidateId) {
  return (dispatch) => {
    axios
      .get(`/self-assessment/discipline-targets/${candidateId}`)
      .then((response) => {
        const {
          status,
          isSelfSponsored,
          disciplines,
          hasSafety,
          hasCompletions,
        } = response.data;

        if (status === 'SUCCESS') {
          dispatch(
            getDisciplineTargetsSuccess({ isSelfSponsored, disciplines, hasSafety, hasCompletions }),
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          const { data } = error.response;
          if (data.status_code === 102) {
            Swal.fire({
              title: 'Error!',
              text: data.status_message,
              icon: 'error',
            }).then(() => {
              dispatch(push('/login'));
            });
          } else {
            console.error(error);
          }
        } else {
          console.error(error);
        }
      });
  };
}

function getDisciplineTargetsSuccess(obj) {
  return {
    type: ACTION_ITEMS.SELF_ASSESSMENT.DISCIPLINE.SET,
    payload: obj,
  };
}
