/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  StepConnector,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PaymentIcon from '@material-ui/icons/Payment';

import CustomTextField from 'common/components/CustomTextField';
import CustomSelect from 'common/components/CustomSelect';
import { validateSponsorCode } from './actions/validateSponsorCode';
import { putSponsorCode } from './actions/putSponsorCode';
import { useStyles } from './SponsorCodeDialog.style';

const STEPS = ['Sponsor Infomation', 'Payment'];

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

const ColorStepIcon = (props) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <InfoIcon />,
    2: <PaymentIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};

const SponsorCodeDialog = (props) => {
  const classes = useStyles();
  const formikRef = useRef();
  const [isSelfSponsor, setIsSelfSponsor] = useState(false);
  const [activeStep] = useState(0);

  const { options, userInfo, userId, open, handleClose } = props;
  const { company } = userInfo;

  const OPTIONS_COUNTRY = options.countries.map((e) => ({
    label: e.name,
    value: e.id,
  }));

  const closeDialog = () => {
    handleClose();
  };

  const handlePrivacyChange = (event) => {
    setIsSelfSponsor(event.target.checked);
  };

  const handleClickCopy = () => {
    formikRef.current.setFieldValue('town', userInfo.town);
    formikRef.current.setFieldValue('streetAddress', userInfo.streetAddress);
    formikRef.current.setFieldValue('postCode', userInfo.postCode);
    formikRef.current.setFieldValue('state', userInfo.state);
    formikRef.current.setFieldValue('countryId', userInfo.countryId);
  };

  const onFormSubmit = (values) => {
    const {
      sponsorCode,
      companyName,
      town,
      streetAddress,
      postCode,
      state,
      countryId,
    } = values;

    let formData;
    if (isSelfSponsor) {
      formData = {
        sponsorCode: 'SSC',
      };
    } else {
      formData = {
        sponsorCode,
        companyName,
        town,
        streetAddress,
        postCode,
        state,
        countryId,
      };
    }

    props.putSponsorCode(formData, userId, () => {
      handleClose(true);
    });
  };

  let initialValues;
  let validationSchema;
  if (isSelfSponsor) {
    initialValues = {};
    validationSchema = Yup.object();
  } else {
    initialValues = {
      sponsorCode: '',
      companyName: company?.name || '',
      town: '',
      streetAddress: '',
      postCode: '',
      state: '',
      countryId: '',
    };
    validationSchema = Yup.object().shape({
      sponsorCode: Yup.string()
        .required('Required')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
        .max(5, 'Must be a 5 digit alpha or less')
        .test(
          'checkLength',
          'Must be a 5 digit alpha',
          (val) => val.length === 5,
        )
        .test(
          'checkDuplSponsorCode',
          'Sponsor code already exists',
          (value) => {
            return new Promise((resolve) => {
              props.validateSponsorCode(value, resolve);
            });
          },
        ),
      companyName: Yup.string().required('Required'),
      town: Yup.string().required('Required'),
      streetAddress: Yup.string().required('Required'),
      postCode: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      countryId: Yup.string().required('Required'),
    });
  }

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      className={classes.dialog}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
    >
      <DialogTitle>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {STEPS.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You haven&apos;t set your sponsor code yet. It&apos;ll be used for
          candidate reference number (CRN).
        </DialogContentText>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
        >
          {(p) => {
            const {
              values,
              touched,
              errors,
              setFieldValue,
              // isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = p;
            const propsToPass = {
              values,
              touched,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
            };
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="privacyPolicy"
                          name="privacyPolicy"
                          checked={isSelfSponsor}
                          onChange={handlePrivacyChange}
                        />
                      }
                      label={
                        <div>
                          <span>self sponsor/no company name </span>
                        </div>
                      }
                    />
                  </Grid>
                  {!isSelfSponsor ? (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="overline">
                          Your Sponsor Information
                        </Typography>
                      </Grid>
                      <CustomTextField
                        autoFocus
                        id="sponsorCode"
                        labelText="Sponsor Code"
                        {...propsToPass}
                      />
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Your sponsor code is a 5 digit alpha code, ie.
                          &quot;SHELL&quot; which Competas will use in a variety
                          of places in the application, such as on Competas
                          Passports.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="overline">
                          Company Information
                        </Typography>
                      </Grid>
                      <CustomTextField
                        id="companyName"
                        labelText="Company Name"
                        disabled
                        {...propsToPass}
                      />
                      <CustomTextField
                        id="streetAddress"
                        labelText="Street Address"
                        {...propsToPass}
                      />
                      <CustomTextField
                        id="town"
                        labelText="Town"
                        {...propsToPass}
                      />
                      <CustomTextField
                        id="state"
                        labelText="State / County"
                        {...propsToPass}
                      />
                      <CustomTextField
                        id="postCode"
                        labelText="Post Code"
                        gridSize={4}
                        {...propsToPass}
                      />
                      <CustomSelect
                        id="countryId"
                        labelText="Country"
                        gridSize={8}
                        options={OPTIONS_COUNTRY}
                        {...propsToPass}
                      />
                    </>
                  ) : null}
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleClickCopy}
                    >
                      Copy information from registration
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Next Step
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

SponsorCodeDialog.propTypes = {
  userId: PropTypes.number.isRequired,
  options: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  validateSponsorCode: PropTypes.func.isRequired,
  putSponsorCode: PropTypes.func.isRequired,
};

SponsorCodeDialog.defaultProps = {
  userInfo: null,
};

const mapStateToProps = ({ dashboard, options }) => {
  const { userInfo } = dashboard;
  return {
    options,
    userInfo,
  };
};

export default connect(mapStateToProps, {
  validateSponsorCode,
  putSponsorCode,
})(SponsorCodeDialog);
