const ACTION_ITEMS = {
  SPONSOR_PORTAL: {
    TOKENS: {
      SET: 'SPONSOR_PORTAL_TOKENS_SET',
      ASSESSMENT_FLAG: {
        UPDATE: 'SPONSOR_PORTAL_ASSESSMENT_FLAG_UPDATE',
      },
    },
    STATISTICS: {
      SET: 'SPONSOR_PORTAL_STATISTICS_SET',
    },
  },
};

export default ACTION_ITEMS;
