import axios from 'axios';
import Swal from 'sweetalert2';

export const requestPassportAccess = (requestorId, candidateId) => async () => {
  Swal.fire({
    title: 'processing...',
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  try {
    const response = await axios.put(
      `/candidate/${candidateId}/passport-request`,
      {
        requestorId,
      },
    );
    Swal.hideLoading();
    const { status } = response.data;

    if (status === 'SUCCESS') {
      Swal.fire({
        title: 'Success!',
        text: 'Successfully requested passport access.',
        icon: 'success',
        confirmButtonColor: '#3f51b5',
      });
    }
  } catch (error) {
    if (error.response.status === 404) {
      Swal.fire({
        title: 'Error',
        text: `${error.response.status} ${error.response.statusText}`,
        icon: 'error',
      });
    } else if (error.response && error.response.data) {
      Swal.fire({
        title: 'Error',
        text: error.response.data.status_message,
        icon: 'error',
      });
    } else {
      console.error(error);
    }
  }
};
