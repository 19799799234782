import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { People as PeopleIcon } from '@material-ui/icons';

const TeamViewerDrawerItem = ({ show, disabled, selected, onClick }) => {
  if (!show) return null;

  const handleClick = () => {
    onClick(TeamViewerDrawerItem.pathname);
  };

  return (
    <ListItem
      button
      selected={selected}
      onClick={handleClick}
      disabled={disabled}
    >
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Talent Management Dashboard" />
    </ListItem>
  );
};

TeamViewerDrawerItem.propTypes = {
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

TeamViewerDrawerItem.defaultProps = {
  show: true,
  disabled: false,
  selected: false,
};

TeamViewerDrawerItem.pathname = '/talent-management';

export default TeamViewerDrawerItem;
