/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Button, Typography } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { redirect } from 'common/actions';
import CandidateCard from './components/CandidateCard/CandidateCard';
// import AddCandidateModal from '../AddCandidateModal/AddCandidateModal';
import { fetchAllPositionCandidates } from '../../actions/fetchAllPositionCandidates';

import { useStyles } from './CandidateList.style';

const CandidateList = (props) => {
  const classes = useStyles();
  const [, setCookie] = useCookies(['roleId', 'disciplineId', 'filters']);

  const { candidates, skills, selectedPosition, selectedCandidate } = props;

  // const [addCandidateModal, setAddCandidateModal] = useState(false);

  useEffect(() => {
    if (selectedPosition) {
      props.fetchAllPositionCandidates(selectedPosition.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPosition]);

  if (!selectedPosition) return null;

  // const handleAddCandidate = () => {
  //   setAddCandidateModal(true);
  // };

  // const handleAddCandidateModalClose = () => {
  //   setAddCandidateModal(false);
  // };

  const handleClickSearch = () => {
    setCookie('roleId', selectedPosition.roleId);
    setCookie('disciplineId', selectedPosition.disciplineId);
    const filters = skills.map((skill) => {
      return {
        topic: skill.disciplineTarget,
        lowest: skill.lowest.toString(),
        desired: skill.desired.toString(),
        highest: skill.highest.toString(),
      };
    });
    setCookie('filters', filters);

    props.redirect('/talent-management');
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5">
        Candidates
        {/* <Fab
          className={classes.add}
          color="primary"
          aria-label="add"
          size="small"
          onClick={handleAddCandidate}
        >
          <AddIcon />
        </Fab> */}
      </Typography>
      <div className={classes.listContainer}>
        <>
          {candidates.map((candidate) => {
            const isSelected = !!(
              selectedCandidate && selectedCandidate.id === candidate.id
            );
            return (
              <CandidateCard
                key={candidate.id}
                positionCandidate={candidate}
                selected={isSelected}
              />
            );
          })}
          <div className={classes.searchContainer}>
            <Button
              className={classes.search}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SearchIcon />}
              onClick={handleClickSearch}
              fullWidth
            >
              Search
            </Button>
          </div>
        </>
      </div>
      {/* <AddCandidateModal
        open={addCandidateModal}
        handleClose={handleAddCandidateModalClose}
      /> */}
    </div>
  );
};

const mapStateToProps = ({
  projectBuilder: { candidates, skills, selectedPosition, selectedCandidate },
}) => {
  return { candidates, skills, selectedPosition, selectedCandidate };
};

export default connect(mapStateToProps, {
  fetchAllPositionCandidates,
  redirect,
})(CandidateList);
