import axios from 'axios';
import Swal from 'sweetalert2';
import ACTION_ITEMS from '../../../actionItems';

export function removeFromTeam(teamId, userId) {
  return (dispatch) => {
    axios
      .delete(`/sponsor/teams/${teamId}/user/${userId}`)
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          Swal.fire({
            title: 'Success!',
            text: 'Successfully removed user.',
            icon: 'success',
            confirmButtonColor: '#3f51b5',
          });
          dispatch(removeCandidateFromTeam(teamId, userId));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function removeCandidateFromTeam(teamId, userId) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.TEAMS.USER.REMOVE,
    payload: {
      teamId,
      userId,
    },
  };
}
