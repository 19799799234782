/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from 'common/components/CustomTextField';
import Copyright from 'common/components/Copyright';
import NotificationBar from 'common/components/NotificationBar';
import { resetPassword } from './actions/resetForm';
import { useStyles } from './ResetForm.style';

const ResetForm = (props) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(null);

  const { isLoading, message, emailSent } = props;

  const onFormSubmit = (values) => {
    setFormValues({
      username: values.username,
    });
  };

  useEffect(() => {
    if (formValues) {
      props.resetPassword(formValues);
    }
  }, [formValues]);

  if (emailSent) {
    return (
      <div>
        <Typography variant="body1">
          If you have an account registered with this email, an email will have
          been sent with instructions to reset your password
        </Typography>
      </div>
    );
  } else {
    return (
      <Formik
        initialValues={{
          username: props.userEmail || '',
        }}
        onSubmit={onFormSubmit}
        validationSchema={Yup.object().shape({
          username: Yup.string().email().required('Required'),
        })}
      >
        {(p) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = p;
          const propsToPass = {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
          };

          return (
            <form className={classes.form} onSubmit={handleSubmit}>
              <CustomTextField
                id="username"
                labelText="Email Address"
                autoFocus
                gridSize={12}
                {...propsToPass}
              />
              <NotificationBar
                show={message !== ''}
                variant="error"
                className={classes.margin}
                message={message}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {isLoading ? <CircularProgress /> : 'Reset Password'}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2">
                    Login
                  </Link>
                </Grid>
                <Grid item>
                  {"Don't have an account? "}
                  <Link href="/signup" variant="body2">
                    Sign Up
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          );
        }}
      </Formik>
    );
  }
};

ResetForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, message, emailSent } = state.forgotPassword;

  return {
    isLoading,
    message,
    emailSent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (formData) => dispatch(resetPassword(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetForm);
