/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Paper, Grid, Typography, CircularProgress } from '@material-ui/core';

import { useCommonStyles } from 'common/common.style';
import { useStyles } from './DataBlock.style';

const DataBlock = (props) => {
  const classesCommon = useCommonStyles();
  const classes = useStyles();

  const { title, value, children } = props;

  return (
    <Paper
      className={clsx(classesCommon.paper, classes.paper, classes.svgWaveMini)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" align="center">
            {value !== -1 ? value : <CircularProgress />}
          </Typography>
        </Grid>
        {children ? (
          <Grid item xs={12}>
            {children}
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};

DataBlock.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default DataBlock;
