/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Grid } from '@material-ui/core';
import { Provider } from 'common/helpers/context';
import UpcomingAssessment from './components/UpcomingAssessment';
import YourPassports from './components/YourPassports';
import PassportRequestTabular from './components/PassportRequestTabular';

import { useStyles } from './CandidatePortal.style';
import { getBookings } from './actions/getBookings';
import { getCandidateAssignments } from './actions/getCandidateAssignments';
import { getPassportRequest } from './actions/getPassportRequest';

const CandidatePortal = (props) => {
  const classes = useStyles();

  const { userInfo, bookings, assignments, anonResults, passportRequests } =
    props;
  const { candidates } = userInfo;

  useEffect(() => {
    if (userInfo) {
      props.getBookings(userInfo.id);
      props.getCandidateAssignments(userInfo.id);
      props.getPassportRequest(userInfo.id);
    }
  }, [userInfo]);

  const getContext = () => ({
    userInfo,
    bookings,
    candidates,
    assignments,
    anonResults,
    passportRequests,
  });

  return (
    <Provider value={getContext()}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={5}>
          {bookings
            .filter((booking) => {
              const { doneTechAssessment, donePsychAssessment } =
                booking.candidate.flags;
              return !doneTechAssessment || !donePsychAssessment;
            })
            .map((booking) => (
              <UpcomingAssessment
                key={`booking-${booking.id}`}
                booking={booking}
              />
            ))}
          <YourPassports shouldHide />
          <PassportRequestTabular
            isOutbound
            title="Passport Requests (Outbound)"
            passportRequests={passportRequests.outbound}
          />
          <PassportRequestTabular
            title="Passport Requests (Inbound)"
            passportRequests={passportRequests.inbound}
          />
        </Grid>
      </Container>
    </Provider>
  );
};

CandidatePortal.propTypes = {
  userInfo: PropTypes.object.isRequired,
  bookings: PropTypes.array.isRequired,
  assignments: PropTypes.array.isRequired,
  passportRequests: PropTypes.object.isRequired,
  getBookings: PropTypes.func.isRequired,
  getCandidateAssignments: PropTypes.func.isRequired,
  getPassportRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { dashboard, candidatePortal } = state;
  const { userInfo } = dashboard;
  const { bookings, assignments, passportRequests } = candidatePortal;
  return { userInfo, bookings, assignments, passportRequests };
};

const mapDispatchToProps = (dispatch) => ({
  getBookings: (userId) => dispatch(getBookings(userId)),
  getCandidateAssignments: (userId) =>
    dispatch(getCandidateAssignments(userId)),
  getPassportRequest: (userId) => dispatch(getPassportRequest(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidatePortal);
