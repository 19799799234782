import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function getClientSecret(amount) {
  return (dispatch) => {
    axios
      .post('/payment/create-intent', amount)
      .then((response) => {
        dispatch(setClientSecret(response.data.client_secret));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}


function setClientSecret(clientSecret) {
  return {
    type: ACTION_ITEMS.PAYMENT.CLIENT_SECRET.SET,
    payload: clientSecret,
  };
}
