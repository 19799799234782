import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function fetchAllPositionSkills(positionId) {
  return (dispatch) => {
    axios
      .get(`/position/${positionId}/skills`)
      .then((response) => {
        const { status, skills } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setPositionSkills(skills));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setPositionSkills(skills) {
  return {
    type: ACTION_ITEMS.PROJECT_BUILDER.SKILL.SET,
    payload: skills,
  };
}
