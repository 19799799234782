const ACTION_ITEMS = {
  PASSWORD: {
    IS_LOADING: {
      SET: 'FORGOT_PASSWORD_IS_LOADING',
    },
    MESSAGE: {
      SET: 'FORGOT_PASSWORD_MESSAGE_SET',
    },
    EMAIL_SENT: {
      SET: 'FORGOT_PASSWORD_EMAIL_SENT',
    },
  },
};

export default ACTION_ITEMS;
