/* eslint-disable default-case */
import React from 'react';
import { Chip } from '@material-ui/core';
import {
  Done as DoneIcon,
  Close as CloseIcon,
  Pause as PauseIcon,
} from '@material-ui/icons';

export const RequestStatusPill = ({ status }) => {
  let icon;
  let color;

  switch (status) {
    case 'Approved':
      icon = <DoneIcon />;
      color = 'primary';
      break;

    case 'Rejected':
      icon = <CloseIcon />;
      color = 'secondary';
      break;

    default:
      icon = <PauseIcon />;
      color = 'default';
      break;
  }

  return <Chip variant="outlined" label={status} icon={icon} color={color} />;
};
