import axios from 'axios';
import Swal from 'sweetalert2';
import { redirect } from 'common/actions';
// import ACTION_ITEMS from '../actionItems';
import { getUserTokens } from '../../../../SponsorPortal/actions/getUserTokens';
import { fetchPurchaseHistory } from '../../PurchaseHistory/actions/fetchPurchaseHistory';

export function postPurchaseToken(formData, userId, callback) {
  return (dispatch) => {
    axios
      .post(`/user/token`, formData)
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          Swal.fire({
            title: 'Success!',
            text: 'Thank you for your purchase, you will now be redirected.',
            icon: 'success',
            confirmButtonColor: '#3f51b5',
          }).then(() => {
            callback();
            dispatch(redirect('/'));
            dispatch(getUserTokens(userId));
            dispatch(fetchPurchaseHistory(userId));
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
