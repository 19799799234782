import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Badge,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withContext } from 'common/helpers/context';
import {
  Done as DoneIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import GroupCandidate from './components/GroupCandidate';
import { updateGroupName } from '../../../../actions/updateGroupName';
import { deleteGroup } from '../../../../actions/deleteGroup';

const StyledBadge = withStyles(() => ({
  badge: {
    right: -20,
    top: 10,
    padding: '0 4px',
    backgroundColor: '#D0DDFE',
    color: '#3D54F9',
  },
}))(Badge);

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#CBD4FF' : '#DFE6FB',
  padding: '15px',
  width: '100%',
  minHeight: 200,
  borderRadius: '5px',
});

const GroupItem = (props) => {
  const { group } = props;
  const { candidates } = group;
  const onDragEnd = () => {};
  const [editing, setEditing] = useState(false);
  const [groupName, setGroupName] = useState(group.name.toUpperCase());

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const onTextChange = (event) => {
    setGroupName(event.target.value.toUpperCase());
  };

  const callback = () => {
    setEditing(false);
  };

  const triggerUpdate = (groupId) => {
    props.updateGroupName(groupId, groupName, callback);
  };

  const triggerDelete = (groupId) => {
    props.deleteGroup(groupId);
  };

  const renderGroupName = (name, id) => {
    if (editing) {
      return (
        <Box mt={2} mb={3}>
          <TextField autoFocus value={name} onChange={onTextChange} />
          <DoneIcon onClick={() => triggerUpdate(id)} color="primary" />
          <CancelIcon onClick={toggleEditing} color="secondary" />
        </Box>
      );
    } else {
      return (
        <IconButton onClick={toggleEditing} color="inherit">
          <StyledBadge
            badgeContent={candidates.length - 1}
            color="primary"
            overlap="rectangular"
            showZero
          >
            <Typography variant="subtitle2" color="textSecondary">
              {name}
            </Typography>
          </StyledBadge>
        </IconButton>
      );
    }
  };

  return (
    <DragDropContext key={`group-list-${group.id}`} onDragEnd={onDragEnd}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          {renderGroupName(groupName, group.id)}
        </Grid>
        <Grid
          item
          xs={4}
          container
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          {group.id > 0 && (
            <Tooltip title={`Delete ${group.name}`} aria-label="delete">
              <DeleteIcon
                onClick={() => triggerDelete(group.id)}
                color="primary"
              />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Droppable droppableId={`list-${group.id}`}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <Grid container spacing={2}>
              {candidates.map((candidate, index) => (
                <GroupCandidate
                  key={candidate.id}
                  index={index}
                  group={group}
                  candidate={candidate}
                />
              ))}
              {provided.placeholder}
            </Grid>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateGroupName: (id, name, callback) =>
    dispatch(updateGroupName(id, name, callback)),
  deleteGroup: (id) => dispatch(deleteGroup(id)),
});

export default connect(null, mapDispatchToProps)(withContext(GroupItem));
