const resource = {
  TITLE: 'What happens next?',
  DESCRIPTION: `As COMPETAS is in private beta, the administrator will contact you with details.`,
  HELPER_SECTION: {
    DESCRIPTIONS: [
      `Someone, likely your employer, has purchased a COMPETAS Competency
      Assessment for you. Please verify your details are correct and
      create your account. For further guidance, please consult our
      Knowledge Library, or contact support.`,
    ],
  },
};

export default resource;
