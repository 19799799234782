import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Grid, Paper } from '@material-ui/core';

import Copyright from 'common/components/Copyright';
import { getConfig } from 'common/actions/getConfig';
import { getOptions } from 'common/actions/getOptions';
import SelfAssessment from './components/SelfAssessment';
import CandidateVerify from './components/CandidateVerify';
import FinishedOnboarding from './components/FinishedOnboarding';
import HelperSection from './components/HelperSection';
import { useStyles } from './CandidateOnboarding.style';

const CandidateOnboarding = (props) => {
  const classes = useStyles();
  const { location, match } = props;
  const { pathname } = location;
  let Component;

  useEffect(() => {
    props.getConfig();
    props.getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (matchUrl(pathname, '/verify/info/')) {
    Component = CandidateVerify;
  } else if (matchUrl(pathname, '/assessment/')) {
    Component = SelfAssessment;
  } else if (matchUrl(pathname, '/finished')) {
    Component = FinishedOnboarding;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={8} md={8} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Switch>
            <Route
              path={`${match.url}/verify/info/:candidateId`}
              component={CandidateVerify}
            />
            <Route
              path={`${match.url}/assessment/:candidateId`}
              component={SelfAssessment}
            />
            <Route
              path={`${match.url}/finished`}
              component={FinishedOnboarding}
            />
          </Switch>
          <Copyright className={classes.footer} />
        </div>
      </Grid>
      <Grid className={classes.helperSection} item xs={false} sm={4} md={4}>
        <HelperSection info={Component.helperInfo} align={Component.align} />
      </Grid>
    </Grid>
  );
};

const matchUrl = (fullUrl, subUrl) => {
  return fullUrl.indexOf(subUrl) > -1;
};

export default withRouter(
  connect(null, { getConfig, getOptions })(CandidateOnboarding),
);
