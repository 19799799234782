import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function fetchUserAlerts(userId) {
  return (dispatch) => {
    axios
      .get(`/user/${userId}/alerts`, {
        userId,
      })
      .then((response) => {
        const { status, alerts } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setAlerts(alerts));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setAlerts(alerts) {
  return {
    type: ACTION_ITEMS.USER_ALERTS.GET,
    payload: { alerts },
  };
}
