import axios from 'axios';
import ACTION_ITEMS from '../../../../../actionItems';

export function addUserToTeam(params) {
  return (dispatch) => {
    const { teamId, email } = params;
    return axios
      .post(`/sponsor/teams/${teamId}/user`, {
        email,
      })
      .then((response) => {
        const { status, user } = response.data;
        if (status === 'SUCCESS') {
          dispatch(addUserToTeamSuccess(teamId, user));
        }
      })
      .catch((error) => {
        throw new Error(error.response.data.status_message);
      });
  };
}

function addUserToTeamSuccess(teamId, user) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.TEAMS.USER.ADD,
    payload: {
      teamId,
      user,
    },
  };
}
