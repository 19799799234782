/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  TextField,
  DialogTitle,
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import { QRCode } from 'react-qrcode-logo';

import { ShareModalContext } from 'common/context';
import logo from 'assets/images/logoIcon.png';

import { useStyles } from './ShareModal.style';

const CustomQRCode = ({ url }) => {
  const classes = useStyles();
  return (
    <div className={classes.qrWrapper}>
      <QRCode value={url} logoImage={logo} size={250} removeQrCodeBehindLogo />
    </div>
  );
};

const ShareModal = () => {
  const { selectedCandidate, showShareModal, handleCloseShareModal } =
    useContext(ShareModalContext);
  const [url, setUrl] = useState('');
  const [urlAnon, setUrlAnon] = useState('');

  const setShareHash = ({ shareHash, shareHashAnon }) => {
    if (shareHash) {
      setUrl(
        `${process.env.REACT_APP_WEB_SOCKET_URL}/passport-share/${shareHash}`,
      );
      setUrlAnon(
        `${process.env.REACT_APP_WEB_SOCKET_URL}/passport-share/${shareHashAnon}`,
      );
    }
  };

  useEffect(() => {
    if (showShareModal) {
      (async () => {
        try {
          const res = await axios.get(
            `/candidate/${selectedCandidate.id}/share-hash`,
          );
          setShareHash(res.data);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [showShareModal]);

  const handleClickGenerateUrl = async () => {
    try {
      const res = await axios.put(
        `/candidate/${selectedCandidate.id}/share-hash`,
      );
      setShareHash(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickCopy = () => {
    copy(url);
  };

  const handleClickCopyAnon = () => {
    copy(urlAnon);
  };

  return (
    <Dialog open={showShareModal} onClose={handleCloseShareModal}>
      <DialogTitle>Passport Share</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {!url ? (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickGenerateUrl}
                fullWidth
              >
                Generate Public Passport URL
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomQRCode url={url} />
                    <TextField
                      variant="outlined"
                      label="Public Passport URL"
                      value={url}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickCopy}
                      startIcon={<FileCopy />}
                      fullWidth
                    >
                      Copy
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomQRCode url={urlAnon} />
                    <TextField
                      variant="outlined"
                      label="Anonymous Passport URL"
                      value={urlAnon}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickCopyAnon}
                      startIcon={<FileCopy />}
                      fullWidth
                    >
                      Copy
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseShareModal}
              fullWidth
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShareModal;
