import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function clearAllUserAlerts(userId) {
  return (dispatch) => {
    axios
      .post(`/user/${userId}/alerts/remove-all`, {
        userId,
      })
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          dispatch(removeAlerts());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function removeAlerts() {
  return {
    type: ACTION_ITEMS.USER_ALERTS.CLEAR,
  };
}
