import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { HomeWork as HomeWorkIcon } from '@material-ui/icons';

const ProjectBuilderDrawerItem = ({ show, disabled, selected, onClick }) => {
  if (!show) return null;

  const handleClick = () => {
    onClick(ProjectBuilderDrawerItem.pathname);
  };

  return (
    <ListItem
      button
      selected={selected}
      onClick={handleClick}
      disabled={disabled}
    >
      <ListItemIcon>
        <HomeWorkIcon />
      </ListItemIcon>
      <ListItemText primary="Framework Builder" />
    </ListItem>
  );
};

ProjectBuilderDrawerItem.propTypes = {
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ProjectBuilderDrawerItem.defaultProps = {
  show: true,
  disabled: false,
  selected: false,
};

ProjectBuilderDrawerItem.pathname = '/project-builder';

export default ProjectBuilderDrawerItem;
