import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { setUnderConstruction } from 'common/actions/setUnderConstruction';

const UnderConstruction = (props) => {
  const handleClose = () => {
    props.setUnderConstruction(false);
  };

  return (
    <Snackbar open={props.open} autoHideDuration={4000} onClose={handleClose}>
      <MuiAlert
        elevation={6}
        severity="error"
        variant="filled"
        onClose={handleClose}
      >
        This feature is under construction!
      </MuiAlert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => ({
  open: state.global.showUnderConstruction,
});

export default connect(mapStateToProps, { setUnderConstruction })(
  UnderConstruction,
);
