/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import image from 'assets/images/1f6507b8023bdde077a65a5c3006b2078caf0f4a.png';
import { redirect } from 'common/actions';
import InfoVerifyForm from './components/InfoVerifyForm';
import { verifyEmail } from './actions/verifyEmail';
import { getCandidateInfo } from './actions/getCandidateInfo';
import { updateCandidateFeedback } from './actions/updateCandidateFeedback';
import resource from './CandidateVerify.resource';

const CandidateVerify = (props) => {
  const { candidateInfo } = props;

  const { candidateId } = props.match.params;

  useEffect(() => {
    if (_.isNil(candidateInfo)) {
      props.getCandidateInfo(candidateId);
    }
  }, [candidateInfo]);

  if (_.isNil(candidateInfo)) return null;

  const onClickFeedback = (feedback, goToNextPage) => {
    if (feedback === 1) {
      props.verifyEmail(candidateId);
    }
    props.updateCandidateFeedback(
      {
        candidateId,
        feedback,
      },
      () => {
        if (goToNextPage) {
          props.redirect(`/candidate-onboarding/assessment/${candidateId}`);
        }
      },
    );
  };

  return (
    <InfoVerifyForm
      candidateInfo={candidateInfo}
      onClickFeedback={onClickFeedback}
    />
  );
};

CandidateVerify.propTypes = {
  verifyEmail: PropTypes.func.isRequired,
  getCandidateInfo: PropTypes.func.isRequired,
  updateCandidateFeedback: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
};

CandidateVerify.helperInfo = {
  image,
  descriptions: resource.HELPER_SECTION.DESCRIPTIONS,
};

CandidateVerify.align = 'center';

const mapStateToProps = (state) => {
  const { candidateInfo } = state.candidateVerify;
  return {
    candidateInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  redirect: (url) => dispatch(redirect(url)),
  verifyEmail: (candidateId) => dispatch(verifyEmail(candidateId)),
  getCandidateInfo: (candidateId) => dispatch(getCandidateInfo(candidateId)),
  updateCandidateFeedback: (formData, callback) =>
    dispatch(updateCandidateFeedback(formData, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidateVerify),
);
