import ACTION_ITEMS from '../actionItems';

export function setUnderConstruction(open) {
  return (dispatch) => {
    dispatch({
      type: ACTION_ITEMS.GLOBAL.UNDER_CONSTRUCTION.SET,
      payload: open,
    });
  };
}
