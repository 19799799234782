import ACTION_ITEMS from './actionItems';

const initialState = {
  clientSecret: null,
  promotionCode: null,
  discount: null,
};

export const purchaseTokenReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.PAYMENT.CLIENT_SECRET.SET: {
      newState = { ...state, clientSecret: action.payload };
      break;
    }

    case ACTION_ITEMS.PAYMENT.PROMOTION_CODE.SET: {
      const promotionCode = action.payload;
      const { coupon } = promotionCode;

      newState = { ...state, promotionCode, discount: coupon.percent_off };
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
