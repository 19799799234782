import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Tooltip, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { deleteSkill } from 'components/Dashboard/components/ProjectBuilder/actions/deleteSkill';
import { setSelectedSkill } from 'components/Dashboard/components/ProjectBuilder/actions/setSelectedSkill';

import { useStyles } from './SkillCard.style';

const LEVELS = [1, 2, 3, 4, 5];

const SkillCard = (props) => {
  const classes = useStyles();

  const { skill, selected, selectedPosition } = props;
  const { id, disciplineTarget, desired } = skill;

  const handleClickDelete = (e) => {
    e.stopPropagation();
    props.deleteSkill(selectedPosition.id, id);
  };

  const handleClickSelectedSkill = () => {
    props.setSelectedSkill(selected ? null : skill);
  };

  return (
    <div
      className={clsx(classes.root, selected && classes.selected)}
      onClick={handleClickSelectedSkill}
    >
      <div className={classes.title}>
        <Typography variant="subtitle2" className={classes.titleText}>
          {disciplineTarget.name}
        </Typography>
        <Tooltip title={`Delete ${disciplineTarget.name}`} aria-label="delete">
          <DeleteIcon onClick={handleClickDelete} color="primary" />
        </Tooltip>
      </div>
      <div className={classes.levelContainer}>
        {LEVELS.map((level) => {
          const isSelected = desired === level;
          return (
            <div
              key={`area-of-expertise-${disciplineTarget.id}_${level}`}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <button
                type="button"
                className={clsx(
                  classes.level,
                  isSelected && classes[`level${level}Selected`],
                )}
              >
                {level}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ projectBuilder: { selectedPosition } }) => {
  return { selectedPosition };
};

export default connect(mapStateToProps, { deleteSkill, setSelectedSkill })(
  SkillCard,
);
