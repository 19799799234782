import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import {
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from '@material-ui/core';
import { DATETIME_FORMAT } from 'common/constants';
import formatWithComma from 'common/helpers/formatWithComma';
import StyledTableRow from 'common/components/StyledTableRow';
import { useStyles } from './PurchaseHistory.style';
import { fetchPurchaseHistory } from './actions/fetchPurchaseHistory';

const columns = [
  {
    id: 'id',
    label: 'ID',
    align: 'right',
    minWidth: 100,
  },
  {
    id: 'totalAmount',
    label: 'TOTAL AMOUNT',
    align: 'right',
    format: (val) => `£ ${formatWithComma(val)}`,
    minWidth: 100,
  },
  {
    id: 'discount',
    label: 'DISCOUNT',
    align: 'right',
    format: (val) => (val === 0 ? 'n/a' : val),
    minWidth: 100,
  },
  {
    id: 'tokenIds',
    label: 'TOKEN ID',
    minWidth: 200,
  },
  {
    id: 'tokenTypes',
    label: 'ROLE TYPE',
    minWidth: 200,
  },
  {
    id: 'createdAt',
    label: 'PURCHASED DATE',
    format: (val) => moment(val).format(DATETIME_FORMAT),
    minWidth: 200,
  },
];

const renderTokenIds = (tokens) => {
  return (
    <Table size="small">
      <TableBody>
        {tokens.map((token) => {
          return (
            <TableRow key={`token-id-${token.tokenId}`}>
              <TableCell>{token.tokenId}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const renderTokenTypes = (tokens) => {
  return (
    <Table size="small">
      <TableBody>
        {tokens.map((token) => {
          const type = token.type === 1 ? 'Engineer' : 'Technician';
          return (
            <TableRow key={`token-type-${token.tokenId}`}>
              <TableCell>{type}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const createTableRow = ({ id, totalAmount, discount, tokens, createdAt }) => {
  return {
    id,
    totalAmount,
    discount,
    tokenIds: renderTokenIds(tokens),
    tokenTypes: renderTokenTypes(tokens),
    createdAt,
  };
};

const PurchaseHistory = (props) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { purchaseHistory, userInfo } = props;

  useEffect(() => {
    if (!_.isNil(userInfo)) {
      props.fetchPurchaseHistory(userInfo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = purchaseHistory.map((history) => createTableRow(history));

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Purchase History</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row) => {
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  className={clsx(
                                    column.noPadding &&
                                      classes.tableCellNoPadding,
                                  )}
                                >
                                  {column.format ? column.format(value) : value}
                                </TableCell>
                              );
                            })}
                          </StyledTableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan="7" style={{ textAlign: 'center' }}>
                        No data
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

PurchaseHistory.propTypes = {
  userInfo: PropTypes.object.isRequired,
  purchaseHistory: PropTypes.array.isRequired,
  fetchPurchaseHistory: PropTypes.func.isRequired,
};

const mapStateToProps = ({ dashboard, purchaseHistory }) => {
  const { userInfo } = dashboard;
  return {
    userInfo,
    purchaseHistory: purchaseHistory.purchaseHistory,
  };
};

export default connect(mapStateToProps, { fetchPurchaseHistory })(
  PurchaseHistory,
);
