import axios from 'axios';

export function updateCandidateFeedback(formData, callback) {
  return () => {
    axios
      .put('/candidate/feedback', formData)
      .then((response) => {
        const { status } = response.data;

        if (status === 'SUCCESS') {
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
