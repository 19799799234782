import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loginReducer } from './components/Login/Login.reducer';
import { signupReducer } from './components/Signup/Signup.reducer';
import { forgotPasswordReducer } from './components/ForgotPassword/ForgotPassword.reducer';
import { dashboardReducer } from './components/Dashboard/Dashboard.reducer';
import { sponsorPortalReducer } from './components/Dashboard/components/SponsorPortal/SponsorPortal.reducer';
import { candidateVerifyReducer } from './components/CandidateOnboarding/components/CandidateVerify/CandidateVerify.reducer';
import { selfAssessmentReducer } from './components/CandidateOnboarding/components/SelfAssessment/SelfAssessment.reducer';
import { psychometricReducer } from './components/Dashboard/components/Psychometric/Psychometric.reducer';
import { candidatePortalReducer } from './components/Dashboard/components/CandidatePortal/CandidatePortal.reducer';
import { candidateSearchReducer } from './components/Dashboard/components/CandidateSearch/CandidateSearch.reducer';
import { teamViewerReducer } from './components/Dashboard/components/TeamViewer/TeamViewer.reducer';
import { projectBuilderReducer } from './components/Dashboard/components/ProjectBuilder/ProjectBuilder.reducer';
import { passportReducer } from './components/Dashboard/components/Passport/Passport.reducer';
import { anonResultsReducer } from './common/components/AnonResults/AnonResults.reducer';
import { optionsReducer } from './common/reducers/options.reducer';
import { purchaseTokenReducer } from './components/Dashboard/components/AssessmentPurchase/components/PurchaseToken/PurchaseToken.reducer';
import { purchaseHistoryReducer } from './components/Dashboard/components/AssessmentPurchase/components/PurchaseHistory/PurchaseHistory.reducer';
import { userAlertsReducer } from './common/components/UserAlerts/UserAlerts.reducer';
import { integrityReviewReducer } from './components/Dashboard/components/ConfigControl/components/IntegrityReview/IntegrityReview.reducer';
import { topicControlReducer } from './components/Dashboard/components/ConfigControl/components/TopicControl/TopicControl.reducer';
import { globalReducer } from './common/reducers/global.reducer';

const createRootReducer = (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    signup: signupReducer,
    forgotPassword: forgotPasswordReducer,
    dashboard: dashboardReducer,
    sponsorPortal: sponsorPortalReducer,
    candidateVerify: candidateVerifyReducer,
    selfAssessment: selfAssessmentReducer,
    psychometric: psychometricReducer,
    candidatePortal: candidatePortalReducer,
    candidateSearch: candidateSearchReducer,
    teamViewer: teamViewerReducer,
    projectBuilder: projectBuilderReducer,
    passport: passportReducer,
    anonResults: anonResultsReducer,
    options: optionsReducer,
    purchaseToken: purchaseTokenReducer,
    purchaseHistory: purchaseHistoryReducer,
    userAlerts: userAlertsReducer,
    integrityReview: integrityReviewReducer,
    topicControl: topicControlReducer,
    global: globalReducer,
  });

  const rootReducer = (state, action) => {
    window.Intercom('update');
    if (action.type === 'RESET_STORE') {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };

  return rootReducer;
};

export default createRootReducer;
