import axios from 'axios';
import ACTION_ITEMS from '../../../actionItems';

export function rejectProctor(assignment) {
  return (dispatch) => {
    axios
      .post(`/integity-review/${assignment.id}/reject`, {
        assignmentId: assignment.id,
      })
      .then((response) => {
        const { statusText } = response;
        if (statusText === 'OK') {
          dispatch(removeAssignment(assignment));
        }
      })
      .catch((error) => {
        console.log('err');
        console.error(error);
      });
  };
}

export function approveProctor(assignment) {
  return (dispatch) => {
    axios
      .post(`/integity-review/${assignment.id}/accept`, {
        assignmentId: assignment.id,
      })
      .then((response) => {
        const { statusText } = response;
        if (statusText === 'OK') {
          dispatch(removeAssignment(assignment));
        }
      })
      .catch((error) => {
        console.log('err');
        console.error(error);
      });
  };
}

function removeAssignment(assignment) {
  return {
    type: ACTION_ITEMS.INTEGRITY_REVIEW.ASSIGNMENTS.REMOVE,
    payload: assignment,
  };
}
