import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'common/components/PageHeader';
import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Copyright from 'common/components/Copyright';

const DataRetention = (props) => {
  const { showLayout } = props;

  const renderHeader = () => {
    if(!showLayout) {
      return(null);
    }

    return (
      <PageHeader />
    );
  };


  const renderFooter = () => {
    if(!showLayout) {
      return(null);
    }

    return (
      <Box mb={5}>
        <Copyright />
      </Box>
    );
  };

  return (
    <div>
      {renderHeader()}
      <Box mt={5} mb={5}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3" component="h1" gutterBottom>Data Retention Policy</Typography>

              <TableContainer component={Paper}>
               <Table>
                 <TableHead>
                   <TableRow>
                     <TableCell>Document</TableCell>
                     <TableCell>Location Stored</TableCell>
                     <TableCell>Record Owner</TableCell>
                     <TableCell>Format</TableCell>
                     <TableCell>Retention Period</TableCell>
                     <TableCell>Basis for Retention</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   <TableRow>
                     <TableCell>Supplier Information</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>7 Years</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Client Information</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>7 years</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Customer Information</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>7 years</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Legal contracts</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>6 years for contracts that are not executed by deed and 12 years for contracts that are executed by deed. You are not legally obliged to keep them this long but you should keep them for the aforementioned limitation periods (ie the time in which someone can bring a claim against you) as you could be penalised by the court if you can’t produce relevant documents</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Audited financial statements, tax returns and assessments and banking records</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>6 years</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Records establishing client’s identity for money laundering purposes</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>5 years</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                    <TableCell><strong>Employment Records</strong></TableCell>
                   </TableRow>
                   <TableRow>
                     <TableCell>Job applications and interview records of unsuccessful candidates</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>A short period, 6 months after notifying unsuccessful candidates (or longer, if there is a clearly communicated policy to keep candidates CVs for future reference). Application forms should give applicants the opportunity to object to their details being retained</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Personnel and training records</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>While employment continues and up to six years after employment ceases</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Written particulars of employment, contracts of employment, and changes to terms and conditions</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>While employment continues and up to six years after employment ceases</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Working time opt-out forms</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Two years from the date on which they were entered into</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Annual leave records</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Six years or possibly longer if leave can be carried over from year to year</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Payroll and wage records for unincorporated businesses eg sole traders and partnerships</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Five years after 31 January following the year of assessment</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Payroll and wage records for companies</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Six years from the financial year-end in which payments were made</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>PAYE records</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Not less than three years after the end of the tax year to which they relate</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Maternity records</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Three years after the end of the tax year in which the maternity pay period ends</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Sickness records required for the purposes of Statutory Sick Pay</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Three years after the end of the tax year in which payments are made</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Current bank details of employees</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>No longer than necessary</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Any reportable accident, death or injury in connection with work</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>For at least three years from the date the report was made</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Consents for the processing of personal and sensitive data</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>For as long as the data is being processed and up to 6 years afterwards</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Disclosure and Barring Service (DBS), formerly Criminal Records Bureau (CRB), checks and disclosures of criminal records forms</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Should be deleted following recruitment process unless assessed as relevant to ongoing employment relationship. Once the conviction is spent, should be deleted unless it is an excluded profession</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Immigration checks</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Two years after the termination of employment</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Company Information</TableCell>
                   </TableRow>
                   <TableRow>
                     <TableCell>Company formation documents such as Certificate of Incorporation</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Permanently</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Register of members</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>Permanently</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Minutes of directors’ meetings</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>10 years from the date of the meeting (but records of meetings held before 1 October 2007 should be kept indefinitely)</TableCell>
                     <TableCell />
                   </TableRow>
                   <TableRow>
                     <TableCell>Copies of shareholder resolutions and minutes of shareholder meetings</TableCell>
                     <TableCell />
                     <TableCell />
                     <TableCell />
                     <TableCell>10 years from the date of the resolution or the meeting (but resolutions or meetings held before 1 October 2007 should be kept indefinitely)</TableCell>
                     <TableCell />
                   </TableRow>
                 </TableBody>
               </Table>
             </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {renderFooter()}
    </div>
  );
};

DataRetention.propTypes = {
  showLayout: PropTypes.bool,
};

DataRetention.defaultProps = {
  showLayout: true,
};


export default DataRetention;
