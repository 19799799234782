import axios from 'axios';

export function putSponsorCode(formData, userId, callback) {
  return () => {
    axios
      .put(`/user/${userId}/sponsor-code`, formData)
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
