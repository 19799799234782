import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { CircularProgress, CssBaseline } from '@material-ui/core';

import Copyright from 'common/components/Copyright';
import { getConfig } from 'common/actions/getConfig';
import { getOptions } from 'common/actions/getOptions';
import RequestsModal from 'common/components/RequestsModal/RequestsModal';
import PermissionsModal from 'common/components/PermissionsModal';
import ShareModal from 'common/components/ShareModal';
import {
  PermissionsModalProvider,
  RequestsModalProvider,
  ShareModalProvider,
} from 'common/context';
import { authenticate } from './actions/authenticate';
// import QuickLinksBar from './components/QuickLinksBar';
import ApplicationBar from './components/ApplicationBar';
import ApplicationDrawer from './components/ApplicationDrawer';
import ConfigControl from './components/ConfigControl';
import SponsorPortal from './components/SponsorPortal';
import AssessmentPurchase from './components/AssessmentPurchase';
import CandidatePortal from './components/CandidatePortal';
// import CandidateSearch from './components/CandidateSearch';
import TeamViewer from './components/TeamViewer';
import Psychometric from './components/Psychometric';
import Passport from './components/Passport';
import UserProfile from './components/UserProfile';
import ProjectBuilder from './components/ProjectBuilder';

import { getUserTokens } from './components/SponsorPortal/actions/getUserTokens';
import { useStyles } from './Dashboard.style';

const socket = io(process.env.REACT_APP_WEB_SOCKET_URL, {
  withCredentials: true,
  extraHeaders: {
    'my-custom-header': 'abcd',
  },
});

const Dashboard = (props) => {
  const classes = useStyles();
  const { authenticated, userId } = props;
  const [cookie, setCookie] = useCookies(['appDrawer']);
  const [open, setOpen] = useState(
    cookie.appDrawer ? cookie.appDrawer === 'true' : true,
  );

  socket.on('refresh_intent_process', () => {
    props.authenticate();
  });

  useEffect(() => {
    props.authenticate();
    props.getConfig();
    props.getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    retrieveUserTokens();
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const retrieveUserTokens = () => props.getUserTokens(userId);

  if (!authenticated) return <CircularProgress size="200" thickness={20} />;

  const handleDrawerOpen = () => {
    setCookie('appDrawer', true);
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setCookie('appDrawer', false);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ApplicationBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <ApplicationDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <RequestsModalProvider>
        <PermissionsModalProvider>
          <ShareModalProvider>
            <main className={classes.content}>
              {/* <QuickLinksBar /> */}
              <div className={classes.appBarSpacer} />
              <Switch>
                <Route path="/" exact component={SponsorPortal} />
                <Route
                  path="/assessment-purchase"
                  component={AssessmentPurchase}
                />
                <Route path="/passport-dashboard" component={CandidatePortal} />
                {/* <Route path="/talent-management" component={CandidateSearch} /> */}
                <Route path="/talent-management" component={TeamViewer} />
                <Route
                  path="/passport/:candidateId/:pageNo"
                  component={(props) => <Passport {...props} />}
                />
                <Route
                  path="/passport/:candidateId"
                  component={(props) => <Passport {...props} />}
                />
                <Route
                  path="/passport-anon/:candidateId/:pageNo"
                  component={(props) => <Passport isAnon={true} {...props} />}
                />
                <Route
                  path="/passport-anon/:candidateId"
                  component={(props) => <Passport isAnon={true} {...props} />}
                />
                <Route path="/profile" component={UserProfile} />
                <Route path="/psychometric" component={Psychometric} />
                <Route path="/project-builder" component={ProjectBuilder} />
                <Route path="/config-control" component={ConfigControl} />
                <Redirect to="/" />
              </Switch>
              <Copyright />
            </main>
            <RequestsModal />
            <PermissionsModal />
            <ShareModal />
          </ShareModalProvider>
        </PermissionsModalProvider>
      </RequestsModalProvider>
    </div>
  );
};

Dashboard.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  authenticate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { authenticated, userInfo } = state.dashboard;
  return {
    authenticated,
    userId: userInfo ? userInfo.id : 0,
  };
};

export default connect(mapStateToProps, {
  authenticate,
  getUserTokens,
  getOptions,
  getConfig,
})(Dashboard);
