import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from 'common/components/CustomSelect';

const CorrectionDialog = (props) => {
  const { open, handleClose, handleChangeRequest, roleId, disciplineId, roleOptions, disciplineOptions } = props;

  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current && roleId) {
      formikRef.current.setFieldValue('roleId', roleId);
      formikRef.current.setFieldValue('disciplineId', disciplineId);
    }
  }, [roleId, disciplineId]);

  const triggerFormSubmit = () => {
    formikRef.current.handleSubmit();
  };


  const onFormSubmit = (values) => {
    const formValues = {
      roleId: values.roleId,
      disciplineId: values.disciplineId,
    };

    handleChangeRequest(formValues);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{"Request assessment changes for role/discipline from your Sponsor?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you think your role or discipline for your assessment should be amended, please make the desired changes below. Once you have submitted the change requests, your sponsor will review the request and reply in due time.
        </DialogContentText>

        <Formik
          innerRef={formikRef}
          initialValues={{
            roleId: '',
            disciplineId: '',
          }}
          onSubmit={onFormSubmit}
          validationSchema={Yup.object().shape({
            roleId: Yup.number().required('Required'),
            disciplineId: Yup.number().required('Required'),
          })}
        >
          {(p) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = p;
            const propsToPass = {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
            };
            return (
              <form onSubmit={handleSubmit}>
                    <Box mt={3} mb={3}>
                      <Box mb={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <CustomSelect
                              id="roleId"
                              labelText="New Assesed Role"
                              options={roleOptions}
                              fullWidth
                              {...propsToPass}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomSelect
                              id="disciplineId"
                              labelText="New Assessed Discipline"
                              fullWidth
                              options={disciplineOptions}
                              {...propsToPass}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={handleClose}
                            color="secondary"
                            fullWidth
                            size="small"
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={triggerFormSubmit}
                          >
                            Request Assessment Changes
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
              </form>
            );
          }}
        </Formik>
      </DialogContent>

    </Dialog>
  );
};

CorrectionDialog.propTypes = {
  handleChangeRequest: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  roldeId: PropTypes.number.isRequired,
  disciplineId: PropTypes.number.isRequired,
  roleOptions: PropTypes.array.isRequired,
  disciplineOptions: PropTypes.array.isRequired,
};

export default CorrectionDialog;
