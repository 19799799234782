/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';

import OptionCountry from 'common/components/OptionCountry';

import { useStyles } from './StripeForm.style';

import { getClientSecret } from '../../../../actions/payments';
import { fetchPromotionCode } from '../../../../actions/fetchPromotionCode';

const BILLING_DETAILS = {
  email: '',
  address: {
    line1: '',
    city: '',
    country: null,
    postal_code: '',
    state: '',
  },
  name: '',
};

const useOptions = () => {
  const fontSize = '16px';
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  );

  return options;
};

const getCountryLabel = (country) => {
  return `${country.label} (${country.code})`;
};

const StripeForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const classes = useStyles();
  const [error, setError] = useState(null);
  const { userInfo, amount, description, addTokensToAccount } = props;
  const { clientSecret, promotionCode, discount } = props.purchaseToken;
  const { portalOptions, handleClose } = props;
  const [billingDetails, setBillingDetails] = useState(BILLING_DETAILS);
  const [promoCode, setPromoCode] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    // Update the document title using the browser API
    const floatAmount = parseFloat(amount.replace(/,/g, ''));
    const purchaser = userInfo.company
      ? userInfo.company.name
      : `${userInfo.firstName} ${userInfo.lastName}`;
    props.getClientSecret({
      amount: floatAmount,
      description: `${purchaser} has purchased ${description}`,
    });
    if (promotionCode) {
      setPromoCode(promotionCode.code);
    }
  }, [discount]);

  const OPTIONS_COUNTRY = portalOptions.countries.map((e) => ({
    label: e.name,
    code: e.isoCodes.split(' / ')[0],
  }));

  const resetForm = () => {
    setBillingDetails(BILLING_DETAILS);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setFormSubmitted(true);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          ...billingDetails,
          address: {
            ...billingDetails.address,
            country: billingDetails.address.country.code,
          },
        },
      },
    });

    setFormSubmitted(false);

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setError(result.error.message);
    } else {
      // The payment has been processed!
      resetForm();

      if (result.paymentIntent.status === 'succeeded') {
        handleClose();
        addTokensToAccount();

        Swal.fire({
          title: 'Success!',
          text: 'Your tokens have been purchased',
          icon: 'success',
          confirmButtonColor: '#3f51b5',
        });
      }
    }
  };

  const updateBillingDetails = (event) => {
    setBillingDetails({
      ...billingDetails,
      [event.target.id]: event.target.value,
    });
  };

  const updateBillingAddressDetails = (event) => {
    const newAddress = {
      ...billingDetails.address,
      [event.target.id]: event.target.value,
    };

    setBillingDetails({
      ...billingDetails,
      address: newAddress,
    });
  };

  const onSelectChange = (event, value) => {
    setBillingDetails({
      ...billingDetails,
      address: {
        ...billingDetails.address,
        country: value,
      },
    });
  };

  const handleChangePromotionCode = (e) => {
    setPromoCode(e.target.value);
  };

  const handleClickApplyCode = () => {
    props.fetchPromotionCode(promoCode);
  };

  const handleClickCopy = () => {
    const {
      firstName,
      lastName,
      email,
      streetAddress,
      town,
      state,
      postCode,
      countryId,
    } = userInfo;
    const country = portalOptions.countries.filter(
      (e) => e.id === countryId,
    )[0];

    setBillingDetails({
      name: `${firstName} ${lastName}`,
      email,
      address: {
        line1: streetAddress,
        city: town,
        state,
        postal_code: postCode,
        country: {
          label: country.name,
          code: country.isoCodes.split(' / ')[0],
        },
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleClickCopy}
          >
            Copy from profile
          </Button>
        </Box>

        <Paper className={classes.paper}>
          <Typography component="span" variant="overline" gutterBottom>
            Shipping &amp; Billing Information
          </Typography>
          <TextField
            className={classes.input}
            id="name"
            label="Name"
            placeholder="Jane Doe"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={billingDetails.name}
            onChange={updateBillingDetails}
          />

          <TextField
            className={classes.input}
            id="email"
            label="Email"
            placeholder="jenny@example.com"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={billingDetails.email}
            onChange={updateBillingDetails}
          />

          <TextField
            className={classes.input}
            id="line1"
            label="Address"
            placeholder="185 Berry Street Suite 550"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={billingDetails.address.line1}
            onChange={updateBillingAddressDetails}
          />

          <TextField
            className={classes.input}
            id="city"
            label="City"
            placeholder="San Francisco"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={billingDetails.address.city}
            onChange={updateBillingAddressDetails}
          />

          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                className={classes.input}
                id="state"
                label="State/County"
                placeholder="CA"
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={billingDetails.address.state}
                onChange={updateBillingAddressDetails}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className={classes.input}
                id="postal_code"
                label="ZIP"
                placeholder="94107"
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={billingDetails.address.postal_code}
                onChange={updateBillingAddressDetails}
              />
            </Grid>
          </Grid>

          <Autocomplete
            options={OPTIONS_COUNTRY}
            id="country"
            autoHighlight
            getOptionLabel={(option) => getCountryLabel(option)}
            getOptionSelected={(option, selectedOption) =>
              option.code === selectedOption.code
            }
            renderOption={(option) => <OptionCountry country={option} />}
            renderInput={(params) => (
              <TextField
                {...params}
                id="country"
                label="Country"
                variant="outlined"
                required
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
            value={billingDetails.address.country}
            onChange={onSelectChange}
            fullWidth
          />
        </Paper>

        <Typography component="span" variant="overline" gutterBottom>
          Payment Information
        </Typography>

        <Paper className={classes.paper}>
          <CardElement options={options} />
        </Paper>

        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={8}>
              <TextField
                id="promotion_code"
                label="Promotion Code"
                placeholder="Enter Promo Code"
                variant="outlined"
                fullWidth
                value={promoCode}
                disabled={!_.isNil(promotionCode)}
                onChange={handleChangePromotionCode}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                className={classes.btnApply}
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!_.isNil(promotionCode)}
                onClick={handleClickApplyCode}
              >
                {!_.isNil(promotionCode) ? 'Applied' : 'Apply'}
              </Button>
            </Grid>
          </Grid>
        </Paper>

        {error && (
          <Box mb={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={formSubmitted || !stripe}
        >
          Pay £ {amount}
        </Button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { dashboard, purchaseToken, options } = state;
  const { userInfo } = dashboard;
  return {
    portalOptions: options,
    userId: userInfo ? userInfo.id : 0,
    userInfo,
    purchaseToken,
  };
};

export default connect(mapStateToProps, {
  getClientSecret,
  fetchPromotionCode,
})(StripeForm);
