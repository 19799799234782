import axios from 'axios';
import Swal from 'sweetalert2';
import { getSponsorTeams } from '../../../actions/getSponsorTeams';
import { addUserToTeam } from '../../AddUserTeamModal/actions/addUserToTeam';

export function addTeam(userId, teamName, user = false) {
  return (dispatch) => {
    return axios
      .post(`/sponsor/${userId}/teams`, { teamName })
      .then((response) => {
        const { status, team } = response.data;
        if (status === 'SUCCESS') {
          if(!user) {
            Swal.fire({
              title: 'Success',
              text: 'Successfully added team',
              icon: 'success',
              confirmButtonColor: '#3f51b5',
            });
          } else {
            const data = {
              teamId: team.id,
              user,
            };
            dispatch(addUserToTeam(data));
          }
          dispatch(getSponsorTeams(userId));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
