import axios from 'axios';
import ACTION_ITEMS from '../../../actionItems';

export function getSponsorGroups(userId) {
  return (dispatch) => {
    axios
      .get(`/sponsor/${userId}/groups`)
      .then((response) => {
        const { status, groups } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setSponsorGroups(groups));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setSponsorGroups(groups) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.GROUPS.SET,
    payload: { groups },
  };
}
