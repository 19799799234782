import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  inner: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    width: 390,
    textAlign: 'center',
  },
  selected: {
    boxShadow:
      '0px 3px 5px -1px rgba(46,91,255,1), 0px 6px 10px 0px rgba(46,91,255,1), 0px 1px 18px 0px rgba(46,91,255,1)',
  },
  description: {
    marginBottom: theme.spacing(1.5),
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
  },
  icon: {
    fontSize: 50,
  },
  summary: {
    flexGrow: 1,
  },
}));
