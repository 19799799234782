import axios from 'axios';
import { push } from 'connected-react-router';

export function logout() {
  return (dispatch) => {
    axios
      .post(`/auth/logout`)
      .then((response) => {
        window.Intercom('shutdown');
        const { status } = response.data;

        if (status === 'SUCCESS') {
          dispatch({ type: 'RESET_STORE' });
          dispatch(push('/login'));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
