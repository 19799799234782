import ACTION_ITEMS from '../actionItems';

const initialState = {
  industries: [],
  roles: [],
  disciplines: [],
  disciplineRoleSpecific: [],
  disciplineTargets: [],
  countries: [],
};

export const optionsReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.OPTIONS.SET: {
      newState = {
        ...state,
        ...action.payload.options,
      };
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
