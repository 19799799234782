import axios from 'axios';
import Swal from 'sweetalert2';
import ACTION_ITEMS from '../actionItems';
import { authenticate } from './authenticate';

export function updateProfile(profile) {
  return (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setMessage(''));
    axios
      .put('/user/profile', profile)
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(authenticate());
        Swal.fire({
          title: 'Profile Updated',
          text: 'Your profile has been successfully updated',
          icon: 'success',
        });
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        dispatch(setMessage(error.response.data.status_message));
      });
  };
}

function setIsLoading(isLoading) {
  return {
    type: ACTION_ITEMS.USER_INFO.IS_LOADING.SET,
    payload: isLoading,
  };
}

function setMessage(message) {
  return {
    type: ACTION_ITEMS.USER_INFO.MESSAGE.SET,
    payload: message,
  };
}
