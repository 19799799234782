import ACTION_ITEMS from '../actionItems';

const initialState = {
  showUnderConstruction: false,
  alert: {
    open: false,
    message: '',
    type: '',
  },
  config: null,
};

export const globalReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.GLOBAL.UNDER_CONSTRUCTION.SET: {
      newState = {
        ...state,
        showUnderConstruction: action.payload,
      };
      break;
    }

    case ACTION_ITEMS.GLOBAL.ALERT.SET: {
      newState = {
        ...state,
        alert: {
          open: action.payload.open,
          message: action.payload.message || state.alert.message,
          type: action.payload.type || state.alert.type,
        },
      };
      break;
    }

    case ACTION_ITEMS.GLOBAL.CONFIG.SET: {
      newState = {
        ...state,
        config: action.payload,
      };
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
