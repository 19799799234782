import React from 'react';
import { connect } from 'react-redux';
import { Button, Box, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { approveProctor, rejectProctor } from './actions/reviewProctor';

const ReviewItem = (props) => {
  const { assignment } = props;
  const { user } = assignment.candidate;

  const handleAccept = () => {
    props.approveProctor(assignment);
  };


  const handleReject = () => {
    props.rejectProctor(assignment);
  };

  return (
    <ListItem>
      <ListItemText>
        {user.firstName} {user.lastName}
      </ListItemText>

      <ListItemSecondaryAction>
        <Box mb={2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleAccept}
          >
            Accept
          </Button>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleReject}
          >
            Reject
          </Button>
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default connect(null, { approveProctor, rejectProctor })(ReviewItem);
