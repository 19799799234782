import { makeStyles } from '@material-ui/core/styles';

export const useCommonStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 280,
  },
  backDrop: {
    filter: 'blur(5px)',
  },
  noLefRightPadding: {
    paddingLeft: '0!important',
    paddingRight: '0!important',
  },
}));
