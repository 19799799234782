import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';

import AdminTokenFormModal from './components/AdminTokenFormModal';
import { getAllUsers } from './actions/getAllUsers';

const AdminTokenModal = (props) => {
  const { open, userInfo } = props;

  const [users, setUsers] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(-1);

  useEffect(() => {
    if (users && users.length === 0) {
      (async () => {
        setUsers(await getAllUsers());
      })();
    }
  });

  const handleClickAddTokens = (userId) => () => {
    setSelectedUserId(userId);
    setOpenForm(true);
  };

  const handleClose = () => {
    if (props.handleClose) props.handleClose();
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ADD TOKEN</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right">ID</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={`user-list-${user.id}`}>
                    <TableCell align="right">{user.id}</TableCell>
                    <TableCell align="left">
                      {user.firstName} {user.lastName}
                    </TableCell>
                    <TableCell align="left">{user.email}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="inherit"
                        onClick={handleClickAddTokens(user.id)}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <AdminTokenFormModal
        open={openForm}
        handleClose={handleCloseForm}
        selectedUserId={selectedUserId}
        userInfo={userInfo}
      />
    </>
  );
};

AdminTokenModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect(null)(AdminTokenModal);
