/* eslint-disable no-unused-vars */
import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { QRCode } from 'react-qrcode-logo';
import { Grid, Paper, Typography, Toolbar, Box } from '@material-ui/core';
import { useCommonStyles } from 'common/common.style';

import logo from 'assets/images/logoIcon.png';
import { redirect } from 'common/actions';
import { withContext } from 'common/helpers/context';
import { useStyles } from './BasicInformation.style';
import PersonalDetails from './components/PersonalDetails';

const BasicInformation = (props) => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();

  const { passport, isAnon } = props;
  const { candidate, sponsor, isExpired } = passport;

  if (!candidate) return null;

  const { user, role, discipline, industry, shareHash, shareHashAnon, flags } =
    candidate;

  const assessmentSponsor = sponsor?.company?.name || 'SSC';
  const url = `${process.env.REACT_APP_WEB_SOCKET_URL}/passport-share/${shareHash}`;
  const urlAnon = `${process.env.REACT_APP_WEB_SOCKET_URL}/passport-share/${shareHashAnon}`;

  let displayCandidate;
  if (!isAnon && !isExpired) {
    displayCandidate = {
      ...candidate,
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.gender,
        dateOfBirth: user.dateOfBirth,
        email: user.email,
      },
    };
  } else {
    displayCandidate = {
      ...candidate,
      user: {
        firstName: 'John',
        lastName: 'Doe',
        gender: 3,
        dateOfBirth: '1959-01-01',
        email: 'jdoe@mail.com',
      },
    };
  }

  return (
    <Grid
      container
      spacing={5}
      className={isExpired ? classesCommon.backDrop : ''}
    >
      <Grid item xs={12} className={classesCommon.noLefRightPadding}>
        <Typography variant="h4" align="center" className={classes.header}>
          Basic Information
        </Typography>
      </Grid>
      <Grid item xs={12} className={classesCommon.noLefRightPadding}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Grid
                item
                sm={12}
                md={3}
                className={clsx(classes.toolbarItem, classes.toolbarItemSplit)}
              >
                <Typography variant="caption">Candidate Name:</Typography>
                <Typography
                  variant="body1"
                  className={isAnon ? classes.backDrop : ''}
                >
                  {displayCandidate.user.firstName}{' '}
                  {displayCandidate.user.lastName}
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                md={3}
                className={clsx(
                  classes.toolbarItem,
                  classes.toolbarItemSplit,
                  classes.toolbarItemRight,
                )}
              >
                <Typography variant="caption">ID:</Typography>
                <Typography variant="body1">{candidate.crn}</Typography>
              </Grid>
              <Grid item sm={12} md={6} className={classes.toolbarItem}>
                <Typography variant="caption">
                  Role - Sector - Discipline:
                </Typography>
                <Typography variant="body1">
                  {role.name} - {industry.name} - {discipline.name}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={3}>
              <Typography variant="overline">Assessment Sponsor</Typography>
              <Typography variant="h3">{assessmentSponsor}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <PersonalDetails
                isAnon={isAnon}
                candidate={displayCandidate}
                sponsorEmail={sponsor.email}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Grid container>
                <Grid item xs={5}>
                  <Typography variant="caption" color="textSecondary">
                    Current Role:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" className={classes.content}>
                    {role.name}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="caption" color="textSecondary">
                    Current Discipline:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" className={classes.content}>
                    {discipline.name}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="caption" color="textSecondary">
                    Date of Assessment:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" className={classes.content}>
                    {flags.dateOfExam}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="caption" color="textSecondary">
                    Assessment condition:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" className={classes.content}>
                    Remote Proctored
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        className={clsx(
          classes.qrCodeContainer,
          classesCommon.noLefRightPadding,
        )}
      >
        {!isAnon ? (
          <Box className={classes.qrCode}>
            <Typography variant="h6" className={classes.qrCodeTitle}>
              Full Passport
            </Typography>
            <QRCode
              value={url}
              logoImage={logo}
              size={250}
              removeQrCodeBehindLogo
            />
          </Box>
        ) : null}
        <Box className={classes.qrCode}>
          <Typography variant="h6" className={classes.qrCodeTitle}>
            Anonymous Passport
          </Typography>
          <QRCode
            value={urlAnon}
            logoImage={logo}
            size={250}
            removeQrCodeBehindLogo
          />
        </Box>
      </Grid>
    </Grid>
  );
};

BasicInformation.propTypes = {};

export default connect(null, { redirect, goBack })(
  withContext(BasicInformation),
);
