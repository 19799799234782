import axios from 'axios';
import ACTION_ITEMS from '../../../actionItems';

export function updateGroupName(groupId, groupName, callback) {
  return (dispatch) => {
    axios
      .put(`/sponsor/group/${groupId}`, {
        groupId,
        groupName,
      })
      .then((response) => {
        const { status, group } = response.data;
        if (status === 'SUCCESS') {
          callback();
          dispatch(updateGroup(group));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function updateGroup(group) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.GROUPS.UPDATE,
    payload: { group },
  };
}
