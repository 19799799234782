import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Dashboard as DashboardIcon } from '@material-ui/icons';

const DashboardDrawerItem = ({ show, disabled, selected, onClick }) => {
  if (!show) return null;

  const handleClick = () => {
    onClick(DashboardDrawerItem.pathname);
  };

  return (
    <ListItem
      button
      selected={selected}
      onClick={handleClick}
      disabled={disabled}
    >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Assessment Dashboard" />
    </ListItem>
  );
};

DashboardDrawerItem.propTypes = {
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

DashboardDrawerItem.defaultProps = {
  show: true,
  disabled: false,
  selected: false,
};

DashboardDrawerItem.pathname = '/';

export default DashboardDrawerItem;
