import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem 1rem 2rem 1rem',
  },
  button: {
    margin: theme.spacing(1),
  },
}));
