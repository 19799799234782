const ACTION_ITEMS = {
  CANDIDATE_PORTAL: {
    UPCOMING_ASSESSMENT: {
      DETAILS: {
        SET: 'CANDIDATE_PORTAL_UPCOMING_ASSESSMENT_DETAILS_SET',
      },
    },
    ASSIGNMENTS: {
      SET: 'CANDIDATE_PORTAL_ASSIGNMENTS_SET',
    },
    PASSPORT_REQUEST: {
      SET: 'CANDIDATE_PORTAL_PASSPORT_REQUEST_SET',
    },
  },
};

export default ACTION_ITEMS;
