import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Badge, Grid, Typography } from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { RESULT_ACTUAL_LEVELS } from 'common/constants';
import { useStyles } from './DisciplineTarget.style';

const MAX_CHARACTERS = 30;

const addEllipsis = (text) =>
  text.length <= MAX_CHARACTERS
    ? text
    : `${text.substring(0, MAX_CHARACTERS)}...`;

const DisciplineTarget = ({ assessments }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container>
        {assessments.map((assessment) => {
          const { id, selfLevel, level: technicalLevel } = assessment;

          return (
            <Grid
              item
              key={`dt-${assessment.id}`}
              xs={12}
              className={classes.container}
            >
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Typography
                    component="h2"
                    variant="body1"
                    style={{ lineHeight: '40px' }}
                  >
                    {addEllipsis(assessment.disciplineTarget.name)}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  {RESULT_ACTUAL_LEVELS.map((level, index) => {
                    const [technicalLevelNumber, sign] = technicalLevel.split(
                      '',
                    );

                    const showSelfLevel = selfLevel.toString() === level;

                    const showTechnicalLevel =
                      technicalLevelNumber.toString() === level;

                    const showCombined =
                      selfLevel.toString() === level &&
                      (technicalLevelNumber.toString() === level ||
                        level === '0');

                    const btnClassNames = clsx(
                      classes.button,
                      // combined
                      showCombined && classes.isSelfAndTech,

                      // Self Assessed
                      showSelfLevel && classes.isSelf,

                      // Achieved
                      showTechnicalLevel && classes.isTech,
                    );

                    return (
                      <Badge
                        key={`button-level-${id}-${index + 1}`}
                        badgeContent={
                          showCombined || showTechnicalLevel ? sign : 0
                        }
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: sign === '-' ? 'left' : 'right',
                        }}
                        className={classes.badge}
                        overlap="rectangular"
                      >
                        <button className={btnClassNames} type="button">
                          {level}
                        </button>
                      </Badge>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

DisciplineTarget.propTypes = {
  assessments: PropTypes.array.isRequired,
};

export default withContext(DisciplineTarget);
