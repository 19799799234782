import React from 'react';
import _ from 'lodash';

const Background = ({ columns, rows }) => (
  <div className="chart-background">
    {_.range(1, columns).map((x) => (
      <div key={`column-key-${x}`} className="chart-col">
        {_.range(1, rows).map((y) => (
          <div key={`row-key-${y}`} className="chart-row">
            &nbsp;
          </div>
        ))}
      </div>
    ))}
  </div>
);

export default Background;
