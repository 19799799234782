/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from 'common/components/CustomTextField';
import NotificationBar from 'common/components/NotificationBar';
import Copyright from 'common/components/Copyright';
import logo from 'assets/images/logoDarkBg.png';
import { login } from './actions/login';
import { useStyles } from './Login.style';

const Login = (props) => {
  const [cookie, setCookie] = useCookies(['homepage', 'accessToken']);

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const classes = useStyles();

  const { isLoading, message } = props;

  const onFormSubmit = (values) => {
    setFormValues({
      email: values.email,
      password: values.password,
    });
  };

  useEffect(() => {
    if (formValues) {
      props.login(formValues, cookie.homepage, setCookie);
    }
  }, [formValues]);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.logoWrapper}>
            <img alt="COMPETAS" src={logo} className={classes.logo} />
          </div>
          <Typography component="h1" variant="h5">
            Sign in to Competas
          </Typography>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={onFormSubmit}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required('Required'),
              password: Yup.string().required('Required'),
            })}
          >
            {(p) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              } = p;
              const propsToPass = {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
              };

              return (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <CustomTextField
                      id="email"
                      labelText="Email Address"
                      autoFocus
                      gridSize={12}
                      {...propsToPass}
                    />
                  </Box>
                  <CustomTextField
                    id="password"
                    labelText="Password"
                    gridSize={12}
                    type="password"
                    {...propsToPass}
                  />
                  <NotificationBar
                    show={message !== ''}
                    variant="error"
                    className={classes.margin}
                    message={message}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {isLoading ? <CircularProgress /> : 'Log in to Competas'}
                  </Button>
                  <Button
                    component={Link}
                    to="/signup"
                    fullWidth
                    variant="outlined"
                    className={classes.signup}
                  >
                    Register today
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link
                        to={{
                          pathname: '/forgot-password',
                          userEmail: values.email,
                        }}
                        variant="body2"
                      >
                        Forgot your password?
                      </Link>
                    </Grid>
                  </Grid>
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </form>
              );
            }}
          </Formik>
        </div>
      </Grid>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
    </Grid>
  );
};

Login.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, message, authenticated } = state.login;

  return {
    authenticated,
    isLoading,
    message,
  };
};

export default connect(mapStateToProps, { login })(Login);
