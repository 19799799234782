/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Link,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '0px',
    justifyContent: 'flex-start',
    borderRadius: '10px',
    textTransform: 'none',
  },
  buttonNext: {
    color: 'black',
    backgroundColor: '#FFE655',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  title: {
    flex: 1,
  },
  helpTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const ProcessItem = ({
  isNext,
  processStatus,
  processName,
  helpTitle,
  helpDescription,
  handleClickItem,
}) => {
  const classes = useStyles();

  const classesAll = clsx({
    [classes.button]: true,
    [classes.buttonNext]: isNext,
  });

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Button
            variant="contained"
            color={processStatus ? 'primary' : 'default'}
            size="large"
            className={classesAll}
            onClick={handleClickItem}
            fullWidth
          >
            {processStatus ? (
              <CheckCircleIcon className={classes.extendedIcon} />
            ) : (
              <RemoveCircleIcon className={classes.extendedIcon} />
            )}
            <Typography className={classes.title}>{processName}</Typography>
          </Button>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={6} className={classes.helpTitle}>
          {helpTitle !== '' ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="process-content"
                id="process-header"
              >
                <Typography className={classes.heading}>{helpTitle}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>{helpDescription}</div>
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

ProcessItem.propTypes = {
  processStatus: PropTypes.bool.isRequired,
  processName: PropTypes.string.isRequired,
  helpTitle: PropTypes.string,
  helpDescription: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  handleClickItem: PropTypes.func,
};

ProcessItem.defaultProps = {
  helpTitle: '',
  helpDescription: (
    <Typography variant="subtitle2" component="p" color="textSecondary">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
      malesuada lacus ex, sit amet blandit leo lobortis eget.
    </Typography>
  ),
  handleClickItem: null,
};

export default ProcessItem;
