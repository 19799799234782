import React from 'react';
import { Button, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import AlertItem from './components/AlertItem';
import { clearAllUserAlerts } from '../../actions/clearAllUserAlerts';

const AlertList = (props) => {
  const { alerts, anchorEl, handleClose, userInfo } = props;
  const ITEM_HEIGHT = 48;

  const triggerUserAlertDeletion = () => {
    props.clearAllUserAlerts(userInfo.id);
  };

  const renderList = () => {
    if(alerts.length > 0) {
      return (
        alerts.map(alert => <AlertItem key={alert.id} alert={alert} />)
      );
    }

    return (
      <MenuItem>
        <Typography variant="body1">
          You do not have any alerts
        </Typography>
      </MenuItem>
    );
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6.5,
            maxWidth: '310px',
          },
        }}
    >
      <MenuItem>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">
              Alerts
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {alerts.length > 0 &&
              <Button size="small" onClick={triggerUserAlertDeletion} variant="outlined">
                Clear all
              </Button>
            }
          </Grid>
        </Grid>
      </MenuItem>
      {renderList()}
    </Menu>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.dashboard.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearAllUserAlerts: (userId) => dispatch(clearAllUserAlerts(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertList);
