import { createContext, useState } from 'react';

export const ShareModalContext = createContext({
  showShareModal: false,
  selectedCandidate: null,
  handleSetSelectCandidate: () => {},
  handleShowShareModal: () => {},
  handleCloseShareModal: () => {},
});

export const ShareModalProvider = ({ children }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const handleSetSelectCandidate = (user) => setSelectedCandidate(user);
  const handleShowShareModal = () => setShowShareModal(true);
  const handleCloseShareModal = () => setShowShareModal(false);

  return (
    <ShareModalContext.Provider
      value={{
        showShareModal,
        selectedCandidate,
        handleSetSelectCandidate,
        handleShowShareModal,
        handleCloseShareModal,
      }}
    >
      {children}
    </ShareModalContext.Provider>
  );
};
