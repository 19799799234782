import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: '#DCF4F8',
    borderRadius: '50%',
    display: 'flex',
    height: '50px',
    justifyContent: 'center',
    width: '50px',
  },
  iconFingerprint: {
    color: '#50AF55',
    height: '30px',
    width: '30px',
  },
}));
