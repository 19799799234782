/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid } from '@material-ui/core';
import TeamItem from './components/TeamItem';

const TeamList = ({ teams }) => {

  return (
    <Grid item xs={12}>
      {teams.map((team) => {
        return <TeamItem key={team.id} team={team} />;
      })}
    </Grid>
  );
};

export default TeamList;
