import { createTheme } from '@material-ui/core/styles';
import PoppinsRegular from './fonts/Poppins/Poppins-Regular.ttf';

const poppins = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Poppins'),
    local('Poppins-Regular'),
    url(${PoppinsRegular}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createTheme({
  typography: {
    fontSize: 12,
    useNextVariants: true,
    subtitle2: {
      fontSize: '0.95rem',
    },
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    primary: {
      lighter: '#E8EBFC',
      light: '#CBD4FF',
      main: '#064AB3',
      contrastText: '#FFFFFF',
    },
    levels: {
      level0: '#DFE6FB',
      level1: '#B0BEF9',
      level2: '#6682F4',
      level3: '#3853F9',
      level4: '#113297',
      level5: '#0C1A47',
      self: '#FB832A',
      achieved: '#3853F9',
    },
    common: {
      green: '#33AC2E',
      red: '#D63649',
      yellow: '#F7C137',
      teal: '#00C1D4',
      purple: '#8C54FF',
      black: '2E384D',
      white: '#FFFFFF',
      grey: {
        darker: '#69707F',
        dark: '#8798AD',
        medium: '#BFC5D2',
        light: '#F4F6FC',
        lightest: '#EEF3F5',
      },
    },
    shape: {
      borderRadius: 1,
    },
    typography: {
      fontSize: 15,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [poppins],
      },
    },
  },
});

export default theme;
