/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Badge,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { SortableTable } from 'common/components/SortableTable';
import PassportActionMenu from 'common/components/PassportActionMenu/PassportActionMenu';
import UnassignedActionMenu from './components/UnassignedActionMenu';
import AssignedActionMenu from './components/AssignedActionMenu';
import ChangeRequestModal from './components/ChangeRequestModal';
import OnboardingModal from '../OnboardingModal';
import BookingModal from '../BookingModal';
import WhatNextModal from '../WhatNextModal';
import { useStyles } from './TokenTable.style';

const TABLE_HEADERS = [
  {
    id: 'tokenId',
    align: 'left',
    numeric: true,
    label: 'Token ID',
  },
  {
    id: 'crn',
    align: 'left',
    numeric: false,
    label: 'ID',
  },
  {
    id: 'candidateName',
    align: 'left',
    numeric: false,
    label: 'Candidate Name',
  },
  {
    id: 'role',
    align: 'left',
    numeric: false,
    label: 'Role',
  },
  {
    id: 'discipline',
    align: 'left',
    numeric: false,
    label: 'Discipline',
  },
  {
    id: 'status',
    align: 'left',
    numeric: true,
    label: 'Status',
  },
  {
    id: 'passport',
    align: 'right',
    label: '',
  },
];

const TokenStatus = (props) => {
  const { isSelfSponsored, assignment } = props;
  const { candidate, booking } = assignment;
  const { doneSelfAssessment, doneTechAssessment, doneCompetasDueDilligence } =
    candidate.flags;

  let feedbackElem = '';
  if (_.isNil(booking)) {
    switch (candidate.user.feedback) {
      case 0:
        feedbackElem = isSelfSponsored
          ? 'Pending verification'
          : 'Pending registration';
        break;
      case 2:
        feedbackElem = 'Marked as incorrect';
        break;
      case 3:
        feedbackElem = 'Marked as not me';
        break;
      case 4:
        feedbackElem = 'Declined';
        break;

      default:
        if (doneSelfAssessment) {
          feedbackElem = 'Ready to be assessed';
        } else {
          feedbackElem = 'Awaiting self assessment';
        }
        break;
    }
  } else if (!doneTechAssessment) {
    feedbackElem = 'Awaiting technical assessment';
  } else if (doneTechAssessment && !doneCompetasDueDilligence) {
    feedbackElem = 'Awaiting integrity review';
  } else {
    feedbackElem = 'Assessment complete';
  }

  return feedbackElem;
};

const ManageRequestButton = (candidate) => {
  let content = '';
  if (candidate.candidate.flags.hasChangeRequest) {
    content = (
      <Button size="small" variant="contained" color="primary">
        Manage Request
      </Button>
    );
  }

  return content;
};

const ActionMenus = ({
  isSelfSponsored,
  item,
  onClickAssignCandidate,
  onClickBookCandidate,
}) => {
  const [anchorElAction, setAnchorElAction] = useState(null);
  // does assignment exist and is it complete?
  const { assignment } = item;
  if (_.isNil(assignment)) {
    return (
      <UnassignedActionMenu
        token={item}
        onClickAssignCandidate={onClickAssignCandidate}
      />
    );
  }

  const { candidate } = assignment;
  const { doneTechAssessment, doneCompetasDueDilligence } = candidate.flags;
  if (item.assignment.status === 1) {
    return (
      <AssignedActionMenu
        isSelfSponsored={isSelfSponsored}
        candidate={candidate}
        onClickBookCandidate={onClickBookCandidate}
      />
    );
  } else {
    // are required user flags completed?
    if (doneTechAssessment === false || doneCompetasDueDilligence === false) {
      return null;
    }

    const handleClickAction = (e) => {
      setAnchorElAction(e.currentTarget);
    };

    const handleActionClose = () => {
      setAnchorElAction(null);
    };

    return (
      <>
        <IconButton onClick={handleClickAction}>
          <MoreVertIcon />
        </IconButton>
        <PassportActionMenu
          anchorEl={anchorElAction}
          feedback={1}
          candidate={candidate}
          onClose={handleActionClose}
          isMySponsored
        />
      </>
    );
  }
};

function createData(
  item,
  userId,
  handleClickAssignCandidate,
  handleClickBookCandidate,
) {
  const { tokenId, assignment } = item;

  if (_.isNil(assignment)) {
    return [
      {
        id: 'tokenId',
        value: `Token ${tokenId}`,
      },
      {
        id: 'crn',
        value: '-',
      },
      {
        id: 'candidateName',
        value: '-',
      },
      {
        id: 'role',
        value: '-',
      },
      {
        id: 'discipline',
        value: '-',
      },
      {
        id: 'status',
        value: '-',
      },
      {
        id: 'passport',
        align: 'right',
        value: (
          <ActionMenus
            item={item}
            onClickAssignCandidate={handleClickAssignCandidate(item)}
          />
        ),
      },
    ];
  }

  const { candidate } = assignment;
  const { user, crn, role, discipline } = candidate;
  const { firstName, lastName } = user;
  const isSelfSponsored = userId === user.id;

  return [
    {
      id: 'tokenId',
      value: `Token ${tokenId}`,
    },
    {
      id: 'crn',
      value: crn,
    },
    {
      id: 'candidateName',
      value: `${firstName} ${lastName}`,
    },
    {
      id: 'role',
      value: role.name,
    },
    {
      id: 'discipline',
      value: discipline.name,
    },
    {
      id: 'status',
      value: (
        <TokenStatus
          isSelfSponsored={isSelfSponsored}
          assignment={assignment}
        />
      ),
    },
    {
      id: 'passport',
      value: (
        <>
          <ManageRequestButton candidate={candidate} />
          <ActionMenus
            isSelfSponsored={isSelfSponsored}
            item={item}
            onClickBookCandidate={handleClickBookCandidate(item)}
          />
        </>
      ),
    },
  ];
}

const TokenTable = (props) => {
  const { listId, userId, tokens, refresh } = props;

  const classes = useStyles();

  const [rows, setRows] = useState([]);

  const [selectedToken, setSelectedToken] = useState(null);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [openWhatNextModal, setOpenWhatNextModal] = useState(false);
  const [changeModelOpen, setChangeModelOpen] = useState(false);

  const closeChangeModel = () => {
    setChangeModelOpen(false);
  };

  const handleClickAssignCandidate = (item) => () => {
    setSelectedToken(item);
    setOpenOnboardingModal(true);
  };

  const handleClickBookCandidate = (item) => () => {
    setSelectedToken(item);
    setOpenBookingModal(true);
  };

  useEffect(() => {
    setRows(
      tokens.map((e) =>
        createData(
          e,
          userId,
          handleClickAssignCandidate,
          handleClickBookCandidate,
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  if (tokens.length === 0) return null;

  return (
    <Grid item xs={12} style={{ marginBottom: '15px' }}>
      <Paper>
        <IconButton color="inherit">
          <Badge
            badgeContent={tokens.length}
            color="primary"
            overlap="rectangular"
            showZero
          >
            <Typography
              className={classes.header}
              variant="subtitle2"
              color="textSecondary"
            >
              {listId.toUpperCase()}
            </Typography>
          </Badge>
        </IconButton>
        <SortableTable
          name={listId}
          headers={TABLE_HEADERS}
          rows={rows}
          searchColumnName="candidateName"
          defaultSortColumnIndex={2}
          searchable
        />
      </Paper>
      {selectedToken && selectedToken.assignment && (
        <ChangeRequestModal
          open={changeModelOpen}
          handleClose={closeChangeModel}
          assignment={selectedToken.assignment}
        />
      )}
      <OnboardingModal
        token={selectedToken}
        open={openOnboardingModal}
        refresh={refresh}
        handleClose={() => setOpenOnboardingModal(false)}
        handleOpenWhatNextModal={() => setOpenWhatNextModal(true)}
      />
      {openBookingModal ? (
        <BookingModal
          token={selectedToken}
          open={openBookingModal}
          refresh={refresh}
          handleClose={() => setOpenBookingModal(false)}
          handleOpenWhatNextModal={() => setOpenWhatNextModal(true)}
        />
      ) : null}
      <WhatNextModal
        open={openWhatNextModal}
        handleClose={() => setOpenWhatNextModal(false)}
      />
    </Grid>
  );
};

TokenTable.propTypes = {
  listId: PropTypes.string.isRequired,
  tokens: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userId: state.dashboard.userInfo.id,
  };
};

export default connect(mapStateToProps)(TokenTable);
