import React from 'react';

const OptionCountry = ({ country }) => (
  <>
    <img
      loading="lazy"
      width="20"
      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
      alt=""
      style={{ marginRight: '15px' }}
    />
    {`${country.label} (${country.code})`}
  </>
);

export default OptionCountry;
