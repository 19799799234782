import axios from 'axios';
import Swal from 'sweetalert2';
import ACTION_ITEMS from '../actionItems';

export function getPassport(candidateId, accessToken) {
  return (dispatch) => {
    Swal.fire({
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    axios
      .get(
        `/candidate/${candidateId}/passport`,
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )
      .then((response) => {
        Swal.close();

        const { status, passport } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setPassport(passport));
        }
      })
      .catch((error) => {
        Swal.close();
        const { data } = error.response;
        if (data && data.status === 'ERROR') {
          Swal.fire({
            title: 'Error!',
            text: data.status_message,
            icon: 'error',
            confirmButtonColor: '#3f51b5',
          });
        }
      });
  };
}

function setPassport(passport) {
  return {
    type: ACTION_ITEMS.PASSPORT.RESULTS.SET,
    payload: passport,
  };
}
