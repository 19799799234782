import axios from 'axios';
import Swal from 'sweetalert2';
import ACTION_ITEMS from 'components/Dashboard/actionItems';

export const uploadImage = (formData, callback) => {
  return (dispatch) => {
    Swal.fire({
      title: 'Uploading and Verifying...',
      html: 'Please wait.  This might take a few seconds.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .post('/face-detection', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        Swal.close();
        const { status, passportPhoto } = response.data;

        if (status === 'SUCCESS') {
          dispatch(passportPhotoSet(passportPhoto));
        }
        if (callback) callback();
      })
      .catch((error) => {
        Swal.close();
        const { data } = error.response;
        if (data && data.status === 'ERROR') {
          Swal.fire({
            title: 'Error!',
            text: data.status_message,
            footer: 'You have an option to skip this step.',
            icon: 'error',
            confirmButtonColor: '#3f51b5',
          });
        }
        if (callback) callback(error);
      });
  };
};

function passportPhotoSet(passportPhoto) {
  return {
    type: ACTION_ITEMS.USER_INFO.PASSPORT_PHOTO.SET,
    payload: { passportPhoto },
  };
}
