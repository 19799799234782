import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { indexOf } from 'lodash';
import { Provider, withContext } from 'common/helpers/context';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import resource from 'components/CandidateOnboarding/components/SelfAssessment/components/AreaOfExpertise/AreaOfExpertise.resource';
import Topic from './components/Topic/Topic';
import { fetchPositionTopics } from '../../actions/fetchPositionTopics';
import { addPositionSkillBulk } from '../../actions/addPositionSkillBulk';

const MIN_LEVEL = 1;
const MAX_LEVEL = 5;

const MAX_SELECTION = 5;

const AddBulkSkillModal = (props) => {
  const { open, selectedPosition, handleClose, disciplines } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [topicLevels, setTopicLevels] = useState({});
  const [hasShownWarning, setHasShownWarning] = useState(false);
  const count = useMemo(() => {
    let x = 0;
    for (const [, value] of Object.entries(topicLevels)) {
      if (value > -1) x += 1;
    }
    return x;
  }, [topicLevels]);

  useEffect(() => {
    props.fetchPositionTopics(selectedPosition.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPosition]);

  useEffect(() => {
    if (disciplines.length > 0) {
      const newTopicLevels = {};
      disciplines.forEach((discipline) => {
        discipline.disciplineTargets.forEach((disciplineTarget) => {
          newTopicLevels[disciplineTarget.id] = -1;
        });
      });
      setTopicLevels(newTopicLevels);
    }
  }, [disciplines]);

  if (disciplines.length === 0) return null;

  const onLevelClick = (topicId, level) => {
    const newTopicLevels = { ...topicLevels };
    if (newTopicLevels[topicId] === -1) {
      newTopicLevels[topicId] = level;
    } else {
      newTopicLevels[topicId] = -1;
    }
    setTopicLevels(newTopicLevels);
  };

  const getContext = () => ({
    topicLevels,
    onLevelClick,
  });

  const handleClickBulkAdd = async () => {
    setIsLoading(true);

    const topics = [];
    for (const [topicId, selectedLevel] of Object.entries(topicLevels)) {
      if (selectedLevel > -1) {
        topics.push({
          disciplineTargetId: topicId,
          lowest:
            selectedLevel - 1 >= MIN_LEVEL ? selectedLevel - 1 : selectedLevel,
          desired: selectedLevel,
          highest:
            selectedLevel + 1 <= MAX_LEVEL ? selectedLevel + 1 : selectedLevel,
        });
      }
    }

    await props.addPositionSkillBulk(selectedPosition.id, { topics });

    setIsLoading(false);
    handleClose();
  };

  if (count > MAX_SELECTION && !hasShownWarning) {
    Swal.fire({
      title: 'Oops!',
      text: 'We advise no more than 5 topics.',
      icon: 'warning',
    });
    setHasShownWarning(true);
  }

  const arrDisciplines = [...disciplines];
  return (
    <Provider value={getContext()}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Topic
            title={resource.SAFETY.TITLE}
            discipline={arrDisciplines.shift()}
            onLevelClick={onLevelClick}
          />
          <Topic
            title={resource.COMPLETIONS.TITLE}
            discipline={arrDisciplines.shift()}
            onLevelClick={onLevelClick}
          />
          <Topic
            title={resource.ROLE_SPECIFIC.TITLE}
            discipline={arrDisciplines.shift()}
            onLevelClick={onLevelClick}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickBulkAdd}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Bulk Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Provider>
  );
};

const mapStateToProps = ({ projectBuilder }) => {
  const { skills, selectedPosition, disciplines } = projectBuilder;

  const disciplineTargetIds = skills.map((skill) => skill.disciplineTargetId);

  return {
    selectedPosition,
    disciplines: disciplines.map((discipline) => ({
      ...discipline,
      disciplineTargets: discipline.disciplineTargets.filter(
        (topic) => indexOf(disciplineTargetIds, topic.id) === -1,
      ),
    })),
  };
};

export default connect(mapStateToProps, {
  fetchPositionTopics,
  addPositionSkillBulk,
})(withContext(AddBulkSkillModal));
