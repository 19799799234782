import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { useStyles } from './Topic.style';
import DisciplineTarget from '../DisciplineTarget';

const Topic = ({ title, description, discipline }) => {
  const classes = useStyles();

  if (isNil(discipline) || isEmpty(discipline.disciplineTargets)) return null;

  const { disciplineTargets } = discipline;

  return (
    <Grid item xs={12}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            {title}
          </Typography>
        </Grid>
        {description.length > 0 ? (
          <Grid item xs={12}>
            <Typography component="p" variant="subtitle2">
              {description}
            </Typography>
          </Grid>
        ) : null}
        {disciplineTargets.map((disciplineTarget) => {
          return (
            <DisciplineTarget
              key={`discipline-target-${disciplineTarget.id}`}
              disciplineTarget={disciplineTarget}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

Topic.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  discipline: PropTypes.object.isRequired,
};

Topic.defaultProps = {
  description: '',
};

export default withContext(Topic);
