/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { List } from '@material-ui/core';
import { getAssignmentsNeedingReview } from './actions/getAssignmentsNeedingReview';
import ReviewItem from './components/ReviewItem';

const IntegrityReview = (props) => {
  const { assignments } = props;

  useEffect(() => {
    props.getAssignmentsNeedingReview();
  }, []);

  return (
    <List dense>
      {assignments.length > 0 &&
        assignments.map((assignment, i) => {
          return <ReviewItem key={i} assignment={assignment} />;
        })}
    </List>
  );
};

const mapStateToProps = (state) => {
  return {
    assignments: state.integrityReview.assignments,
  };
};

export default connect(mapStateToProps, { getAssignmentsNeedingReview })(
  IntegrityReview,
);
