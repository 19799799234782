import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem 0rem 1rem 0rem',
  },
  title: {
    paddingBottom: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  icons: {
    display: 'block',
    height: '60px',
    width: '60px',
    lineHeight: '60px',
    textAlign: 'center',
    borderRadius: '50%',
    color: 'white',
    fontSize: theme.typography.fontSize * 3,
    fontWeight: 'bold',
  },
  level0: {
    backgroundColor: theme.palette.levels.self,
  },
  level1: {
    backgroundColor: theme.palette.levels.self,
  },
  level2: {
    backgroundColor: theme.palette.levels.self,
  },
  level3: {
    backgroundColor: theme.palette.levels.self,
  },
  level4: {
    backgroundColor: theme.palette.levels.self,
  },
  level5: {
    backgroundColor: theme.palette.levels.self,
  },
}));
