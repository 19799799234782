/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
// import { Switch, Route } from 'react-router-dom';
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
} from '@material-ui/core';
import {
  Info as InfoIcon,
  Assessment as AssessmentIcon,
  People as PeopleIcon,
} from '@material-ui/icons';

import { Provider } from 'common/helpers/context';
import { useCookies } from 'react-cookie';
import { Alert } from '@material-ui/lab';
import { isBefore } from 'date-fns';
import { useStyles } from './Passport.style';
import { getPassport } from './actions/getPassport';
import { setPassport } from './actions/setPassport';
import BasicInformation from './components/BasicInformation';
import TechnicalAssessmentResults from './components/TechnicalAssessmentResults';
import PsychometricAssessmentResults from './components/PsychometricAssessmentResults';
import FooterLogo from './components/FooterLogo';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`passport-tabpanel-${index}`}
      aria-labelledby={`passport-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `passport-tab-${index}`,
    'aria-controls': `passport-tabpanel-${index}`,
  };
}

function isPassportAnon(userInfo, passport) {
  if (!passport.sponsor) return true;

  if (userInfo.id === passport.sponsor.id) return false;

  if (!passport.passportRequest) return true;

  return passport.passportRequest.feedback !== 1;
}

const Passport = (props) => {
  const classes = useStyles();

  const { userInfo, passport, match } = props;
  const { candidateId, pageNo } = match.params;
  const [value, setValue] = useState(pageNo ? pageNo - 1 : 0);
  const [cookie] = useCookies(['accessToken']);

  useEffect(() => {
    props.getPassport(candidateId, cookie.accessToken);

    return () => {
      props.setPassport({
        candidate: null,
        technical: [],
        psychometric: [],
      });
    };
  }, [candidateId]);

  const { candidate } = passport;

  passport.isExpired = false;
  if (candidate) {
    const {
      assignment: { expiry },
    } = candidate;
    passport.isExpired = isBefore(new Date(expiry), new Date());
  }

  const getContext = () => ({
    userInfo,
    passport,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isAnon = props.isAnon || isPassportAnon(userInfo, passport);

  return (
    <Provider value={getContext()}>
      <Container maxWidth="lg" className={classes.container}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="secondary"
            className={classes.tabBackground}
            centered
          >
            <Tab
              icon={<InfoIcon />}
              label={isMobile ? 'Info' : 'Basic Information'}
              className={classes.tab}
              {...a11yProps(0)}
            />
            <Tab
              icon={<AssessmentIcon />}
              label={isMobile ? 'Tech' : 'Technical Results'}
              className={classes.tab}
              {...a11yProps(1)}
            />
            <Tab
              icon={<PeopleIcon />}
              label={isMobile ? 'Psyche' : 'Psychometric Results'}
              className={classes.tab}
              {...a11yProps(2)}
            />
          </Tabs>
          {passport.isExpired ? (
            <Alert severity="warning" elevation={6} variant="filled">
              <Typography>Passport has expired.</Typography>
            </Alert>
          ) : null}
        </AppBar>
        {!passport.isExpired && (
          <>
            <TabPanel value={value} index={0} style={{ root: { padding: 0 } }}>
              <BasicInformation isAnon={isAnon} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TechnicalAssessmentResults />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PsychometricAssessmentResults />
            </TabPanel>
          </>
        )}
        <FooterLogo />
      </Container>
    </Provider>
  );
};

Passport.propTypes = {
  isAnon: PropTypes.bool,
  userInfo: PropTypes.object.isRequired,
  passport: PropTypes.object.isRequired,
  getPassport: PropTypes.func.isRequired,
  setPassport: PropTypes.func.isRequired,
};

Passport.defaultProps = {
  isAnon: false,
};

const mapStateToProps = (state) => {
  const { dashboard, passport } = state;
  const { userInfo } = dashboard;
  return { userInfo, passport };
};

export default connect(mapStateToProps, { getPassport, setPassport })(Passport);
