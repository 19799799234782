import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Typography, Paper } from '@material-ui/core';

import { withContext } from 'common/helpers/context';
import { useStyles } from './BookingDetails.style';

const BookingDetails = ({ booking }) => {
  const classes = useStyles();

  const { bookedDate, location, candidate } = booking;
  const { role, discipline, crn } = candidate;
  const when = moment(bookedDate).format('hh:mm. YYYY-MMM-DD').toUpperCase();

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="subtitle2" component="p" color="textSecondary">
            Candidate Reference Number
          </Typography>
          <Typography variant="h5">{crn}</Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="subtitle2" component="p" color="textSecondary">
            When
          </Typography>
          <Typography variant="h5">{when}</Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="subtitle2" component="p" color="textSecondary">
            Where
          </Typography>
          <Typography variant="h5">{location}</Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="subtitle2" component="p" color="textSecondary">
            Role/Discipline
          </Typography>
          <Typography variant="h5">
            {role.name}/{discipline.name}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

BookingDetails.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default withContext(BookingDetails);
