const ACTION_ITEMS = {
  TEAM_VIEWER: {
    COMPANIES: {
      ADD: 'TEAM_VIEWER_ADD_COMPANY',
    },
    USERS: {
      SET: 'TEAM_VIEWER_FETCH_USERS',
      ADD: 'TEAM_VIEWER_ADD_USER',
    },
    REQUESTS: {
      SET: 'TEAM_VIEWER_SET_REQUESTS',
      UPDATE_LIST: 'TEAM_VIEWER_UPDATE_REQUEST_LIST',
      BLOCK_COMPANY: 'TEAM_VIEWER_BLOCK_COMPANY_REQUEST',
    },
    PROJECTS: {
      SET: 'TEAM_VIEWER_SET_PROJECT',
      ADD: 'TEAM_VIEWER_ADD_PROJECT',
    },
    SELECTED_CANDIDATE: {
      SET: 'TEAM_VIEWER_SET_SELECTED_CANDIDATE',
    },
    REQUESTOR: {
      SET: 'TEAM_VIEWER_SET_REQUESTOR',
    },
    SELECTED_REQUEST: {
      SET: 'TEAM_VIEWER_SET_SELECTED_REQUEST',
    },
    GROUPS: {
      SET: 'TEAM_VIEWER_GROUPS_SET',
      ADD: 'TEAM_VIEWER_GROUPS_ADD',
      SELECTED: 'TEAM_VIEWER_GROUPS_SELECTED',
      UPDATE: 'TEAM_VIEWER_GROUPS_UPDATE',
      DELETE: 'TEAM_VIEWER_GROUPS_DELETE',
      CANDIDATE: {
        ADD: 'TEAM_VIEWER_GROUPS_CANDIDATE_ADD',
        REMOVE: 'TEAM_VIEWER_GROUPS_CANDIDATE_REMOVE',
      },
    },
    TEAMS: {
      SET: 'TEAM_VIEWER_TEAMS_SET',
      ADD: 'TEAM_VIEWER_TEAMS_ADD',
      SELECTED: 'TEAM_VIEWER_TEAMS_SELECTED',
      UPDATE: 'TEAM_VIEWER_TEAMS_UPDATE',
      DELETE: 'TEAM_VIEWER_TEAMS_DELETE',
      USER: {
        ADD: 'TEAM_VIEWER_TEAMS_USER_ADD',
        REMOVE: 'TEAM_VIEWER_TEAMS_USER_REMOVE',
      },
    },
    TEAM: {
      SET: 'TEAM_VIEWER_TEAM_SET',
    },
  },
};

export default ACTION_ITEMS;
