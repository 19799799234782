import { makeStyles } from '@material-ui/core/styles';

export const useStyles = (topicGroups) => {
  const graphClasses = {};
  topicGroups.forEach((topicGroup, index) => {
    graphClasses[`topicGroup_${index}`] = {
      backgroundColor: topicGroup.graphColor,
    };
  });

  return makeStyles(() => ({
    ul: {
      listStyle: 'none',
      display: 'flex',
      flexGrow: 1,
    },
    span: {
      padding: '5px 10px 5px 10px',
      borderColor: '#dbdbdb',
      borderStyle: 'solid',
      borderWidth: '1px',
      marginBottom: 0,
      position: 'relative',
      fontWeight: 'normal',
      fontSize: '15px',
      textAlign: 'center',
      textTransform: 'none !important',
    },
    firstSpan: {
      borderRadius: '8px 0 0 8px',
    },
    lastSpan: {
      borderRadius: '0 8px 8px 0',
    },
    ...graphClasses,
  }));
};
