import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
} from '@material-ui/core';
import { postAddTokens } from './actions/postAddTokens';

const AdminTokenFormModal = (props) => {
  const { open, handleClose, selectedUserId, userInfo } = props;

  const [engToken, setEngToken] = useState(0);
  const [techToken, setTechToken] = useState(0);

  const handleChangeEngToken = (e) => {
    setEngToken(parseInt(e.target.value, 10));
  };

  const handleChangeTechToken = (e) => {
    setTechToken(parseInt(e.target.value, 10));
  };

  const handleSubmit = () => {
    props.postAddTokens(
      { userId: selectedUserId, engToken, techToken },
      userInfo.id,
      () => handleClose(),
    );
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="engToken"
              label="Engineers"
              type="number"
              value={engToken}
              onChange={handleChangeEngToken}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              id="techToken"
              label="Technicians"
              type="number"
              value={techToken}
              onChange={handleChangeTechToken}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, { postAddTokens })(AdminTokenFormModal);
