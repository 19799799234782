import axios from 'axios';
import Swal from 'sweetalert2';

export function resendInvitation(candidateId) {
  return () => {
    Swal.fire({
      title: 'processing...',
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .post('/candidate/invitation', { candidateId })
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          Swal.hideLoading();
          Swal.fire({
            title: 'Success!',
            text: 'Invitation email sent successfully.',
            icon: 'success',
            confirmButtonColor: '#3f51b5',
          });
        }
      })
      .catch((error) => {
        Swal.hideLoading();
        if (error.response && error.response.data) {
          Swal.fire({
            title: 'Error',
            text: error.response.data.status_message,
            icon: 'error',
          });
        } else {
          console.error(error);
        }
      });
  };
}
