/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useCommonStyles } from 'common/common.style';
import LineChart from './components/LineChart';
import RadarChart from './components/RadarChart';
import { useStyles } from '../../PsychometricAssessmentResults.style';

const ResultGroupType = ({ canvassId, title, data, color }) => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();

  const dataLabels = data.map((element) => element.topic.name);
  const datasets = data.map((element) => parseFloat(element.score));

  return (
    <Grid item xs={12} className={classesCommon.noLefRightPadding}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              className={classes.subheader}
              align={isMobile ? 'center' : 'left'}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <LineChart
              label={title}
              canvassId={`${canvassId}LineChart`}
              datalabels={dataLabels}
              datasets={datasets}
              color={color}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <RadarChart
              label={title}
              canvassId={`${canvassId}RadarChart`}
              datalabels={dataLabels}
              datasets={datasets}
              color={color}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ResultGroupType.propTypes = {
  data: PropTypes.array,
};

ResultGroupType.defaultProps = {
  data: [],
};

export default ResultGroupType;
