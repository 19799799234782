import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, CircularProgress } from '@material-ui/core';

import CustomPasswordField from 'common/components/CustomPasswordField';
import Copyright from 'common/components/Copyright';
import NotificationBar from 'common/components/NotificationBar';
import { updatePassword } from './actions/passwordForm';
import { useStyles } from './PasswordForm.style';

const PasswordForm = (props) => {
  const formikRef = useRef();
  const classes = useStyles();
  const { match } = props;
  const { userId, token } = match.params;

  const { isLoading, message } = props;

  const handleFormSubmit = (values) => {
    props.updatePassword({
      userId,
      token,
      password: values.password,
    });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        password: '',
      }}
      onSubmit={handleFormSubmit}
      validationSchema={Yup.object().shape({
        password: Yup.string().required('Required'),
      })}
    >
      {(p) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          // isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = p;
        const propsToPass = {
          values,
          touched,
          errors,
          setFieldValue,
          handleChange,
          handleBlur,
        };
        return (
          <form
            className={classes.form}
            onSubmit={(v) => {
              handleSubmit(v);
            }}
            style={{ marginTop: '30px' }}
          >
            <CustomPasswordField
              id="password"
              labelText="Password"
              hint="Password must contain a minimum of 8 characters to include a combination of number(s); upper case letter(s) and lower case letter(s)"
              gridSize={12}
              {...propsToPass}
            />
            <NotificationBar
              show={message !== ''}
              variant="error"
              className={classes.margin}
              message={message}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isLoading ? <CircularProgress /> : 'Save Password'}
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

PasswordForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  updatePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, message } = state.forgotPassword;

  return {
    isLoading,
    message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (formData) => dispatch(updatePassword(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
