import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const ConfirmationModal = (props) => {
  const { open, title, text, yesText, noText } = props;
  const handleYes = () => {
    if (props.handleYes) props.handleYes();
  };

  const handleClose = () => {
    if (props.handleClose) props.handleClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleNo || handleClose} color="primary">
          {noText}
        </Button>
        <Button onClick={handleYes} color="primary" autoFocus>
          {yesText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  handleYes: PropTypes.func,
  handleNo: PropTypes.func,
  handleClose: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  title: 'Are you sure?',
  text: '',
  noText: 'No',
  yesText: 'Yes',
  handleYes: undefined,
  handleNo: undefined,
  handleClose: undefined,
};

export default ConfirmationModal;
