const ACTION_ITEMS = {
  PAYMENT: {
    CLIENT_SECRET: {
      GET: 'PAYMENT_CLIENT_SECRET_GET',
      SET: 'PAYMENT_CLIENT_SECRET_SET',
    },
    PROMOTION_CODE: {
      SET: 'PAYMENT_PROMOTION_CODE_SET',
    },
  },
};

export default ACTION_ITEMS;
