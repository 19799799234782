/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import IOSSwitch from 'common/components/IOSSwitch';
import { redirect } from 'common/actions';
import { useStyles } from './ConfigControl.style';
import { applyConfig } from './actions/applyConfig';
import IntegrityReview from './components/IntegrityReview';
import TopicControl from './components/TopicControl';

const Control = ({ title, value, mainObj, onChange }) => {
  const handleChange = (e) => {
    onChange({
      ...mainObj,
      [title]: e.target.checked,
    });
  };

  return (
    <FormGroup row>
      <FormControlLabel
        labelPlacement="start"
        control={
          <IOSSwitch
            checked={value}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        style={{ textTransform: 'capitalize' }}
        label={title}
      />
    </FormGroup>
  );
};

const CategoryControl = ({ title, item, mainObj, onChange }) => {
  const handleChange = (updatedItem) => {
    onChange({
      ...mainObj,
      [title]: updatedItem,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={10}>
        {Object.keys(item).map((key) => (
          <Control
            key={`control-${key}`}
            parentKey={title}
            title={key}
            value={item[key]}
            mainObj={item}
            onChange={handleChange}
          />
        ))}
      </Grid>
    </Grid>
  );
};

const ConfigControl = (props) => {
  const classes = useStyles();

  const { userInfo, config } = props;

  const [settings, setSettings] = useState(null);

  useEffect(() => {
    if (!_.isNil(config)) {
      setSettings(config.settings);
    }
  }, [config]);

  if (userInfo.accountType !== 0) {
    props.redirect('/');
    return null;
  }

  if (_.isNil(config)) return null;

  const handleClickApply = () => {
    props.applyConfig(settings);
  };

  const handleClickReset = () => {
    setSettings(config.settings);
  };

  const hasUpdated = _.isEqual(config.settings, settings);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5">Config Controls</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">Integrity Reviews</Typography>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.container}>
            {!_.isNil(settings) &&
              Object.keys(settings).map((key) => (
                <CategoryControl
                  key={`category-control-${key}`}
                  title={key}
                  item={settings[key]}
                  mainObj={settings}
                  onChange={setSettings}
                />
              ))}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.container}>
            <IntegrityReview />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            disabled={hasUpdated}
            onClick={handleClickApply}
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
          <Button
            className={classes.button}
            disabled={hasUpdated}
            onClick={handleClickReset}
            variant="contained"
            color="primary"
          >
            Reset
          </Button>
        </Grid>
        <TopicControl />
      </Grid>
    </Container>
  );
};

ConfigControl.propTypes = {
  userInfo: PropTypes.object,
  redirect: PropTypes.func.isRequired,
  applyConfig: PropTypes.func.isRequired,
};

ConfigControl.defaultProps = {
  userInfo: null,
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.dashboard.userInfo,
    config: state.global.config,
  };
};

export default connect(mapStateToProps, { redirect, applyConfig })(
  ConfigControl,
);
