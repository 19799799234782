import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: '10px',
    marginTop: '4px',
    padding: '10px',
  },
  add: {
    marginLeft: '10px',
  },
  searchContainer: {
    display: 'flex',
    padding: '10px',
    flex: 1,
    alignItems: 'center',
  },
  search: {
    height: '60px',
  },
}));
