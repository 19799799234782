import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { addProject } from '../../actions/addProject';

const AddProjectModal = (props) => {
  const { open, handleClose } = props;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddProject = async () => {
    setIsLoading(true);
    await props.addProject(name, description);
    setIsLoading(false);
    handleClose();
  };

  const handleOnChange = (setter) => (event) => {
    setter(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Project Name"
          type="text"
          value={name}
          onChange={handleOnChange(setName)}
          fullWidth
        />
        <TextField
          margin="dense"
          id="description"
          label="Project Description"
          type="text"
          value={description}
          onChange={handleOnChange(setDescription)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={name.length === 0}
          onClick={handleAddProject}
        >
          {isLoading ? <CircularProgress /> : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddProjectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return { projects: state.projectBuilder.projects };
};

export default connect(mapStateToProps, {
  addProject,
})(withContext(AddProjectModal));
