import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    width: '645px',
    padding: '25px',
  },
  legend: {
    display: 'inline',
    height: '1.7em',
    marginLeft: '0.5em',
    padding: '5px',
    borderRadius: '5px',
  },
  legendSelf: {
    backgroundColor: theme.palette.levels.self,
    color: theme.palette.common.white,
  },
  legendAchieved: {
    backgroundColor: theme.palette.levels.achieved,
    color: theme.palette.common.white,
  },
}));
