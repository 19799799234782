/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Paper,
  Grid,
  Typography,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import { redirect } from 'common/actions';

import PsychometricHeader from './components/PsychometricHeader';
// import PsychometricTimer from './components/PsychometricTimer';

import { getPsychQuestion } from './actions/getPsychQuestion';
import { setPsychQuestion } from './actions/setPsychQuestion';
import { postPsychAnswer } from './actions/postPsychAnswer';
import { useStyles } from './PsychometricAssessment.style';

const PsychometricAssessment = (props) => {
  const classes = useStyles();

  const { userId, candidate, question, count } = props;

  const [plotCount, setPlotCount] = useState(1);
  const [plotArray, setPlotArray] = useState(['', '', '', '', '']);
  const [hasClicked1, setHasClicked1] = useState(false);
  const [hasClicked2, setHasClicked2] = useState(false);
  const [hasClicked3, setHasClicked3] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
        {
          const i = parseInt(event.key, 10) - 1;
          if (plotArray[i] !== '' || plotCount !== 4) {
            handleClickPlot(i);
          }
        }
        break;

      case 'Enter':
        if (plotCount === 4 && !isSubmitting) {
          handleSubmitAnswer();
        }
        break;
    }
  };

  useEffect(() => {
    if (!_.isNull(candidate) && _.isNull(question)) {
      props.getPsychQuestion(candidate.id);
    }

    return () => setPsychQuestion(null);
  }, [candidate, question]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => document.removeEventListener('keydown', handleKeyDown, false);
  });

  if (_.isNull(candidate)) {
    props.redirect('/psychometric');
    return null;
  }

  if (_.isNull(question)) return null;

  const showPopup = (plot) => {
    let text;
    switch (plot) {
      case 1:
        text = `You clicked this option as "1", this indicates that of the available answers, this one is the most like you`;
        setHasClicked1(true);
        break;

      case 2:
        text = `You clicked this option as "2", this indicates that of the available answers, this one is the next most like you`;
        setHasClicked2(true);
        break;

      case 3:
        text = `You clicked this option as "3", this indicates that of the available answers, this one isn't the most like you, but it's closer than the remaining other options`;
        setHasClicked3(true);
        break;
      default:
        break;
    }
    Swal.fire({
      icon: 'info',
      title: 'Info',
      text,
    });
  };

  const firstQuestionPopup = () => {
    if (
      (!hasClicked1 && plotCount === 1) ||
      (!hasClicked2 && plotCount === 2) ||
      (!hasClicked3 && plotCount === 3)
    ) {
      showPopup(plotCount);
    }
  };

  const handleClickPlot = (index) => {
    if (plotArray[index] === '' && plotCount === 4) return;

    let newPlotCount = 1;
    const newPlotArray = Array.from(plotArray);

    if (newPlotArray[index] === '') {
      newPlotArray[index] = plotCount;
      newPlotCount = plotCount + 1;

      if (question.qNo === 1) {
        firstQuestionPopup();
      }

      setPlotCount(newPlotCount);
      setPlotArray(newPlotArray);
    } else {
      for (let x = 0; x < newPlotArray.length; x++) {
        if (parseInt(newPlotArray[x], 10) >= parseInt(plotArray[index], 10)) {
          newPlotArray[x] = '';
        } else if (newPlotArray[x] !== '') {
          newPlotCount += 1;
        }
      }

      setPlotCount(newPlotCount);
      setPlotArray(newPlotArray);
    }
  };

  const handleSubmitAnswer = () => {
    const answers = question.options
      .map((option, index) => {
        const order = plotArray[index];
        const { primaryTopic, secondaryTopic } = option;
        return {
          primaryId: primaryTopic ? primaryTopic.topicId : null,
          secondaryId: secondaryTopic ? secondaryTopic.topicId : null,
          order,
        };
      })
      .filter((option) => option.order !== '');

    setIsSubmitting(true);
    props.postPsychAnswer(
      userId,
      candidate.id,
      question.id,
      question.type,
      answers,
      () => {
        setIsSubmitting(false);
        setPlotCount(1);
        setPlotArray(['', '', '', '', '']);
      },
    );
  };

  return (
    <Grid container spacing={2}>
      <PsychometricHeader />
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12} className={classes.questionContainer}>
              <Typography variant="h6" className={classes.questionTitle}>
                Question {question.qNo} of {count}
              </Typography>
            </Grid>
            {/* <Grid item xs={4} className={classes.questionContainer}>
              <PsychometricTimer candidate={candidate} />
            </Grid> */}
            <Grid item xs={12} className={classes.container}>
              <Typography variant="h6">{question.question}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.questionOption}>
              <Grid container justify="center">
                <Grid item xs={8}>
                  <FormControl>
                    <FormGroup>
                      {question.options.map((option, index) => {
                        return (
                          <FormControlLabel
                            key={`option-${index}`}
                            control={
                              <TextField
                                className={classes.questionInput}
                                inputProps={{
                                  readOnly: true,
                                }}
                                variant="outlined"
                                value={plotArray[index]}
                                disabled={
                                  plotArray[index] === '' && plotCount === 4
                                }
                                onClick={() => handleClickPlot(index)}
                              />
                            }
                            label={option.text}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <Grid container justify="center">
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitAnswer}
                    disabled={isSubmitting || plotCount !== 4}
                    fullWidth
                  >
                    NEXT QUESTION
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

PsychometricAssessment.propTypes = {
  userId: PropTypes.number.isRequired,
  candidate: PropTypes.object,
};

PsychometricAssessment.defaultProps = {
  candidate: null,
};

const mapStateToProps = (state) => {
  const { question, count } = state.psychometric;
  return { question, count };
};

export default connect(mapStateToProps, {
  redirect,
  getPsychQuestion,
  setPsychQuestion,
  postPsychAnswer,
})(PsychometricAssessment);
