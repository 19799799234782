import React from 'react';
import { AppBar, Box, Link, Toolbar, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import logo from 'assets/images/logoDarkBgWhiteFont.png';

const PageHeader = () => {
  const history = useHistory();

  const goToHome = () => {
    history.push('/');
  };

  return (
    <AppBar position="relative">
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
          <Box mt={2}>
            <Link to="/">
              <img
                onClick={goToHome}
                alt="logo"
                src={logo}
                style={{ maxWidth: '160px' }}
              />
            </Link>
          </Box>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default PageHeader;
