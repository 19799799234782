import React from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import resolveAvatar from 'common/helpers/resolveAvatar';
import resolveLocation from 'common/helpers/resolveLocation';
import { setUnderConstruction } from 'common/actions/setUnderConstruction';
import { setAlert } from 'common/actions/setAlert';
import {
  fetchProject,
  feedbackRequest,
  blockCompany,
} from '../../../../components/Dashboard/components/TeamViewer/actions';

const Label = ({ label, value }) => (
  <Box mb={2}>
    <Typography variant="overline" color="textSecondary">
      {label}
    </Typography>
    <Typography variant="body1">{value}</Typography>
  </Box>
);

const RequestorSummary = (props) => {
  const { request } = props;
  const { requestor } = request;

  if (!requestor) {
    return null;
  }

  const { firstName, lastName, company, email, contactPhone, passportPhoto } =
    requestor;
  const fullName = `${firstName} ${lastName}`;
  const location = resolveLocation(requestor);

  const handleClickAcceptRequest = () => {
    props.feedbackRequest(request, 1, setAlert, props.clearSelected);
  };

  const handleClickRejectRequest = () => {
    props.feedbackRequest(request, 2, setAlert, props.clearSelected);
  };

  const handleClickBlockCompany = (event) => {
    event.preventDefault();
    props.setUnderConstruction(true);

    // props.blockCompany(request);
  };

  return (
    <Card>
      <Box pl={2} mt={1} mb={0}>
        <Typography variant="overline" display="block" color="textSecondary">
          REQUESTOR
        </Typography>
      </Box>

      <CardContent>
        <Box md={3}>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Avatar
                  alt={fullName}
                  src={resolveAvatar(passportPhoto)}
                  className="avatar"
                />
                <Typography variant="h5" align="center">
                  {company}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  {fullName}
                </Typography>
              </Box>
              <Label label="EMAIL" value={email} />
              <Label label="TELEPHONE" value={contactPhone} />
            </Grid>
            <Grid item xs={7}>
              <Label label="PROJECT" value="N/A" />
              <Label label="LOCATION" value={location} />
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleClickAcceptRequest}
            >
              Accept
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleClickRejectRequest}
            >
              Reject
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleClickBlockCompany}
            >
              Block
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    requestor: state.teamViewer.users.filter(
      (user) => user.id === ownProps.request.requestorId,
    ),
    project: state.teamViewer.projects.filter(
      (project) => project.id === ownProps.request.projectId,
    ),
    users: state.teamViewer.users,
    request: state.teamViewer.selectedRequest,
  };
};

export default connect(mapStateToProps, {
  fetchProject,
  feedbackRequest,
  blockCompany,
  setAlert,
  setUnderConstruction,
})(RequestorSummary);
