import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'common/components/PageHeader';
import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Copyright from 'common/components/Copyright';

const DataProtection = (props) => {
  const { showLayout } = props;

  const renderHeader = () => {
    if(!showLayout) {
      return(null);
    }

    return (
      <PageHeader />
    );
  };


  const renderFooter = () => {
    if(!showLayout) {
      return(null);
    }

    return (
      <Box mb={5}>
        <Copyright />
      </Box>
    );
  };

  return (
    <div>
      {renderHeader()}
      <Box mt={5} mb={5}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3" component="h1" gutterBottom>Data Protection Policy</Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Purpose and Scope
              </Typography>

              <Typography variant="body1" gutterBottom>
                This document sets out the obligations of Dexcrest Limited and Competas Global Limited with regard to data protection and the rights of people with whom it works, in respect of their personal data under the Data Protection Act 2018 c12 (‘the Act’) and the EU General Data Protection Regulation 2016/679.
              </Typography>
              <Typography variant="body1" gutterBottom>
                The organisation views the correct and lawful handling of personal data as a contributing factor in its on-going success and dealings with third parties and its employees and shall ensure that it handles all personal data correctly and lawfully.
              </Typography>
              <Typography variant="body1" gutterBottom>
                This Policy aims to ensure compliance with the Act and the Regulation.  All references to the Act in this Policy means the Act and the Regulation.
              </Typography>
              <Typography variant="body1" gutterBottom>
                This policy applies to all our employees and contractors.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Partners and any third parties that work with us and who have or may have access to personal data, will be expected to have read, understood and to comply with this policy. No third party may access any personal data that we hold without having first entered into a data confidentiality agreement.  We have the right to audit third party compliance with the agreement.
              </Typography>

              <Typography variant="h5" component="h2" gutterBottom>
                Responsibilities
              </Typography>
              <TableContainer component={Paper}>
               <Table>
                 <TableHead>
                   <TableRow>
                     <TableCell>&nbsp;</TableCell>
                     <TableCell>Role</TableCell>
                     <TableCell>Reporting to</TableCell>
                     <TableCell>Responsibilities</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   <TableRow>
                     <TableCell>2.1</TableCell>
                     <TableCell>All personnel</TableCell>
                     <TableCell>DPO</TableCell>
                     <TableCell>The procedures set out herein must be followed by all personnel</TableCell>
                   </TableRow>
                   <TableRow>
                     <TableCell>2.2</TableCell>
                     <TableCell>Data Controller (Dexcrest)</TableCell>
                     <TableCell>ICO</TableCell>
                     <TableCell>For statutory purposes, for notifying and updating the Information Commissioner’s Office (see section 7), and for processing requests from data subjects (see section 7)</TableCell>
                   </TableRow>
                   <TableRow>
                     <TableCell>2.3</TableCell>
                     <TableCell>Data Processor (Dexcrest)</TableCell>
                     <TableCell>ICO</TableCell>
                     <TableCell>Organisations that process data on behalf of the Controller</TableCell>
                   </TableRow>
                 </TableBody>
               </Table>
             </TableContainer>

             <Typography variant="body1" gutterBottom>
               Dexcrest is the data controller / and or a data processor under the GDPR.  All our employees are responsible for developing and encouraging good information handling practices.  Their responsibilities are set out in individual job descriptions.
             </Typography>

             <Typography variant="h5" component="h2" gutterBottom>
               Organisational Measures
             </Typography>

             <Typography variant="body1" gutterBottom>
               All personnel engaged with the organisation will be made fully aware of both their individual rights and responsibilities and the organisation’s responsibilities under the Act. A copy of this policy will be made available on the Public Drive or supplied on request.
             </Typography>

             <Typography variant="body1" gutterBottom>
               The DPO (Data Protection Officer) has responsibility for:
             </Typography>

             <ul>
               <li>Development and implementation of the GDPR, along with security and risk management as required by this policy</li>
               <li>Data protection on a day to day basis and is a first point of contact for data compliance questions, responding to DSARS or dealing with breaches</li>
               <li>For ensuring that all staff are trained in the importance of collecting accurate data and maintaining it</li>
             </ul>

             <Typography variant="h5" component="h2" gutterBottom>
               Requirements of the Act
             </Typography>

             <Typography variant="body1" gutterBottom>
               The Act applies to all organisations holding personal data about natural, living data subjects in electronic format and hard copy; they must follow the basic protection principles of good information handling.
             </Typography>

             <Typography variant="body1" gutterBottom>
               The Act sets out eight principles with which any party handling personal data must comply.  All personal data:
             </Typography>

             <ol>
               <li>Must be fairly and lawfully processed.</li>
               <li>Must be obtained and processed only for specified and lawful purposes.</li>
               <li>Must be adequate, relevant, and not excessive.</li>
               <li>Must be accurate and, where necessary, kept up to date.</li>
               <li>Must not be kept for longer than necessary, only for as long as it is needed.</li>
               <li>Must be processed in line with the rights of the individual.</li>
               <li>Must be kept secure, accessed only by personnel who have designated rights of access.</li>
               <li>Must not be transferred to countries outside the European Economic Area unless the information is adequately protected.</li>
             </ol>

             <Typography variant="body1" gutterBottom>
               Any and all employees’ personal data collected in order to ensure that the organisation can efficiently manage its employees and meet any and all relevant obligations imposed by law.
             </Typography>

             <Typography variant="h5" component="h2" gutterBottom>
               Rights of Data Subjects
             </Typography>

             <Typography variant="body1" gutterBottom>
               Under the Act, data subjects have the following rights:
             </Typography>

             <ul>
               <li>The right to be informed that their personal data is being processed.</li>
               <li>The right to access any of their personal data held by the organisation within one month of making a request.  This may be increased to three months if more complex.</li>
               <li>The right to prevent the processing of their personal data in limited circumstances.</li>
               <li>The right to rectify, block, erase or destroy incorrect personal data.</li>
             </ul>

             <Typography variant="body1" gutterBottom>
               A data subject can request at any time to see the information which the organisation holds about them in writing to the Data Controller.
             </Typography>

             <Typography variant="h5" component="h2" gutterBottom>
               Personal Data Held by the Organisation
             </Typography>

             <Typography variant="body1" gutterBottom>
               Personal data is defined by the Act as data which relates to a natural, living individual who can be identified from that data, and includes any expression of opinion about the individual. The Act defines ‘sensitive personal data’ as that relating to the racial or ethnic origin of the data subject; their political opinions; their religious beliefs, trade union membership, their physical or mental health condition; their sexual life; the commission or alleged commission by them of any offence; or any proceedings for any offence committed or alleged to have been committed by them.
             </Typography>

             <Typography variant="body1" gutterBottom>
               The organisation holds personal data which is relevant to its employees which must be collected, held and processed in accordance with this policy. This includes:
             </Typography>

             <ul>
               <li>Identification information relating to personnel including, but not limited to, names and contact details, passports and visas, proof of Right to Work</li>
               <li>Employment records including, but not limited to, interview notes, curricula vitae, application forms, assessments and performance reviews.</li>
               <li>Details of salaries including increases, bonuses, commission, overtime, benefits and expenses.</li>
               <li>Records of disciplinary matters including reports and warnings, both formal and informal.</li>
               <li>Details of grievances including documentary evidence, notes from interviews, procedures followed and outcomes.</li>
               <li>Next of kin/emergency contact details</li>
               <li>Referees, references held on file</li>
               <li>Health information, sickness forms and details of injuries</li>
               <li>Criminal record information</li>
             </ul>

             <Typography variant="h5" component="h2" gutterBottom>
               Data Protection Principles and Procedures
             </Typography>

             <Typography variant="body1" gutterBottom>
               All processing of personal data must be conducted in accordance with the data protection principles as set out in Article 5 of the GDPR.  Our policies and procedures are designed to ensure compliance with the principles.
             </Typography>
             <Typography variant="body1" gutterBottom>
               Personal data must be processed lawfully, fairly and transparently
             </Typography>
             <Typography variant="body1" gutterBottom>
               Lawful – identify a lawful basis before you can process personal data. These are often referred to as the “conditions for processing”, for example consent.
             </Typography>
             <Typography variant="body1" gutterBottom>
               Fairly – in order for processing to be fair, the data controller has to make certain information available to the data subjects as practicable. This applies whether the personal data was obtained directly from the data subjects or from other sources. 
             </Typography>
             <Typography variant="body1" gutterBottom>
               The GDPR has increased requirements about what information should be available to data subjects, which is covered in the transparency requirement.
             </Typography>
             <Typography variant="body1" gutterBottom>
               Transparently – the GDPR includes rules on giving privacy information to data subjects in Articles 12, 13 and 14. These are detailed and specific, placing an emphasis on making privacy notices understandable and accessible. Information must be communicated to the data subject in an intelligible form using clear and plain language.
             </Typography>

             <ul>
               <li>Personal data must be accurate and kept up to date with every effort to erase or rectify without delay </li>
               <li>Data that is stored by the data controller must be reviewed and updated as necessary. No data should be kept unless it is reasonable to assume that it is accurate. </li>
               <li>It is also the responsibility of the data subject to ensure that data held by companies is accurate and up to date. Completion of a registration or application form by a data subject will include a statement that the data contained therein is accurate at the date of submission.</li>
               <li>The DPO is responsible for ensuring that appropriate procedures and policies are in place to keep personal data accurate and up to date, considering the volume of data collected, the speed with which it might change and any other relevant factors.</li>
               <li>On at least an annual basis, the DPO will review the retention dates of all the personal data that our organisation processes and by completing our data inventory, will identify any data that is no longer required in the context of the registered purpose.  This data will be securely deleted/destroyed in line with our <strong>QMS-04-05 IT Policy</strong>.</li>
               <li>The DPO is responsible for responding to requests for information and rectification from data subjects (<strong>QMS-01-02 04 Competas DSAR and ID Verification</strong>)  within one month (<strong>QMS-01-08 Competas Data Subject Access Request Procedure</strong>) This can be extended to a further two months for complex requests. If a company decides not to comply with the request, the DPO must respond to the data subject to explain its reasoning and inform them of their right to complain to the supervisory authority and seek judicial remedy. The DSAR register (<strong>QMS-01-02 03 Competas DSAR Register</strong>) must be kept up to date by the DPO.</li>
               <li>The DPO is responsible for making appropriate arrangements that, where third-party organisations may have been passed inaccurate or out-of-date personal data, to inform them that the information is inaccurate and/or out of date and is not to be used to inform decisions about the data subjects concerned; and for passing any correction to the personal data to the third party where this is required.</li>
             </ul>

             <Typography variant="body1" gutterBottom>
               Personal data must be kept in a form such that the data subject can be identified only as long as is necessary for processing.
             </Typography>

             <ul>
               <li>Where personal data is retained beyond the processing date, it will be minimised, encrypted or pseudonymised to protect the identity of the data subject in the event of a data breach.  The breach procedure must be followed.</li>
               <li>Personal data will be retained in line with the Data Retention Policy and, once its retention date is passed, it must be securely destroyed as set out in this procedure.</li>
               <li>The DPO must specifically approve any data retention that exceeds the retention periods defined in the Retention Policy and must ensure that the justification is clearly identified and in line with the requirements of the data protection legislation.</li>
             </ul>

             <Typography variant="body1" gutterBottom>
               Personal data must be processed in a manner that ensures the appropriate security, our DPO will carry out a risk assessment considering all the circumstances of our controlling or processing operations.
             </Typography>

             <ul>
               <li>In determining appropriateness, our DPO will also consider the extent of possible damage or loss that might be caused to data subjects (e.g. staff or customers) if a security breach occurs, the effect of any security breach on our company itself, and any likely reputational damage including the possible loss of customer trust.</li>
             </ul>

             <Typography variant="body1" gutterBottom>
               Personal data may be disclosed within the organisation.  Personal data may be passed from one department to another in accordance with the data protection principles and this Policy.  Under no circumstances will personal data be passed to any department or any individual within the organisation that does not reasonably require access to that personal data in view of the purpose(s) for which it was collected and is being processed.
             </Typography>

             <Typography variant="body1" gutterBottom>
               The Act requires the organisation to have security levels that are appropriate to:
             </Typography>

             <ul>
               <li>The nature of the information in question.</li>
               <li>The harm that might result from its improper use, or from its accidental loss or destruction.</li>
             </ul>

             <Typography variant="body1" gutterBottom>
               The Act does not define “appropriate”, but it does say that an assessment of the appropriate security measures in a particular case should consider technological developments and the costs involved. The Act does not require organisations to have state-of-the-art security technology to protect the personal data it holds, but security arrangements should be regularly reviewed as technology advances.
             </Typography>

             <Typography variant="body1" gutterBottom>
               The organisation ensures compliance with security requirements of the Act by carrying out the following actions:
             </Typography>

             <ul>
               <li>Installing a firewall and virus-checking software on the computers</li>
               <li>Ensuring that the operating system is set up to receive approved automatic updates</li>
               <li>Protecting computers by downloading the latest patches or security updates, which should cover vulnerabilities</li>
               <li>Only allowing staff access to the information they need to do their job including those assigned to temporary staff</li>
               <li>Encrypting any personal information held electronically that would cause damage or distress if it were lost or stolen</li>
               <li>Regularly backing up the network data</li>
               <li>Securely removing all personal information before disposing of hardware (by using technology or destroying the hard disk)</li>
               <li>Password protection </li>
               <li>Automatic locking of idle terminals</li>
               <li>Removal of access rights for USB and other memory media</li>
               <li>Security of local and wide area networks</li>
               <li>Privacy enhancing technologies such as pseudonymisation and anonymisation</li>
               <li>Identifying all relevant and appropriate international security standards</li>
               <li>Encryption of devices that leave the organisations premises such as laptops.</li>
             </ul>

             <Typography variant="body1" gutterBottom>
               For further information, please refer to <strong>QMS-04-05 IT Policy</strong>.
             </Typography>

             <Typography variant="body1" gutterBottom>
               When assessing appropriate organisational measures the DPO will consider the following:
             </Typography>

             <ul>
               <li>The appropriate training levels throughout the company</li>
               <li>Measures that consider the reliability of employees (such as references etc.)</li>
               <li>The inclusion of data protection in employment contracts</li>
               <li>Identification of disciplinary action measures for data breaches</li>
               <li>Monitoring of staff for compliance with relevant security standards</li>
               <li>Physical access controls to electronic and paper-based records</li>
               <li>Adoption of a clear desk policy</li>
               <li>Storing of paper-based data in lockable fire-proof cabinets</li>
               <li>Restricting the use of portable electronic devices outside of the workplace</li>
               <li>Restricting the use of employee’s own personal devices being used in the workplace</li>
               <li>Adopting clear rules about passwords</li>
               <li>Making regular backups of personal data and storing the media off-site</li>
               <li>The imposition of contractual obligations on the importing organisations to take appropriate security measures when transferring data outside the EEA</li>
               <li>Ensuring that any external storage devices are scanned and approved before use</li>
               <li>All proper procedures and processes required to safeguard IT systems should always be followed.</li>
             </ul>

             <Typography variant="body1" gutterBottom>
               These controls have been selected based on identified risks to personal data, and the potential for damage or distress to data subjects whose data is being processed.
             </Typography>
             <Typography variant="body1" gutterBottom>
               Methods of collecting, holding and processing personal data shall be regularly evaluated and reviewed. The organisation shall retain all personal records following the end of employment for up to seven years. Any personal data which is out-of-date or no longer required shall be deleted or otherwise destroyed in accordance with the <strong>QMS-01-07 Competas Data Retention Policy</strong>.
             </Typography>
             <Typography variant="body1" gutterBottom>
               All personal data shall be kept up to date. If a person’s personal data changes, that person has a duty to inform their departmental manager of those changes.
             </Typography>
             <Typography variant="body1" gutterBottom>
               In addition, all personnel working on behalf of the organisation handling personal data:
             </Typography>

             <ul>
               <li>Will be appropriately trained to do so, and will be appropriately supervised, and their performance will be regularly evaluated and reviewed</li>
               <li>Will be bound to do so in accordance with the principles of the Act and this Policy by contract.  Failure by any personnel to comply with the principles or this Policy shall constitute a disciplinary offence, or breach of contract</li>
               <li>In all cases, personnel will be made aware that failure to comply with the principles of this Policy may also constitute a criminal offence under the Act.</li>
             </ul>

             <Typography variant="body1" gutterBottom>
               Contractors working on behalf of the organisation handling personal data will have within their contract stated that they must:
             </Typography>

             <ul>
               <li>Ensure that any and all of their employees who are involved in the processing of personal data are held to the same conditions as those relevant employees of the organisation arising out of this Policy and the Act.</li>
               <li>If they fail in their obligations under this Policy that party shall indemnify and hold harmless the organisation against any costs, liability, damages, loss, claims or proceedings which may arise out of that failure.</li>
             </ul>

             <Typography variant="h5" component="h2" gutterBottom>
               Notification to the Information Commissioner’s Office
             </Typography>

             <Typography variant="body1" gutterBottom>
               Notification is a statutory requirement and the organisation must notify the Information Commissioner’s Office (ICO at www.ico.org.uk) that it is processing personal data, and is registered in the register of data controllers. Failure to notify constitutes a criminal offence.
             </Typography>

             <Typography variant="body1" gutterBottom>
               The organisation’s data controller (see Clause 2) renews its notification with the Information Commissioner’s Office every October and any changes have to be notified at that time. ICO notifies the organisation that annual renewal is due.
             </Typography>

             <Typography variant="body1" gutterBottom>
               Information about the Act, data protection policy and procedure, notification and contact details is filed and maintained by the data controller. Information relating to GDPR is located at <a href="https://www.eugdpr.org/eugdpr.org.html">https://www.eugdpr.org/eugdpr.org.html</a>
             </Typography>

            </Grid>
          </Grid>
        </Container>
      </Box>
      {renderFooter()}
    </div>
  );
};

DataProtection.propTypes = {
  showLayout: PropTypes.bool,
};

DataProtection.defaultProps = {
  showLayout: true,
};


export default DataProtection;
