import axios from 'axios';
import ACTION_ITEMS from '../../../actionItems';

export function getSponsorTeams(userId) {
  return (dispatch) => {
    axios
      .get(`/sponsor/${userId}/teams`)
      .then((response) => {
        const { status, teams } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setSponsorTeams(teams));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setSponsorTeams(teams) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.TEAMS.SET,
    payload: { teams },
  };
}
