import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '50px',
    minHeight: '800px',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    marginTop: '25px',
  },
  column: {
    flexGrow: 1,
    flexBasis: 0,
  },
  left: {
    marginRight: '5px',
  },
  middle: {
    marginLeft: '5px',
    marginRight: '5px',
  },
  right: {
    marginLeft: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
}));
