import axios from 'axios';
import Swal from 'sweetalert2';
import ACTION_ITEMS from '../actionItems';

export const getPsychAssessments = (userId) => async (dispatch) => {
  Swal.fire({
    title: 'processing...',
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  try {
    const response = await axios.get(`/psychometric/${userId}/assessments`);
    const { candidates } = response.data;

    Swal.close();
    dispatch({
      type: ACTION_ITEMS.PSYCHOMETRIC.CANDIDATES.SET,
      payload: candidates,
    });
  } catch (error) {
    Swal.close();
    if (error.response && error.response.data) {
      Swal.fire({
        title: 'Error',
        text: error.response.data.status_message,
        icon: 'error',
      });
    } else {
      console.error(error);
    }
  }
};
