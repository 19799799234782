import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Grid, IconButton } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { withContext } from 'common/helpers/context';
import resolveLocation from 'common/helpers/resolveLocation';
import { SortableTable } from 'common/components/SortableTable';
import PassportActionMenu from 'common/components/PassportActionMenu/PassportActionMenu';

const TABLE_HEADERS = [
  {
    id: 'crn',
    align: 'left',
    numeric: true,
    label: 'ID',
  },
  {
    id: 'name',
    align: 'left',
    numeric: false,
    label: 'NAME',
  },
  {
    id: 'location',
    align: 'left',
    numeric: false,
    label: 'ADDRESS',
  },
  {
    id: 'role',
    align: 'left',
    numeric: false,
    label: 'ROLE',
  },
  {
    id: 'discipline',
    align: 'left',
    numeric: false,
    label: 'DISCIPLINE',
  },
  {
    id: 'expires',
    align: 'left',
    numeric: true,
    label: 'EXPIRES',
  },
  {
    id: 'passport',
    align: 'left',
    numeric: false,
    label: '',
  },
];

function createData(item, handleClickAction) {
  const { user, crn, role, discipline, expiry } = item;
  const { firstName, lastName } = user;
  const location = resolveLocation(user);

  return [
    {
      id: 'crn',
      value: crn,
    },
    {
      id: 'name',
      value: `${firstName} ${lastName}`,
    },
    {
      id: 'location',
      value: location,
    },
    {
      id: 'role',
      value: role.name,
    },
    {
      id: 'discipline',
      value: discipline.name,
    },
    {
      id: 'expires',
      value: moment(expiry).format('YYYY-MM-DD'),
    },
    {
      id: 'passport',
      value: (
        <Box display="flex" justifyContent="right">
          <IconButton
            aria-controls="passport-action-menu"
            aria-haspopup="true"
            onClick={handleClickAction(item)}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
}

const TeamTable = ({ candidates }) => {
  const items = candidates || [];

  const [anchorElAction, setAnchorElAction] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(items.map((e) => createData(e, handleClickAction)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidates]);

  const handleClickAction = (item) => (e) => {
    setSelectedCandidate(item);
    setAnchorElAction(e.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorElAction(null);
  };

  return (
    <Grid item xs={12}>
      <SortableTable
        name="team-table"
        headers={TABLE_HEADERS}
        rows={rows}
        defaultSortColumnIndex={1}
        searchable
      />
      <PassportActionMenu
        candidate={selectedCandidate}
        feedback={1}
        anchorEl={anchorElAction}
        onClose={handleActionClose}
      />
    </Grid>
  );
};

export default withContext(TeamTable);
