// import React, { useEffect } from 'react';
import React from 'react';
// import { connect } from 'react-redux';
import {
  Avatar,
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
// import { fetchCompany } from '../../../../actions';
import resolveAvatar from 'common/helpers/resolveAvatar';
import resolveLocation from 'common/helpers/resolveLocation';
import './UserProfile.css';

const Label = ({ label, value }) => (
  <Box mb={2}>
    <Typography variant="overline" color="textSecondary">
      {label}
    </Typography>
    <Typography variant="body1">{value}</Typography>
  </Box>
);

const UserProfile = (props) => {
  const { candidate, parent } = props;

  const { user, crn, role, discipline } = candidate;
  const {
    email,
    firstName,
    lastName,
    contactPhone,
    company,
    passportPhoto,
  } = user;
  let gridSize;
  let leftGrid;
  let rightGrid;

  switch (parent) {
    case 'permissions':
      gridSize = 6;
      leftGrid = 4;
      rightGrid = 8;
      break;

    case 'requests':
      leftGrid = 12;
      rightGrid = 12;
      break;

    default:
      break;
  }

  // useEffect(() => {
  //   if (companies.length === 0) {
  //     props.fetchCompany(companyId);
  //   }
  // });

  const fullName = `${firstName} ${lastName}`;
  const location = resolveLocation(user);

  return (
    <Grid item xs={gridSize}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="overline" color="textSecondary">
                INDIVIDUAL
              </Typography>
            </Grid>

            <Grid item xs={leftGrid}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Avatar
                  alt={fullName}
                  src={resolveAvatar(passportPhoto)}
                  className="avatar"
                />
                <Typography variant="h5" align="center">
                  {company}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  {fullName}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={rightGrid}>
              <Label label="ID" value={crn} />
              <Label label="LOCATION" value={location} />
              {role ? <Label label="ROLE" value={role.name} /> : null}
              {discipline ? (
                <Label label="DISCIPLINE" value={discipline.name} />
              ) : null}
              <Label label="EMAIL" value={email} />
              <Label label="TELEPHONE" value={contactPhone} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

// const mapStateToProps = (state, ownProps) => {
//   return {
//     companies: state.teamViewer.companies.filter(
//       (company) => company.id === ownProps.candidate.companyId,
//     ),
//   };
// };

// export default connect(mapStateToProps, { fetchCompany })(UserProfile);
export default UserProfile;
