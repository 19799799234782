import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { useStyles } from './DisciplineTarget.style';

const LEVELS = [1, 2, 3, 4, 5];

const DisciplineTarget = ({ topicLevels, disciplineTarget, onLevelClick }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.container}>
      <Grid container>
        <Grid item xs={7}>
          <Typography
            component="h2"
            variant="body1"
            style={{ lineHeight: '40px' }}
          >
            {disciplineTarget.name}
          </Typography>
        </Grid>
        {LEVELS.map((level) => {
          const isSelected = topicLevels[disciplineTarget.id] === level;
          return (
            <Grid
              key={`area-of-expertise-${disciplineTarget.id}_${level}`}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              item
              xs={1}
            >
              <button
                type="button"
                className={clsx(
                  classes.level,
                  classes[`level${level}`],
                  isSelected && classes[`level${level}Selected`],
                )}
                onClick={() => onLevelClick(disciplineTarget.id, level)}
              >
                {level}
              </button>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

DisciplineTarget.propTypes = {
  disciplineTarget: PropTypes.object.isRequired,
};

export default withContext(DisciplineTarget);
