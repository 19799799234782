import axios from 'axios';
import ACTION_ITEMS from '../../../actionItems';

export function deleteTeam(teamId) {
  return (dispatch) => {
    axios
      .delete(`/sponsor/teams/${teamId}`)
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          dispatch(removeTeam(teamId));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function removeTeam(teamId) {
  return {
    type: ACTION_ITEMS.TEAM_VIEWER.TEAMS.DELETE,
    payload: { team: { id: teamId } },
  };
}
