import React from 'react';
import clsx from 'clsx';
import { LEVEL_DEFEINITIONS as levelDetails } from 'common/constants';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useCommonStyles } from 'common/common.style';

const LevelDefinitions = ({ size }) => {
  const classes = useCommonStyles();
  const fixedDoubleHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const renderDefinition = (level, key) => {
    return (
      <Grid key={key} item sm={size || 12} md={size || 4}>
        <Paper className={fixedDoubleHeightPaper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{level.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                component="p"
                color="textSecondary"
              >
                {level.description}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      {Object.keys(levelDetails).map((level, i) =>
        renderDefinition(levelDetails[level], i),
      )}
    </Grid>
  );
};

export default LevelDefinitions;
