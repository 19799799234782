import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { deleteCandidate } from 'components/Dashboard/components/ProjectBuilder/actions/deleteCandidate';
import { setSelectedCandidate } from 'components/Dashboard/components/ProjectBuilder/actions/setSelectedCandidate';

import { useStyles } from './CandidateCard.style';

// eslint-disable-next-line consistent-return
const getStatus = (status) => {
  switch (status) {
    default:
    case 0:
      return 'pending';
    case 1:
      return 'accepted';
    case 2:
      return 'rejected';
  }
};

const CandidateCard = (props) => {
  const classes = useStyles();

  const { positionCandidate, selected, selectedPosition } = props;
  const {
    id,
    candidate: { user },
    status,
  } = positionCandidate;

  const handleClickDelete = () => {
    Swal.fire({
      text: 'Are you sure you want to delete this candidate?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        props.deleteCandidate(selectedPosition.id, id);
      }
    });
  };

  const handleClickSelectedCandidate = () => {
    props.setSelectedCandidate(selected ? null : positionCandidate);
  };

  return (
    <Card
      className={clsx(classes.root, selected && classes.selected)}
      onClick={handleClickSelectedCandidate}
    >
      <CardContent>
        <div className={classes.title}>
          <Typography variant="subtitle2">
            {user.firstName} {user.lastName}
          </Typography>
          <Tooltip
            title={`Delete ${user.firstName} ${user.lastName}`}
            aria-label="delete"
          >
            <DeleteIcon onClick={handleClickDelete} color="primary" />
          </Tooltip>
        </div>
        <Typography
          variant="subtitle2"
          className={classes.pos}
          color="textSecondary"
        >
          Status: {getStatus(status)}
        </Typography>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({ projectBuilder: { selectedPosition } }) => {
  return { selectedPosition };
};

export default connect(mapStateToProps, {
  deleteCandidate,
  setSelectedCandidate,
})(CandidateCard);
