const ACTION_ITEMS = {
  ANON_RESULTS: {
    OPEN: {
      SET: 'ANON_RESULTS_OPEN_SET',
    },
    ASSESSMENTS: {
      SET: 'ANON_RESULTS_ASSESSMENTS_SET',
      RESET: 'ANON_RESULTS_ASSESSMENTS_RESET',
    },
  },
};

export default ACTION_ITEMS;
