/* eslint-disable no-param-reassign */
export const OPTIONS_GENDER = [
  {
    label: 'Male',
    value: 1,
  },
  {
    label: 'Female',
    value: 2,
  },
  {
    label: 'Prefer not to say',
    value: 3,
  },
];

export const OPTIONS_TIMEZONE = [
  {
    label: '(GMT-12:00) International Date Line West',
    value: -12,
  },
  {
    label: '(GMT-11:00) Midway Island, Samoa',
    value: -11,
  },
  {
    label: '(GMT-10:00) Hawaii',
    value: -10,
  },
  {
    label: '(GMT-09:00) Alaska',
    value: -9,
  },
  {
    label: '(GMT-08:00) Pacific Time (US & Canada)',
    value: -8,
  },
  {
    label: '(GMT-08:00) Tijuana, Baja California',
    value: -8,
  },
  {
    label: '(GMT-07:00) Arizona',
    value: -7,
  },
  {
    label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
    value: -7,
  },
  {
    label: '(GMT-07:00) Mountain Time (US & Canada)',
    value: -7,
  },
  {
    label: '(GMT-06:00) Central America',
    value: -6,
  },
  {
    label: '(GMT-06:00) Central Time (US & Canada)',
    value: -6,
  },
  {
    label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
    value: -6,
  },
  {
    label: '(GMT-06:00) Saskatchewan',
    value: -6,
  },
  {
    label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
    value: -5,
  },
  {
    label: '(GMT-05:00) Eastern Time (US & Canada)',
    value: -5,
  },
  {
    label: '(GMT-05:00) Indiana (East)',
    value: -5,
  },
  {
    label: '(GMT-04:00) Atlantic Time (Canada)',
    value: -4,
  },
  {
    label: '(GMT-04:00) Caracas, La Paz',
    value: -4,
  },
  {
    label: '(GMT-04:00) Manaus',
    value: -4,
  },
  {
    label: '(GMT-04:00) Santiago',
    value: -4,
  },
  {
    label: '(GMT-03:00) Brasilia',
    value: -3,
  },
  {
    label: '(GMT-03:00) Buenos Aires, Georgetown',
    value: -3,
  },
  {
    label: '(GMT-03:00) Greenland',
    value: -3,
  },
  {
    label: '(GMT-03:00) Montevideo',
    value: -3,
  },
  {
    label: '(GMT-02:00) Mid-Atlantic',
    value: -2,
  },
  {
    label: '(GMT-01:00) Cape Verde Is.',
    value: -1,
  },
  {
    label: '(GMT-01:00) Azores',
    value: -1,
  },
  {
    label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
    value: 0,
  },
  {
    label:
      '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    value: 0,
  },
  {
    label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 1,
  },
  {
    label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: 1,
  },
  {
    label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    value: 1,
  },
  {
    label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    value: 1,
  },
  {
    label: '(GMT+01:00) West Central Africa',
    value: 1,
  },
  {
    label: '(GMT+02:00) Amman',
    value: 2,
  },
  {
    label: '(GMT+02:00) Athens, Bucharest, Istanbul',
    value: 2,
  },
  {
    label: '(GMT+02:00) Beirut',
    value: 2,
  },
  {
    label: '(GMT+02:00) Cairo',
    value: 2,
  },
  {
    label: '(GMT+02:00) Harare, Pretoria',
    value: 2,
  },
  {
    label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: 2,
  },
  {
    label: '(GMT+02:00) Jerusalem',
    value: 2,
  },
  {
    label: '(GMT+02:00) Minsk',
    value: 2,
  },
  {
    label: '(GMT+02:00) Windhoek',
    value: 2,
  },
  {
    label: '(GMT+03:00) Kuwait, Riyadh, Baghdad',
    value: 3,
  },
  {
    label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    value: 3,
  },
  {
    label: '(GMT+03:00) Nairobi',
    value: 3,
  },
  {
    label: '(GMT+03:00) Tbilisi',
    value: 3,
  },
  {
    label: '(GMT+04:00) Abu Dhabi, Muscat',
    value: 4,
  },
  {
    label: '(GMT+04:00) Baku',
    value: 4,
  },
  {
    label: '(GMT+04:00) Yerevan',
    value: 4,
  },
  {
    label: '(GMT+05:00) Yekaterinburg',
    value: 5,
  },
  {
    label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
    value: 5,
  },
  {
    label: '(GMT+06:00) Almaty, Novosibirsk',
    value: 6,
  },
  {
    label: '(GMT+06:00) Astana, Dhaka',
    value: 6,
  },
  {
    label: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
    value: 7,
  },
  {
    label: '(GMT+07:00) Krasnoyarsk',
    value: 7,
  },
  {
    label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    value: 8,
  },
  {
    label: '(GMT+08:00) Kuala Lumpur, Singapore',
    value: 8,
  },
  {
    label: '(GMT+08:00) Irkutsk, Ulaan Bataar',
    value: 8,
  },
  {
    label: '(GMT+08:00) Perth',
    value: 8,
  },
  {
    label: '(GMT+08:00) Taipei',
    value: 8,
  },
  {
    label: '(GMT+09:00) Osaka, Sapporo, Tokyo',
    value: 9,
  },
  {
    label: '(GMT+09:00) Seoul',
    value: 9,
  },
  {
    label: '(GMT+09:00) Yakutsk',
    value: 9,
  },
  {
    label: '(GMT+10:00) Brisbane',
    value: 10,
  },
  {
    label: '(GMT+10:00) Canberra, Melbourne, Sydney',
    value: 10,
  },
  {
    label: '(GMT+10:00) Hobart',
    value: 10,
  },
  {
    label: '(GMT+10:00) Guam, Port Moresby',
    value: 10,
  },
  {
    label: '(GMT+10:00) Vladivostok',
    value: 10,
  },
  {
    label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    value: 11,
  },
  {
    label: '(GMT+12:00) Auckland, Wellington',
    value: 12,
  },
  {
    label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
    value: 12,
  },
  {
    label: "(GMT+13:00) Nuku'alofa",
    value: 13,
  },
];

export const OPTIONS_DAY = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '11',
    value: 11,
  },
  {
    label: '12',
    value: 12,
  },
  {
    label: '13',
    value: 13,
  },
  {
    label: '14',
    value: 14,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '16',
    value: 16,
  },
  {
    label: '17',
    value: 17,
  },
  {
    label: '18',
    value: 18,
  },
  {
    label: '19',
    value: 19,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '21',
    value: 21,
  },
  {
    label: '22',
    value: 22,
  },
  {
    label: '23',
    value: 23,
  },
  {
    label: '24',
    value: 24,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '26',
    value: 26,
  },
  {
    label: '27',
    value: 27,
  },
  {
    label: '28',
    value: 28,
  },
  {
    label: '29',
    value: 29,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '31',
    value: 31,
  },
];

export const OPTIONS_MONTH = [
  {
    label: 'January',
    value: 1,
  },
  {
    label: 'Febuary',
    value: 2,
  },
  {
    label: 'March',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'June',
    value: 6,
  },
  {
    label: 'July',
    value: 7,
  },
  {
    label: 'August',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'October',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'December',
    value: 12,
  },
];

export const OPTIONS_YEAR = (function (startYear) {
  const currentYear = new Date().getFullYear();
  const years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push((startYear += 1));
  }
  return years;
})(1930).map((e) => ({
  label: e,
  value: e,
}));
