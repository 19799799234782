const ACTION_ITEMS = {
  PROJECT_BUILDER: {
    PROJECT: {
      SET: 'PROJECT_BUILDER_PROJECT_SET',
    },
    SELECTED_PROJECT: {
      SET: 'PROJECT_BUILDER_SELECTED_PROJECT_SET',
    },
    POSITION: {
      SET: 'PROJECT_BUILDER_POSITION_SET',
    },
    SELECTED_POSITION: {
      SET: 'PROJECT_BUILDER_SELECTED_POSITION_SET',
    },
    SKILL: {
      SET: 'PROJECT_BUILDER_SKILL_SET',
    },
    SELECTED_SKILL: {
      SET: 'PROJECT_BUILDER_SELECTED_SKILL_SET',
    },
    CANDIDATE: {
      SET: 'PROJECT_BUILDER_CANDIDATE_SET',
    },
    SELECTED_CANDIDATE: {
      SET: 'PROJECT_BUILDER_SELECTED_CANDIDATE_SET',
    },
    DISCIPLINES: {
      SET: 'PROJECT_BUILDER_DISCIPLINES_SET',
    },
  },
};

export default ACTION_ITEMS;
