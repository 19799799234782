import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function getAssignmentsNeedingReview() {
  return (dispatch) => {
    axios
      .get('/integity-review/all')
      .then((response) => {
        const { status, assignments } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setAssignmentsForReview(assignments));
        }
      })
      .catch((error) => {
        console.log('err');
        console.error(error);
      });
  };
}

function setAssignmentsForReview(assignments) {
  return {
    type: ACTION_ITEMS.INTEGRITY_REVIEW.ASSIGNMENTS.SET,
    payload: assignments,
  };
}
