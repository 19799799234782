import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, TextField, Typography } from '@material-ui/core';

import { useStyles } from './TokenQuantitySelector.style';

const PER_TECHNICIAN_TOKEN = 300;
const PER_ENGINEER_TOKEN = 450;

const numberWithCommas = (value, valuePerToken) => {
  const newValue = (value * valuePerToken)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `£ ${newValue}`;
};

const defaultProps = {
  borderColor: 'rgba(0, 0, 0, 0.2)',
  border: 1,
  style: {
    width: '100%',
    padding: '15px',
  },
};

const TokenQuantitySelector = ({ disabled, roleType, value, handleChange }) => {
  const classes = useStyles();

  const valuePerToken =
    roleType === 1 ? PER_ENGINEER_TOKEN : PER_TECHNICIAN_TOKEN;

  return (
    <div className={classes.container}>
      <Box display="flex">
        <Box borderRadius={16} {...defaultProps}>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <TextField
                type="text"
                label={roleType === 1 ? 'ENGINEER' : 'TECHNICIAN'}
                variant="outlined"
                InputProps={{
                  min: 0,
                  style: { textAlign: 'right', fontSize: '20px' },
                }}
                InputLabelProps={{
                  style: { fontWeight: 'bolder', color: 'black' },
                }}
                className={classes.showControls}
                value={value}
                onChange={handleChange}
                disabled={disabled}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h6"
                color="textSecondary"
                id="discrete-slider"
                gutterBottom
                align="right"
              >
                £ {valuePerToken}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="right"
              >
                per assessment
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="right"
              >
                Sub-Total
              </Typography>
              <Typography variant="h5" align="right">
                {numberWithCommas(value, valuePerToken)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

TokenQuantitySelector.propTypes = {
  roleType: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default TokenQuantitySelector;
