import axios from 'axios';
import Swal from 'sweetalert2';
import { getSponsorGroups } from '../../../actions/getSponsorGroups';
import { addCandidateToGroup } from '../../CandidateSelectModal/actions/addCandidateToGroup';

export function addGroup(userId, groupName, candidate = false) {
  return (dispatch) => {
    return axios
      .post(`/sponsor/${userId}/groups`, { groupName })
      .then((response) => {
        const { status, group } = response.data;
        if (status === 'SUCCESS') {
          if(!candidate) {
            Swal.fire({
              title: 'Success',
              text: 'Successfully added group',
              icon: 'success',
              confirmButtonColor: '#3f51b5',
            });
          } else {
            const data = {
              groupId: group.id,
              candidate,
            };
            dispatch(addCandidateToGroup(data));
          }
          dispatch(getSponsorGroups(userId));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
