/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';

const CustomSelect = ({
  id,
  labelText,
  values,
  options,
  touched,
  errors,
  handleChange,
  handleBlur,
  callback,
  autoFocus,
  disabled,
  placeholder,
  gridSize,
}) => {
  const errorMessage = errors[id];
  const hasError =
    !_.isNil(errorMessage) && !_.isNil(touched[id]) && touched[id];

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  let customHandleChange = null;
  if (handleChange) {
    customHandleChange = (event) => {
      if (callback) callback(event.target.value);
      handleChange(id)(event);
    };
  }

  return (
    <Grid item xs={gridSize}>
      <FormControl variant="outlined" fullWidth error={hasError}>
        <InputLabel
          shrink
          id={`${id}-label`}
          ref={inputLabel}
          htmlFor={`outlined-${id}-always-notched`}
        >
          {labelText}
        </InputLabel>
        <Select
          defaultValue=""
          displayEmpty
          disabled={disabled}
          autoFocus={autoFocus}
          id={id}
          labelId={`${id}-label`}
          value={values[id]}
          input={
            <OutlinedInput
              notched
              labelWidth={labelWidth}
              name={id}
              id={`outlined-${id}-always-notched`}
            />
          }
          onChange={customHandleChange}
          onBlur={handleBlur(id)}
          error={hasError}
          autoWidth
        >
          {placeholder ? (
            <MenuItem value="">
              <em>{placeholder}</em>
            </MenuItem>
          ) : null}
          {options.map(({ label, value }, index) => (
            <MenuItem key={`${id}-option-${index}`} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {hasError ? <FormHelperText>{errorMessage}</FormHelperText> : null}
      </FormControl>
    </Grid>
  );
};

CustomSelect.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func.isRequired,
  callback: PropTypes.func,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  gridSize: PropTypes.number,
};

CustomSelect.defaultProps = {
  disabled: false,
  autoFocus: false,
  placeholder: null,
  gridSize: 6,
  handleChange: null,
  callback: null,
};

export default CustomSelect;
