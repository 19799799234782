import ACTION_ITEMS from './actionItems';

const initialState = {
  filters: [],
  results: {},
  stats: {
    exact: 0,
    partial: 0,
    total: 0,
  },

  // Load Position Modal
  projects: [],
  positions: [],
  skills: [],
};

export const candidateSearchReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.CANDIDATE_SEARCH.FILTERS.SET: {
      newState = { ...state, filters: action.payload };
      break;
    }

    case ACTION_ITEMS.CANDIDATE_SEARCH.RESULTS.SET: {
      const results = action.payload;
      newState = {
        ...state,
        results,
        stats: {
          exact: results.exact.length,
          partial: results.partial.length,
          total: results.exact.length + results.partial.length,
        },
      };
      break;
    }
    case ACTION_ITEMS.CANDIDATE_SEARCH.PROJECT.SET:
      newState = { ...state, projects: action.payload };
      break;

    case ACTION_ITEMS.CANDIDATE_SEARCH.POSITION.SET:
      newState = { ...state, positions: action.payload };
      break;

    case ACTION_ITEMS.CANDIDATE_SEARCH.SKILL.SET:
      newState = { ...state, skills: action.payload };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
};
