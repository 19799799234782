/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
} from '@material-ui/core';
import IOSSwitch from 'common/components/IOSSwitch';
import { ACTUAL_LEVEL_IDS } from 'common/constants';

import { useStyles } from './TopicControl.style';
import { fetchTopics } from './actions/fetchTopics';
import { putTopic } from './actions/putTopic';

function composeDiscipline(
  disciplines,
  disciplineId,
  topicId,
  property,
  value,
) {
  const arrDisciplines = disciplines.slice();

  arrDisciplines.forEach((discipline) => {
    if (discipline.id === disciplineId) {
      discipline.disciplineTargets.forEach((topic) => {
        if (topic.id === topicId) {
          topic[property] = value;
          if (property === 'disabled') {
            topic.enableEngineers = !value;
            topic.enableTechnicians = !value;
          }
        }
      });
    }
  });

  return arrDisciplines;
}

const TopicControl = (props) => {
  const classes = useStyles();
  const [disciplines, setDisciplines] = useState([]);

  useEffect(() => {
    if (props.disciplines.length === 0) {
      props.fetchTopics();
    }
  });

  useEffect(() => {
    setDisciplines(props.disciplines);
  }, [props.disciplines]);

  const handleToggleEnable = (disciplineId, topicId, property, value) => () => {
    setDisciplines(
      composeDiscipline(disciplines, disciplineId, topicId, property, !value),
    );
    props.putTopic(topicId, property, !value);
  };

  const handleChangeLevel = (disciplineId, topicId, property, value) => {
    setDisciplines(
      composeDiscipline(disciplines, disciplineId, topicId, property, value),
    );
    props.putTopic(topicId, property, value);
  };

  return (
    <Grid item xs={12}>
      <Paper className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Topic Controls</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">ID</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Enabled</TableCell>
                    <TableCell align="left">Enabled (Engineer)</TableCell>
                    <TableCell align="left">Enabled (Technician)</TableCell>
                    <TableCell align="left">Max Level</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {disciplines.map((discipline) => {
                    return discipline.disciplineTargets.map((topic) => {
                      return (
                        <TableRow key={`user-list-${topic.id}`}>
                          <TableCell align="right">{topic.id}</TableCell>
                          <TableCell align="left">
                            {discipline.name} &gt; {topic.name}
                          </TableCell>
                          <TableCell align="left">
                            <IOSSwitch
                              checked={!topic.disabled}
                              onChange={handleToggleEnable(
                                discipline.id,
                                topic.id,
                                'disabled',
                                topic.disabled,
                              )}
                              name="checkedB"
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="left">
                            <IOSSwitch
                              checked={topic.enableEngineers}
                              onChange={handleToggleEnable(
                                discipline.id,
                                topic.id,
                                'enableEngineers',
                                topic.enableEngineers,
                              )}
                              name="checkedB"
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="left">
                            <IOSSwitch
                              checked={topic.enableTechnicians}
                              onChange={handleToggleEnable(
                                discipline.id,
                                topic.id,
                                'enableTechnicians',
                                topic.enableTechnicians,
                              )}
                              name="checkedB"
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Select
                              value={topic.maxLevel}
                              onChange={(e) => {
                                handleChangeLevel(
                                  discipline.id,
                                  topic.id,
                                  'maxLevel',
                                  e.target.value,
                                );
                              }}
                              label="Level"
                              autoWidth
                            >
                              {ACTUAL_LEVEL_IDS.map(({ label, id }, index) => (
                                <MenuItem
                                  key={`lowest-option-${index}`}
                                  value={id}
                                >
                                  Level {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    });
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

TopicControl.propTypes = {};

const mapStateToProps = (state) => {
  const { disciplines } = state.topicControl;
  return { disciplines };
};

export default connect(mapStateToProps, { fetchTopics, putTopic })(
  TopicControl,
);
