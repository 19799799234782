import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
    minHeight: theme.mixins.toolbar.minHeight + 35,
    '@media (min-width:0px) and (orientation: landscape)': {
      minHeight: 83,
    },
    '@media (min-width:600px)': {
      minHeight: 99,
    },
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#F3F5FB',
  },
}));
