import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useCommonStyles } from 'common/common.style';
import BarChart from './components/BarChart';
import { useStyles } from '../../PsychometricAssessmentResults.style';

const ResultGroup = ({ groups }) => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();

  return (
    <Grid item xs={12} className={classesCommon.noLefRightPadding}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              className={classes.subheader}
              align={isMobile ? 'center' : 'left'}
            >
              Behavioural Summary
            </Typography>
          </Grid>
          {groups.map((group, index) => {
            return (
              <React.Fragment key={`result-group-${index}`}>
                <BarChart
                  canvassId={`bar-canvass-role-discipline-specific-${index}`}
                  title={group.name}
                  topicGroups={[groups[index]]}
                />
              </React.Fragment>
            );
          })}
        </Grid>
      </Paper>
    </Grid>
  );
};

ResultGroup.propTypes = {
  groups: PropTypes.array,
};

ResultGroup.defaultProps = {
  groups: [],
};

export default ResultGroup;
