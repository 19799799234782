import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    [theme.breakpoints.up('sm')]: {
      height: '90px',
      marginTop: '20px',
      marginBottom: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '60px',
      marginTop: '20px',
      marginBottom: '20px',
    },
  },
}));
