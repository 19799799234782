import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { useStyles } from './PsychometricHeader.style';

const PsychometricHeader = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.container}>
        <Typography variant="h5" className={classes.headerTitle}>
          Complete your psychometric evaluation
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          The following section covers the human factors and behaviours related
          to you and your actions in the workplace. The assessment has been
          designed around measurable or observable performance statements as
          well as questions which are designed to provide a robust observation
          and analysis of your personal behaviours, attitudes and qualities.
        </Typography>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          className={classes.headerSub2}
        >
          It is your responsibility to read through each statement and select
          the top three choices by numbering the one most like you as a <u>1</u>
          , the next most like you with a score of <u>2</u> and the 3rd most
          like you with the score of <u>3</u>. You may ignore the two least like
          you.
        </Typography>
      </Paper>
    </Grid>
  );
};

export default PsychometricHeader;
