import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function getUserTokens(userId) {
  return (dispatch) => {
    axios
      .get(`/user/${userId}/tokens`)
      .then((response) => {
        const { status, tokens } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setUserTokens(tokens));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setUserTokens(tokens) {
  return {
    type: ACTION_ITEMS.SPONSOR_PORTAL.TOKENS.SET,
    payload: { tokens },
  };
}
