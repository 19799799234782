export const getStatusText = (feedback) => {
  let statusText;

  switch (feedback) {
    case 1:
      statusText = 'Approved';
      break;

    case 2:
      statusText = 'Rejectedd';
      break;

    default:
      statusText = 'Pending';
      break;
  }
  return statusText;
};
