import ACTION_ITEMS from './actionItems';

const initialState = {
  projects: [],
  positions: [],
  skills: [],
  candidates: [],
  disciplines: [],
  selectedProject: null,
  selectedPosition: null,
  selectedSkill: null,
  selectedCandidate: null,
};

export const projectBuilderReducer = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case ACTION_ITEMS.PROJECT_BUILDER.PROJECT.SET:
      newState = { ...state, projects: action.payload };
      break;

    case ACTION_ITEMS.PROJECT_BUILDER.SELECTED_PROJECT.SET:
      newState = action.payload
        ? { ...state, selectedProject: action.payload }
        : {
            ...state,
            selectedProject: action.payload,
            selectedPosition: null,
            selectedSkill: null,
          };
      break;

    case ACTION_ITEMS.PROJECT_BUILDER.POSITION.SET:
      newState = { ...state, positions: action.payload };
      break;

    case ACTION_ITEMS.PROJECT_BUILDER.SELECTED_POSITION.SET:
      newState = { ...state, selectedPosition: action.payload };
      newState = action.payload
        ? { ...state, selectedPosition: action.payload }
        : {
            ...state,
            selectedPosition: action.payload,
            selectedSkill: null,
          };
      break;

    case ACTION_ITEMS.PROJECT_BUILDER.SKILL.SET:
      newState = { ...state, skills: action.payload };
      break;

    case ACTION_ITEMS.PROJECT_BUILDER.SELECTED_SKILL.SET:
      newState = { ...state, selectedSkill: action.payload };
      break;

    case ACTION_ITEMS.PROJECT_BUILDER.CANDIDATE.SET:
      newState = { ...state, candidates: action.payload };
      break;

    case ACTION_ITEMS.PROJECT_BUILDER.SELECTED_CANDIDATE.SET:
      newState = { ...state, selectedCandidate: action.payload };
      break;

    case ACTION_ITEMS.PROJECT_BUILDER.DISCIPLINES.SET:
      newState = { ...state, disciplines: action.payload };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
};
