import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { deletePosition } from 'components/Dashboard/components/ProjectBuilder/actions/deletePosition';
import { setSelectedPosition } from 'components/Dashboard/components/ProjectBuilder/actions/setSelectedPosition';
import { useStyles } from './PositionCard.style';

const PositionCard = (props) => {
  const classes = useStyles();

  const { position, selected } = props;
  const { id, name, role, discipline } = position;

  const handleClickDelete = () => {
    Swal.fire({
      text: 'Are you sure you want to delete this position?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        props.deletePosition(id);
      }
    });
  };

  const handleClickSelectedPosition = () => {
    props.setSelectedPosition(selected ? null : position);
  };

  return (
    <Card
      className={clsx(classes.root, selected && classes.selected)}
      onClick={handleClickSelectedPosition}
    >
      <CardContent>
        <div className={classes.title}>
          <Typography variant="subtitle2">{role.name}</Typography>
          <Tooltip title={`Delete ${name}`} aria-label="delete">
            <DeleteIcon onClick={handleClickDelete} color="primary" />
          </Tooltip>
        </div>
        <Typography variant="subtitle2">{discipline.name}</Typography>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({ projectBuilder: { selectedProject } }) => {
  return { selectedProject };
};

export default connect(mapStateToProps, {
  deletePosition,
  setSelectedPosition,
})(PositionCard);
