import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Paper, Typography } from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { useStyles } from './Topic.style';
import DisciplineTarget from './components/DisciplineTarget';

const Topic = ({ title, description, discipline }) => {
  const classes = useStyles();

  if (_.isNil(discipline)) return null;

  const { disciplineTargets } = discipline;

  return (
    <Grid item xs={12}>
      <Paper className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h1" variant="h4">
              {title}
            </Typography>
          </Grid>
          {description.length > 0 ? (
            <Grid item xs={12}>
              <Typography component="p" variant="subtitle2">
                {description}
              </Typography>
            </Grid>
          ) : null}
          {disciplineTargets.map((disciplineTarget) => {
            return (
              <DisciplineTarget
                key={`discipline-target-${disciplineTarget.id}`}
                disciplineTarget={disciplineTarget}
              />
            );
          })}
        </Grid>
      </Paper>
    </Grid>
  );
};

Topic.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  discipline: PropTypes.object.isRequired,
};

Topic.defaultProps = {
  description: '',
};

export default withContext(Topic);
