/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import CustomTextField from 'common/components/CustomTextField';
import CustomSelect from 'common/components/CustomSelect';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { setConfirm } from 'common/actions/setConfirm';
import { submitOnboarding } from './actions/submitOnboarding';

const mapValues = (e) => ({
  label: e.name,
  value: e.id,
});

const OnboardingModal = (props) => {
  const { userInfo, options, token, open, refresh, handleClose } = props;
  const [formValues, setFormValues] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isProfileSelfSponsored, setIsSelfSponsored] = useState(false);
  const isUserSelfSponsored = userInfo.intentType === 2;

  const formikRef = useRef();

  if (!token) return null;

  const { industries, roles, disciplineRoleSpecific } = options;

  const OPTIONS_ROLE = roles
    .filter((e) => e.type === token.type)
    .map(mapValues);
  const OPTIONS_DISCIPLINE = disciplineRoleSpecific.map(mapValues);
  const OPTIONS_INDUSTRY = industries.map(mapValues);

  const onFormSubmit = (values) => {
    setFormValues({
      ...values,
      tokenId: token.id,
    });

    setIsSelfSponsored(values.email === userInfo.email);

    setOpenConfirm(true);
  };

  const handleClickCancel = () => handleClose();

  const handleConfirmYes = () => {
    setOpenConfirm(false);
    handleClose();
    props.submitOnboarding(
      userInfo.id,
      formValues,
      isProfileSelfSponsored
        ? 'Please now complete your Verification and Photo Upload.'
        : 'Candidate assigned successfully.',
      () => refresh(),
    );
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          innerRef={formikRef}
          initialValues={{
            firstName: isUserSelfSponsored ? userInfo.firstName : '',
            lastName: isUserSelfSponsored ? userInfo.lastName : '',
            email: isUserSelfSponsored ? userInfo.email : '',
            roleId: '',
            disciplineId: '',
            industryId: '',
            sponsorId: token.sponsor.id,
          }}
          onSubmit={onFormSubmit}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            email: Yup.string()
              .email('Please enter a valid email')
              .required('Required'),
            roleId: Yup.number().required('Required'),
            disciplineId: Yup.number().required('Required'),
            industryId: Yup.number().required('Required'),
          })}
        >
          {(p) => {
            const {
              values,
              touched,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            } = p;
            const propsToPass = {
              values,
              touched,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
            };
            return (
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                  COMPLETE CANDIDATE ONBOARDING
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <CustomTextField
                      id="firstName"
                      labelText="Candidate First Names"
                      {...propsToPass}
                    />
                    <CustomTextField
                      id="lastName"
                      labelText="Candidate Last Name"
                      {...propsToPass}
                    />
                    <CustomTextField
                      id="email"
                      type="email"
                      labelText="Email"
                      {...propsToPass}
                    />
                    <CustomSelect
                      id="roleId"
                      labelText="Assessed Role"
                      options={OPTIONS_ROLE}
                      {...propsToPass}
                    />
                    <CustomSelect
                      id="disciplineId"
                      labelText="Assessed Discipline"
                      options={OPTIONS_DISCIPLINE}
                      {...propsToPass}
                    />
                    <CustomSelect
                      id="industryId"
                      labelText="Industry"
                      options={OPTIONS_INDUSTRY}
                      {...propsToPass}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions style={{ padding: '25px' }}>
                  <Button
                    variant="contained"
                    type="button"
                    color="secondary"
                    onClick={handleClickCancel}
                    fullWidth
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                  >
                    Assign token
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <ConfirmationModal
        open={openConfirm}
        text={
          isProfileSelfSponsored
            ? 'Please ensure your specific role and discipline are correct before confirming'
            : "You won't be able to reverse this action."
        }
        handleYes={handleConfirmYes}
        handleClose={handleConfirmClose}
      />
    </>
  );
};

OnboardingModal.propTypes = {
  options: PropTypes.object.isRequired,
  token: PropTypes.object,
  open: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  submitOnboarding: PropTypes.func.isRequired,
};

OnboardingModal.defaultProps = {
  token: null,
};

const mapStateToProps = (state) => ({
  userInfo: state.dashboard.userInfo,
  options: state.options,
});

export default withRouter(
  connect(mapStateToProps, { submitOnboarding, setConfirm })(OnboardingModal),
);
