import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Chart from 'chart.js';

const renderChart = ({ canvassId, label, datalabels, datasets, color }) => {
  return new Chart(canvassId, {
    type: 'radar',
    data: {
      labels: datalabels,
      datasets: [
        {
          label,
          data: datasets,
          backgroundColor: color,
          borderColor: color.replace('.6', '1'),
          borderWidth: 1,
        },
      ],
    },
    options: {
      tooltips: {
        enabled: false,
      },
      scale: {
        ticks: {
          beginAtZero: true,
          max: 5,
          min: 0,
          stepSize: 0.5,
          suggestedMax: 5,
        },
      },
    },
  });
};

const RadarChart = ({
  canvassId,
  label,
  datalabels,
  datasets,
  color,
  width,
  height,
}) => {
  if (datasets.length > 0) {
    setTimeout(() => {
      renderChart({ canvassId, label, datalabels, datasets, color });
    }, 0);
  }

  return (
    <Grid item xs={12}>
      <canvas id={canvassId} width={width} height={height} />
    </Grid>
  );
};

RadarChart.propTypes = {
  canvassId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  datalabels: PropTypes.array.isRequired,
  datasets: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string.isRequired,
};

RadarChart.defaultProps = {
  width: 400,
  height: 200,
};

export default RadarChart;
