import axios from 'axios';

export function answerChangeRequest(formData, callback) {
  return () => {
    axios
      .put(`/candidate/data-change-request`, formData)
      .then(() => {
        callback();
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
