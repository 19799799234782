import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { getComparator, stableSort } from './SortableTable.utils';
import { SortableTableHead } from './SortableTableHead';
import { SortableTableSearchable } from './SortableTableSearchable';
import { useCommonStyles } from 'common/common.style';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const SortableTable = (props) => {
  const {
    name,
    headers,
    rows,
    defaultSortColumnIndex = 0,
    searchable = false,
    searchColumnName = 'name',
    onClickRow,
  } = props;
  const classesCommon = useCommonStyles();

  const [filteredData, setFilteredData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(headers[defaultSortColumnIndex].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setFilteredData(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (data) => setFilteredData(data);

  return (
    <Paper>
      {searchable && (
        <SortableTableSearchable
          columnName={searchColumnName}
          items={rows}
          onSearch={handleSearch}
        />
      )}
      <TableContainer>
        <Table stickyHeader aria-label={name}>
          <SortableTableHead
            headCells={headers}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            rowCount={filteredData.length}
          />
          <TableBody>
            {filteredData.length > 0 ? (
              stableSort(filteredData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, x) => {
                  return (
                    <StyledTableRow
                      key={`${name}-row-${x}`}
                      onClick={onClickRow ? () => onClickRow(item) : null}
                    >
                      {item.map(({ value, align = 'left', blur }, y) => {
                        return (
                          <TableCell
                            key={`${name}-item-${y}`}
                            align={align}
                            className={blur ? classesCommon.backDrop : ''}
                          >
                            {blur ? 'John Doe' : value}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })
            ) : (
              <StyledTableRow>
                <TableCell
                  colSpan={headers.length}
                  style={{ textAlign: 'center' }}
                >
                  No data
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

SortableTable.propTypes = {
  name: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  defaultSortColumnIndex: PropTypes.number,
  searchable: PropTypes.bool,
  searchColumnName: PropTypes.string,
  onClickRow: PropTypes.func,
};
