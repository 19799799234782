import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  StepConnector,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PaymentIcon from '@material-ui/icons/Payment';

import { useStyles } from './PaymentDialog.style';

import StripeForm from './components/StripeForm';

const STEPS = ['Sponsor Infomation', 'Payment'];

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

const ColorStepIcon = (props) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <InfoIcon />,
    2: <PaymentIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};

const PaymentDialog = (props) => {
  const classes = useStyles();
  const [activeStep] = useState(1);
  const { open, amount, description, addTokensToAccount, handleClose } = props;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const closeDialog = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      className={classes.dialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {STEPS.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Enter your payment details below</DialogContentText>

        <Elements stripe={stripePromise}>
          <StripeForm
            amount={amount}
            description={description}
            addTokensToAccount={addTokensToAccount}
            handleClose={handleClose}
          />
        </Elements>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;
