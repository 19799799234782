import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function fetchTopics() {
  return (dispatch) => {
    axios
      .get('/admin/topics')
      .then((response) => {
        const { status, disciplines } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setTopics(disciplines));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setTopics(disciplines) {
  return {
    type: ACTION_ITEMS.TOPIC_CONTROLS.TOPICS.SET,
    payload: disciplines,
  };
}
