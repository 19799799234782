import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  AssignmentInd as AssignmentIndIcon,
  ArrowRight as ArrowRightIcon,
} from '@material-ui/icons';

import { useStyles } from './IntentTypeButton.style';

const IntentTypeButton = (props) => {
  const classes = useStyles();

  const { item, intentType, setIntentType } = props;

  const { id, title, descriptions } = item;

  let paperClasses = classes.paper;
  if (intentType === id) {
    paperClasses = paperClasses.concat(' ').concat(classes.selected);
  }

  return (
    <Grid className={classes.inner} item xs={12}>
      <Card className={paperClasses} component={Paper} elevation={6}>
        <CardContent>
          <div
            style={{ display: 'flex' }}
            onClick={() => {
              setIntentType(id);
            }}
          >
            <div className={classes.iconContainer}>
              <AssignmentIndIcon
                className={classes.icon}
                color="primary"
                fontSize="large"
              />
            </div>
            <div className={classes.summary}>
              <Typography variant="h6" align="center" gutterBottom>
                {title}
              </Typography>
              <List>
                {descriptions.map((description, i) => (
                  <ListItem key={`intent-type-description-${i}`}>
                    <ListItemIcon>
                      <ArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText>{description}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

IntentTypeButton.propTypes = {
  item: PropTypes.object.isRequired,
  intentType: PropTypes.number.isRequired,
  setIntentType: PropTypes.func.isRequired,
};

export default IntentTypeButton;
