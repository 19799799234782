import axios from 'axios';
import ACTION_ITEMS from '../../../actionItems';

export function resetPassword({ username }) {
  return (dispatch) => {
    dispatch(setMessage(''));
    dispatch(setIsLoading(true));
    axios.post('/auth/resetPassword', { username })
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsSent(true));
      }).catch((error) => {
        const { data } = error.response;
        if(data.status_message === 'User does not exist') {
          dispatch(setIsLoading(false));
          dispatch(setIsSent(true));
        } else {
          dispatch(setIsSent(false));
          dispatch(setMessage(data.status_message));  
        }
      });
  };
}

function setIsLoading(isLoading) {
  return {
    type: ACTION_ITEMS.PASSWORD.IS_LOADING.SET,
    payload: { isLoading },
  };
}

function setIsSent(emailSent) {
  return {
    type: ACTION_ITEMS.PASSWORD.EMAIL_SENT.SET,
    payload: { emailSent },
  };
}

function setMessage(message) {
  return {
    type: ACTION_ITEMS.PASSWORD.MESSAGE.SET,
    payload: { message },
  };
}
