const ACTION_ITEMS = {
  LOGIN: {
    IS_LOADING: {
      SET: 'LOGIN_IS_LOADING_SET',
    },
    MESSAGE: {
      SET: 'LOGIN_MESSAGE_SET',
    },
    AUTHENTICATED: {
      SET: 'LOGIN_AUTHENTICATION_SET',
    },
  },
};

export default ACTION_ITEMS;
