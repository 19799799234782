import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  Collapse,
  Badge,
  Button,
  Grid,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { ToggleButton } from '@material-ui/lab';
import clsx from 'clsx';
import { useCommonStyles } from 'common/common.style';
import LevelDefinitions from 'common/components/LevelDefinitions';
import HorizontalStatisticsChart from './components/HorizontalStatisticsChart';
import { useStyles } from './SelfVsAchieved.style';
import { generateActualLevelMap } from 'common/helpers/generateActualLevelMap';

const SelfVsAchieved = ({ canvassId, title, disciplineTargets, height }) => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();
  const [showLevelDefinitions, setShowLevelDefinitions] = useState(false);
  const [toggleSelfAssessed, setToggleSelfAssessed] = useState(true);
  const [toggleAchieved, setToggleAchieved] = useState(true);

  const handleClickDefinitions = () => {
    setShowLevelDefinitions(!showLevelDefinitions);
  };
  const handleClickSelfAssessed = () => {
    setToggleSelfAssessed(!toggleSelfAssessed);
  };
  const handleClickAchieved = () => {
    setToggleAchieved(!toggleAchieved);
  };

  return (
    <Grid item xs={12} className={classesCommon.noLefRightPadding}>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Typography variant="h5" align="center">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {!isMobile ? (
              <Button
                size="small"
                variant="outlined"
                onClick={handleClickDefinitions}
              >
                {showLevelDefinitions
                  ? 'Hide Level Definitions'
                  : 'Show Level Definitions'}
              </Button>
            ) : (
              <IconButton
                size="small"
                variant="outlined"
                onClick={handleClickDefinitions}
              >
                <HelpIcon />
              </IconButton>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: showLevelDefinitions ? 'block' : 'none' }}
          >
            <Collapse in={showLevelDefinitions}>
              <LevelDefinitions />
            </Collapse>
          </Grid>
          <Grid item xs={12} className={classes.legendWrapper}>
            <ToggleButton
              value={1}
              selected={!toggleSelfAssessed}
              onChange={handleClickSelfAssessed}
              className={clsx(classes.legend, classes.legendSelf)}
            >
              Self Assessed
            </ToggleButton>
            <ToggleButton
              value={2}
              selected={!toggleAchieved}
              onChange={handleClickAchieved}
              className={clsx(classes.legend, classes.legendAchieved)}
            >
              Achieved
            </ToggleButton>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container>
              {disciplineTargets.map((topic) => {
                const { id, name, maxLevel, assessment } = topic;
                const { selfLevel, technicalLevel } = assessment[0];
                const [technicalLevelNumber, sign] = technicalLevel.split('');

                const arrLevel = generateActualLevelMap(maxLevel);

                console.log('arrLevel =', arrLevel);

                return (
                  <React.Fragment key={`topics-${id}`}>
                    <Grid item xs={12} sm={7} className={classes.topicName}>
                      <Typography variant="body2">
                        {name.toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} className={classes.row}>
                      {arrLevel.map((level, index) => {
                        const showSelfLevel =
                          selfLevel.toString() === level && toggleSelfAssessed;

                        const showTechnicalLevel =
                          technicalLevelNumber.toString() === level &&
                          toggleAchieved;

                        const showCombined =
                          selfLevel.toString() === level &&
                          (technicalLevelNumber.toString() === level ||
                            level === '0') &&
                          toggleSelfAssessed &&
                          toggleAchieved;

                        const btnClassNames = clsx(
                          classes.button,
                          // combined
                          showCombined && classes.isSelfAndTech,

                          // Self Assessed
                          showSelfLevel && classes.isSelf,

                          // Achieved
                          showTechnicalLevel && classes.isTech,
                        );

                        return (
                          <Badge
                            key={`button-level-${id}-${index + 1}`}
                            badgeContent={
                              showCombined || showTechnicalLevel ? sign : 0
                            }
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: sign === '-' ? 'left' : 'right',
                            }}
                            className={classes.badge}
                            overlap="rectangular"
                          >
                            <button className={btnClassNames} type="button">
                              {level}
                            </button>
                          </Badge>
                        );
                      })}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
          {!isMobile ? (
            <HorizontalStatisticsChart
              canvassId={canvassId}
              disciplineTargets={disciplineTargets}
              height={height}
            />
          ) : null}
        </Grid>
      </Paper>
    </Grid>
  );
};

SelfVsAchieved.propTypes = {
  canvassId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disciplineTargets: PropTypes.array.isRequired,
};

export default SelfVsAchieved;
