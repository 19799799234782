import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { withContext } from 'common/helpers/context';
import GroupList from './components/GroupList';
import AddGroupModal from './components/AddGroupModal';
import { getSponsorGroups } from './actions/getSponsorGroups';

import { useStyles } from './Groups.style';

const Groups = (props) => {
  const classes = useStyles();

  const { userId, groups } = props;
  const [addGroupModal, setAddGroupModal] = useState(false);

  useEffect(() => {
    if (groups.length === 0) {
      props.getSponsorGroups(userId);
    }
  });

  const handleAddGroup = () => {
    setAddGroupModal(true);
  };

  const handleModalClose = () => {
    setAddGroupModal(false);
  };

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">
            Groups
            <Fab
              className={classes.add}
              color="primary"
              aria-label="add"
              size="small"
              onClick={handleAddGroup}
            >
              <AddIcon />
            </Fab>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <GroupList groups={groups} />
        </Grid>
      </Grid>
      <AddGroupModal open={addGroupModal} handleClose={handleModalClose} />
    </Grid>
  );
};

Groups.propTypes = {
  userId: PropTypes.number.isRequired,
  groups: PropTypes.array.isRequired,
  getSponsorGroups: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return { groups: state.teamViewer.groups };
};

export default connect(mapStateToProps, {
  getSponsorGroups,
})(withContext(Groups));
