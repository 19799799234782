import ACTION_ITEMS from './actionItems';

const initialState = {
  tokens: [],
  statistics: {
    assessmentsAvailable: -1,
    techniciansAssessed: -1,
    engineersAssessed: -1,
    avgCompetency: -1,
  },
};

export const sponsorPortalReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.SPONSOR_PORTAL.TOKENS.SET: {
      newState = { ...state, tokens: action.payload.tokens };
      break;
    }

    case ACTION_ITEMS.SPONSOR_PORTAL.STATISTICS.SET: {
      newState = {
        ...state,
        statistics: { ...state.statistics, ...action.payload },
      };
      break;
    }

    case ACTION_ITEMS.SPONSOR_PORTAL.TOKENS.ASSESSMENT_FLAG.UPDATE: {
      console.log('need to update specified token');
      console.log(action.payload);
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
