import axios from 'axios';
import { push } from 'connected-react-router';
import Swal from 'sweetalert2';
import ACTION_ITEMS from '../../../actionItems';

export function updatePassword({ userId, token, password }) {
  return (dispatch) => {
    dispatch(setMessage(''));
    dispatch(setIsLoading(true));
    // eslint-disable-next-line
    userId = Number(userId);
    axios
      .post('/auth/updatePassword', { userId, token, password })
      .then(() => {
        dispatch(setIsLoading(false));
        Swal.fire({
          title: 'Success!',
          text: 'Successfully changed your password.',
          icon: 'success',
          confirmButtonColor: '#3f51b5',
        }).then(() => {
          dispatch(setIsSent(true));
          dispatch(push('/login'));
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(setIsLoading(false));
        dispatch(setIsSent(false));
        const { data } = error.response;
        dispatch(setMessage(data.status_message));
      });
  };
}

function setIsLoading(isLoading) {
  return {
    type: ACTION_ITEMS.PASSWORD.IS_LOADING.SET,
    payload: { isLoading },
  };
}

function setIsSent(emailSent) {
  return {
    type: ACTION_ITEMS.PASSWORD.EMAIL_SENT.SET,
    payload: { emailSent },
  };
}

function setMessage(message) {
  return {
    type: ACTION_ITEMS.PASSWORD.MESSAGE.SET,
    payload: { message },
  };
}
