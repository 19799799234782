import React from 'react';
import { isMobile } from 'react-device-detect';
import { Grid, Typography, Paper } from '@material-ui/core';
import { withContext } from 'common/helpers/context';
import { useCommonStyles } from 'common/common.style';
import Description from './components/Description';
import StrongestScores from './components/StrongestScores';
import DevelopmentConsiderations from './components/DevelopmentConsiderations';
import ResultGroupType from './components/ResultGroupType';
import { ResultGroup, ResultGroupMobile } from './components/ResultGroup';

import { useStyles } from './PsychometricAssessmentResults.style';

const PsychometricAssessmentResults = ({ passport }) => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();

  const { psychometric, isExpired } = passport;

  const {
    groups: topicGroups = [],
    behavioural = [],
    types = {},
  } = psychometric;

  return (
    <Grid
      container
      spacing={5}
      className={isExpired ? classesCommon.backDrop : ''}
    >
      <Grid item xs={12} className={classesCommon.noLefRightPadding}>
        <Typography variant="h4" align="center" className={classes.header}>
          <b>Psychometric Results</b>
        </Typography>
      </Grid>
      {topicGroups.length === 0 ? (
        <Grid item xs={12} className={classesCommon.noLefRightPadding}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle2" align="center">
              Psychometric Evaluation has not been completed for this Passport
            </Typography>
          </Paper>
        </Grid>
      ) : (
        <>
          <Description />
          <StrongestScores behavioural={behavioural} />
          <DevelopmentConsiderations behavioural={behavioural} />
          <ResultGroupType
            canvassId="psychTaskResponses"
            title="Task Responses"
            data={types.taskResponses}
            color="rgba(54, 162, 235, 0.6)"
          />
          <ResultGroupType
            canvassId="psychProcessingResponses"
            title="Processing Responses"
            data={types.processingResponses}
            color="rgba(255, 126, 20, .6)"
          />
          <ResultGroupType
            canvassId="psychLeadershipResponses"
            title="Leadership Responses"
            data={types.leadership}
            color="rgba(102, 0, 102, .6)"
          />
          {isMobile ? (
            <ResultGroupMobile groups={topicGroups} />
          ) : (
            <ResultGroup groups={topicGroups} />
          )}
        </>
      )}
    </Grid>
  );
};

export default withContext(PsychometricAssessmentResults);
