import React from 'react';
import _ from 'lodash';
// import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useCommonStyles } from 'common/common.style';
import { withContext } from 'common/helpers/context';
// import LevelChart from './components/LevelChart';
// import StatisticsChart from './components/StatisticsChart';
import SelfVsAchieved from './components/SelfVsAchieved';

import { useStyles } from './TechnicalAssessmentResults.style';

const TechnicalAssessmentResults = ({ passport }) => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();

  const { technical, isExpired } = passport;

  const { safety, completions, roleSpecific } = technical;

  if (_.isNil(roleSpecific)) return null;

  return (
    <Grid container spacing={5} className={isExpired ? classesCommon.backDrop : ''}>
      <Grid item xs={12} className={classesCommon.noLefRightPadding}>
        <Typography variant="h4" align="center" className={classes.header}>
          Technical Results
        </Typography>
      </Grid>
      {!_.isNil(safety) ? (
        <SelfVsAchieved
          canvassId="selfVsAchieved-canvass-safety-specific"
          title="Safety Topics"
          disciplineTargets={safety.disciplineTargets}
        />
      ) : null}
      {!_.isNil(roleSpecific) ? (
        <SelfVsAchieved
          canvassId="selfVsAchieved-canvass-role-discipline-specific"
          title="Role/Discipline Specific"
          disciplineTargets={roleSpecific.disciplineTargets}
        />
      ) : null}
      {!_.isNil(completions) ? (
        <SelfVsAchieved
          canvassId="selfVsAchieved-canvass-completions-specific"
          title="Systems Completion and Commissioning"
          disciplineTargets={completions.disciplineTargets}
        />
      ) : null}
      {/* <LevelChart
        canvassId="level-canvass-role-discipline-specific"
        title="Role/Discipline Specific"
        disciplineTargets={technical[2].disciplineTargets}
      />
      <LevelChart
        canvassId="level-canvass-safety"
        title="Safety"
        disciplineTargets={technical[0].disciplineTargets}
      />
      <LevelChart
        canvassId="level-canvass-completion"
        title="Systems Completion and Commissioning"
        disciplineTargets={technical[1].disciplineTargets}
      />
      <StatisticsChart
        canvassId="stats-canvass-completion"
        title="Role/Discipline Specific"
        disciplineTargets={technical[2].disciplineTargets}
      /> */}
    </Grid>
  );
};

TechnicalAssessmentResults.propTypes = {};

export default withContext(TechnicalAssessmentResults);
