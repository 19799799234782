import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '8px',
  },
  selected: {
    border: '2px solid #084ab3',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
