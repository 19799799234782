import axios from 'axios';
import Swal from 'sweetalert2';
import { getCandidateAssignments } from './getCandidateAssignments';
import { getPassportRequest } from './getPassportRequest';

export function submitPassportRequest(
  { userId, candidateId, feedback },
  callback,
) {
  return (dispatch) => {
    axios
      .put(`/candidate/${candidateId}/passport-request`, {
        feedback,
      })
      .then((response) => {
        const { status } = response.data;
        if (status === 'SUCCESS') {
          let text;
          let title;
          let icon;
          switch (feedback) {
            case 0:
              title = 'Passport Requested';
              text =
                "Candidate's manager will consider your application. If accepted you will have full access to this passport. Until then you can view results anonymously.";
              icon = 'success';
              break;

            case 1:
              title = 'Passport Request Approved';
              text =
                'You have accepted this passport request. Your candidate may now access their full passport.';
              icon = 'success';
              break;

            case 2:
              title = 'Passport Request Rejected';
              text =
                "You have rejected this passport request.  Candidate's result can still be viewed anonymously.";
              icon = 'warning';
              break;

            default:
              break;
          }
          if (userId) {
            dispatch(getCandidateAssignments(userId));
            dispatch(getPassportRequest(userId));
          }
          Swal.fire({
            title,
            text,
            icon,
            confirmButtonColor: '#3f51b5',
          }).then(() => {
            if (callback) callback();
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
