import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function fetchAllProjects() {
  return (dispatch) => {
    axios
      .get(`/projects`)
      .then((response) => {
        const { status, projects } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setProjects(projects));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setProjects(projects) {
  return {
    type: ACTION_ITEMS.CANDIDATE_SEARCH.PROJECT.SET,
    payload: projects,
  };
}
