import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import Chart from 'chart.js';

const renderChart = ({ canvassId, topicGroups }) => {
  const labels = [];
  const datasets = [];
  const dataColors = [];
  topicGroups.forEach((topicGroup) => {
    topicGroup.topics.forEach((topic) => {
      labels.push(topic.name);
      datasets.push(parseFloat(topic.assessment.score));
      dataColors.push(topicGroup.graphColor);
    });
  });

  return new Chart(canvassId, {
    type: 'horizontalBar',
    data: {
      labels,
      datasets: [
        {
          data: datasets,
          backgroundColor: dataColors,
          borderColor: dataColors.map((e) => e.replace('.6', '1')),
          borderWidth: 1,
          fill: false,
          lineTension: 0,
          barThickness: 20,
        },
      ],
    },
    options: {
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              autoSkip: false,
            },
          },
        ],
        xAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: true,
              min: 0,
              stepSize: 0.5,
            },
          },
        ],
      },
    },
  });
};

const BarChart = ({ canvassId, title, topicGroups }) => {
  if (topicGroups.length > 0) {
    setTimeout(() => {
      renderChart({ canvassId, title, topicGroups });
    }, 0);
  }

  return (
    <Grid item xs={12}>
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      <div>
        <canvas id={canvassId} />
      </div>
    </Grid>
  );
};

BarChart.propTypes = {
  canvassId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  topicGroups: PropTypes.array.isRequired,
};

export default BarChart;
