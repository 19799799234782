import React, { useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Badge,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withContext } from 'common/helpers/context';
import {
  Done as DoneIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import UserTeam from './components/UserTeam';
import { updateTeamName } from '../../../../actions/updateTeamName';
import { deleteTeam } from '../../../../actions/deleteTeam';

const StyledBadge = withStyles(() => ({
  badge: {
    right: -20,
    top: 10,
    padding: '0 4px',
    backgroundColor: '#D0DDFE',
    color: '#3D54F9',
  },
}))(Badge);

const GRID_SIZE = 3;
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#E7EBFB',
  padding: GRID_SIZE,
  width: '100%',
  minHeight: 200,
});

const TeamItem = (props) => {
  const { team } = props;
  const { users } = team;
  const onDragEnd = () => {};
  const [editing, setEditing] = useState(false);
  const [teamName, setTeamName] = useState(team.name.toUpperCase());

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const onTextChange = (event) => {
    setTeamName(event.target.value.toUpperCase());
  };

  const callback = () => {
    setEditing(false);
  };

  const triggerUpdate = (teamId) => {
    props.updateTeamName(teamId, teamName, callback);
  };

  const triggerDelete = (teamId) => {
    Swal.fire({
      text: 'Are you sure you want to delete this team?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        props.deleteTeam(teamId);
      }
    });
  };

  const renderTeamName = (name, id) => {
    if (editing) {
      return (
        <Box mt={2} mb={3}>
          <TextField autoFocus value={name} onChange={onTextChange} />
          <DoneIcon onClick={() => triggerUpdate(id)} color="primary" />
          <CancelIcon onClick={toggleEditing} color="secondary" />
        </Box>
      );
    } else {
      return (
        <IconButton onClick={toggleEditing} color="inherit">
          <StyledBadge
            badgeContent={users.length - 1}
            color="primary"
            overlap="rectangular"
            showZero
          >
            <Typography variant="subtitle2" color="textSecondary">
              {name}
            </Typography>
          </StyledBadge>
        </IconButton>
      );
    }
  };

  return (
    <DragDropContext key={`team-list-${team.id}`} onDragEnd={onDragEnd}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          {renderTeamName(teamName, team.id)}
        </Grid>
        <Grid
          item
          xs={4}
          container
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          {team.id > 0 && (
            <Tooltip title={`Delete ${team.name}`} aria-label="delete">
              <DeleteIcon
                onClick={() => triggerDelete(team.id)}
                color="primary"
              />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Droppable droppableId={`list-${team.id}`}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <Grid container spacing={2}>
              {users.map((user, index) => (
                <UserTeam
                  key={user.id}
                  index={index}
                  team={team}
                  user={user}
                />
              ))}
              {provided.placeholder}
            </Grid>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateTeamName: (id, name, callback) =>
    dispatch(updateTeamName(id, name, callback)),
  deleteTeam: (id) => dispatch(deleteTeam(id)),
});

export default connect(null, mapDispatchToProps)(withContext(TeamItem));
