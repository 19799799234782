import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    justify: 'center',
  },
  buttonContainer: {
    textAlign: 'center',
    marginBottom: '50px',
  },
}));
