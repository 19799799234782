import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { Grid, Paper, Typography, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { withContext } from 'common/helpers/context';
import { LEVEL_IDS, RESULT_ACTUAL_LEVELS } from 'common/constants';
import { useCommonStyles } from 'common/common.style';
import AddFilterDialog from './components/AddFilterDialog/AddFilterDialog';
import MeasurementLabel from './components/MeasurementLabel';
import Background from './components/Background';

import SingleSlider from './components/SingleSlider';
import './SliderFilter.css';

const formatDecimal = (num) =>
  parseFloat((Math.round(num * 100) / 100).toFixed(2));

const DEFAULT_VALUES = {
  4: {
    lowest: '0',
    desired: '1',
    highest: '2',
  },
  7: {
    lowest: '1',
    desired: '2',
    highest: '3',
  },
  10: {
    lowest: '2',
    desired: '3',
    highest: '4',
  },
  13: {
    lowest: '2',
    desired: '3',
    highest: '4',
  },
};

const HASHMAP_LEVELS = {};
const HASHMAP_VALUES = {};
const LEVELS_VALUES = {};

LEVEL_IDS.forEach((maxLevel) => {
  let actualLevels = RESULT_ACTUAL_LEVELS.slice(
    0,
    LEVEL_IDS.indexOf(maxLevel) + 1,
  );

  const step = formatDecimal(100 / (actualLevels.length - 1));

  HASHMAP_LEVELS[maxLevel] = {};
  HASHMAP_VALUES[maxLevel] = {};

  LEVELS_VALUES[maxLevel] = RESULT_ACTUAL_LEVELS.map((label, index) => {
    const value = formatDecimal(index * step);
    const elem = { value, label };

    HASHMAP_LEVELS[maxLevel][label] = value;
    HASHMAP_VALUES[maxLevel][value] = label;

    return elem;
  }).reverse();
});

const SliderFilter = (props) => {
  const classes = useCommonStyles();

  const [, setCookie] = useCookies(['roleId', 'disciplineId', 'filters']);

  const [showAddFilterDialog, setShowAddFilterDialog] = useState(false);

  const { disciplineId, selectedFilters, setSelectedFilters } = props;

  useEffect(() => {
    const filteredFilters = selectedFilters.filter(
      (filter) =>
        disciplineId === 0 ||
        [1, 2, disciplineId].includes(filter.topic.disciplineId),
    );
    setSelectedFilters(filteredFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplineId]);

  useEffect(() => {
    // Set cookie
    setCookie('filters', selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedFilters)]);

  const displayFilters = () => {
    const filtersHtml = [];
    let visualIndex;

    selectedFilters.forEach((filter, i) => {
      const singleFilter = [];
      visualIndex = i + 1;

      singleFilter.topic = filter.topic;
      singleFilter.visualIndex = visualIndex;
      singleFilter.lowest = filter.lowest;
      singleFilter.desired = filter.desired;
      singleFilter.highest = filter.highest;
      const filterItem = createFilterHtml(singleFilter);
      filtersHtml.push(filterItem);
    });

    return filtersHtml;
  };

  const createFilterHtml = (filter) => {
    const {
      visualIndex,
      topic: { maxLevel },
    } = filter;

    const newScale = [
      HASHMAP_LEVELS[maxLevel][filter.lowest],
      HASHMAP_LEVELS[maxLevel][filter.desired],
      HASHMAP_LEVELS[maxLevel][filter.highest],
    ];

    return (
      <SingleSlider
        levels={LEVELS_VALUES[maxLevel]}
        key={`slider-${visualIndex}`}
        index={visualIndex}
        topic={filter.topic}
        filter={newScale}
        updateFilter={updateFilter}
        removeFilter={removeFilter}
      />
    );
  };

  const showDialog = () => {
    setShowAddFilterDialog(true);
  };

  const handleAddFilter = (topic) => {
    hideDialog();
    addFilter(topic);
  };

  const hideDialog = () => {
    setShowAddFilterDialog(false);
  };

  const addFilter = (topic) => {
    const newFilter = {
      topic,
      ...DEFAULT_VALUES[topic.maxLevel],
    };

    const newFilters = selectedFilters;
    newFilters.push(newFilter);

    setSelectedFilters(newFilters);
  };

  const updateFilter = (event, value, topicId) => {
    const [lowest, desired, highest] = value;
    const newFilters = selectedFilters.map((e) => {
      let item = { ...e };
      const { topic } = item;
      if (topic.id === topicId) {
        item = {
          ...item,
          lowest: HASHMAP_VALUES[topic.maxLevel][lowest],
          desired: HASHMAP_VALUES[topic.maxLevel][desired],
          highest: HASHMAP_VALUES[topic.maxLevel][highest],
        };
      }
      return item;
    });

    setSelectedFilters(newFilters);
  };

  const removeFilter = (event, topicId) =>
    setSelectedFilters(
      selectedFilters.filter((filter) => filter.topic.id !== topicId),
    );

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <div className="slider-filter">
          <Typography variant="subtitle2" color="textSecondary">
            {props.title.toUpperCase()}
          </Typography>

          <div className="chart-container">
            <MeasurementLabel levels={LEVELS_VALUES[13]} />

            <div className="chart">
              <div className="chart-content">
                <div className="filters">
                  {displayFilters()}

                  <div className="add-filter">
                    <Fab color="primary" aria-label="add" size="small">
                      <AddIcon onClick={showDialog} />
                    </Fab>
                  </div>
                </div>

                {showAddFilterDialog ? (
                  <AddFilterDialog
                    open={showAddFilterDialog}
                    onClose={hideDialog}
                    onAddTopic={handleAddFilter}
                  />
                ) : null}
              </div>

              <Background columns={11} rows={RESULT_ACTUAL_LEVELS.length} />
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

SliderFilter.propTypes = {
  selectedFilters: PropTypes.array.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
};

export default withContext(SliderFilter);
