import axios from 'axios';
import Swal from 'sweetalert2';
import { push } from 'connected-react-router';

export function submitAssessment(candidateId, isSelfSponsored, assessment) {
  return (dispatch) => {
    Swal.fire({
      title: 'Submitting self assessments...',
      html: 'Please wait.  This might take a few seconds.',
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    axios
      .post(`/self-assessment/${candidateId}`, { assessment })
      .then((response) => {
        Swal.close();
        const { status } = response.data;
        if (status === 'SUCCESS') {
          let options;
          if (isSelfSponsored) {
            options = {
              icon: 'success',
              title: 'You can now do one of the following:',
              html: `<ol style="text-align: left">
                 <li>Complete your psychometric evaluation</li>
                 <li>Book your safety and technical assessment</li>
                 <li>Log out and come back another time</li>
                 <li>Purchase/assign additional assessments</li>
               </ol>`,
            };
          } else {
            options = {
              icon: 'success',
              title: 'Success',
              text: `Please contact your sponsor to arrange a suitable time for your technical assessment.  Optionally, you may now complete your Psychometric Evaluation.`,
            };
          }

          Swal.fire(options).then(() => {
            dispatch(push('/psychometric'));
          });
        }
      })
      .catch((error) => {
        Swal.close();
        const { data } = error.response;
        if (data.status_code === 102) {
          Swal.fire({
            title: 'Error!',
            text: data.status_message,
            icon: 'error',
          }).then(() => {
            dispatch(push('/login'));
          });
        } else {
          console.error(error);
        }
      });
  };
}
