import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useCommonStyles } from 'common/common.style';
import { useStyles } from '../../PsychometricAssessmentResults.style';

const Description = () => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();

  return (
    <Grid item xs={12} className={classesCommon.noLefRightPadding}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              className={classes.subheader}
              align={isMobile ? 'center' : 'left'}
            >
              The results of your psychometric evaluation
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Please view this section for the results of your psychometric
              assessment. This section has been separated into three discrete
              areas:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ol>
              <li style={{ marginLeft: '15px' }}>
                <b>Task Responses</b>
              </li>
              <li style={{ marginLeft: '15px' }}>
                <b>Processing Responses</b> (inc. Information Processing)
              </li>
              <li style={{ marginLeft: '15px' }}>
                <b>Leadership</b> (inc. Working Conditions, Personality &amp;
                Willingness)
              </li>
              <li style={{ marginLeft: '15px' }}>
                <b>Summary of 8 topic areas</b> (Conflict Handling, Working
                Style, Self-Motivation, Work Responses, Team Attitude,
                Leadership Style, Helicopter View Level, Talking Action-Work
                Ethic)
              </li>
            </ol>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Description;
