import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Chart from 'chart.js';

const MAX_VALUE = 7;
const HEIGHT_PER_ROW = 30;
const LEGEND_HEIGHT_OFFSET = 40;

const getStatistics = (details) => {
  const statistics = {
    correctCount: 0,
    incorrectCount: 0,
    iDontKnowCount: 0,
    badAnswerCount: 0,
  };

  details.forEach((detail) => {
    if (typeof detail.isCorrect === 'number') {
      if (detail.isCorrect === 1) {
        statistics.correctCount += 1;
      } else {
        statistics.incorrectCount += 1;
        if (detail.isBadAnswer === 1) {
          statistics.badAnswerCount += 1;
        } else if (detail.answer === '-1') {
          statistics.iDontKnowCount += 1;
        }
      }
    }
  });

  return statistics;
};

const renderChart = ({ canvassId, disciplineTargets }) => {
  const labels = [];
  const answerStatistics = {
    correct: [],
    incorrect: [],
    iDontKnow: [],
    badAnswer: [],
  };

  disciplineTargets.forEach((topic) => {
    labels.push(topic.name);

    const statistics = getStatistics(topic.assessment[0].details);

    answerStatistics.correct.push(statistics.correctCount);
    answerStatistics.incorrect.push(
      statistics.incorrectCount - statistics.iDontKnowCount,
    );
    answerStatistics.iDontKnow.push(statistics.iDontKnowCount);
    answerStatistics.badAnswer.push(statistics.badAnswerCount);
  });

  // convert it to percentage
  const mapWithPercent = (value) => ((value / MAX_VALUE) * 100).toFixed(0);

  return new Chart(canvassId, {
    type: 'horizontalBar',
    data: {
      labels,
      datasets: [
        {
          stack: 1,
          label: 'Correct',
          data: answerStatistics.correct.map(mapWithPercent),
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
        {
          stack: 2,
          label: 'Incorrect',
          data: answerStatistics.incorrect.map(mapWithPercent),
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          stack: 2,
          label: "I don't know",
          data: answerStatistics.iDontKnow.map(mapWithPercent),
          backgroundColor: 'rgba(153, 102, 255, 0.6)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      maxBarThickness: 10,
      tooltips: {
        enabled: false,
        callbacks: {
          label: (tooltipItem, data) => {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const label = dataset.label || '';
            const value = dataset.data[tooltipItem.index] || '';
            return `${label}: ${value}%`;
          },
        },
      },
      legend: {
        maintainAspectRatio: false,
        labels: {
          boxWidth: 10,
        },
      },
      scales: {
        xAxes: [
          {
            barPercentage: 1,
            stacked: true,
            ticks: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              color: 'rgba(171,171,171,1)',
              lineWidth: 0.5,
            },
            ticks: {
              display: false, // this will remove only the label
            },
          },
        ],
      },
    },
  });
};

const HorizontalStatisticsChart = ({ canvassId, disciplineTargets }) => {
  setTimeout(() => {
    renderChart({ canvassId, disciplineTargets });
  }, 0);

  const height = `${
    disciplineTargets.length * HEIGHT_PER_ROW + LEGEND_HEIGHT_OFFSET
  }px`;

  return (
    <Grid item xs={6}>
      <div style={{ position: 'relative', height, marginTop: '-31px' }}>
        <canvas id={canvassId} />
      </div>
    </Grid>
  );
};

HorizontalStatisticsChart.propTypes = {
  canvassId: PropTypes.string.isRequired,
  disciplineTargets: PropTypes.array.isRequired,
};

export default HorizontalStatisticsChart;
