import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Button, IconButton, Link, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { withContext } from 'common/helpers/context';
import {
  PermissionsModalContext,
  RequestsModalContext,
  ShareModalContext,
} from 'common/context';
import { removeFromGroup } from '../Groups/actions/removeFromGroup';

const ActionMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    handleSetSelectCandidate: handleSetSelectCandidateRequests,
    handleShowRequestsModal,
  } = useContext(RequestsModalContext);
  const {
    handleSetSelectCandidate: handleSetSelectCandidatePermissions,
    handleShowPermissionsModal,
  } = useContext(PermissionsModalContext);
  const {
    handleSetSelectCandidate: handleSetSelectCandidateShare,
    handleShowShareModal,
  } = useContext(ShareModalContext);
  const { user, candidate, groupId } = props;
  const selectedUser = user || candidate;

  if (!selectedUser) return null;

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleClickAction = (event) => {
    stopPropagation(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickViewRequests = (event) => {
    stopPropagation(event);
    handleClose();
    handleSetSelectCandidateRequests(selectedUser);
    handleShowRequestsModal();
  };

  const handleClickManagePerms = (event) => {
    stopPropagation(event);
    handleClose();
    handleSetSelectCandidatePermissions(selectedUser);
    handleShowPermissionsModal();
  };

  const handleClickShare = (event) => {
    stopPropagation(event);
    handleClose();
    handleSetSelectCandidateShare(selectedUser);
    handleShowShareModal();
  };

  const handleRemoveClick = (candidateId) => {
    props.removeFromGroup(groupId, candidateId, handleClose);
  };

  const renderRemoveMember = () => {
    if (groupId) {
      return (
        <MenuItem>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              handleRemoveClick(selectedUser.id);
            }}
            fullWidth
          >
            Remove from group
          </Button>
        </MenuItem>
      );
    }

    return null;
  };
  const { doneTechAssessment, doneCompetasDueDilligence } = selectedUser.flags;

  return (
    <>
      <IconButton onClick={handleClickAction}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!doneTechAssessment && !doneCompetasDueDilligence && (
          <MenuItem>
            <Button variant="contained" disabled>
              Awaiting Assessment
            </Button>
          </MenuItem>
        )}
        {doneTechAssessment && !doneCompetasDueDilligence && (
          <MenuItem>
            <Button variant="contained" disabled>
              Awaiting Assessment
            </Button>
          </MenuItem>
        )}
        {doneTechAssessment && doneCompetasDueDilligence && (
          <MenuItem>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/passport-anon/${selectedUser.id}/1`}
              target="_blank"
              rel="noopener noreferrer"
              fullWidth
            >
              Anonymous Results
            </Button>
          </MenuItem>
        )}
        {doneTechAssessment && doneCompetasDueDilligence && (
          <MenuItem>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/passport/${selectedUser.id}`}
              target="_blank"
              rel="noopener noreferrer"
              fullWidth
            >
              View Passport
            </Button>
          </MenuItem>
        )}
        <MenuItem onClick={handleClickViewRequests}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickViewRequests}
            fullWidth
          >
            View Requests
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClickManagePerms}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickManagePerms}
            fullWidth
          >
            Manage Permissions
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickShare}
            fullWidth
          >
            Share
          </Button>
        </MenuItem>
        {renderRemoveMember()}
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state.teamViewer.selectedCandidate };
};

export default connect(mapStateToProps, {
  removeFromGroup,
})(withContext(ActionMenu));
