import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(() => ({
  label: {
    paddingLeft: 0,
    marginLeft: 0,
  },
  helpText: {
    margin: 0,
    padding: 0,
    color: '#8798AD',
    position: 'relative',
    top: -11,
  },
}));
