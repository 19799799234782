import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  button: {
    height: '100%',
  },
  buttonGroup: {
    display: 'flex',
    flex: '',
    gap: 20,
    justifyContent: 'space-around',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
