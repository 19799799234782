import React from 'react';
import { Grid } from '@material-ui/core';
import logo from 'assets/images/logoDarkBgWhiteFont.png';

import { useStyles } from './FooterLogo.style';

const FooterLogo = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.logoWrapper}>
      <img alt="logo" src={logo} className={classes.logo} />
    </Grid>
  );
};

export default FooterLogo;
