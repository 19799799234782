const ACTION_ITEMS = {
  CANDIDATE_SEARCH: {
    FILTERS: {
      SET: 'CANDIDATE_SEARCH_FILTERS_SET',
    },
    RESULTS: {
      SET: 'CANDIDATE_SEARCH_RESULTS_SET',
    },
    PROJECT: {
      SET: 'CANDIDATE_SEARCH_PROJECT_SET',
    },
    POSITION: {
      SET: 'CANDIDATE_SEARCH_POSITION_SET',
    },
    SKILL: {
      SET: 'CANDIDATE_SEARCH_SKILL_SET',
    },
  },
};

export default ACTION_ITEMS;
