import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '2rem 1rem 2rem 1rem',
  },
  questionContainer: {
    backgroundColor: '#0054F8 !important',
    padding: '1rem 1rem 1rem 1rem',
  },
  questionTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'white',
  },
  questionOption: {
    fontWeight: 'bold',
    padding: '0 4rem 1rem 4rem',
  },
  questionInput: {
    maxWidth: '40px',
    minWidth: '40px',
    margin: '0 10px 10px 10px',
    cursor: 'pointer',
  },
}));
