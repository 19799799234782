import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '0.5rem',
  },
  level: {
    border: 0,
    alignSelf: 'center',
    display: 'block',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold',
  },
  level0: {
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.levels.self,
    },
  },
  level1: {
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.levels.self,
    },
  },
  level2: {
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.levels.self,
    },
  },
  level3: {
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.levels.self,
    },
  },
  level4: {
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.levels.self,
    },
  },
  level5: {
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.levels.self,
    },
  },
  level0Selected: {
    color: 'white',
    backgroundColor: theme.palette.levels.self,
  },
  level1Selected: {
    color: 'white',
    backgroundColor: theme.palette.levels.self,
  },
  level2Selected: {
    color: 'white',
    backgroundColor: theme.palette.levels.self,
  },
  level3Selected: {
    color: 'white',
    backgroundColor: theme.palette.levels.self,
  },
  level4Selected: {
    color: 'white',
    backgroundColor: theme.palette.levels.self,
  },
  level5Selected: {
    color: 'white',
    backgroundColor: theme.palette.levels.self,
  },
}));
