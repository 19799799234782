import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, FormControl, TextField } from '@material-ui/core';

const CustomTextField = ({
  id,
  labelText,
  type,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  autoFocus,
  disabled,
  gridSize,
  InputProps,
}) => {
  const errorMessage = errors[id];
  const hasError =
    !_.isNil(errorMessage) && !_.isNil(touched[id]) && touched[id];

  return (
    <Grid item xs={gridSize}>
      <FormControl fullWidth error={hasError}>
        <TextField
          disabled={disabled}
          autoFocus={autoFocus}
          id={id}
          variant="outlined"
          label={labelText}
          type={type}
          value={values[id]}
          onChange={handleChange ? handleChange(id) : null}
          onBlur={handleBlur(id)}
          InputLabelProps={{
            shrink: true,
          }}
          error={hasError}
          helperText={hasError ? errorMessage : null}
          InputProps={InputProps}
          fullWidth
        />
      </FormControl>
    </Grid>
  );
};

CustomTextField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  type: PropTypes.string,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  gridSize: PropTypes.number,
  InputProps: PropTypes.object,
};

CustomTextField.defaultProps = {
  disabled: false,
  autoFocus: false,
  gridSize: 6,
  type: 'text',
  handleChange: null,
  InputProps: null,
};

export default CustomTextField;
