/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Grid, Typography } from '@material-ui/core';
import TokenTable from './components/TokenTable';

const ID_UNASSIGNED = 'unassigned';
const ID_ASSIGNED = 'assigned';
const ID_READY = 'ready';
const ID_ASSESSED = 'assessed';

const TokenAssignmentTabular = (props) => {
  const [unassignedTokens, setUnassignedTokens] = useState([]);
  const [assignedTokens, setAssignedTokens] = useState([]);
  const [readyTokens, setReadyTokens] = useState([]);
  const [assessedTokens, setAssessedTokens] = useState([]);

  const { forwardedRef, roleType, tokens, refresh } = props;
  useEffect(() => {
    const unassigned = [];
    const assigned = [];
    const ready = [];
    const assessed = [];

    tokens.forEach((token) => {
      const { assignment } = token;
      if (_.isNil(assignment)) {
        unassigned.push(token);
      } else {
        const { status } = assignment;
        switch (status) {
          case 1:
            assigned.push(token);
            break;

          case 2:
            ready.push(token);
            break;

          case 3:
            assessed.push(token);
            break;

          default:
            break;
        }
      }
    });

    setUnassignedTokens(unassigned);
    setAssignedTokens(assigned);
    setReadyTokens(ready);
    setAssessedTokens(assessed);
  }, [tokens]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid item xs={12} ref={forwardedRef}>
      <Typography variant="h6">
        {roleType === 1 ? 'Technician' : 'Engineer'} Token Status
      </Typography>
      <Box mb={2} />
      <Grid container>
        <Grid item xs={12}>
          <TokenTable
            listId={ID_UNASSIGNED}
            tokens={unassignedTokens}
            refresh={refresh}
          />
        </Grid>
        <Grid item xs={12}>
          <TokenTable
            listId={ID_ASSIGNED}
            tokens={assignedTokens}
            refresh={refresh}
          />
        </Grid>
        <Grid item xs={12}>
          <TokenTable
            listId={ID_READY}
            tokens={readyTokens}
            refresh={refresh}
          />
        </Grid>
        <Grid item xs={12}>
          <TokenTable
            listId={ID_ASSESSED}
            tokens={assessedTokens}
            refresh={refresh}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

TokenAssignmentTabular.propTypes = {
  forwardedRef: PropTypes.object.isRequired,
  roleType: PropTypes.number.isRequired,
  tokens: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default TokenAssignmentTabular;
