import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  permissions: {
    marginBottom: 30,
    height: '95%',
  },
  permissionsContainer: {
    height: '100%',
  },
}));
