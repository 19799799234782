import ACTION_ITEMS from './actionItems';

const initialState = {
  candidate: null,
  technical: {
    safety: null,
    completions: null,
    roleSpecific: null,
  },
  psychometric: {},
};

export const passportReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case ACTION_ITEMS.PASSPORT.RESULTS.SET: {
      newState = {
        ...state,
        ...action.payload,
      };
      break;
    }

    default:
      newState = state;
      break;
  }

  return newState;
};
