import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function getCandidateAssignments(userId) {
  return (dispatch) => {
    axios
      .get(`/candidate/${userId}/assignments`)
      .then((response) => {
        const { status, assignments } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setAssignments(assignments));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setAssignments(assignments) {
  return {
    type: ACTION_ITEMS.CANDIDATE_PORTAL.ASSIGNMENTS.SET,
    payload: { assignments },
  };
}
