import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxHeight: '80vh',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));
