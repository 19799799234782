import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { push } from 'connected-react-router';
import ACTION_ITEMS from '../../../actionItems';
import { getPsychAssessments } from '../../../actions/getPsychAssessments';

export const postPsychAnswer = (
  userId,
  candidateId,
  questionId,
  questionType,
  answers,
  callback,
) => async (dispatch) => {
  Swal.fire({
    title: 'processing...',
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });

  try {
    const response = await axios.post(`/psychometric/${candidateId}/answer`, {
      questionId,
      questionType,
      answers,
    });
    Swal.close();
    const { question, count } = response.data;

    if (_.isNil(question)) {
      Swal.fire({
        title: 'Success',
        text: `You've finished your Psychometric Evaluation. Your Sponsor will now be notified, and will  contact you to arrange a suitable time to sit your Competas Technical Assessment. Your progress is saved, it is safe to log out.`,
        icon: 'success',
        confirmButtonColor: '#3f51b5',
      }).then(() => {
        dispatch(getPsychAssessments(userId));
        dispatch(push('/psychometric'));
      });
    } else {
      dispatch({
        type: ACTION_ITEMS.PSYCHOMETRIC.QUESTION.SET,
        payload: {
          question,
          count,
        },
      });

      if (callback) callback();
    }
  } catch (error) {
    Swal.close();
    if (callback) callback();

    if (error.message === 'Network Error') {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
      });
    }

    if (error.response && error.response.data) {
      Swal.fire({
        title: 'Error',
        text: error.response.data.status_message,
        icon: 'error',
      });
    } else {
      console.error(error);
    }
  }
};
