import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import Legends from './components/Legends';
import AreaOfExpertise from './components/AreaOfExpertise';
import { getDisciplineTargets } from './actions/getDisciplineTargets';
import resource from './SelfAssessment.resource';

const SelfAssessment = (props) => {
  const candidateId = parseInt(props.match.params.candidateId, 10);

  useEffect(() => {
    props.getDisciplineTargets(candidateId);
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5">
          {resource.TITLE}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="p" variant="subtitle2">
              The topics listed below are based on the discipline and role that
              has been selected. There are six self-assessment levels which are
              explained in detail below.
            </Typography>
            <br />
            <Typography component="p" variant="subtitle2">
              Please ensure you complete the self-assessment accurately and
              honestly
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Legends />
      </Grid>
      <Grid item xs={12}>
        <AreaOfExpertise candidateId={candidateId} />
      </Grid>
    </Grid>
  );
};

SelfAssessment.propTypes = {
  getDisciplineTargets: PropTypes.func.isRequired,
};

SelfAssessment.helperInfo = [
  {
    title: resource.HELPER_SECTION[0].TITLE,
    image: resource.HELPER_SECTION[0].IMAGE,
  },
  {
    title: resource.HELPER_SECTION[1].TITLE,
    image: resource.HELPER_SECTION[1].IMAGE,
  },
];

const mapDispatchToProps = (dispatch) => ({
  getDisciplineTargets: (candidateId) =>
    dispatch(getDisciplineTargets(candidateId)),
});

export default withRouter(connect(null, mapDispatchToProps)(SelfAssessment));
