import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Layers as LayersIcon } from '@material-ui/icons';

const PassportDashboardItem = ({ show, disabled, selected, onClick }) => {
  if (!show) return null;

  const handleClick = () => {
    onClick(PassportDashboardItem.pathname);
  };

  return (
    <ListItem
      button
      selected={selected}
      onClick={handleClick}
      disabled={disabled}
    >
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Passport Requests Dashboard" />
    </ListItem>
  );
};

PassportDashboardItem.propTypes = {
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

PassportDashboardItem.defaultProps = {
  show: true,
  disabled: false,
  selected: false,
};

PassportDashboardItem.pathname = '/passport-dashboard';

export default PassportDashboardItem;
