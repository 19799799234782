import axios from 'axios';
import ACTION_ITEMS from '../actionItems';

export function getOptions() {
  return (dispatch) => {
    axios
      .get(`/candidate/options`)
      .then((response) => {
        const { status, options } = response.data;
        if (status === 'SUCCESS') {
          dispatch(setOptions(options));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function setOptions(options) {
  return {
    type: ACTION_ITEMS.OPTIONS.SET,
    payload: { options },
  };
}
